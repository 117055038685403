import { trackPromise } from 'react-promise-tracker';
import ApiProvider from '../providers/apiProvider';
import { IFilter, IJoin, IOrder, ISensor } from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Service to work with sensor API resources
 *
 * @class SensorService
 */
export class SensorService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {String}
     */
    get urlPattern(): string {

        return '/sensors(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return ['name', 'comment'];
    }

    /**
     * Get list of users
     *
     * @params {Object} params Query params
     *
     * @return {Promise<Object>}
     */
    list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<AxiosResponse> {

        return this.http
            .get(this.url(), {
                params: this.prepareListParams(search, order, join, filter),
                headers: this.headers,
            });
    }

    /**
     * Update sensor
     *
     * @param {ISensor} sensor
     *
     * @return {Promise<Object>}
     */
    update(sensor: ISensor): Promise<AxiosResponse> {

        return this.http
            .patch(this.url({ id: sensor.id }), sensor, {
                headers: this.headers,
            });
    }
}
