import React, { FC } from 'react';
import { useMainSocket } from '../../hooks/socket/useMainSocket';
import DashboardTimer from '../../components/DashboardTimer/DashboardTimer';
import { useRealTimeSensorDataSocket } from '../../hooks/socket/useRealTimeSensorDataSocket';

interface IProps {
    children: React.ReactNode
}

const SocketProvider: FC<IProps> = ({ children }: IProps) => {
    useMainSocket();
    useRealTimeSensorDataSocket();
    return (

        <DashboardTimer>
            {children}
        </DashboardTimer>
    );
};

export default SocketProvider;
