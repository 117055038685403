import { trackPromise } from 'react-promise-tracker';
import ApiProvider from '../providers/apiProvider';
import { IOrder, IUser } from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Service to work with user API resources
 *
 * @class UserService
 */
export class UserService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/users(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return ['username', 'email'];
    }

    /**
     * Get list of users
     *
     * @params {string} search A search string
     * @params {IOrder} order An order params
     *
     * @return {Promise<Object>}
     */
    list(search: string, order: IOrder): Promise<AxiosResponse> {

        return this.http
            .get(this.url(), {
                params: this.prepareListParams(search, order),
                headers: this.headers,
            });
    }

    /**
     * Create new user account
     *
     * @param {IUser} user
     *
     * @return {Promise<Object>}
     */
    store(user: IUser): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .post(this.url(), user, {
                    headers: this.headers,
                })
        );
    }

    /**
     * Update user account
     *
     * @param {IUser} user
     *
     * @return {Promise<Object>}
     */
    update(user: IUser): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .patch(this.url({ id: user.id }), user, {
                    headers: this.headers,
                })
        );
    }

    /**
     * Remove user by ID
     *
     * @param {number} userId
     *
     * @return {Promise<Object>}
     */
    remove(userId: number): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .delete(this.url({ id: userId }), {
                    headers: this.headers,
                })
        );
    }
}
