import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { DrawerProps } from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import CodeIcon from '@material-ui/icons/Code';
import { makeStyles } from '@material-ui/core/styles';
import { LayoutActions } from '../../../actions/layout';
import {
    selectDrawerIsResize, selectDrawerLastMousePosition,
    selectDrawerWidth, selectIsFullScreenDrawer,
    selectPositionDrawer,
} from '../../../selectors/layout/responsiveDrawerSelector';

import './styles/ResponsiveDrawer.scss';
import { selectMaxWidthSideBar } from '../../../selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import { fullBrowserVersion, isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { getWidthFromMousePosition } from '../../../functions/getWidthFromMousePosition';
import { selectMousePosition } from '../../../selectors/mouse/mouseSelector';
import { selectMinimapVisibility } from '../../../selectors/graphMinimapVisibility/graphMinimapVisibilitySelector';
import { getHeightFromMousePosition } from '../../../functions/getHeightFromMousePosition';

interface IProps {
    defaultWidth: number;
    currentRef: (instance: HTMLElement | null) => void;
}

const useStyles = makeStyles((isFullScreen: boolean) => ({
    drawerPaper: {
        marginTop: '80px',
        padding: '10px',
        overflow: 'visible',
    },
    fullWidth: {
        width: 'auto',
        margin: `${isFullScreen && fullBrowserVersion ? 0 : -20}px -10px 0`,
        overflow: 'hidden',
        display: 'block',
        height: '100%',
    },
}));

/**
 * Responsive drawer component
 *
 * @param {number} defaultWidth
 * @param {string} anchor
 * @param {boolean} open
 * @param {function} onClose
 * @param {any} children
 * @param {function} startResize
 *
 * @param currentRef
 * @return {JSX.Element}
 *
 * @constructor
 */
const ResponsiveDrawer: FC<IProps & DrawerProps> = (
    {
        defaultWidth,
        anchor,
        open,
        onClose,
        children,
        currentRef,
    }: IProps & DrawerProps,
) => {

    const dispatch = useDispatch();

    const isResize = useSelector(selectDrawerIsResize);
    const mouse = useSelector(selectMousePosition);
    const drWidth = useSelector(selectDrawerWidth);
    const width = isResize? getWidthFromMousePosition(mouse): (drWidth|| defaultWidth);
    // const drawerParamHeight = useSelector(selectDrawerHeight);
    const isFullScreen = useSelector(selectIsFullScreenDrawer);
    const positionDrawer = useSelector(selectPositionDrawer);
    const barWidth = useSelector(selectMaxWidthSideBar);

    // Removed the calculation of the height from the selector.
    const lastMousePosition = useSelector(selectDrawerLastMousePosition),
        visible = useSelector(selectMinimapVisibility);

    const [touchEvent, setTouchEvent] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [startTouchEvent, setStartTouchEvent] = useState(false);
    const drawerParamHeightTest = getHeightFromMousePosition((isResize ? startTouchEvent? touchEvent: mouse : lastMousePosition), visible);

    /**
     * Handle mouse down event on resize button
     */
    const handleMouseDown = useCallback((event: React.MouseEvent)  => {

            if (event) {

                dispatch(LayoutActions.drawerStopResize(event as unknown as MouseEvent));
            }

            if (!isResize) {

                dispatch(LayoutActions.drawerStartResize());
            }

        setStartTouchEvent(true);


    }, [dispatch, isResize, setStartTouchEvent]);


    /**
     * Handle mouse up event on resize button
     */
    const handleMouseUp = useCallback((event: MouseEvent) => {

            dispatch(LayoutActions.drawerStopResize(event));

    }, [dispatch]);

    /**
     * Handle mouse up event on resize button
     */
    const handleTouchEnd = useCallback((event: TouchEvent)=> {

        dispatch(LayoutActions.drawerStopResizeTouchEnd(event));

    }, [dispatch]);

    useEffect(() => {


        if (isResize) {

            window.addEventListener('mouseup', handleMouseUp);

            if ('ontouchstart' in window) {

                window.addEventListener('touchend', handleTouchEnd);
            }

        } else {

            window.removeEventListener('mouseup', handleMouseUp);

            if ('ontouchstart' in window) {

                window.removeEventListener('touchend', handleTouchEnd);
            }
        }

        return () => {

            window.removeEventListener('mouseup', handleMouseUp);

            if ('ontouchstart' in window) {

                window.removeEventListener('touchend', handleTouchEnd);
            }
        };

    }, [isResize, handleMouseUp, handleTouchEnd]);

    /**
     * On Touch start
     * @type {(event: React.TouchEvent) => void}
     */
    const onTouchStart = useCallback((event: React.TouchEvent) => {

        setStartTouchEvent(true);

        dispatch(LayoutActions.drawerStopResize(event as unknown as TouchEvent));

        if (!isResize) {

            setTouchEvent({
                x: (event as unknown as TouchEvent).touches[0].clientX,
                y: (event as unknown as TouchEvent).touches[0].clientY,
            });

            dispatch(LayoutActions.drawerStartResize());
        }

    }, [dispatch, isResize, setTouchEvent]);

    /**
     * On Mouse leave
     *
     * @type {() => void}
     */
    const onMouseLeave = useCallback(
        () => {

            setStartTouchEvent(false);
        },
        [setStartTouchEvent],
    );

    /**
     * On Mouse move
     * @type {(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void}
     */
    const onMouseMove = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

            setTouchEvent({
                x: event.clientX,
                y: event.clientY,
            });
        },
        [setTouchEvent],
    );

    /**
     * On Touch move
     *
     * @type {(event: React.TouchEvent<HTMLButtonElement>) => void}
     */
    const onTouchMove = useCallback(
        (event: React.TouchEvent<HTMLButtonElement>) => {
            setTouchEvent({
                x: event.changedTouches[0].clientX,
                y: event.changedTouches[0].clientY,
            });
        },
        [setTouchEvent],
    );


    // /**
    //  * Chek landscape.
    //  *
    //  * @return {boolean}
    //  */
    // const isLandscape = () => {
    //
    //     return (window.orientation === 90 || window.orientation === -90);
    // };

    const classes = useStyles(isFullScreen);
    // const { body: { offsetHeight } } = document;
    const paperClass = { paper: classes.drawerPaper };

    const positionDrawerRightStyle: React.CSSProperties = {
            width: isResize ? getWidthFromMousePosition(startTouchEvent? touchEvent: mouse) : width,
            pointerEvents: 'all',
            maxWidth: `calc(100% - ${barWidth}px)`,
            height: `calc(100% - ${isTablet ? 70 : 0}px)`,
        },
        positionDrawerBottomStyle: React.CSSProperties = {
            height: drawerParamHeightTest.height,
            marginTop: drawerParamHeightTest.margin,
            width: `calc(100% - ${barWidth}px)`,
            pointerEvents: 'all',
        },
        paperPropsFullScreenStyle: React.CSSProperties = {
            height: '100%',
            // height: offsetHeight || '100%',
            width: '100%',
            minHeight: '100%',
            minWidth: '100%',
            pointerEvents: 'all',
            marginTop: 0,
        };

    const paperPropsStyle: React.CSSProperties = positionDrawer === 'right' ?
            positionDrawerRightStyle : positionDrawerBottomStyle;

    /**
     * Stop propagation
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const stopPropagation = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.stopPropagation();
    }, []);

    return (
        <Drawer
            variant="persistent"
            anchor={anchor}
            open={open}
            onClose={onClose}
            className={`hmi-drawer-${positionDrawer}`}
            classes={paperClass}
            BackdropProps={
                { invisible: true }
            }
            ModalProps={
                { keepMounted: false, style: { pointerEvents: 'none' } }
            }
            PaperProps={
                { style: isFullScreen? paperPropsFullScreenStyle:paperPropsStyle }
            }
            ref={instance => currentRef(instance as HTMLElement | null)}
            onClick={stopPropagation}
        >
            {!isFullScreen ?
                <Fab
                    onMouseDown={handleMouseDown}
                    onTouchStart={onTouchStart}
                    onMouseLeave={onMouseLeave}
                    onMouseMove={onMouseMove}
                    onTouchMove={onTouchMove}
                    size="small"
                    className={`drawer ${positionDrawer}`}
                    disableRipple
                >
                    <CodeIcon />
                </Fab>
                : null
            }
            <div
                role="presentation"
                className={`${classes.fullWidth} ${isFullScreen ? 'full-screen-drawer' : ''} ${isMobileOnly ? 'only-mobile' : ''} ${isMobile ? 'mobile' : ''}`}
                onClick={stopPropagation}
            >
                {children}
            </div>
        </Drawer>
    );
};


export default React.memo(ResponsiveDrawer);
