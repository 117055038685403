import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    // IAlertSidebarProps,
    // IAlertSidebarState,
    INotification,
} from '../../core/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { NotificationAction, statesActions } from '../../core/actions';
import {
    AutoSizer,
} from 'react-virtualized';
import { GraphActions } from '../../base/store/actions';

import './AlertSidebar.scss';
import * as d3 from 'd3';
import {
    calcRealTimeIndentation,
    selectBrushSelection,
} from '../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectScreenWidth } from '../../core/selectors/dashboard/dashboardSelector';
import { selectAllNotification, selectNotificationCount } from '../../core/selectors/notification/notificationSelector';
import moment from 'moment';
import DynamicList, { createCache } from 'react-window-dynamic-list';
import { selectRBAC } from '../../core/selectors/auth/authSelector';
import AlertItemForm from './AlertItemForm';
import { selectGraphPeriodRange } from '../../core/selectors/graphPeriod/graphPeriodSelector';
import {
    selectGraphSelectionAlert,
    selectGraphSelectionAlertHover,
} from '../../core/selectors/graphSelection/graphSelectionSelector';
import { appConfig } from '../../config/appConfig';

const AlertSidebar: React.FC<any> = (...props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [list, setList] = useState<INotification[]>([]);
    const [notificationSelect, setNotificationSelect] = useState<INotification | undefined>(undefined);
    const [showCommentInput, setShowCommentInput] = useState<number | undefined>(undefined);
    const [selectedAlert, setSelectedAlert] = useState<number | undefined>(undefined);
    const [scrollToIndex, setScrollToIndex] = useState<number | undefined>(undefined);

    const dynamicListRef: React.MutableRefObject<import('react-window').VariableSizeList<any> | undefined> | undefined = useRef(undefined);
    const cacheDC = createCache();
    const scaleTime = d3.scaleTime();

    const unRidNotification = useSelector(selectNotificationCount),
        selection = useSelector(selectGraphPeriodRange),
        rbac = useSelector(selectRBAC),
        notification = useSelector(selectAllNotification),
        selectionRange = useSelector(selectBrushSelection),
        realTimeIndentation = useSelector(calcRealTimeIndentation),
        screenWidth = useSelector(selectScreenWidth)
            - realTimeIndentation - appConfig.correctionFactorForDrawingTheTimeline,
        notificationSelectState = useSelector(selectGraphSelectionAlert);

    useEffect(() => {

        if (!selection && list.length === 0) {

            dispatch(NotificationAction.list(moment().subtract({ d: 1 }).toDate(), new Date()));

        } else if (selection) {

            dispatch(NotificationAction.list(selection.startDate, selection.endDate));

        }

        return () => {

            dispatch(GraphActions.unHoveredAlertGraph());
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selection]);

    useEffect(() => {

        if (list !== notification) {

            setList(notification);
            if (!showCommentInput) {

                dynamicListRef.current && dynamicListRef.current.resetAfterIndex(0, true);

            }

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification, setList]);

    useEffect(() => {

        if (notificationSelectState && notificationSelectState.id !== notificationSelect?.id) {

            setScrollToIndex(list.findIndex(value => value.id === notificationSelectState.id));
            setSelectedAlert(notificationSelectState.id);
        }

        if (notificationSelect && !notificationSelectState) {
            setSelectedAlert(undefined);
            setShowCommentInput(undefined);
        }

    }, [notificationSelectState, setSelectedAlert, setShowCommentInput, setScrollToIndex]);

    const markAllAsRead = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        dispatch(NotificationAction.markAllAsReadAction());

    }, [dispatch]);


    useEffect(() => {

        if (scrollToIndex && dynamicListRef?.current && !showCommentInput) {

            // setTimeout(() => {
            scrollToIndex && dynamicListRef.current.scrollToItem(scrollToIndex, 'start');
            // }, 0);
        }

    }, [scrollToIndex, dynamicListRef, showCommentInput]);

    const operands = {
        '=': '=',
        '!=': '≠',
        '>': '>',
        '<': '<',
        '>=': '≥',
        '<=': '≤',
    };

    /**
     * Click handler
     *
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event
     * @param {number} index
     */
    const handleListItemClick =(
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedAlert(index);

    };

    /**
     * Row render click handler
     *
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event
     */
    const renderRowClickHandler = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        const index = event.currentTarget.tabIndex;

        handleListItemClick(event, list[index]?.id || 0);

        if (showCommentInput && showCommentInput !== list[index].id) {

            setShowCommentInput(undefined);
        }

        if (list[index]?.isNew && list[index].id) {

            dispatch(NotificationAction.markAsReadAction({ ...list[index], isNew: false }));
        }

        if (!showCommentInput || showCommentInput !== notificationSelect?.id) {

            dispatch(GraphActions.unHoveredAlertGraph());
            dispatch(statesActions.deselectAllStates());
        }

        if (selectionRange && list[index] && !showCommentInput) {

            scaleTime.range([0, screenWidth])
                .domain(selectionRange);

            const x1 = scaleTime(new Date(list[index].startTime)),
                x2 = scaleTime(new Date(list[index].endTime || selectionRange[1]));
            const width = (x2) - (x1);

            if (list[index]?.id || 0) {

                dispatch(GraphActions.selectAlertGraph({ ...list[index], isNew: false }, {
                    left: x1,
                    width: width,
                }));
            }
        }

        setScrollToIndex(undefined);
        list[index] && setNotificationSelect(list[index]);


    }, [
        dispatch,
        list,
        notificationSelect?.id,
        scaleTime,
        screenWidth,
        selectionRange,
        showCommentInput,
    ]);

    /**
     * On Mouse Enter Row
     *
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event
     */
    const onMouseEnterRow = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        const index = event.currentTarget.tabIndex;
        //
        const notification: INotification = list[index];

        if (selectedAlert !== notification?.id || !selectedAlert || notification) {

            if (selectionRange) {
                scaleTime.range([0, screenWidth])
                    .domain(selectionRange);
                const [from, to] = selectionRange,
                    x1CorrectTime = new Date(notification.startTime).getTime() < new Date(from).getTime() ?
                        new Date(from) : new Date(notification.startTime);

                const x1 = scaleTime(x1CorrectTime),
                    x2 = scaleTime(new Date(notification.endTime || to));
                const width = (x2) - (x1);

                dispatch(GraphActions.hoveredAlertGraph(notification, {
                    left: x1,
                    width: width,
                }));
            }

            setScrollToIndex(undefined);
        }

    }, [
        dispatch,
        list,
        scaleTime,
        screenWidth,
        selectedAlert,
        selectionRange,
    ]);

    /**
     * On Mouse Leave from body
     *
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event
     */
    const onMouseLeaveBody=useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>)=> {

        event.preventDefault();
        // setNotificationHover(undefined);
        // setNotificationSelect(undefined);

    }, []);

    /**
     * On mouse leave side bar.
     */
    const onMouseLeaveSideBar = useCallback(() => {

        dispatch(GraphActions.unHoveredAlertGraph());

        dispatch(statesActions.deselectAllStates());

    }, [dispatch]);

    /**
     * Added reset for selected edit item.
     *
     * @param props
     */
    const onScrollResetFunction = useCallback((props: any) => {

        if (showCommentInput) {

            setShowCommentInput(undefined);

        }
    }, [showCommentInput, setShowCommentInput]);

    /**
     * Update comment action
     * @type {(data: INotification) => void}
     */
    const updateComment = useCallback((data: INotification) => {

        dispatch(NotificationAction.updateCommentAction(data));

    }, [dispatch]);

    /**
     * Set Show Component Input
     *
     * @param {number | undefined} itemId
     */
    const setShowCommentInputCallback = useCallback((itemId: number | undefined) => {

        setShowCommentInput(itemId);

        if (!itemId) {
            const resetAfterIndexTimeout = setTimeout(() => {

                const indexArr = list.findIndex(value => value.id === itemId);

                dynamicListRef.current && dynamicListRef.current.resetAfterIndex(indexArr > -1 ? indexArr : 0, true);

                clearTimeout(resetAfterIndexTimeout);

            }, 20);

        }
    }, [setShowCommentInput, dynamicListRef, list]);

    return (
        <React.Fragment>
            <div
                className="wrap-alert-sidebar"
                onMouseLeave={onMouseLeaveSideBar}
            >
                <div className="header">{t('ALERTS')}</div>
                {unRidNotification > 0 &&
                <div className="read-all">
                    <div
                        className="read-all-btn"
                        onClick={markAllAsRead}
                    >
                        {t('READ_ALL')}
                    </div>
                </div>
                }
                <div
                    className="body"
                    onMouseLeave={onMouseLeaveBody}
                >
                    <AutoSizer>
                        {({ height, width }) => (
                            <DynamicList
                                ref={dynamicListRef}
                                data={list}
                                cache={cacheDC}
                                height={height}
                                width={width}
                                recalculateItemsOnResize={{ width: false, height: false }}
                                lazyMeasurement
                                onScroll={onScrollResetFunction}
                            >
                                {({ index, style }) => (
                                    <>
                                        <div
                                            style={style}
                                            className={`alert-item ${selectedAlert === list[index].id ? 'selected' : ''} ${showCommentInput === list[index].id ? 'edit' : ''}`}
                                            onClick={renderRowClickHandler}
                                            onMouseEnter={onMouseEnterRow}
                                            tabIndex={index}
                                        >
                                            <div
                                                className={`alert-item--wrap ${list.length - 1 === index && list.length > 2 ? 'last-item' : ''}`}
                                            >
                                                <div className="alert-item--body">
                                                    <div className="alert-item--path">
                                                        <div className="text">
                                                            ...{`/${list[index].unit || ''}/${list[index].sensor || ''}/`}
                                                            <span className={'param'}>
                                                                    {`${list[index].um || ''} ${list[index].operator ? operands[list[index].operator] : ''}`}
                                                                <span
                                                                    style={{ fontStyle: 'italic' }}
                                                                >
                                                                        {` ${list[index].value}`}
                                                                </span>
                                                                <span className={'for'}>
                                                                    {' ' + t('FOR') + ' '}
                                                                </span>
                                                                <span style={{ fontStyle: 'italic' }}>
                                                                    {Math.round(list[index].threshold / 60).toFixed(1)}
                                                                    {' ' + t('MINUTES')}
                                                                </span>
                                                            </span>
                                                        </div>
                                                        {list[index]?.isNew ?
                                                            <span className={'read-icon'} /> : null}
                                                    </div>
                                                    <AlertItemForm
                                                        dataAlert={list[index]}
                                                        rbac={rbac}
                                                        showCommentInput={showCommentInput === list[index].id}
                                                        setShowCommentInput={setShowCommentInputCallback}
                                                        updateComment={updateComment}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </DynamicList>
                        )}
                    </AutoSizer>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AlertSidebar;
