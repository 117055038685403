import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import './styles/Rule.scss';
import * as d3 from 'd3';
import {
    selectHmiPlayerRealTimeStatus,
    selectHmiPlayerSchema,
    selectHmiPlayerValue,
} from '../../../selectors/hmi/playerSelector';
import { selectHmiPlayerVisibility } from '../../../selectors/hmi/visibilitySelector';
import { RootState } from '../../../store';
import { selectDrawerWidth, selectPositionDrawer } from '../../../selectors/layout/responsiveDrawerSelector';
import {
    calcRealTimeIndentation,
    selectBrushSelection,
} from '../../../selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectScreenWidth } from '../../../selectors/dashboard/dashboardSelector';

/**
 * Graph rule functional component
 *
 * @param {React.PropsWithChildren<any>} props
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const Rule: React.FC<any> = (props: any) => {

    const {
            graphRule,
            minimapVisible = true,
            mode = true,
            selection,
            screenWidth,
            realTimePlayer,
            playerValue,
        } = props,
        styles = { display: 'none', left: '0px', height: 'calc(100% - 87px)' },
        scale = d3.scaleTime();

    let x = 0,
        currentDate = undefined,
        currentDateWithOutDate = undefined;

    /**
     * get height structural tree
     */
    const getStructuralTreeHeight = () => {
        const getElement: any = document.getElementsByClassName('structure-tree');

        return getElement[0] ?
            getElement[0].classList.contains('monitoring-mode') ? getElement[0].offsetHeight + 27 : getElement[0].offsetHeight - 13 : 0;

    };
    if (selection && screenWidth && graphRule.x !== undefined) {
        scale.range([0, screenWidth])
            .domain(selection);
        if ((scale.invert(graphRule.x < 0 ? 0 : graphRule.x).getTime())) {

            const diff = new Date(selection[1]).getTime() - new Date(selection[0]).getTime();
            const dayCount = diff / 86400000;

            currentDateWithOutDate = moment(realTimePlayer ? playerValue : scale.invert(graphRule.x < 0 ? 0 : graphRule.x)).format(dayCount > 27.5 ? 'D MMM HH:mm' : 'HH:mm');
        }
    }

    if (graphRule) {

        styles.display = graphRule.display ? 'block' : 'none';
        styles.left = (Math.round(graphRule.x) + props.maxWidthSideBar) > props.maxWidthSideBar ? (Math.round(graphRule.x < 0 ? 0 : graphRule.x) + props.maxWidthSideBar) + 'px' : props.maxWidthSideBar + 'px';
        x = Math.round(graphRule.x < 0 ? 0 : graphRule.x);
        styles.height = getStructuralTreeHeight() + 112 > window.innerHeight ?
            mode ? getStructuralTreeHeight() + (minimapVisible ? 0 : 54) : getStructuralTreeHeight() + (minimapVisible ? 0 : 54) : `calc(100% - ${minimapVisible ? 86 : 31}px)`;

    }

    if (graphRule && !!graphRule.data && !!graphRule.data[x]) {
        currentDate = moment(realTimePlayer ? playerValue : graphRule.data[x].timestamp).format('HH:mm');
    }

    return (
        <div className={minimapVisible ? 'rule' : 'rule mini-map-hidden'}>
            <div className={graphRule.mobileDetect ? 'line touch' : 'line'} style={styles}>
                <span
                    className={'time'}
                >
                    {currentDate ? currentDate : currentDateWithOutDate ? currentDateWithOutDate : ''}
                </span>
            </div>
        </div>
    );
};

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const {
        graphRule,
        graphMinimapVisibility,
        graphStructuralTreeVisibility,
        graphHistogramHeight,
    } = state;

    const schema = selectHmiPlayerSchema(state);
    const isVisible = selectHmiPlayerVisibility(state) && schema !== null,
        drawWidth = selectDrawerWidth(state),
        anchor: 'right' | 'bottom' = selectPositionDrawer(state) as 'right' | 'bottom',
        selection = selectBrushSelection(state),
        screenWidthOrigin = selectScreenWidth(state),
        screenWidth = screenWidthOrigin - (isVisible && anchor === 'right' ? drawWidth : 0)
            - calcRealTimeIndentation(state) - 1,
        realTimePlayer = selectHmiPlayerRealTimeStatus(state) && isVisible,
        playerValue = selectHmiPlayerValue(state);

    return {
        graphRule,
        minimapVisible: graphMinimapVisibility.visible,
        visibleSideBar: graphStructuralTreeVisibility.visible,
        maxWidthSideBar: graphStructuralTreeVisibility.maxWidth,
        mode: graphHistogramHeight.mode,
        selection,
        screenWidth,
        realTimePlayer,
        playerValue,
    };
};

export default connect(mapStateToProps)(Rule);
