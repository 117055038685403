import { useEffect } from 'react';
import { Canvas } from '../../components/ConfigurationTree/HMI/Editor/canvas/Canvas';
import { selectHmiValueBySensorId } from '../../core/selectors/hmi/playerSelector';
import { useSelector } from 'react-redux';
import { FabricObject } from '../../base/components/Editor/interfaces';
import { maxString } from '../../core/helpers/fittingStringHelper';
import { selectHmiHoverSensor } from '../../core/selectors/hmi/playerHoverItem';
import { fabric } from 'fabric';

export const useHmiSensorsData = async(canvas: Canvas | null): Promise<void> => {

    const hmiValueBySensorId = useSelector(selectHmiValueBySensorId);
    const dedicatedSensor = useSelector(selectHmiHoverSensor);

    useEffect(() => {

        let setLabelPosition: NodeJS.Timeout | undefined = undefined;

        if (canvas) {

            canvas.canvas.forEachObject(async(obj: FabricObject) => {

                const hmiData = hmiValueBySensorId.find(value => value?.id === obj.objectId);

                if (hmiData) {

                    const formatData = hmiData.value === undefined || hmiData.value === null ? hmiData.value : Number(hmiData.value).toFixed(2);

                    if (dedicatedSensor && dedicatedSensor.id === hmiData.sensorId) {

                        obj.set('dirty', true);

                        obj.set('shadow', new fabric.Shadow({
                            color: 'rgba(0,0,0,0.4)',
                            blur: 10,
                        }));

                    } else {

                        obj.set('dirty', true);
                        obj.set('shadow', 'null');
                    }

                    if (!dedicatedSensor) {

                        obj.set('dirty', true);
                        obj.set('shadow', 'null');
                    }

                    const dataValue = typeof hmiData.value === 'number' ?
                        (Number(formatData)).toString().length >= 10 ?
                            maxString((Number(formatData)).toString(), 10)
                            :
                            formatData
                            + ' ' + hmiData.um : 'No data';

                    if (obj.type === 'pitCode') {

                        obj.set('dirty', true);

                        obj.set('dataValue', dataValue);

                        obj.set('sensorType', hmiData.sensorType);

                        obj.set('label', hmiData.sensorName);

                        obj.set('text', hmiData.sensorName);

                    } else {

                        obj.set('dirty', true);

                        obj.set('fillColor', hmiData.color);
                        obj.set('fill', hmiData.color);

                        if (hmiData.frameColor) {

                            obj.set('strokeColor', hmiData.frameColor);
                            obj.set('stroke', hmiData.frameColor);
                        }

                        if (obj.type === 'indicator' && hmiData.sensorType === 'state') {

                            const stateText = hmiData?.stateText || 'No data';

                            obj.set('dataValue', stateText);
                            obj.set('fillColor', hmiData.color);
                            obj.set('fill', hmiData.color);
                        }

                        if (obj.type === 'indicator' && hmiData.sensorType !== 'state') {

                            obj.set('dataValue', dataValue);
                            obj.set('fillColor', '#FFFFFF');
                            obj.set('fill', '#FFFFFF');
                            obj.set('strokeColor', hmiData.color);
                            obj.set('stroke', hmiData.color);
                        }

                    }
                }

                obj.lockMovementX = true;
                obj.lockMovementY = true;
                obj.lockScalingX = true;
                obj.lockScalingY = true;
                obj.lockRotation = true;

            }).requestRenderAll();

            setLabelPosition = setTimeout(()=>{

                for (let i = 0; i < hmiValueBySensorId.length; i++) {
                    const value = hmiValueBySensorId[i];

                    const currentTargetObject: FabricObject | undefined = canvas.canvas.getObjects().find((obj: FabricObject)=>obj.objectId === value?.id && obj.type !== 'pitCode');
                    const currentTargetLabelObject: FabricObject | undefined = canvas.canvas.getObjects().find((obj: FabricObject)=>obj.objectId === value?.id && obj.type === 'pitCode');

                    if (currentTargetObject && currentTargetLabelObject) {

                        const position = canvas.handler.getPositionRelativeToObject(currentTargetObject, currentTargetLabelObject);

                        if (
                            (currentTargetLabelObject as FabricObject).top !== position.top ||
                            (currentTargetLabelObject as FabricObject).left !== position.left
                        ) {

                            (currentTargetLabelObject as FabricObject).set(position);
                            (currentTargetLabelObject as FabricObject).setCoords();
                        }
                    }
                }

                canvas.canvas.renderAll();

                setLabelPosition && clearTimeout(setLabelPosition);

            }, 0);

            for (const value of canvas.canvas.getObjects().sort((a: FabricObject, b: FabricObject) => a.zIndex < b.zIndex ? -1 : 1)) {
                const index = canvas.canvas.getObjects().sort((a: FabricObject, b: FabricObject) => a.zIndex < b.zIndex ? -1 : 1).indexOf(value);
                value.set({ dirty: true });
                value.moveTo(index);
                value.setCoords();
            }
        }

        return () => {

            setLabelPosition && clearTimeout(setLabelPosition);
        };

    }, [hmiValueBySensorId, canvas, dedicatedSensor]);



};