import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import moment from 'moment';
import { graphConstants } from '../../../core/constants';
import { appConfig } from '../../../config/appConfig';
import * as d3 from 'd3';
import { IUnit } from '../../../core/interfaces';
import { selectDashboardOnline, selectScreenWidth } from '../../../core/selectors/dashboard/dashboardSelector';
import store from '../../../core/store';

const scale = d3.scaleTime();


/**
 * Graphics related actions
 *
 * @type {Object}
 */
export const GraphActions = {

    getStartEndDate: (startDate: Date, endDate: Date): { startDate: Date, endDate: Date } => {

        const screenWidth = selectScreenWidth(store.getState());

        scale.domain([startDate, endDate]).range([0, screenWidth]);

        return {
            startDate: startDate,
            // endDate: scale.invert(screenWidth + 42),
            endDate: scale.invert(screenWidth),
        };
    },

    /**
     * A peak mouse enter event handler
     *
     * @param {number} x
     * @param {boolean} mobileDetect
     *
     * @return {Promise<Object>}
     */
    peakEnter: (x: number, mobileDetect: boolean) => {

        //Action creators
        const action = (x: number) => {

            return {
                type: graphConstants.peakMouseEnter,
                x: x,
                mobileDetect: mobileDetect,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action(x));
        };
    },


    /**
     * A peak mouse enter event handler for empty line
     *
     * @param {number} x
     *
     * @return {Promise<Object>}
     */
    peakEnterEmptyLine: (x: number) => {

        //Action creators
        const action = (x: number) => {

            return {
                type: graphConstants.peakEnterEmptyLine,
                x: x,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action(x));
        };
    },

    /**
     * A peak touch move event handler
     *
     * @param {Object} event
     * @param {boolean} mobileDetect
     *
     * @return {Promise<Object>}
     */
    peakEnterTouch: (event: any, mobileDetect: boolean) => {

        //Action creators
        const action = (event: any) => {

            return {
                type: graphConstants.peakMouseEnter,
                x: event,
                mobileDetect: mobileDetect,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action(event));
        };
    },

    /**
     * A peak mouse leave event handler
     *
     * @return {Promise<Object>}
     */
    peakLeave: () => {

        //Action creators
        const action = () => {

            return {
                type: graphConstants.peakMouseLeave,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action());
        };
    },

    /**
     * Toggle histogram height
     *
     * @param {boolean} small
     *
     * @return {Promise<Object>}
     */
    histogramToggleHeight: (small: boolean) => {

        //Action creators
        const switchToNormal = () => {

            return {
                type: graphConstants.histogramSwitchNormal,
            };

        }, switchToSmall = () => {

            return {
                type: graphConstants.histogramSwitchSmall,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(small ? switchToNormal() : switchToSmall());
        };
    },

    /**
     * Toggle histogram mode
     *
     * @param {boolean} mode
     *
     * @return {Promise<Object>}
     */
    histogramToggleMode: (mode: boolean) => {

        //Action creators
        const switchMode = (mode: boolean) => {

            return {
                type: graphConstants.histogramSwitchMode,
                mode,
            };

        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(switchMode(mode));
        };
    },

    /**
     * Switch histogram mode
     *
     * @param {string} mode
     *
     * @return {Promise<Object>}
     */
    histogramSwitchMode: (mode: string) => {

        //Action creators
        const linear = () => {

            return {
                type: graphConstants.histogramSwitchLinear,
            };

        }, logarithmic = () => {

            return {
                type: graphConstants.histogramSwitchLogarithmic,
            };

        }, overlapping = () => {

            return {
                type: graphConstants.histogramSwitchOverlapping,
            };
        }, defaultMode = () => {

            return {
                type: graphConstants.histogramModeDisable,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            switch (mode) {

                case graphConstants.histogramModeLinear:

                    dispatch(linear());

                    break;

                case graphConstants.histogramModeLogarithmic:

                    dispatch(logarithmic());

                    break;

                case graphConstants.histogramModeOverlapping:

                    dispatch(overlapping());

                    break;

                case graphConstants.histogramModeDisable:

                    dispatch(defaultMode());

                    break;
            }
        };
    },

    /**
     * Toggle bar chart height
     *
     * @param {boolean} small
     *
     * @return {Promise<Object>}
     */
    barToggleHeight: (small: boolean) => {

        //Action creators
        const switchToNormal = () => {

            return {
                type: graphConstants.barSwitchNormal,
            };

        }, switchToSmall = () => {

            return {
                type: graphConstants.barSwitchSmall,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(small ? switchToNormal() : switchToSmall());
        };
    },

    /**
     * Show/hide description for bar charts
     *
     * @param {boolean} visible
     *
     * @return {Promise<Object>}
     */
    barToggleDescription: (visible: boolean) => {

        //Action creators
        const show = () => {

            return {
                type: graphConstants.barDescriptionShow,
            };

        }, hide = () => {

            return {
                type: graphConstants.barDescriptionHide,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(visible ? hide() : show());
        };
    },

    /**
     * Show/hide a table view for bar chart
     *
     * @param {boolean} visible
     * @param {number} top
     * @param sensorId
     * @param { IUnit } unit
     * @param { HTMLCanvasElement } ref
     * @param { boolean } hrMode
     *
     * @return {Promise<Object>}
     */
    barToggleTableView: (visible: boolean, top: number, sensorId?: string | number, unit?: IUnit, ref?: HTMLCanvasElement, hrMode?: boolean) => {

        //Action creators
        const show = () => {

            return {
                type: graphConstants.barTableViewShow,
                top: top,
                sensorId: sensorId,
                currentUnit: unit,
                ref,
                hrMode,
            };

        }, hide = () => {

            return {
                type: graphConstants.barTableViewHide,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(visible ? hide() : show());
        };
    },

    /**
     * Show/hide Minimap
     *
     * @param {boolean} visible
     *
     * @return {Promise<Object>}
     */
    minimapToggle: (visible: boolean) => {

        //Action creators
        const show = () => {

            return {
                type: graphConstants.minimapShow,
            };

        }, hide = () => {

            return {
                type: graphConstants.minimapHide,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(visible ? hide() : show());
        };
    },

    /**
     * Show/hide HMI player
     *
     * @param {boolean} isVisible
     *
     * @return {Promise<Object>}
     */
    toggleHMI: (isVisible: boolean) => {

        //Action creators
        const show = () => {

            return {
                type: graphConstants.hmiShow,
            };

        }, hide = () => {

            return {
                type: graphConstants.hmiHide,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(isVisible ? hide() : show());
        };
    },

    /**
     * Show/hide structural tree side bar
     *
     * @param {boolean} visible
     *
     * @return {Promise<Object>}
     */
    sideBarSTToggle: (visible: boolean) => {

        //Action creators
        const show = () => {

            window.localStorage.setItem('sidebar', 'false');

            return {
                type: graphConstants.sideBarSTShow,
            };

        }, hide = () => {

            window.localStorage.setItem('sidebar', 'true');

            return {
                type: graphConstants.sideBarSTHide,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(visible ? hide() : show());
        };
    },

    /**
     * Minimap brush handler
     *
     * @param {Date[]} selection
     * @param {boolean} brushRule
     *
     * @return {Promise<Object>}
     */
    minimapBrushed: (selection: Date[], brushRule?: boolean) => {

        //Action creators
        const action = () => {

            return {
                type: graphConstants.minimapBrush,
                selection: selection,
                brushRule: brushRule,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action());
        };
    },

    /**
     * Minimap timer rule
     *
     * @param {boolean} timerRule
     *
     * @return {Promise<Object>}
     */
    minimapTimerRule: (timerRule: boolean) => {

        //Action creators
        const action = () => {

            return {
                type: graphConstants.minimapBrushTimerOnOff,
                timerRule: timerRule,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action());
        };
    },

    /**
     * Range selection handler
     * @param {Object<startDate: Date, endDate: Date, key: string>} selection
     * @param {boolean} timerRule
     * @return {Promise<Object>}
     */
    rangeSelect: (selection: { startDate: Date, endDate: Date, key: string }, timerRule?: boolean) => {

        //Action creators
        const action = (selection: { startDate: Date, endDate: Date, key: string }) => {


            if (selection.startDate > new Date() || selection.endDate > new Date()) {

                if (selection.startDate > new Date()) {

                    selection.startDate = moment().subtract({ d: 1 }).toDate();
                }

            }

            if (selection.endDate > new Date()) {

                const currentRangeDate = GraphActions.getStartEndDate(selection.startDate, new Date());

                selection.endDate = currentRangeDate.endDate;
            }

            const periodDay = moment(selection.endDate).diff(moment(selection.startDate), 'd');

            const periodDayInHours = moment(selection.endDate).diff(moment(selection.startDate), 'h');

            let scaleDates = null;

            if (periodDay > 1 && periodDay <= 7) {

                scaleDates = graphConstants.minimapScaleDatesWeek;
            }
            if (periodDayInHours > 48 && periodDayInHours <= 168) {

                scaleDates = graphConstants.minimapScaleDatesWeek;
            }

            if (periodDay > 7 && periodDay <= moment().daysInMonth()) {

                scaleDates = graphConstants.minimapScaleDatesMonth;
            }

            if (periodDay > moment().daysInMonth()) {

                scaleDates = graphConstants.minimapScaleDatesYear;
            }

            return {
                type: graphConstants.minimapRangeSelect,
                range: selection,
                timerRule,
                scaleDates,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action(selection));
        };
    },

    /**
     * Period selection handler
     * @param {string} actionType
     *
     * @return {Promise<Object>}
     */
    periodSelect: (actionType: string) => {

        //Action creators
        const hours = (actionType: string, hours: number) => {

            const currentDayDate = GraphActions.getStartEndDate(moment().subtract({ h: hours }).toDate(), new Date());

            const selection = {
                startDate: currentDayDate.startDate,
                endDate: currentDayDate.endDate,
                key: 'selection',
            };

            return {
                type: graphConstants.minimapSelectPeriod,
                currentPeriod: actionType,
                scaleDates: graphConstants.minimapScaleDatesDay,
                range: selection,
            };

        }, day = (actionType: string) => {

            const currentDayDate = GraphActions.getStartEndDate(moment().subtract({ d: 1 }).toDate(), new Date());

            const selection = {
                startDate: currentDayDate.startDate,
                endDate: currentDayDate.endDate,
                key: 'selection',
            };

            return {
                type: graphConstants.minimapSelectPeriod,
                currentPeriod: actionType,
                scaleDates: graphConstants.minimapScaleDatesDay,
                range: selection,
            };

        }, week = (actionType: string) => {

            const currentWeekDate = GraphActions.getStartEndDate(moment().subtract({ d: 7 }).toDate(), new Date());

            const selection = {
                startDate: currentWeekDate.startDate,
                endDate: currentWeekDate.endDate,
                key: 'selection',
            };

            return {
                type: graphConstants.minimapSelectPeriod,
                currentPeriod: actionType,
                scaleDates: graphConstants.minimapScaleDatesWeek,
                range: selection,
            };

        }, month = (actionType: string) => {

            const currentMonthDate = GraphActions.getStartEndDate(moment().subtract({ d: moment().daysInMonth() }).toDate(), new Date());

            const selection = {
                startDate: currentMonthDate.startDate,
                endDate: currentMonthDate.endDate,
                key: 'selection',
            };

            return {
                type: graphConstants.minimapSelectPeriod,
                currentPeriod: actionType,
                scaleDates: graphConstants.minimapScaleDatesMonth,
                range: selection,
            };

        }, year = (actionType: string) => {

            const currentYearDate = GraphActions.getStartEndDate(moment().subtract({ d: 365 }).toDate(), new Date());

            const selection = {
                startDate: currentYearDate.startDate,
                endDate: currentYearDate.endDate,
                key: 'selection',
            };

            return {
                type: graphConstants.minimapSelectPeriod,
                currentPeriod: actionType,
                scaleDates: graphConstants.minimapScaleDatesYear,
                range: selection,
            };

        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {
            switch (actionType) {

                // case '2Hours':
                //
                //     dispatch(hours(actionType, 2));
                //
                //     break;
                case '12Hours':

                    dispatch(hours(actionType, 12));

                    break;
                case 'day':

                    dispatch(day(actionType));

                    break;
                case 'week':

                    dispatch(week(actionType));

                    break;
                case 'month':

                    dispatch(month(actionType));

                    break;
                case 'year':

                    dispatch(year(actionType));

                    break;
            }
        };
    },

    /**
     * Zoom selection handler
     *
     * @param {Object<startDate: Date, endDate: Date, key: string>} selection
     * @param {string} direction
     * @param {active: boolean, width: number} selectionParam
     *
     * @return {Promise<Object>}
     */
    zoomSelect: (selection: { startDate: Date, endDate: Date, key: string }, direction: string) => {

        const zoomIn = (selection: { startDate: Date, endDate: Date, key: string }) => {

            //period in milliseconds
            const period = moment(selection.endDate).diff(moment(selection.startDate)) / 4,
                periodDay = moment(selection.endDate).diff(moment(selection.startDate), 'd');
            let scaleDates = 1;

            if (period > 900000) {

                selection.startDate = moment(selection.startDate).add({ millisecond: period }).toDate();
                selection.endDate = moment(selection.endDate).subtract({ millisecond: period }).toDate();

            }

            if (periodDay > 1 && periodDay < 7) {
                scaleDates = graphConstants.minimapScaleDatesWeek;
            }

            if (periodDay >= 7 && periodDay < moment().daysInMonth()) {
                scaleDates = graphConstants.minimapScaleDatesMonth;
            }

            if (periodDay >= moment().daysInMonth()) {
                scaleDates = graphConstants.minimapScaleDatesYear;
            }

            return {
                type: graphConstants.minimapSelectZoomIn,
                range: selection,
                scaleDates,
            };

        }, zoomOut = (selection: { startDate: Date, endDate: Date, key: string }) => {

            //period in milliseconds
            const period = moment(selection.endDate).diff(moment(selection.startDate)) / 2,
                periodDay = moment(selection.endDate).diff(moment(selection.startDate), 'days');
            let scaleDates = 1;

            selection.startDate = moment(selection.startDate).subtract({ millisecond: period }).toDate();

            if (moment(selection.endDate).add({ millisecond: period }).isBefore(new Date())) {

                selection.endDate = moment(selection.endDate).add({ millisecond: period }).toDate();

            } else {

                selection.endDate = new Date();

                //Uncomment in case of a date shift problem online
                // if (dashboardOnline) {

                    const currentSelection = GraphActions.getStartEndDate(selection.startDate, new Date());

                    selection.endDate = currentSelection.endDate;

                // }

            }
            if (period === 0) {

                selection.startDate = moment(selection.startDate).subtract({ minutes: 720 }).toDate();

                selection.endDate = moment(selection.endDate).add({ minutes: 720 }).toDate();
            }

            // minimum Year
            if (selection.startDate < moment().subtract({ y: appConfig.minimumYear }).toDate()) {

                selection.startDate = moment().subtract({ y: appConfig.minimumYear }).toDate();
            }

            if (periodDay > 0 && periodDay <= 7) {
                scaleDates = graphConstants.minimapScaleDatesWeek;
            }
            if (periodDay > 7 && periodDay <= moment().daysInMonth()) {
                scaleDates = graphConstants.minimapScaleDatesMonth;
            }
            if (periodDay > moment().daysInMonth()) {
                scaleDates = graphConstants.minimapScaleDatesYear;
            }

            return {
                type: graphConstants.minimapSelectZoomOut,
                range: selection,
                scaleDates,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            switch (direction) {
                case 'zoomIn':

                    dispatch(zoomIn(selection));

                    break;
                case 'zoomOut':

                    dispatch(zoomOut(selection));

                    break;
            }
        };
    },

    /**
     * Shift selection handler
     *
     * @param {Object<startDate: Date, endDate: Date, key: string>}  selection
     * @param { string } direction
     * @param { string } minimapPeriod
     *
     * @return {Promise<Object>}
     */
    shiftSelection: (selection: { startDate: Date, endDate: Date, key: string }, direction: string, minimapPeriod: string) => {

        const periodDay = moment(selection.endDate).diff(moment(selection.startDate), 'hours');
        const currentPeriodDay = periodDay === 0 ? 1 : !minimapPeriod ? periodDay + 1 : periodDay;

        const shiftRight = (selection: { startDate: Date, endDate: Date, key: string }) => {

                if (moment(selection.endDate).add({ h: periodDay }).isBefore(new Date())) {

                    selection.startDate = moment(selection.startDate).add({ h: currentPeriodDay }).toDate();
                    selection.endDate = moment(selection.endDate).add({ h: currentPeriodDay }).toDate();
                } else {
                    selection.startDate = moment().subtract({ h: periodDay }).toDate();

                    const currentSelection = GraphActions.getStartEndDate(selection.startDate, new Date());

                    selection.endDate = currentSelection.endDate;
                }

                return {
                    type: graphConstants.minimapShifrRight,
                    range: selection,
                };
            },
            shiftLeft = (selection: { startDate: Date, endDate: Date, key: string }) => {

            if (moment(selection.startDate).subtract({ h: currentPeriodDay }).isAfter(moment().subtract({ y: appConfig.minimumYear }).toDate())) {

                    selection.startDate = moment(selection.startDate).subtract({ h: currentPeriodDay }).toDate();
                    selection.endDate = moment(selection.endDate).subtract({ h: currentPeriodDay }).toDate();
                } else {
                    selection.startDate = moment().subtract({ y: appConfig.minimumYear }).toDate();
                    selection.endDate = moment(selection.startDate).add({ h: periodDay === 0 ? 1 : periodDay }).toDate();
                }

                return {
                    type: graphConstants.minimapShiftLeft,
                    range: selection,
                };
            };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            switch (direction) {
                case 'right':
                    dispatch(shiftRight(selection));
                    break;

                case 'left':
                    dispatch(shiftLeft(selection));
                    break;
            }
        };
    },

    /**
     * Handler reset for  name selection
     *
     * @return {Promise<Object>}
     */
    resetSelection: () => {

        const action = () => {

            return {
                type: graphConstants.minimapReset,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action());
        };
    },

    /**
     * Make a state selected
     *
     * @param {Object} state
     * @param {Object} position
     *
     * @return {Promise<Object>}
     */
    selectAlertGraph: (state: any, position?: { left: number, width: number }) => {

        const action = (state: any, position?: { left: number, width: number }) => {

            return {
                type: graphConstants.SELECT,
                alert: state,
                position: position ? position : {},
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action(state, position));
        };
    },

    /**
     * Make a state selected
     *
     * @param {Object} state
     * @param {Object} position
     *
     * @return {Promise<Object>}
     */
    hoveredAlertGraph: (state: any, position?: { left: number, width: number }) => {

        const action = (state: any, position?: { left: number, width: number }) => {

            return {
                type: graphConstants.HOVERED,
                alert: state,
                position: position ? position : {},
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action(state, position));
        };
    },

    /**
     * Deselect all states
     *
     * @return {Promise<Object>}
     */
    unHoveredAlertGraph: () => {

        const action = () => {

            return {
                type: graphConstants.UNHOVERED,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action());
        };
    },

    /**
     * Deselect all states
     *
     * @return {Promise<Object>}
     */
    deselectAlertGraph: () => {

        const action = () => {

            return {
                type: graphConstants.DESELECT_ALL,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action());
        };
    },
};
