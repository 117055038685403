import { IChartAlert, IChartData, IHistogramData, IHmiObject, ISensor, IStateItem } from '../../core/interfaces';
import { useParamSelector } from '../useParamSelector';
import {
    selectSensorDataBySensorId,
} from '../../core/selectors/HistogramChart/sensorDataSelector';
import {
    getDataRangeRole,
    selectSensorHrDataBySensorId,
} from '../../core/selectors/HistogramChartHr/sensorDataHrSelector';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllNotification } from '../../core/selectors/notification/notificationSelector';
import { selectHmiPlayerSchema } from '../../core/selectors/hmi/playerSelector';
import { selectHmiPlayerVisibility } from '../../core/selectors/hmi/visibilitySelector';
// import { selectNarrowSensorDataBySensorId } from '../../core/selectors/hmi/dashboardDataNarrovSelector';
import { selectPositionDrawer } from '../../core/selectors/layout/responsiveDrawerSelector';
import { HmiPlayerActions } from '../../core/actions';
import { selectBrushSelection } from '../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { usePrevious } from '../usePrevious';
// import isEqual from 'lodash/isEqual';

export interface IChartDataWithColor extends IChartData{
    color: string;
    originColor: string;
}

export interface IHmiObjectWithData extends IHmiObject {
    dataHistogram : IChartDataWithColor[] |  IStateItem[] | undefined;
    sensor: ISensor;
}

export const useDataHistogram = (sensor: ISensor, hrMode: boolean, mixMode: boolean, color: string):[any[], IChartDataWithColor[], IChartAlert[]] => {
    const sensorId = `${sensor.controllerId}.${sensor.sensorId}`;
    const [alertPointArr, setAlertPointArr] = useState<any[]>([]);
    const [dataWithAlertColor, setDataWithAlertColor] = useState<IChartDataWithColor[]>([]);
    const [poolingGapsData, setPoolingGapsData] = useState<IHistogramData | null>(null);
    const dispatch = useDispatch();

    const data: IHistogramData = useParamSelector(selectSensorDataBySensorId, sensorId),
        range = useSelector(getDataRangeRole),
        dataHr: IHistogramData = useParamSelector(selectSensorHrDataBySensorId, sensor.id),
        // dataNarrow: IHistogramData = useParamSelector(selectNarrowSensorDataBySensorId, sensor.id),
        notifications = useSelector(selectAllNotification),
        anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom',
        selection: Date[] | undefined = useSelector(selectBrushSelection),
        prewSelection = usePrevious(selection),
        alertData: IChartAlert[] = !hrMode ? data?.alerts || [] : [];

    const schema = useSelector(selectHmiPlayerSchema);
    const isVisible = useSelector(selectHmiPlayerVisibility) && schema !== null;

    useEffect(() => {

        if (!hrMode ? data?.values : dataHr?.values) {

            const values = !hrMode ? data : dataHr;
            // const values = isVisible && anchor === 'right' && dataNarrow?.values.length > 0? dataNarrow : !hrMode ? data : dataHr;

            // if (!isEqual(values, poolingGapsData)) {
            if (JSON.stringify(values) !== JSON.stringify(poolingGapsData)) {
                    setPoolingGapsData(values);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dataHr, setPoolingGapsData, anchor, range, isVisible]);
// }, [data, dataHr, setPoolingGapsData, isVisible, anchor, dataNarrow, range]);

    const updateData = async (chartData: IChartDataWithColor[]) => {
        setAlertPointArr([]);
        const [from, to] = selection;
        return  await new Promise((resolve) => {

            const newChartData = chartData.reduce((dataArr: IChartDataWithColor[], data) => {
                if (new Date(data.timestamp).getTime() > new Date(from).getTime() &&
                    new Date(data.timestamp).getTime() < new Date(to).getTime()) {
                    const alertPointArrNew = alertPointArr;
                    let defaultColor = color;
                    if (alertData) {
                        for (const value of alertData) {
                            const notificationEndTime = value.endTime ? new Date(value.endTime) : new Date();

                            if (new Date(data.timestamp).getTime() >= new Date(value.startTime).getTime() &&
                                new Date(data.timestamp).getTime() <= new Date(notificationEndTime).getTime() &&
                                !mixMode) {

                                defaultColor = '#e30c0c';
                            }


                            if (new Date(data.timestamp).getTime() >= new Date(value.startTime).getTime() &&
                                new Date(data.timestamp).getTime() <= new Date(notificationEndTime).getTime()) {


                                const alertPointIndex = alertPointArrNew.findIndex(alPoint => alPoint.id === value.notificationId);
                                const currentNotification = notifications && notifications.find(ntf => ntf.id === value.notificationId);

                                if (alertPointIndex === -1) {

                                    const isNew = currentNotification ? currentNotification.isNew : value.isNew;
                                    const comment = currentNotification ? currentNotification.comment : value.comment;

                                    alertPointArrNew.push({
                                        id: value.notificationId,
                                        point: [dataArr.length],
                                        endTime: notificationEndTime,
                                        startTime: value.startTime,
                                        comment: comment || '',
                                        um: value.um,
                                        isNew: isNew,
                                        value: value.value,
                                    });

                                }
                            }

                        }
                    }
                    data.color = defaultColor;
                    data.originColor = defaultColor;

                    setAlertPointArr(alertPointArrNew);
                    dataArr.push(data);
                }
                return dataArr;

            }, []);

            resolve(newChartData);
        });
    };

    useEffect(() => {

        if (poolingGapsData?.values && selection) {

            const colorData = updateData(poolingGapsData.values as IChartDataWithColor[]);

            colorData.then(value => {

                if (dataWithAlertColor !== value) {

                    setDataWithAlertColor(value as unknown as IChartDataWithColor[]);

                }
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [poolingGapsData, color, selection]);

    useEffect(() => {
        if (schema) {
            const hmiObjectBySensor = schema?.hmiObjects && schema?.hmiObjects.find(value => value.sensorId === sensor.id);

            if (hmiObjectBySensor) {
                const data: IHmiObjectWithData = { ...hmiObjectBySensor, dataHistogram: dataWithAlertColor, sensor: sensor };

                dispatch(HmiPlayerActions.setHMIObjectWIthData(data));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataWithAlertColor, dispatch, sensor, schema]);


    return [
        alertPointArr,
        dataWithAlertColor,
        alertData,
    ];
};