import React from 'react';
import { useSelector } from 'react-redux';

import './styles/Rule.scss';
import { ISensor, IChartData } from '../../../interfaces';
import { useTranslation } from 'react-i18next';
import { selectScreenWidth } from '../../../selectors/dashboard/dashboardSelector';
import { selectGraphRule } from '../../../selectors/graphRule/graphRuleSelector';
import { selectHistogramHeight } from '../../../selectors/graphHistogramHeight/histogramHeightSelector';
import {
    calcRealTimeIndentation,
} from '../../../selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectHmiPlayerRealTimeStatus, selectHmiPlayerSchema } from '../../../selectors/hmi/playerSelector';
import { selectHmiPlayerVisibility } from '../../../selectors/hmi/visibilitySelector';
import {
    selectDrawerWidthWithDrawRules,
    selectPositionDrawer,
} from '../../../selectors/layout/responsiveDrawerSelector';
import { useParamSelector } from '../../../../hooks/useParamSelector';
import { selectGraphDataLastValuesById } from '../../../selectors/graphDataLastValue/graphDataLastValueSelector';

interface IRuleTextProps {
    data:  IChartData[];
    sensor: ISensor;
    hrMode: boolean;
    barWidth: number;
}

/**
 * A rule text component to display graph values
 *
 * @param {React.PropsWithChildren<any>} props
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const RuleText: React.FC<IRuleTextProps> = (
    {
        data,
        sensor,
        hrMode,
        barWidth,
    }: IRuleTextProps,
) => {

    const { t } = useTranslation();
    const schema = useSelector(selectHmiPlayerSchema);
    const isVisible = useSelector(selectHmiPlayerVisibility) && schema !== null;
    const realTimeStatus = useSelector(selectHmiPlayerRealTimeStatus);

    // const drawWidth = useSelector(selectDrawerWidthWithDrawRules),
        // anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom',
    // realTimeIndentation = useSelector(calcRealTimeIndentation),
    const width = useSelector(selectScreenWidth),
        graphRule = useSelector(selectGraphRule),
        graphHeight = useSelector(selectHistogramHeight),
        lastValue = useParamSelector(selectGraphDataLastValuesById, sensor.id);

    if (graphRule && graphRule.display && data && data.length) {

        let x = Math.round(graphRule.x);

        if (x < 0) {
            x = 0;
        }

        const styles = {
            right: (width - x) + 'px',
            top: ((graphHeight / 2) - 15) + 'px',
        };

        let minText: string | number = 'auto',
            maxText: string | number = 'auto';

        if (sensor.graphPreferences && sensor.graphPreferences[0]) {

            minText = sensor.graphPreferences[0].min || sensor.graphPreferences[0].min === 0 ? sensor.graphPreferences[0].min : 'auto';
            maxText = sensor.graphPreferences[0].max || sensor.graphPreferences[0].max === 0 ? sensor.graphPreferences[0].max : 'auto';
        }

        let index = 0;

        // let index = realTimeStatus && isVisible ?
        //     data.length - 1
        //     : data.length < width && !isVisible ?
        //         Math.round(x * (data.length / (width - realTimeIndentation - (isVisible  && anchor === 'right' ? drawWidth : 0))))
        //         : x;

        if (realTimeStatus && isVisible) {
            index = data.length - 1;
        } else if (data.length <= width && !isVisible) {
            index = Math.round(x / barWidth);
        } else {
            index = Math.round(x / barWidth);
        }

        if (index === -1) {

            index = 0;
        }

        const displayData = !realTimeStatus ?
            data[index] && data[index].value !== null ? parseFloat(String(data[index].value)).toFixed(2) : 'No data'
        :
            lastValue !== null ? parseFloat(String(lastValue)).toFixed(2) : 'No data';

        return (
            <div className="rule-text" style={styles}>
                <span>{displayData}</span>
                {data[index] ?
                    <span className="description" >
                        {sensor.um ? sensor.um : ''} ({sensor.graphPreferences && sensor.graphPreferences[0] ? `${minText}, ${maxText}` : hrMode? t('CM') :''})
                    </span> : null
                }
            </div>
        );
    }

    return null;
};

export default RuleText;
