import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AlertAction, FormActions, NotificationAction, statesActions } from '../../../core/actions';
import {
    IActiveProducts,
    INotification,
    ISensor,
    IStateData,
    IStateItem,
} from '../../../core/interfaces';
import RuleText from '../../../core/ui/components/Board/RuleText';

import settingsIcon from '../../../core/ui/assets/images/settings.png';

import '../../../core/ui/components/Board/styles/Chart.scss';
import { GraphActions } from '../../store/actions';
import { selectHistogramHeight } from '../../../core/selectors/graphHistogramHeight/histogramHeightSelector';
import { selectGraphBarHeight } from '../../../core/selectors/graphBarHeight/graphBarHeightSelector';
import { selectScreenWidth } from '../../../core/selectors/dashboard/dashboardSelector';
import {
    calcRealTimeIndentation,
    selectBrushSelection,
} from '../../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectRBAC } from '../../../core/selectors/auth/authSelector';
import {
    selectMaxWidthSideBar,
} from '../../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import {
    selectGraphSelectionAlert,
    selectGraphSelectionAlertHover,
} from '../../../core/selectors/graphSelection/graphSelectionSelector';
import * as d3 from 'd3';
import { useDataHistogram } from '../../../hooks/histogramChart/useDataHistogram';
import {
    selectHmiPlayerMode,
    selectHmiPlayerSchema,
    selectHmiPlayerValue,
} from '../../../core/selectors/hmi/playerSelector';
import {
    selectDrawerIsResize,
    selectDrawerWidthWithDrawRules,
    selectPositionDrawer,
} from '../../../core/selectors/layout/responsiveDrawerSelector';
import { selectHmiPlayerVisibility } from '../../../core/selectors/hmi/visibilitySelector';
import { usePrevious } from '../../../hooks/usePrevious';
import isEqual from 'lodash/isEqual';
import { isMobileOnly } from 'react-device-detect';
import { HistogramWatcher } from '../../../core/watchers/histogramWatcher';


interface IProps {
    forceMix?: boolean;
    dataState?: IStateData;
    sensor: ISensor;
    sensorTargetValue?: {
        activeProducts: IActiveProducts[],
        targetValues: {
            maxTargetValue: number | null,
            minTargetValue: number | null,
            productId: number;
            sensorId: number;
        }[]
    };
    hrMode?: boolean;
}

let prevDrawWidth = 500;
/**
 * A D3 Bar chart component
 *
 * @class HistogramChart
 */
const HistogramChart: React.FC<IProps> = (
    {
        sensor,
        sensorTargetValue,
        hrMode = false,
        forceMix = false,
        dataState,
    }: IProps,
) => {

    const dispatch = useDispatch();
    const chartRef = useRef<HTMLCanvasElement | null>(null);
    const alertRef = useRef<HTMLCanvasElement | null>(null);
    const chartWrapperRef = useRef<HTMLDivElement | null>(null);
    const [chartContext, setChartContext] = useState<CanvasRenderingContext2D | null>(null);
    const [alertContext, setAlertContext] = useState<CanvasRenderingContext2D | null>(null);
    const [mixMode, setMixMode] = useState<boolean>(forceMix);
    const [barWidth, setBarWidth] = useState<number>(1);
    const [supportsTouch, setSupportsTouch] = useState<boolean>(false);
    const [selectedAlert, setSelectedAlert] = useState<any | null>(null);

    const watcher = new HistogramWatcher(sensor, mixMode, hrMode, forceMix, dataState);

    const [
        alertPointArr,
        dataWithAlertColor,
        alertData,
    ] = useDataHistogram(sensor, hrMode, mixMode, watcher.sensorPreferences.color);

    const schema = useSelector(selectHmiPlayerSchema);
    const isVisible = useSelector(selectHmiPlayerVisibility) && schema !== null;
    const preDataWithAlertColor = usePrevious(dataWithAlertColor);
    const drawWidth = useSelector(selectDrawerWidthWithDrawRules);
    const isResize = useSelector(selectDrawerIsResize);

    const histogramHeight = useSelector(selectHistogramHeight),
        stateHeight = useSelector(selectGraphBarHeight),
        selection: Date[] | undefined = useSelector(selectBrushSelection),
        anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom',
        screenWidthOrigin = useSelector(selectScreenWidth),
        screenWidth = screenWidthOrigin - (!isMobileOnly && isVisible && anchor === 'right' ? isResize ? prevDrawWidth : drawWidth : 0),
        brushSelection = useSelector(selectBrushSelection),
        rbac = useSelector(selectRBAC),
        maxWidthSideBar = useSelector(selectMaxWidthSideBar),
        graphSelectionAlert = useSelector(selectGraphSelectionAlert),
        graphSelectionAlertHover = useSelector(selectGraphSelectionAlertHover),
        value = useSelector(selectHmiPlayerValue),
        sideBarLogic = JSON.parse(localStorage.getItem('sidebar') as string),
        scaleTime: d3.ScaleTime<number, number> = d3.scaleTime(),
        HMIPlayerStatus = useSelector(selectHmiPlayerMode),
        realTimeIndentation = useSelector(calcRealTimeIndentation);

    const scalePosition = d3.scaleTime().range([0, screenWidth - 1.5])
        .domain(selection);

    let isMounted = true;

    const height = forceMix ? stateHeight : histogramHeight;

    useEffect(() => {

        if (!isResize) {

            prevDrawWidth = drawWidth;
        }

    }, [isResize, drawWidth]);


    useEffect(() => {

        checkTouchSupport();

        if (chartRef.current) {

            chartRef.current.width = screenWidth - realTimeIndentation;
            chartRef.current.height = height;

            setChartContext(chartRef.current.getContext('2d'));
        }

        if (alertRef.current) {

            alertRef.current.width = screenWidth - realTimeIndentation;
            alertRef.current.height = height;

            setAlertContext(alertRef.current.getContext('2d'));
        }

        return ()=> {
            watcher.unsubscribe();
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {


        if (alertContext && alertData.length > 0) {

            watcher.calcPointNotificationTitle(isMounted, alertContext, alertData, barWidth).then(item => item);

            graphSelectionAlert && watcher.createWrapRectangle(alertContext, alertData, graphSelectionAlert).then(item => item);

            graphSelectionAlertHover && watcher.createWrapRectangle(alertContext, alertData, graphSelectionAlertHover).then(item => item);

        }
        // Temporarily disabled because it blocked deselection.

        // selectedAlert && createWrapRectangle(selectedAlert);

    }, [watcher, selectedAlert, alertContext, alertData, dataWithAlertColor, graphSelectionAlert, graphSelectionAlertHover, isVisible, barWidth, isMounted]);


    /**
     * Check supports touch
     */
    const checkTouchSupport = () => {

        if ('ontouchstart' in window) {

            //iOS & android
            setSupportsTouch(true);

            // this.props.peakEnterTouch({ touches: [{ clientX: 440 }] }, this.supportsTouch, this.props.data);
        }
    };

    /**
     *
     * @TODO: temp method to find a state by peak index. Review once backend will be ready
     */
    const getStateByPeak = useCallback((index: number) => {

        const { statesIntervalTotal, statesPerInterval } = watcher.generateStateIntervals;

        const peakInterval = index * (statesIntervalTotal / (screenWidth - realTimeIndentation));

        const result = statesPerInterval.find(s => s.start !== null && s.start <= peakInterval && s.end !== null && s.end > peakInterval);

        return result ? result.state : null;
    }, [screenWidth, watcher, realTimeIndentation]);


    /**
     * Render chart peak
     *
     * @param {number} index
     * @param {number} y
     * @param {number} height
     * @param {string} color
     * @param {string} key
     *
     * @return {JSX.Element}
     */
    const renderPeak = useCallback((index: number, y: number, height: number, color: string) => {

        if (chartContext) {

            chartContext.moveTo((barWidth * index), y);

            chartContext.fillStyle = color;
            barWidth < 1 ?
                chartContext.fillRect((barWidth * index), y, (barWidth), height)
                :
                chartContext.fillRect(Math.round(barWidth * index), y, (Math.round(barWidth * (index + 1)) - Math.round(barWidth * index)), height);
        }

    }, [chartContext, barWidth]);


    /**
     * Draw a horizontal line for the target value
     *
     * @param {number} x1
     * @param {number} x2
     * @param {number} y
     * @param {string} color
     */
    const drawLine = useCallback((x1: number, x2: number, y: number, color: string) => {

        if (chartContext) {

            chartContext.beginPath();
            chartContext.strokeStyle = color;
            chartContext.moveTo(x1, y);
            chartContext.lineWidth = 0.5;
            chartContext.lineTo(x2, y);
            chartContext.stroke();
            chartContext.closePath();
        }
    }, [chartContext]);


    useEffect(() => {

        if (dataWithAlertColor.length > 0) {

            if (isMobileOnly ? (!isEqual(dataWithAlertColor, preDataWithAlertColor)
                || (!isVisible && isEqual(dataWithAlertColor, preDataWithAlertColor))
                || (isVisible && !isEqual(dataWithAlertColor, preDataWithAlertColor))) : dataWithAlertColor) {

                const calculateBarWidth = Math.abs((screenWidth - realTimeIndentation) / (dataWithAlertColor.length));

            setBarWidth(!isFinite(calculateBarWidth) ?
                    barWidth : calculateBarWidth !== 0 ? +calculateBarWidth: 1);
            }
        }

    }, [
        dataWithAlertColor,
        barWidth,
        realTimeIndentation,
        screenWidth,
        isVisible,
        preDataWithAlertColor,
    ]);

    /**
     * Update chart with new data
     */
    const updateChart = useCallback(() => {

        if (brushSelection && chartContext && (screenWidth >= 0 || JSON.stringify(preDataWithAlertColor) !== JSON.stringify(dataWithAlertColor))) {

            if (dataWithAlertColor) {

                watcher.calcPointForDraw(dataWithAlertColor, isMounted).then(dataPoint => {

                        chartContext.clearRect(0, 0, (screenWidth - realTimeIndentation), height);

                        dataPoint.map(dataPointItem => renderPeak(
                            dataPointItem.index,
                            dataPointItem.y,
                            dataPointItem.height,
                            dataPointItem.color,
                        ));
                    },
                ).finally(() => {

                    watcher.calcPointTargetValue(isMounted, sensorTargetValue).then(dataPoint => {

                        dataPoint.map(dataPointItem => drawLine(
                            dataPointItem.x1,
                            dataPointItem.x2,
                            dataPointItem.y,
                            dataPointItem.color,
                        ));
                    });
                });

            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dataWithAlertColor,
        chartContext,
        screenWidth,
        sensorTargetValue,
        height,
        preDataWithAlertColor,
        isMounted,
    ]);

    useEffect(() => {

        //update render check
        // if (!isEqual(dataWithAlertColor, preDataWithAlertColor)
        //     ||(!isVisible && isEqual(dataWithAlertColor, preDataWithAlertColor))
        //     ||(isVisible && !isEqual(dataWithAlertColor, preDataWithAlertColor))
        // ) {

        if (isMobileOnly ? (!isEqual(dataWithAlertColor, preDataWithAlertColor)
            || (!isVisible && isEqual(dataWithAlertColor, preDataWithAlertColor))
            || (isVisible && !isEqual(dataWithAlertColor, preDataWithAlertColor))) : dataWithAlertColor) {

            updateChart();
        }

    }, [dataWithAlertColor, preDataWithAlertColor, updateChart, isVisible]);

    /**
     * Show state details, when it have a comment
     *
     * @param state
     */
    const showStateDetails = useCallback((state: IStateItem | null) => {

        if (state) {

            dispatch(statesActions.toggleStateDetails(true, state));

        } else {

            dispatch(statesActions.toggleStateDetails(false));
        }
    }, [dispatch]);

    /**
     * Hide graph settings icon
     */
    const hideStateDetails = useCallback((event) => {

        event.preventDefault();

        if (mixMode) {

            showStateDetails(null);

        }

        if (!supportsTouch) {

            if (HMIPlayerStatus === 'stop') {

                dispatch(GraphActions.peakLeave());
            }

            if (HMIPlayerStatus === 'pause') {

                dispatch(GraphActions.peakEnterEmptyLine(Math.ceil(scalePosition(new Date(value)) || 0)));
            }
        }

        dispatch(AlertAction.toggleStateDetails(false));

    }, [dispatch, mixMode, showStateDetails, supportsTouch, HMIPlayerStatus, value, scalePosition]);

    /**
     * Show graph settings edit form
     */
    const editSettings = useCallback((event) => {

        event.preventDefault();
        event.stopPropagation();

        if (sensor.graphPreferences && sensor.graphPreferences[0]) {

            const preference = sensor.graphPreferences[0];

            preference['sensor'] = sensor.id;

            dispatch(FormActions.toggle(false, 'histogramForm', { ...preference, sensorName: sensor.name }));
        }
    }, [dispatch, sensor]);


    /**
     * Toggle histogram mix mode with state chart
     */
    const toggleMixMode = useCallback((event) => {

        event.preventDefault();

        if (!forceMix && sensor.isKeyParameter && dataState) {

            if (mixMode) {

                showStateDetails(null);
            }

            setMixMode(!mixMode);
        }
    }, [mixMode, setMixMode, sensor, forceMix, dataState, showStateDetails]);

    /**
     * Show state details, when it have a comment
     *
     * @param index
     */
    const showAlertDetails = useCallback((index: number) => {

        if (alertPointArr.length > 0) {

            if (selection) {

                let selectedItem: null | number = null;

                const currentTime = scaleTime.range([0, (screenWidth - realTimeIndentation)])
                    .domain(selection).invert(index);

                for (const value1 of alertData) {
                    const showLogic = new Date(value1.startTime).getTime() <= new Date(currentTime).getTime() &&
                        new Date(value1.endTime || new Date()).getTime() >= new Date(currentTime).getTime();

                    if (showLogic) {

                        selectedItem = index;

                        dispatch(AlertAction.toggleStateDetails(true, value1));
                    }

                    if (!showLogic && selectedItem === null) {

                        dispatch(AlertAction.toggleStateDetails(false));
                    }
                }
            }
        }

    }, [dispatch, alertPointArr, selection, scaleTime, screenWidth, alertData, realTimeIndentation]);

    /**
     * Handling Alert Selection in state
     *
     * @param { index } index
     */
    const selectedAlertCallback = useCallback(async(index: number) => {

        if (!isMounted) {
            return;
        }

        let selectedA: any | null = null;

        if (selection) {
            scaleTime.range([0, screenWidth - realTimeIndentation])
                .domain(selection);

            const pointDate = scaleTime.invert(index);

            for (const value1 of alertPointArr) {
                const indexAlertPointArr = alertPointArr.indexOf(value1);

                if (!isMounted) {
                    continue;
                }

                const startTime = new Date(value1.startTime).getTime(),
                    endTime = new Date(value1.endTime).getTime(),
                    pointDateTimestamp = pointDate.getTime();

                if ((startTime <= pointDateTimestamp && endTime >= pointDateTimestamp) && !mixMode) {

                    selectedA = value1;

                    dispatch(FormActions.toggle(false, 'alert-sidebar'));

                    if (value1.isNew) {

                        alertPointArr[indexAlertPointArr] = { ...value1, isNew: false };

                        dispatch(NotificationAction.markAsReadAction({
                            ...value1 as unknown as INotification,
                            isNew: false,
                        }));

                    }
                }
            }

            if (!selectedA) {

                dispatch(GraphActions.deselectAlertGraph());
            }

            if (selectedA) {

                const x1 = scaleTime(new Date(selectedA.startTime)) || 0,
                    x2 = scaleTime(new Date(selectedA.endTime || selection[1])) || 0;
                const width = (x2) - (x1);

                dispatch(GraphActions.selectAlertGraph(selectedA, {
                    left: x1,
                    width: width,
                }));
            }
        }

        setSelectedAlert(selectedA);

    }, [dispatch, setSelectedAlert, alertPointArr, mixMode, scaleTime, screenWidth, selection, realTimeIndentation, isMounted]);

    /**
     * On click handler
     *
     * @param {React.MouseEvent<HTMLCanvasElement, MouseEvent>} event
     */
    const onClickCanvasHandler = useCallback((event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {

        event.preventDefault();

        const index = (event.pageX - (sideBarLogic ? 76 : 320 || maxWidthSideBar));

        selectedAlertCallback(index).then(alert => alert);

    }, [maxWidthSideBar, sideBarLogic, selectedAlertCallback]);

    /**
     * Оn mouse move handler
     *
     * @param {React.MouseEvent<HTMLCanvasElement, MouseEvent>} event
     */
    const onMouseMoveCanvasHandler = useCallback((event: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        const settingLogic = event.currentTarget.getAttribute('itemID') === 'realtime-setting';

        const index = (event.pageX - (sideBarLogic ? 76 : 320 || maxWidthSideBar));

        if (settingLogic) {
            event.stopPropagation();
        }

        //this one is too heavy. Try to do anything around, when backend will be ready
        if (mixMode && !settingLogic) {

            const state = getStateByPeak(index);

            if (state) {
                showStateDetails(state);
            }
        }

        if (alertData.length && !settingLogic) {

            showAlertDetails(index);
        }

        if ((HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') && !settingLogic) {

            dispatch(GraphActions.peakEnter(index, supportsTouch));
        }

    }, [
        dispatch,
        supportsTouch,
        alertData,
        showStateDetails,
        getStateByPeak,
        maxWidthSideBar,
        sideBarLogic,
        showAlertDetails,
        mixMode,
        HMIPlayerStatus,
    ]);


    /**
     * On touch start handler
     *
     * @param {React.TouchEvent<HTMLCanvasElement>} event
     */
    const onTouchStartCanvasHandler = useCallback((event: React.TouchEvent<HTMLCanvasElement | HTMLDivElement>) => {

        event.preventDefault();

        const settingLogic = event.currentTarget.getAttribute('itemId') === 'realtime-setting';

        if ((HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') && !settingLogic) {
            const index = event.touches[0].pageX - (sideBarLogic ? 76 : 320 || maxWidthSideBar);

            dispatch(GraphActions.peakEnterTouch(index >= 0? index : 0, supportsTouch));
        }

        if (settingLogic) {
            event.stopPropagation();
        }

    }, [dispatch, maxWidthSideBar, supportsTouch, sideBarLogic, HMIPlayerStatus]);

    /**
     * On touch move handler
     *
     * @param {React.TouchEvent<HTMLCanvasElement>} event
     */
    const onTouchMoveCanvasHandler = useCallback((event: React.TouchEvent<HTMLCanvasElement | HTMLDivElement>) => {

        event.preventDefault();

        const settingLogic = event.currentTarget.getAttribute('itemId') === 'realtime-setting';

        if ((HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') && !settingLogic) {

            dispatch(GraphActions.peakEnterTouch((event.touches[0].pageX - (sideBarLogic ? 76 : 320 || maxWidthSideBar)), supportsTouch));
        }

        if (settingLogic) {

            event.stopPropagation();
        }

    }, [dispatch, supportsTouch, maxWidthSideBar, sideBarLogic, HMIPlayerStatus]);

    /**
     * deselect states
     * @type {() => void}
     */
    const deselectStates = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>)=> {

        event.preventDefault();

        dispatch(statesActions.deselectAllStates());

    }, [dispatch]);

    const leftPositionSettingIcon: React.CSSProperties = { left: screenWidth - (realTimeIndentation) };
    
    return (
        <div
            className="chart-wrapper"
            ref={chartWrapperRef}
            onClick={deselectStates}
            onDoubleClick={toggleMixMode}
            onMouseLeave={hideStateDetails}
        >
            <RuleText hrMode={hrMode} data={dataWithAlertColor || []}  sensor={sensor} barWidth={barWidth} />
            {rbac.can('histogram-setting:update') && !hrMode && !forceMix ? (
                <div
                    className={`graph-settings ${selection && new Date(selection[1]) > (new Date()) ? 'realtime': ''}`}
                    onClick={editSettings}
                    onMouseMove={onMouseMoveCanvasHandler}
                    onTouchStart={onTouchStartCanvasHandler}
                    onTouchMove={onTouchMoveCanvasHandler}
                    style={leftPositionSettingIcon}
                    itemID={realTimeIndentation > 0 ? 'realtime-setting': 'setting'}
                >
                    <img src={settingsIcon} alt="icon" />
                </div>
            ) : null}
            <canvas
                ref={chartRef}
                className="chart"
                width={screenWidth - realTimeIndentation}
                height={height}
            />
            <canvas
                ref={alertRef}
                className="chart-alert"
                width={screenWidth - realTimeIndentation}
                height={height}
                onClick={onClickCanvasHandler}
                onMouseMove={onMouseMoveCanvasHandler}
                onTouchStart={onTouchStartCanvasHandler}
                onTouchMove={onTouchMoveCanvasHandler}
            />
        </div>
    );
};

export default React.memo(HistogramChart);