import React, { CSSProperties, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Sensor from './Sensor';
import KeySensor from './KeySensor';
import ChangeProduct from '../ChangeProduct/ChangeProduct';
import { Tooltip, ProductsVerticalLineGraph } from '../../../../core/ui/components';
import { MonitoringActions, statesActions } from '../../../../core/actions';
import { GraphActions } from '../../../../base/store/actions';
import {
    ISensor,
    IMonitoringTreeUnitProps, IFlatTreeUnit,
} from '../../../../core/interfaces';
import Can from '../../../../rbac/Can';
import { isMobile } from 'react-device-detect';
import { selectSelectedDashboard } from '../../../../core/selectors/dashboardSelect/selectedDashboardSelector';
import { useParamSelector } from '../../../../hooks/useParamSelector';
import { selectFlatMonitoringTreeItemById } from '../../../../core/selectors/monitoringTree/monitoringTreeSelector';
import {
    selectHmiPlayerMode,
} from '../../../../core/selectors/hmi/playerSelector';
import { selectScreenWidth } from '../../../../core/selectors/dashboard/dashboardSelector';
import {
    calcRealTimeIndentation,
} from '../../../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectMaxWidthSideBar } from '../../../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import { sortOrder } from '../../../../helpers/sortOrder';

/**
 * Monitoring tree unit functional component
 *
 * @param {any} unit
 * @param {any} process
 * @param {any} factory
 * @param {number} index
 * @param {number} processIndex
 * @param {number} factoryIndex
 * @param {number} maxWidthSideBar
 * @param {boolean} visibleSideBar
 * @param {string} searchField
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Unit: React.FC<IMonitoringTreeUnitProps> = (
    {
        unitID,
        process,
        factory,
        index,
        processIndex,
        factoryIndex,
        maxWidthSideBar,
        visibleSideBar,
        searchField,
    }: IMonitoringTreeUnitProps,
) => {


    const dispatch = useDispatch();

    const selectedDashboard = useSelector(selectSelectedDashboard),
        unit = useParamSelector(selectFlatMonitoringTreeItemById, unitID, 'unit') as IFlatTreeUnit,
        HMIPlayerStatus = useSelector(selectHmiPlayerMode);


    const dashboardRealTime = true;
    const screenWidth = useSelector(selectScreenWidth) + useSelector(selectMaxWidthSideBar);
    const realTimeIndentation = useSelector(calcRealTimeIndentation);



    const unitIsMinimized = !unit.isMinimized && unit.data.some((sensor: ISensor) => sensor.isVisible),
        sensorIsKeyParameter = unit.data.some((sensor: ISensor) => sensor.isKeyParameter && sensor.isVisible);

    /**
     * Apply filter and sorting to sensors array
     *
     * @param {ISensor[]} sensors
     *
     * @returns {ISensor[]}
     */
    const filter = useCallback((sensors: ISensor[])=> {

        return sensors.filter(sensor => sensor.name.toLowerCase().match(searchField) && sensor.isVisible);
    }, [searchField]);


    /**
     * Apply filter and sorting to sensors array
     *
     * @param {ISensor[]} sensors
     *
     * @returns {ISensor[]}
     */
    const filterAndSorting = useCallback((sensors: ISensor[]): ISensor[] => {

        const filtered = filter(sensors);

        sortOrder(filtered);

        return filtered;
    }, [filter]);

    /**
     * Mouse move handler
     *
     * @param {React.MouseEvent} event
     */
    const mouseMoveHandler = useCallback((event: React.MouseEvent) => {

        event.preventDefault();

        if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

            if (dashboardRealTime && screenWidth - realTimeIndentation - 1 >= event.pageX) {

                dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));
            }
            if (!dashboardRealTime) {

                dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));

            }

        }

    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, screenWidth, realTimeIndentation]);

    /**
     * Touch start handler
     *
     * @param {React.TouchEvent} event
     */
    const touchStartHandler = useCallback((event: React.TouchEvent) => {

        event.preventDefault();

        if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

            if (dashboardRealTime && screenWidth - realTimeIndentation - 1 >= event.touches[0].pageX) {

                dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
            }

            if (!dashboardRealTime) {

                dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

            }

        }

    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, screenWidth, realTimeIndentation]);

    /**
     * Touch move handler
     *
     * @param {React.TouchEvent} event
     */
    const touchMoveHandler = useCallback((event: React.TouchEvent) => {

        event.preventDefault();

        if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

            if (dashboardRealTime && screenWidth - realTimeIndentation - 1 >= event.touches[0].pageX) {

                dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
            }

            if (!dashboardRealTime) {

                dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

            }

        }

    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, screenWidth, realTimeIndentation]);

    /**
     * Key sensor
     */
    const keySensor = unit.data.find((sensor: ISensor) => sensor.isKeyParameter === true);

    const rightSectionStyle = {
        maxWidth: maxWidthSideBar,
        minWidth: maxWidthSideBar,
        transition: 'max-width 0.2s linear',
    }, visibleSideBarStyle: CSSProperties = {
        fontWeight: 'bold',
        direction: 'ltr',
        color: '#333333',
    };

    const deselectStates = useCallback(() => {

        dispatch(GraphActions.deselectAlertGraph());
        dispatch(statesActions.deselectAllStates());

    }, [dispatch]);

    const peakLeave = useCallback(() => {

        if (HMIPlayerStatus === 'stop') {

            dispatch(GraphActions.peakLeave());

        }

        if (HMIPlayerStatus === 'pause') {

            dispatch(MonitoringActions.peakEnterEmptyLineUnitMonitoringTree());
        }

    }, [dispatch, HMIPlayerStatus]);

    const SensorList = useMemo(() => {
        return filterAndSorting(unit.data).map((sensor, sensorIndex) => {

            if (selectedDashboard && !selectedDashboard.graphs_switch && sensorIndex !== 0) {

                return null;
            }

            return (
                <Sensor
                    key={sensor.id}
                    sensor={sensor}
                    unit={unit}
                    process={process}
                    factory={factory}
                    keySensor={keySensor}
                    visibleSideBar={visibleSideBar}
                    maxWidthSideBar={maxWidthSideBar}
                />
            );
        });

    }, [
        unit,
        factory,
        filterAndSorting,
        keySensor,
        maxWidthSideBar,
        process,
        selectedDashboard,
        visibleSideBar,
    ]);

    return (
        <div
            key={unit.id}
            className={index === 0 && processIndex === 0 && factoryIndex === 0 ? 'process-item first-item-on-tree' : 'process-item'}
        >
            <React.Fragment>
                {unitIsMinimized || sensorIsKeyParameter ?
                    <div className="item-title display-flex"
                         onClick={deselectStates}
                    >
                        <div
                            className="right-section breadcrumb small-height"
                            style={rightSectionStyle}
                        >
                            <Tooltip disableTouchListener title={`${factory.name}/${process.name}/${unit.name}`}>
                                <div className={`breadcrumb-text ${isMobile ? 'is-mobile' : ''}`} >
                                    <div hidden={visibleSideBar}>
                                        {factory.name}/{process.name}/<span>{unit.name}</span>
                                    </div>
                                    {visibleSideBar ?
                                        <div
                                            style={visibleSideBarStyle}
                                        >
                                            {`/${unit.name}`}
                                        </div> : null}
                                </div>
                            </Tooltip>
                            {!visibleSideBar ? (
                                <Can action="product:change">
                                    <ChangeProduct unitId={unit.id} />
                                </Can>
                            ) : null}
                        </div>
                        <div
                            className="left-section product-line"
                            onMouseMove={mouseMoveHandler}
                            onTouchStart={touchStartHandler}
                            onTouchMove={touchMoveHandler}
                            onMouseLeave={peakLeave}
                        >
                            <ProductsVerticalLineGraph
                                unitId={unit.id}
                            />
                        </div>
                    </div>
                    :
                    null
                }
            </React.Fragment>
            {unitIsMinimized ?
                SensorList
                :
                keySensor ?
                    <KeySensor
                        sensor={keySensor}
                        unit={unit}
                        visibleSideBar={visibleSideBar}
                    />
                 : null
            }
        </div>
    );
};

export default Unit;
