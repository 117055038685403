import React, { useCallback } from 'react';
import { ProductsVerticalLineGraph } from '../../../../core/ui/components';
import { useTranslation } from 'react-i18next';
import { IProcessItemTreeProps } from '../../../../core/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { GraphActions } from '../../../../base/store/actions';
import {
    selectHmiPlayerMode,
    selectHmiPlayerSchema,
    selectHmiPlayerValue
} from '../../../../core/selectors/hmi/playerSelector';
import { selectDashboardOnline, selectScreenWidth } from '../../../../core/selectors/dashboard/dashboardSelector';
import {
    calcRealTimeIndentation,
    selectBrushSelection
} from '../../../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectMaxWidthSideBar } from '../../../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import { selectDrawerWidth, selectPositionDrawer } from '../../../../core/selectors/layout/responsiveDrawerSelector';
import * as d3 from 'd3';
import { selectHmiPlayerVisibility } from '../../../../core/selectors/hmi/visibilitySelector';


const ProcessItemTree: React.FC<IProcessItemTreeProps> = (
    {
        visibleSideBar,
        maxWidthSideBar,
        unitId,
        ...props
    }: IProcessItemTreeProps,
) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const HMIPlayerStatus = useSelector(selectHmiPlayerMode);

    const dashboardRealTime = useSelector(selectDashboardOnline);
    const screenWidth = useSelector(selectScreenWidth) + useSelector(selectMaxWidthSideBar);

    const schema = useSelector(selectHmiPlayerSchema);
    const isVisibleSchema = useSelector(selectHmiPlayerVisibility) && schema !== null;

    const realTimeIndentation = useSelector(calcRealTimeIndentation),
        anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom',
        value = useSelector(selectHmiPlayerValue),
        selection = useSelector(selectBrushSelection),
        drawWidth = useSelector(selectDrawerWidth),
        histogramWidth = useSelector(selectScreenWidth) - (isVisibleSchema  && anchor === 'right' ? drawWidth : 0) - realTimeIndentation;

    const scale = d3.scaleTime().range([0, histogramWidth - 1.5])
        .domain(selection);

    /**
     * On mouse move callback
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onMouseMoveCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>)=> {

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

                if (dashboardRealTime && screenWidth - realTimeIndentation - 1 >= event.pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));
                }
                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));

                }

            }

        }

    }, [maxWidthSideBar, dispatch, HMIPlayerStatus, dashboardRealTime, screenWidth, realTimeIndentation]);

    /**
     * On touch start callback
     *
     * @type {(event: React.TouchEvent<HTMLDivElement>) => void}
     */
    const onTouchStartCallback = useCallback((event: React.TouchEvent<HTMLDivElement>)=> {

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause'){

                if (dashboardRealTime && screenWidth - realTimeIndentation - 1 >= event.touches[0].pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
                }

                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

                }

            }

        }

    }, [maxWidthSideBar, dispatch, HMIPlayerStatus, dashboardRealTime, screenWidth, realTimeIndentation]);

    /**
     * On touch move callback
     *
     * @type {(event: React.TouchEvent<HTMLDivElement>) => void}
     */
    const onTouchMoveCallback = useCallback((event: React.TouchEvent<HTMLDivElement>)=> {

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

                if (dashboardRealTime && screenWidth - realTimeIndentation - 1 >= event.touches[0].pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
                }

                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

                }

            }

        }

    }, [maxWidthSideBar, dispatch, HMIPlayerStatus, dashboardRealTime, screenWidth, realTimeIndentation]);

    /**
     * On mouse leave callback
     *
     * @type {() => void}
     */
    const onMouseLeaveCallback = useCallback(()=> {

        if (HMIPlayerStatus === 'stop') {

            dispatch(GraphActions.peakLeave());

        }

        if (HMIPlayerStatus === 'pause') {

            dispatch(GraphActions.peakEnterEmptyLine(Math.ceil(scale(new Date(value)))));
        }

    }, [dispatch, HMIPlayerStatus, scale, value]);

    const rightSectionStyle ={
        maxWidth: maxWidthSideBar,
        minWidth: maxWidthSideBar,
        transition: 'max-width 0.2s',
        transitionTimingFunction: 'cubic-bezier(0.1, 0.1, 0.1, 0.1)',
    };

    return (
        <div className="item-title no-pointer">
            <div
                className={visibleSideBar ? 'right-section left-padding-3 limit-padding hidden' : 'right-section left-padding-3 product-scheme'}
                style={rightSectionStyle}
            >
                {!props.displayProductRule && <span className="product-scheme-title">{t('PRODUCT')}</span>}
            </div>
            <div
                className="left-section no-chart product-scheme"
                onMouseMove={onMouseMoveCallback}
                onTouchStart={onTouchStartCallback}
                onTouchMove={onTouchMoveCallback}
                onMouseLeave={onMouseLeaveCallback}
            >
                {!props.displayProductRule ?
                    <ProductsVerticalLineGraph
                        unitId={unitId}
                    />
                :
                    null
                }
            </div>
        </div>
    );
};

export default React.memo(ProcessItemTree);