import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CropFreeIcon from '@material-ui/icons/CropFree';
import TuneIcon from '@material-ui/icons/Tune';
import CloseIcon from '@material-ui/icons/Close';
import { GraphActions } from '../../../base/store/actions';
import { HmiPlayerActions, HmiSchemaAction } from '../../../core/actions';
import { selectHmiPlayerVisibility } from '../../../core/selectors/hmi/visibilitySelector';
import {
    selectHmiPlayerMode,
    selectHmiPlayerSchema,
    selectHmiPlayerValue,
} from '../../../core/selectors/hmi/playerSelector';
import { RootState } from '../../../core/store';
import { IHmiSchema } from '../../../core/interfaces';
import { HmiPlayerWatcher } from '../../../core/watchers/hmiPlayer';
import ResponsiveDrawer from '../../../core/ui/components/Layout/ResponsiveDrawer';
import Schema from './Schema';
import ControlPanel from './ControlPanel';

import './styles/Player.scss';
import HmiParameters from './HmiParameters';
import {
    selectDrawerWidth, selectIsFullScreenDrawer,
    selectPositionDrawer,
} from '../../../core/selectors/layout/responsiveDrawerSelector';
import { LayoutActions } from '../../../core/actions/layout';
import { fullBrowserVersion, isMobile, isMobileOnly } from 'react-device-detect';
import { selectMaxWidthSideBar } from '../../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import { usePrevious } from '../../../hooks/usePrevious';
import * as d3 from 'd3';
import {
    calcRealTimeIndentation,
    selectBrushSelection,
} from '../../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectScreenWidth } from '../../../core/selectors/dashboard/dashboardSelector';
import { selectHmiErrors } from '../../../core/selectors/hmiSchemas/hmiOpenSchemeSelector';
import { CommonError } from '../../../core/ui/components';

interface IProps {
    isVisible: boolean;
    schema: IHmiSchema | null,
    toggleHMI: (isVisible: boolean) => void;
    setSchema: (schema: IHmiSchema | null) => void;
    stopPlayer: () => void;
}

const DEFAULT_WIDTH = window.innerWidth;

/**
 * HMI Player component
 *
 * @param {boolean} isVisible
 * @param {IHmiSchema} schema
 * @param {function} toggleHMI
 * @param {function} setSchema
 * @param {function} stopPlayer
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const Player: FC<IProps> = ({ isVisible, schema, toggleHMI, setSchema, stopPlayer }: IProps) => {

    const dispatch = useDispatch();

    const hmiParametersBtnRef = useRef<null | HTMLButtonElement>(null);
    const [hmiWrapperRef, setHmiWrapperRef] = useState<null | HTMLElement>(null);
    const [hmiParametersMenuAnchor, setParametersHmiMenuAnchor] = useState<null | HTMLButtonElement>(null);


    const anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom';
    // const drawerParam = useSelector(selectDrawerHeight);
    const isFullScreen = useSelector(selectIsFullScreenDrawer) || false;
    const barWidth = useSelector(selectMaxWidthSideBar),
        value = useSelector(selectHmiPlayerValue),
        selection = useSelector(selectBrushSelection),
        realTimeIndentation = useSelector(calcRealTimeIndentation),
        drawWidth = useSelector(selectDrawerWidth),
        screenWidth = useSelector(selectScreenWidth) - (isVisible  && anchor === 'right' ? drawWidth : 0) - realTimeIndentation,
        HMIPlayerStatus = useSelector(selectHmiPlayerMode),
        errorsHmi = useSelector(selectHmiErrors);

    const scale = d3.scaleTime().range([0, screenWidth - 1.5])
        .domain(selection);

    const preAnchor = usePrevious(anchor);
    const preIsFullScreen = usePrevious(isFullScreen);
    const preBarWidth = usePrevious(barWidth);

    useEffect(() => {

        const watcher = new HmiPlayerWatcher();

        window.addEventListener('resize', runForceUpdate);
        window.addEventListener('orientationchange', runForceUpdate);

        return () => {

            watcher.unsubscribe();

            window.removeEventListener('resize', runForceUpdate);
            window.addEventListener('orientationchange', runForceUpdate);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //TODO: Implemement resize for workarea width and height
    const schemaContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {

        setParametersHmiMenuAnchor(null);

    }, [anchor]);

    /**
     * Handle drawer close event
     */
    const onClose =useCallback(() => {

        toggleHMI(isVisible);
        setSchema(null);
        dispatch(LayoutActions.drawerToggleFullScreen(false));
        dispatch(HmiPlayerActions.setRealTime(false));
        dispatch(HmiSchemaAction.editSchema(null, true));

        stopPlayer();
    }, [dispatch, toggleHMI, setSchema, isVisible, stopPlayer]);
    /**
     * Close HMI menu handler
     */
    const closeHmiMenu = useCallback(() => {

        setParametersHmiMenuAnchor(null);

    }, [setParametersHmiMenuAnchor]);


    /**
     * Update
     */
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const runForceUpdate = useCallback(() => {

        forceUpdate();

        const forceUpdateTimeout = setTimeout(() => {

            forceUpdate();

            clearTimeout(forceUpdateTimeout);

        }, 150);

    }, [forceUpdate]);

    /**
     * Chek landscape.
     *
     * @return {boolean}
     */
    const isLandscape = () => {

        return (window.orientation === 90 || window.orientation === -90);
    };


    useEffect(() => {

        if (!isMobileOnly && ((preAnchor !== anchor) || (preIsFullScreen !== isFullScreen) || (preBarWidth !== barWidth))) {

            forceUpdate();

            runForceUpdate();
        }

    }, [runForceUpdate, forceUpdate, isFullScreen, preAnchor, preIsFullScreen,  anchor, preBarWidth, barWidth]);


    /**
     *
     * @type {() => void}
     */
    const onPressFullScreenBtn = useCallback(() => {

        dispatch(LayoutActions.drawerToggleFullScreen((!isFullScreen)));

    }, [isFullScreen, dispatch]);

    const anchorRule = anchor === 'right' ? 0 : 80;

    const containerHeight = hmiWrapperRef && hmiWrapperRef?.children[0] && schemaContainerRef.current ?
        isFullScreen ?
            '100%' :
            (hmiWrapperRef?.children[0].clientHeight - 70) : 0;

    const schemaContainerStyle: React.CSSProperties = {
        height: fullBrowserVersion && !isMobile? `calc(100% - ${isFullScreen? 230 : !anchorRule? 270 : 187}px)` : containerHeight,
    };

    const preventClickAction = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();
        // event.stopPropagation();

    }, []);

    /**
     * On mouse enter callback
     *
     * @type {() => void}
     */
    const onMouseEnterCallback = useCallback(() => {

        if (HMIPlayerStatus !== 'stop') {

            dispatch(GraphActions.peakEnterEmptyLine(Math.ceil(scale(new Date(value)))));
        }

    }, [dispatch, scale, value, HMIPlayerStatus]);

    useEffect(() => {

        if (schema) {

            dispatch(HmiSchemaAction.getSchema(schema));
        }

        return () => {
            if (schema) {

                HmiSchemaAction.editSchema(schema, true);
            }
        };

    }, [schema]);

    useEffect(() => {

        let closeTimeout: NodeJS.Timeout | undefined = undefined;

        if ('common' in errorsHmi) {

            closeTimeout = setTimeout(() => {

                onClose();
                closeTimeout && clearTimeout(closeTimeout);

            }, 3000);
        }

        return () => {

            closeTimeout && clearTimeout(closeTimeout);
        };

    }, [errorsHmi, errorsHmi]);

    /**
     * Stop propagation
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const stopPropagation = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        // event.stopPropagation();
    }, []);

    return (
        <span onMouseEnter={onMouseEnterCallback} onMouseMove={stopPropagation}>
            <ResponsiveDrawer
                defaultWidth={(DEFAULT_WIDTH - barWidth)/2 > 500? (DEFAULT_WIDTH - barWidth)/2 : 500}
                open={isVisible}
                onClose={onClose}
                anchor={'right'}
               currentRef={setHmiWrapperRef}
            >
                <h3 className="player-title" >
                    {schema? schema.name : ''}
                </h3>
                <CommonError errors={errorsHmi} />
                <div
                    className="button-panel"
                    onClick={preventClickAction}
                >
                    {!isMobileOnly &&
                        <Button
                            className={`parameter-btn ${isFullScreen ? 'active' : ''}`}
                            variant="outlined"
                            onClick={onPressFullScreenBtn}
                        >
                            <CropFreeIcon />
                        </Button>
                    }
                    <Button
                        variant="outlined"
                        ref={hmiParametersBtnRef}
                        className={`parameter-btn ${hmiParametersMenuAnchor ? 'active' : ''}`}
                        onClick={(event)=>{
                            if (!hmiParametersMenuAnchor) {
                                setParametersHmiMenuAnchor(event.currentTarget);
                            }
                        }}
                    >
                        <TuneIcon />
                    </Button>
                    <IconButton className="parameter-btn" aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div
                    className="schema-container"
                    ref={schemaContainerRef}
                    style={schemaContainerStyle}
                    onClick={preventClickAction}
                >
                    {/*{schema && isVisible && (*/}
                        <Schema
                            schema={schema}
                            workareaWidth={schemaContainerRef?.current?.clientWidth || 0}
                            workareaHeight={schemaContainerRef?.current?.clientHeight || 0}
                        />
                    {/*)}*/}
                </div>
                <ControlPanel />
            </ResponsiveDrawer>
            <HmiParameters
                anchorEl={hmiParametersMenuAnchor}
                open={Boolean(hmiParametersMenuAnchor)}
                onClose={closeHmiMenu}
            />
        </span>
    );
};

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const schema = selectHmiPlayerSchema(state);

    return {
        isVisible: selectHmiPlayerVisibility(state) && schema !== null,
        schema,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    toggleHMI: GraphActions.toggleHMI,
    setSchema: HmiPlayerActions.setSchema,
    stopPlayer: HmiPlayerActions.stop,
});

export default connect(mapStateToProps, mapDispatchToProps)(Player);
