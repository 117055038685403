import React, { useCallback, useState, useEffect } from 'react';
import { Checkbox } from '@material-ui/core';
import { ReactComponent as StateIcon } from '../../../../core/ui/assets/images/icons/state_icon.svg';
import { ReactComponent as StateSelectedIcon } from '../../../../core/ui/assets/images/icons/downtime-selected.svg';
import { IKpiStateItemProps } from '../../../../core/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { FormActions, MonitoringActions, statesActions } from '../../../../core/actions';
import { GraphActions } from '../../../../base/store/actions';
import { StateChart } from '../../../../base/components';
import {
    selectMaxWidthSideBar,
    selectVisibleSideBar,
} from '../../../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import { selectGraphBarHeight } from '../../../../core/selectors/graphBarHeight/graphBarHeightSelector';
import { selectSelectedDashboard } from '../../../../core/selectors/dashboardSelect/selectedDashboardSelector';
import {
    selectHmiPlayerMode,
    selectHmiPlayerSchema,
    selectHmiPlayerValue
} from '../../../../core/selectors/hmi/playerSelector';
import { selectDashboardOnline, selectScreenWidth } from '../../../../core/selectors/dashboard/dashboardSelector';
import {
    calcRealTimeIndentation,
    selectBrushSelection
} from '../../../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectHmiHoverItem } from '../../../../core/selectors/hmi/playerHoverItem';
import { selectDrawerWidth, selectPositionDrawer } from '../../../../core/selectors/layout/responsiveDrawerSelector';
import * as d3 from 'd3';
import { selectHmiPlayerVisibility } from '../../../../core/selectors/hmi/visibilitySelector';
import { HmiObjectAction } from '../../../../core/actions/hmiObjectAction';
import { isDesktop, isMobile } from 'react-device-detect';

// TODO Uncomment after adding KPI
// import {ReactComponent as Function} from "../../../../core/ui/assets/images/icons/function-v2.svg";
// import {ReactComponent as FunctionSelected} from "../../../../core/ui/assets/images/icons/function-selected.svg";


/**
 * Formula Component
 *
 * @param {Object} process
 * @param {ISensor} sensor
 * @constructor
 *
 * @return JSX.Element
 */
const KpiStateItem: React.FC<IKpiStateItemProps> = (
    {
        sensor,
        unit,
    }: IKpiStateItemProps,
) => {

    const [isVisible, setIsVisible] = useState<boolean | undefined>(sensor.isVisible);
    const schema = useSelector(selectHmiPlayerSchema);
    const isVisibleSchema = useSelector(selectHmiPlayerVisibility) && schema !== null;

    const visibleSideBar = useSelector(selectVisibleSideBar),
        maxWidthSideBar = useSelector(selectMaxWidthSideBar),
        graphHeight = useSelector(selectGraphBarHeight),
        selectedDashboard = useSelector(selectSelectedDashboard),
        HMIPlayerStatus = useSelector(selectHmiPlayerMode);

    const hoverItem = useSelector(selectHmiHoverItem);
    const dashboardRealTime = useSelector(selectDashboardOnline);
    const screenWidth = useSelector(selectScreenWidth) + useSelector(selectMaxWidthSideBar);
    const realTimeIndentation = useSelector(calcRealTimeIndentation),
        anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom',
        value = useSelector(selectHmiPlayerValue),
        selection = useSelector(selectBrushSelection),
        drawWidth = useSelector(selectDrawerWidth),
        histogramWidth = useSelector(selectScreenWidth) - (isVisibleSchema  && anchor === 'right' ? drawWidth : 0) - realTimeIndentation;

    const scale = d3.scaleTime().range([0, histogramWidth - 1.5])
        .domain(selection);

    const dispatch = useDispatch();

    useEffect(() => {

        if (isVisible !== sensor.isVisible) {
            setIsVisible(sensor.isVisible);
        }

    }, [sensor]);

    /**
     * On mouse move callback
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onMouseMoveCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

                if (dashboardRealTime && screenWidth - realTimeIndentation - 1 >= event.pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));
                }
                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));

                }
            }
        }

    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, screenWidth, realTimeIndentation]);

    /**
     * On touch start callback
     *
     * @type {(event: React.TouchEvent<HTMLDivElement>) => void}
     */
    const onTouchStartCallback = useCallback((event: React.TouchEvent<HTMLDivElement>) => {

        event.preventDefault();

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

                if (dashboardRealTime && screenWidth - realTimeIndentation - 1 >= event.touches[0].pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
                }

                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

                }
            }

        }

    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, screenWidth, realTimeIndentation]);

    /**
     * On touch move callback
     *
     * @type {(event: React.TouchEvent<HTMLDivElement>) => void}
     */
    const onTouchMoveCallback = useCallback((event: React.TouchEvent<HTMLDivElement>) => {

        event.preventDefault();

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

                if (dashboardRealTime && screenWidth - realTimeIndentation - 1 >= event.touches[0].pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
                }

                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

                }
            }

        }
    }, [maxWidthSideBar, dispatch, HMIPlayerStatus, dashboardRealTime, screenWidth, realTimeIndentation]);

    /**
     * On mouse leave callback
     *
     * @type {() => void}
     */
    const onMouseLeaveCallback = useCallback(() => {

        if (HMIPlayerStatus === 'stop') {

            dispatch(GraphActions.peakLeave());
        }

        if (HMIPlayerStatus === 'pause') {

            dispatch(GraphActions.peakEnterEmptyLine(Math.ceil(scale(new Date(value)))));
        }

    }, [dispatch, HMIPlayerStatus, scale, value]);

    /**
     * Deselect all states and alerts
     *
     * @type {() => void}
     * */
    const deselectStatesAndAlerts = useCallback(() => {

        dispatch(statesActions.deselectAllStates());

        dispatch(GraphActions.deselectAlertGraph());

        dispatch(GraphActions.barToggleTableView(true, 0));

        dispatch(HmiObjectAction.hoverSensorOnTree(sensor));

    }, [dispatch]);

    /**
     * Update monitoring tree callback
     *
     * @type {() => void}
     */
    const updateMonitoringTreeCallback = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        event.preventDefault();

        event.stopPropagation();

        if (selectedDashboard && !isMobile) {

            dispatch(MonitoringActions.update(
                selectedDashboard.id,
                'sensor',
                sensor.id,
                {
                    sensorChange: {
                        ...sensor,
                        isVisible: !isVisible,
                        position: sensor.position,
                    },
                },
            ));

            setIsVisible(!isVisible);
        }

        dispatch(FormActions.toggle(false));

    }, [sensor, selectedDashboard, dispatch, isVisible, setIsVisible]);

    /**
     * Stop propagation callback
     */
    const stopPropagationCallback = useCallback((event) => {

        event.stopPropagation();

    }, []);

    const rightSectionSensorNameStyle = {
        maxWidth: maxWidthSideBar,
        minWidth: maxWidthSideBar,
        transition: 'max-width 0.2s',
        transitionTimingFunction: 'cubic-bezier(0.1, 0.1, 0.1, 0.1)',
        height: graphHeight,
    };
    /**
     * Mouse enter callback  for the hmi player
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onMouseEnterForPlayer = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent> |  React.TouchEvent<HTMLDivElement>) => {

        event.preventDefault();

        dispatch(HmiObjectAction.hoverSensorOnTree(sensor));

    }, [dispatch, sensor]);

    /**
     * Mouse leave callback  for the hmi player
     *
     * @type {() => void}
     */
    const onMouseLeaveForPlayer = useCallback(() => {

        dispatch(HmiObjectAction.hoverSensorOnTree(undefined));

    }, [dispatch]);

    /**
     * Update monitoring tree callback
     *
     * @type {() => void}
     */
    const onTouchUpdateMonitoringTreeCallback = useCallback(async (event: React.TouchEvent<HTMLButtonElement>) => {

        event.preventDefault();

        event.stopPropagation();

        if (selectedDashboard && !isDesktop) {


            const setTimeoutUpdate = setTimeout(()=>{
                dispatch(MonitoringActions.update(
                    selectedDashboard.id,
                    'sensor',
                    sensor.id,
                    {
                        sensorChange: {
                            ...sensor,
                            isVisible: !isVisible,
                            position: sensor.position,
                        },
                    },
                ));

                setIsVisible(!isVisible);

                dispatch(FormActions.toggle(false));
                clearTimeout(setTimeoutUpdate);
            }, 50);


        }

    }, [selectedDashboard, sensor, dispatch, setIsVisible, isVisible]);


    if (sensor.sensorType !== 'state') {

        return null;
    }

    return (
        <React.Fragment>
            {/*{ sensor.type === 'kpi' ?*/}
            {/*    <div*/}
            {/*        className={'item-title no-pointer'}*/}
            {/*        onMouseMove={event => {*/}

            {/*            if (peakEnterEmpty) {*/}
            {/*                peakEnterEmpty(event.pageX - maxWidthSideBar!)*/}
            {/*            }*/}
            {/*        }}*/}
            {/*        onTouchStart={event => {*/}

            {/*            props.deselectStates();*/}

            {/*            props.deselectAlertGraph();*/}

            {/*            if (peakEnterEmpty) {*/}
            {/*                peakEnterEmpty(event.touches[0].pageX - maxWidthSideBar!)*/}
            {/*            }*/}
            {/*        }}*/}
            {/*        onTouchMove={event => {*/}

            {/*            if (peakEnterEmpty) {*/}
            {/*                peakEnterEmpty(event.touches[0].pageX - maxWidthSideBar!)*/}
            {/*            }*/}
            {/*        }}*/}
            {/*        onMouseLeave={() => {*/}
            {/*            if (peakLeave) {*/}
            {/*                peakLeave();*/}
            {/*            }*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <div*/}
            {/*            className={visibleSideBar ? 'right-section left-padding-4 limit-padding hidden' : 'right-section left-padding-4'}*/}
            {/*            style={{*/}
            {/*                maxWidth: maxWidthSideBar,*/}
            {/*                minWidth: maxWidthSideBar,*/}
            {/*                transition: 'max-width 0.2s',*/}
            {/*                transitionTimingFunction: 'cubic-bezier(0.1, 0.1, 0.1, 0.1)'*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            <Checkbox*/}
            {/*                icon={*/}
            {/*                    <Function*/}
            {/*                        style={{*/}
            {/*                            height: 24,*/}
            {/*                            width: 24*/}
            {/*                        }}/>*/}
            {/*                }*/}
            {/*                checkedIcon={*/}
            {/*                    <FunctionSelected*/}
            {/*                        style={{*/}
            {/*                            height: 24,*/}
            {/*                            width: 24*/}
            {/*                        }}/>*/}
            {/*                }*/}
            {/*                value="factory"*/}
            {/*                checked={sensor.isVisible}*/}
            {/*                className={''}*/}
            {/*                onChange={(event, checked) => {*/}

            {/*                    processAction(unit.id, checked, "kpi", sensor.id)*/}
            {/*                }}*/}
            {/*            />*/}
            {/*            KPI*/}
            {/*        </div>*/}
            {/*        <div*/}
            {/*            className={sensor.isVisible ? "left-section with-chart" : "left-section"}>*/}
            {/*            {sensor.isVisible ?*/}
            {/*                <div>{*/}
            {/*                     this will be a chart*/}
            {/*                    chart will be here</div>*/}
            {/*                : null}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    : ''*/}
            {/*}*/}
            {/*{ sensor.type === 'state' ?*/}
            <div
                className={`item-title no-pointer ${hoverItem?.sensorId === sensor.id ? 'hover' : ''}`}
            >
                {/* Added a hidden element for the correct rendering of the icon in the radio button. Remove icons after redrawing.*/}
                <StateIcon
                    style={{
                        display: 'none',
                    }}
                />
                <div
                    className={visibleSideBar ? 'right-section sensor-name left-padding-4 limit-padding hidden' : 'right-section sensor-name left-padding-4'}
                    style={rightSectionSensorNameStyle}
                    onClick={deselectStatesAndAlerts}
                    onTouchStart={deselectStatesAndAlerts}
                    onMouseEnter={onMouseEnterForPlayer}
                    onMouseLeave={onMouseLeaveForPlayer}
                    onTouchEnd={onMouseLeaveForPlayer}
                >
                    <Checkbox
                        icon={
                            <StateIcon
                                className="tree-icon-item"
                            />
                        }
                        checkedIcon={
                            <StateSelectedIcon
                                className
                                    ="tree-icon-item"
                            />
                        }
                        value="factory"
                        checked={isVisible}
                        className={''}
                        onClick={updateMonitoringTreeCallback}
                        onTouchStart={onTouchUpdateMonitoringTreeCallback}
                    />
                    <span className="sensor-name">{sensor.name}</span>
                </div>
                <div
                    className={sensor.isVisible ? 'left-section with-chart' : 'left-section'}
                    style={{ height: graphHeight }}
                    onClick={stopPropagationCallback}
                    onMouseMove={onMouseMoveCallback}
                    onTouchMove={onTouchMoveCallback}
                    onTouchStart={onTouchStartCallback}
                    onMouseLeave={onMouseLeaveCallback}
                >
                    {isVisible ? 
                        <span>
                            <StateChart sensor={sensor} currentUnit={unit} blockMix tableTitle={''} hrMode={false} />
                        </span> : null
                    }
                </div>
            </div>
            {/*    : ''*/}
            {/*}*/}
        </React.Fragment>
    );
};

export default React.memo(KpiStateItem);
