import SocketProvider from '../../core/providers/socketProvider';
import { IActivateProduct, IHistogramData } from '../../core/interfaces';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardActions, ProductActions } from '../../core/actions';
import { selectAppSettings } from '../../core/selectors/appSetting/appSettingSelector';
import { appConfig } from '../../config/appConfig';
import { AppRoles } from '../../rbac/roles';
import { history, poolingGaps } from '../../helpers';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import {
    selectDashboardOnline,
    selectScreenWidth,
} from '../../core/selectors/dashboard/dashboardSelector';
import {
    calcRealTimeIndentation,
    selectBrushSelection,
    selectGraphMinimapBrushRule,
} from '../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import {
    selectMonitoringTree,
    selectUnitsForCurrentDashboard,
} from '../../core/selectors/monitoringTree/monitoringTreeSelector';
import { selectCurrentUser } from '../../core/selectors/auth/authSelector';
import { selectGraphPeriodRange } from '../../core/selectors/graphPeriod/graphPeriodSelector';
import { usePrevious } from '../usePrevious';
import { isEmptyArray } from '../../helpers/isEmptyArray';
import {
    selectMaxWidthSideBar
} from '../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';

const socket = new SocketProvider();

export const useMainSocket = (): void => {

        const dispatch = useDispatch();

    const [socketId, setSocketId] = useState<string | undefined>(undefined);
    let socketMessageId = 0,
        // socketNarrowMessageId = 0,
        socketMessageIdProduct = 0,
        socketMessageIdForRange = 0,
        // socketNarrowMessageIdForRange = 0,
        overrideSensorData = false,
        overrideProductData = false,
        rangePeriodUpdated = false,
        firstLoad = true,
        firstLoadRange = true,
        rangeRule = true;

    const sideBarMaxWidth = useSelector(selectMaxWidthSideBar),
        settings = useSelector(selectAppSettings),
        valueRepeatTimeout = settings.valueRepeatTimeout,
        brushSelection = useSelector(selectBrushSelection),
        previousBrushSelection = usePrevious(brushSelection),
        screenWidth = useSelector(selectScreenWidth) - useSelector(calcRealTimeIndentation),
        previousScreenWidth = usePrevious(screenWidth),
        dashboardOnline = useSelector(selectDashboardOnline),
        prewDashboardOnline = usePrevious(dashboardOnline),
        brushRule = useSelector(selectGraphMinimapBrushRule),
        arrOfUnit = useSelector(selectUnitsForCurrentDashboard),
        user = useSelector(selectCurrentUser),
        brushRange = useSelector(selectGraphPeriodRange),
        previousBrushRange = usePrevious(brushRange),
        // narrowScreenWidth = screenWidth - useSelector(selectDrawerWidth),
        // IsResize = useSelector(selectDrawerIsResize),
        // previousIsResize = usePrevious(IsResize),
        monitoringTree = useSelector(selectMonitoringTree);


    //
    // socket.listen('getNarrowGraphData', data => processSocketResponse(data));
    // socket.listen('narrowGraphDataUpdate', data => processSocketUpdates(data));
    // socket.listen('getNarrowGraphDataRange', data => processSocketResponse(data));
    // socket.listen('narrowGraphDataRangeUpdate', data => processSocketUpdates(data));

    /**
     * Update screen width available for charts
     */
    const updateScreenWidth = useCallback(() => {

        const sideBarWidth = JSON.parse(localStorage.getItem('sidebar') as string) ? appConfig.dashboardLeftOffsetSmall : appConfig.dashboardLeftOffsetLarge;

        const screenWidth = window.innerWidth < 960 ? 960 - sideBarWidth : window.innerWidth - sideBarWidth;

        dispatch(DashboardActions.updateScreenWidth(screenWidth));

    }, [
        dispatch,
        sideBarMaxWidth,
    ]);
    /**
     * Get socket ID
     *
     * @param {string} id
     */
    const getSocketId = useCallback((id: string): void => {

        if (socketId !== id) {
            setSocketId(id);

            if (socketId && brushSelection) {

                const [from, to] = brushSelection;

                socket.call('reconnect', {

                    access_token: localStorage.getItem('auth_token'),
                    from: from.toISOString(),
                    to: to.toISOString(),
                    numberOfDataPoints: screenWidth || 1000,
                    messageIdGraph: socketMessageId,
                    messageIdGraphRange: socketMessageIdForRange,
                    messageIdProduct: socketMessageIdProduct,
                    realtime: dashboardOnline,
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSocketId, socketId]);

    useEffect(() => {

        window.addEventListener('resize', updateScreenWidth);
        socket.getSocketId(getSocketId);
        if (isMobile) {
            window.addEventListener('orientationchange', updateScreenWidth);
        }

        callSocketGraphDataRange();
        callSocketActiveProductData();

        return () => {
            window.removeEventListener('resize', updateScreenWidth);
            socket.disconnect();
            if (isMobile) {
                window.removeEventListener('orientationchange', updateScreenWidth);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (monitoringTree.length === 0) {

            dispatch(DashboardActions.processSensorsDataLoaded);

        }

    }, [monitoringTree, dispatch]);



    /**
     * Process response from sockets and call a callback
     *
     * @param {Object} response
     */
    const processSocketResponse = async <D extends unknown>(response: { messageId: number, data: D }): Promise<void> => {

        if (socketMessageId === response.messageId && !isEmptyArray(response.data)) {

            await poolingGaps(response.data as unknown as IHistogramData[], valueRepeatTimeout).then(value => {

                dispatch(DashboardActions.processSensorsData(value));

                overrideSensorData = false;

            });
        }

        // if (socketNarrowMessageId === response.messageId && !isEmptyArray(response.data)) {
        //
        //     poolingGaps(response.data as unknown as IHistogramData[], valueRepeatTimeout).then(value => {
        //
        //        dispatch(DashboardActions.processNarrowSensorsData(value));
        //
        //     });
        // }

        if (socketMessageIdForRange === response.messageId && !isEmptyArray(response.data)) {

            await poolingGaps(response.data as unknown as IHistogramData[], valueRepeatTimeout).then(value => {

                dispatch(DashboardActions.processSensorsDataRange(value));

            });
        }

        // if (socketNarrowMessageIdForRange === response.messageId && !isEmptyArray(response.data)) {
        //
        //     poolingGaps(response.data as unknown as IHistogramData[], valueRepeatTimeout).then(value => {
        //
        //         dispatch(DashboardActions.processNarrowSensorsDataRange(value));
        //
        //     });
        // }
    };

    /**
     * Process response from update event and call a callback
     *
     * @param {Object} response
     */
    const processSocketUpdates = async <D extends unknown>(response: { messageId: number, data: D }): Promise<void> => {

        if (socketMessageId === response.messageId && !isEmptyArray(response.data)) {
            await poolingGaps(response.data as unknown as IHistogramData[], valueRepeatTimeout).then(value => {

            rangePeriodUpdated = true;

            dispatch(DashboardActions.updateSensorData(value));

            });

        }

        if (socketMessageIdForRange === response.messageId && !isEmptyArray(response.data)) {
            await poolingGaps(response.data as unknown as IHistogramData[], valueRepeatTimeout).then(value => {

                dispatch(DashboardActions.updateSensorDataRange(value));
            });

        }
    };

    /**
     * Process response from sockets and call a callback
     *
     * @param {Object} response
     */
    const processProductSocketResponse = (response: { messageId: number, data: IActivateProduct[] }): void => {

        if (socketMessageIdProduct === response.messageId && !isEmptyArray(response.data)) {

            dispatch(ProductActions.processProductData(response.data, overrideSensorData, rangeRule));

            overrideProductData = false;
        }
    };

    /**
     * Process response from update event and call a callback
     *
     * @param {Object} response
     */
    const processProductSocketUpdates = (response: { messageId: number, data: IActivateProduct[] }) => {

        if (socketMessageIdProduct === response.messageId && !isEmptyArray(response.data)) {

            dispatch(ProductActions.updateProductData(response.data));
        }
    };

    /**
     * Set Positive Override
     */
    const setPositiveOverride = () => {
        overrideSensorData = true;

        overrideProductData = true;
    };

    /**
     * Call Active Product Data
     */
    const callSocketActiveProductData = () => {

        socketMessageIdProduct = Math.floor(100000000 + (Math.random() * 900000000));

        setPositiveOverride();

        if (brushRange) {
            const getActiveProductDataArgs = {
                messageId: socketMessageIdProduct,
                from: brushRange.startDate.toISOString(),
                to: brushRange.endDate.toISOString(),
                units: arrOfUnit ? arrOfUnit : [],
                access_token: localStorage.getItem('auth_token'),
                subscribe: dashboardOnline,
            };

            socket.call('getActiveProductData', getActiveProductDataArgs, processProductSocketResponse);
        }
    };

    /**
     * Call Graph Data
     */
    const callSocketGraphData = () => {

        socketMessageId = Math.floor(100000000 + (Math.random() * 900000000));

        setPositiveOverride();

        if (previousBrushRange && brushRange && brushRange !== previousBrushRange) {

            const getGraphDataArgs = {
                messageId: socketMessageId,
                numberOfDataPoints: screenWidth ? screenWidth : 500,
                from: brushRange.startDate.toISOString(),
                to: brushRange.endDate.toISOString(),
                units: arrOfUnit ? arrOfUnit : [],
                access_token: localStorage.getItem('auth_token'),
                subscribe: dashboardOnline,
            };

            socket.call('getGraphData', getGraphDataArgs, processSocketResponse);
        }
    };

    /**
     * Call Graph Data Range
     */
    const callSocketGraphDataRange = () => {

        socketMessageIdForRange = Math.floor(100000000 + (Math.random() * 900000000));

        setPositiveOverride();

        if (brushSelection !== previousBrushSelection) {
            const [from, to] = brushSelection;

            to.setSeconds(0);
            to.setMilliseconds(0);

            const getGraphDataArgsRange = {
                messageId: socketMessageIdForRange,
                numberOfDataPoints: screenWidth !== 0 ? screenWidth: 500,
                from: from.toISOString(),
                to: to.toISOString(),
                units: arrOfUnit ? arrOfUnit : [],
                access_token: localStorage.getItem('auth_token'),
                subscribe: false,
            };

            socket.call('getGraphDataRange', getGraphDataArgsRange, processSocketResponse);
        }
    };

    // /**
    //  * Call Graph Data
    //  */
    // const callSocketNarrowGraphData = () => {
    //
    //
    //     socketNarrowMessageId = Math.floor(100000000 + (Math.random() * 900000000));
    //
    //     setPositiveOverride();
    //
    //     if (previousBrushRange && brushRange && brushRange !== previousBrushRange) {
    //         const getGraphDataArgs = {
    //             messageId: socketNarrowMessageId,
    //             numberOfDataPoints: narrowScreenWidth !== 0 ? narrowScreenWidth: 500,
    //             from: brushRange.startDate.toISOString(),
    //             to: brushRange.endDate.toISOString(),
    //             units: arrOfUnit ? arrOfUnit : [],
    //             access_token: localStorage.getItem('auth_token'),
    //             subscribe: dashboardOnline,
    //         };
    //
    //         socket.call('getNarrowGraphData', getGraphDataArgs, processSocketResponse);
    //     }
    // };

    // /**
    //  * Call Graph Data Range
    //  */
    // const callSocketNarrowGraphDataRange = () => {
    //
    //     socketNarrowMessageIdForRange = Math.floor(100000000 + (Math.random() * 900000000));
    //
    //     setPositiveOverride();
    //
    //     if (brushSelection !==  previousBrushSelection) {
    //         const [from, to] = brushSelection;
    //
    //         const getGraphDataArgsRange = {
    //             messageId: socketNarrowMessageIdForRange,
    //             numberOfDataPoints: narrowScreenWidth !== 0 ? narrowScreenWidth : 500,
    //             from: from.toISOString(),
    //             to: to.toISOString(),
    //             units: arrOfUnit ? arrOfUnit : [],
    //             access_token: localStorage.getItem('auth_token'),
    //             subscribe: false,
    //         };
    //
    //         socket.call('getNarrowGraphDataRange', getGraphDataArgsRange, processSocketResponse);
    //     }
    // };

    useLayoutEffect(() => {

        socket.listen('getGraphData', data => processSocketResponse(data));
        socket.listen('graphDataUpdate', data => processSocketUpdates(data));
        socket.listen('getGraphDataRange', data => processSocketResponse(data));
        socket.listen('graphDataRangeUpdate', data => processSocketUpdates(data));
        socket.listen('getActiveProductData', data => processProductSocketResponse(data));
        socket.listen('activeProductUpdate', data => processProductSocketUpdates(data));

        updateScreenWidth();

    }, [
        updateScreenWidth,
        processSocketResponse,
        processSocketUpdates,
        processProductSocketResponse,
        processProductSocketUpdates,
    ]);

    useEffect(() => {
        if (!settings.isConfigured && !settings.hasDashboards && user && user.role === AppRoles.SUPER) {

            history.push('/configuration');

        }
    }, [settings, user]);

    /**
     *
     */
    useLayoutEffect(() => {

        if (
            brushSelection &&
            ((previousBrushSelection && brushSelection !== previousBrushSelection) || !previousBrushSelection) &&
            brushRule
        ) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [from, to] = brushSelection;

            if (brushRange) {

                // eslint-disable-next-line react-hooks/exhaustive-deps
                rangeRule = new Date(brushRange.startDate).getTime() === new Date(from).getTime();


            }


            if (brushSelection !== previousBrushSelection ||
                firstLoad ||
                (screenWidth && screenWidth !== previousScreenWidth && !isMobile)) {

                // if (!timerRule) {
                //
                //     dispatch(DashboardActions.processSensorsDataLoaded());
                // }

                callSocketGraphDataRange();
                // callSocketNarrowGraphDataRange();

                // eslint-disable-next-line react-hooks/exhaustive-deps
                firstLoad = false;
            }

        }

    }, [
        previousBrushSelection,
        brushSelection,
        brushRule,
    ]);

    /**
     * main range
     */
    useEffect(() => {

        if (
            brushRange
            &&
            ((previousBrushRange && brushRange !== previousBrushRange) || !previousBrushRange) &&
            brushRule
        ) {

            if ((brushRange && previousBrushRange?.startDate &&
                    moment(brushRange.startDate).isAfter(previousBrushRange.startDate)) ||
                firstLoadRange ||
                (screenWidth && screenWidth !== previousScreenWidth && !isMobile)) {

                callSocketGraphData();
                // callSocketNarrowGraphData();

                // eslint-disable-next-line react-hooks/exhaustive-deps
                firstLoadRange = false;

            }
        }

    }, [brushRange, screenWidth]);

    useEffect(() => {

        callSocketActiveProductData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        arrOfUnit,
        brushRange,
    ]);


    //
    // useEffect(() => {
    //
    //     if (!firstLoad && !IsResize && previousIsResize !== IsResize && !isMobile) {
    //
    //         // callSocketNarrowGraphDataRange();
    //         //
    //         // callSocketNarrowGraphData();
    //     }
    //
    //
    // }, [firstLoad, IsResize, previousIsResize, callSocketNarrowGraphDataRange, callSocketNarrowGraphData]);

    // useLayoutEffect(() => {
    //
    //     if (sideBarMaxWidth && sideBarMaxWidth !== previousSideBarMaxWidth) {
    //
    //         updateScreenWidth();
    //     }
    //
    // }, [updateScreenWidth, sideBarMaxWidth, previousSideBarMaxWidth]);



    useEffect(() => {

        if (dashboardOnline !== prewDashboardOnline) {

            if (!dashboardOnline) {

                socket.stop('getGraphData', processSocketResponse);
                socket.stop('getGraphDataRange', processSocketResponse);
                socket.stop('getActiveProductData', processProductSocketResponse);

            } else {

                socket.listen('getGraphData', processSocketResponse);
                socket.listen('getGraphDataRange', processSocketResponse);
                socket.listen('getActiveProductData', processProductSocketResponse);
            }
        }

    }, [dashboardOnline, prewDashboardOnline, processSocketResponse, processProductSocketResponse]);

};