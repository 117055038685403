import React, { useCallback, useEffect, useState } from 'react';
import { graphConstants } from '../../../../core/constants';
import { ReactComponent as DropdownArrowIcon } from '../../../../core/ui/assets/images/icons/dropdown-arrow.svg';
import { Checkbox } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { MonitoringActions } from '../../../../core/actions';
import {
    IActiveProductData,
    ISensorTargetValueState,
    IStructuralTreeUnitTitleProps,
} from '../../../../core/interfaces';
import { Tooltip } from '../../../../core/ui/components';
import { GraphActions } from '../../../../base/store/actions';
import { HistogramChart } from '../../../../base/components';
import { selectSelectedDashboard } from '../../../../core/selectors/dashboardSelect/selectedDashboardSelector';
import {
    selectMaxWidthSideBar,
    selectVisibleSideBar,
} from '../../../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import {
    selectHmiPlayerMode,
    selectHmiPlayerSchema,
    selectHmiPlayerValue,
} from '../../../../core/selectors/hmi/playerSelector';
import { selectDashboardOnline, selectScreenWidth } from '../../../../core/selectors/dashboard/dashboardSelector';
import {
    calcRealTimeIndentation,
    selectBrushSelection
} from '../../../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectHmiHoverItem } from '../../../../core/selectors/hmi/playerHoverItem';
import { selectHmiPlayerVisibility } from '../../../../core/selectors/hmi/visibilitySelector';
import { selectDrawerWidth, selectPositionDrawer } from '../../../../core/selectors/layout/responsiveDrawerSelector';
import * as d3 from 'd3';
import { HmiObjectAction } from '../../../../core/actions/hmiObjectAction';
import { isDesktop } from 'react-device-detect';

const StructuralTreeUnitTitle: React.FC<IStructuralTreeUnitTitleProps> = (
    {
        GetMinimizedUnit,
        unit,
        GetSensorWithKeyParam,
        histogramHeight,
        handleClick,
        checkedIcon,
        icon,
        histogramData,
        activeProductData,
    }: IStructuralTreeUnitTitleProps,
) => {

    const dispatch = useDispatch();

    const histogram = histogramData.filter((value) => value.isKeyParameter === true)[0];

    const visibleSideBar = useSelector(selectVisibleSideBar),
        maxWidthSideBar = useSelector(selectMaxWidthSideBar),
        selectedDashboard = useSelector(selectSelectedDashboard),
        HMIPlayerStatus = useSelector(selectHmiPlayerMode);

    const schema = useSelector(selectHmiPlayerSchema);
    const isVisibleSchema = useSelector(selectHmiPlayerVisibility) && schema !== null;

    const dashboardRealTime = useSelector(selectDashboardOnline);
    const screenWidth = useSelector(selectScreenWidth) + useSelector(selectMaxWidthSideBar);
    const realTimeIndentation = useSelector(calcRealTimeIndentation),
        anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom',
        value = useSelector(selectHmiPlayerValue),
        selection = useSelector(selectBrushSelection),
        drawWidth = useSelector(selectDrawerWidth),
        histogramWidth = useSelector(selectScreenWidth) - (isVisibleSchema  && anchor === 'right' ? drawWidth : 0) - realTimeIndentation;

    const scale = d3.scaleTime().range([0, histogramWidth - 1.5])
        .domain(selection);

    const hoverItem = useSelector(selectHmiHoverItem);


    const [sensorTargetValue, setSensorTargetValue] = useState<ISensorTargetValueState | undefined>(undefined);

    /**
     * CollapseUnit Callback. Updates a component in the monitoring tree.
     */
    const collapseUnitCallback = useCallback(() => {

        handleClick(unit.id, 'unit');

        if (selectedDashboard) {

            dispatch(MonitoringActions.update(selectedDashboard.id, 'unit', unit.id, { isMinimized: !unit.isMinimized }));

            dispatch(HmiObjectAction.hoverSensorOnTree(undefined));

        }

    }, [handleClick, unit, dispatch, selectedDashboard]);

    /**
     * MouseMove Callback. A peak mouse enter the event handler for an empty line.
     */
    const mouseMoveCallback = useCallback((event) => {

        event.preventDefault();

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

                if (dashboardRealTime && screenWidth - realTimeIndentation  - 1 >= event.pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));
                }
                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));

                }
            }

        }

    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, screenWidth, realTimeIndentation]);

    /**
     * TouchStart Callback. A peak mouse enter the event handler for an empty line.
     */
    const touchStartCallback = useCallback((event) => {

        event.preventDefault();

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

                if (dashboardRealTime && screenWidth - realTimeIndentation  - 1 >= event.touches[0].pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
                }

                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

                }
            }

        }
    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, screenWidth, realTimeIndentation]);

    /**
     * TouchMove Callback. A peak mouse enter the event handler for an empty line.
     */
    const touchMoveCallback = useCallback((event) => {

        event.preventDefault();

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

                if (dashboardRealTime && screenWidth - realTimeIndentation - 1 >= event.touches[0].pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
                }

                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

                }
            }

        }
    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, screenWidth, realTimeIndentation]);

    /**
     * MouseLeave Callback. A peak mouse enter the event handler for an empty line.
     */
    const mouseLeaveCallback = useCallback(() => {

        if (HMIPlayerStatus === 'stop') {

            dispatch(GraphActions.peakLeave());
        }

        if (HMIPlayerStatus === 'pause') {

            dispatch(GraphActions.peakEnterEmptyLine(Math.ceil(scale(new Date(value)))));
        }

    }, [dispatch, HMIPlayerStatus, scale, value]);

    useEffect(() => {

        if (activeProductData && GetSensorWithKeyParam) {

            const activeProduct = activeProductData as IActiveProductData,
                activeProducts = activeProduct.activeProducts;

            const sensorTargetValueData = {
                activeProducts: activeProducts,
                targetValues: GetSensorWithKeyParam.targetValues!,
            };

            setSensorTargetValue(sensorTargetValueData);

        }
    }, [activeProductData]);

    /**
     * Mouse enter callback  for the hmi player
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onMouseEnterForPlayer = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent> |  React.TouchEvent<HTMLDivElement>) => {

        event.preventDefault();

        if (!GetMinimizedUnit && GetSensorWithKeyParam) {

            dispatch(HmiObjectAction.hoverSensorOnTree(histogram));
        }

    }, [dispatch, histogram, GetMinimizedUnit, GetSensorWithKeyParam]);

    /**
     * Mouse leave callback  for the hmi player
     *
     * @type {() => void}
     */
    const onMouseLeaveForPlayer = useCallback(() => {
        if (!GetMinimizedUnit && GetSensorWithKeyParam) {

            dispatch(HmiObjectAction.hoverSensorOnTree(undefined));
        }
    }, [dispatch, GetMinimizedUnit, GetSensorWithKeyParam]);

    const rightSectionStyle = {
        maxWidth: maxWidthSideBar,
        minWidth: maxWidthSideBar,
        transition: 'max-width 0.2s',
        transitionTimingFunction: 'cubic-bezier(0.1, 0.1, 0.1, 0.1)',
    };

    const sensorName = `${unit.name}${!GetMinimizedUnit && GetSensorWithKeyParam ? ' (' + GetSensorWithKeyParam.name + ')' : ''}`;

    return (
        <div
            className={`${!GetMinimizedUnit && GetSensorWithKeyParam && histogramHeight !== graphConstants.histogramSmallHeight ? 'item-title extended' : 'item-title '} ${hoverItem?.sensorId === GetSensorWithKeyParam?.id ? 'hover' : ''}`}
        >
            <div
                className="wrap-section"
                onMouseEnter={onMouseEnterForPlayer}
                onTouchStart={onMouseEnterForPlayer}
                onMouseLeave={onMouseLeaveForPlayer}
                onTouchEnd={onMouseLeaveForPlayer}
            >
                <div
                    className={'right-section left-padding-3 ' +
                        (GetMinimizedUnit ? 'active ' : '') +
                        (visibleSideBar ? ' limit-padding hidden ' : '') +
                        (!GetMinimizedUnit && GetSensorWithKeyParam && histogram ? 'sensor-name ' : '') +
                        (isDesktop ? 'desktop' : '')
                    }
                    style={rightSectionStyle}
                    onClick={collapseUnitCallback}
                >
                    <DropdownArrowIcon />
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value="factory"
                        checked={!unit.isMinimized}
                        className={''}
                        disabled
                    />
                    <Tooltip
                        title={sensorName}
                        enterDelay={500}
                        style={{ zIndex: 1200 }}
                        enterTouchDelay={200}
                        leaveTouchDelay={2000}
                        disableTouchListener
                    >
                        <span className={'sensor-name edit'}>
                            {sensorName}
                        </span>
                    </Tooltip>
                </div>
                <div
                    className={!GetMinimizedUnit && GetSensorWithKeyParam ? 'left-section with-chart' : 'left-section no-chart'}
                    onMouseMove={mouseMoveCallback}
                    onTouchStart={touchStartCallback}
                    onTouchMove={touchMoveCallback}
                    onMouseLeave={mouseLeaveCallback}
                >
                    {
                        !GetMinimizedUnit && GetSensorWithKeyParam && histogram ?
                            <HistogramChart
                                sensor={histogram}
                                sensorTargetValue={sensorTargetValue}
                                hrMode={false}
                            />
                            : null
                    }

                </div>
            </div>
        </div>

    );
};

export default React.memo(StructuralTreeUnitTitle);