import React from 'react';
import { connect, useSelector } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Router, Switch, Route } from 'react-router';
import i18next from 'i18next';
import moment from 'moment';

import { UserActions, MouseActions, AppAction } from './core/actions';
import { PrivateRoute } from './core/routes';
import {
    ConfigurationTree,
    Dashboard,
    Login,
    ForgotPassword,
    RestorePassword,
    CreateAccount,
    Setting,
} from './components';
import { history } from './helpers';
import { IAppSettingState, IAuthState } from './core/interfaces';
import { isMobile } from 'react-device-detect';
import { RootState } from './core/store';
import { modules } from './modules';
import { selectDrawerIsResize } from './core/selectors/layout/responsiveDrawerSelector';

interface IApp {
    auth: IAuthState;
    logout: () => void;
    profile: () => void;
    loadAppSetting: () => void;
    trackMouse: (event: {clientX: number, clientY: number}) => void;
    appSetting: IAppSettingState;
}

interface IState {
    profileLoading: boolean;
}

/**
 * Main application component
 *
 * @class App
 */
class App extends React.Component<IApp & WithTranslation, IState> {

    /**
     * Constructor
     *
     * @param {IApp & WithTranslation} props
     */
    constructor(props: IApp & WithTranslation) {

        super(props);

        this.state = {
            profileLoading: true,
        };

        this.props.profile();

        this.props.loadAppSetting();

        this.moduleMount = false;

        this.trackMouseCallback = this.trackMouseCallback.bind(this);
    }

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {

        const { appSetting } = this.props;


        if (appSetting.settings) {

            let language = localStorage.getItem('language');

            if (!language) {

                language = appSetting.settings.language;

                localStorage.setItem('language', language);
            }

            i18next.changeLanguage(language);

            moment.locale(language);
        }

        window.addEventListener('mousemove', this.trackMouseCallback);

        const rootElement = document.body;
        if (rootElement && isMobile) {
            rootElement.classList.add('media-type-mobile');
        }
    }

    componentDidUpdate(prevProps: Readonly<IApp & WithTranslation>, prevState: Readonly<IState>, snapshot?: any) {

        if (!this.moduleMount) {

            this.moduleMountAction();
        }
    }

    /**
     * Callback before the component will be removed from DOM
     */
    componentWillUnmount() {

        window.removeEventListener('mousemove', this.trackMouseCallback);
    }

    /**
     * Module mount status
     *
     * @type {boolean}
     * @private
     */
    private moduleMount: boolean;

    trackMouseCallback(ev: MouseEvent) {
        const { trackMouse } = this.props;

        trackMouse(ev);

    }

    /**
     * Checking Permission to Mount a Module
     */
    moduleMountAction() {

        const { appSetting } = this.props,
            { moduleMount } = this;

        const { settings, isLoaded } = appSetting;

        if (!moduleMount && isLoaded) {

            modules.forEach((module) => {

                /**
                 * optional parameter for control from the main api
                 */
                if (module.id === 'qv-hr') {

                    module.setModuleStatus(settings.qvhr.isEnabled);
                }

            });

            this.moduleMount = settings.qvhr.isEnabled;
        }
    }

    /**
     * User logout
     */
    logout() {

        this.props.logout();
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { auth } = this.props;

        return Object.keys(auth).length > 0 ? (
            <Router history={history}>
                <Switch>
                    <PrivateRoute
                        exact
                        path="/"
                        component={Dashboard}
                        route="dashboard"
                    />
                    <PrivateRoute
                        exact
                        path="/configuration"
                        component={ConfigurationTree}
                        route="configuration"
                    />
                    <PrivateRoute path="/setting" component={Setting} route="setting" />
                    <Route path="/login" component={Login} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route path="/restore/:token" component={RestorePassword} />
                    <Route path="/registration/:token" component={CreateAccount} />
                </Switch>
            </Router>
        ) : null;
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { auth, appSetting } = state;

    return {
        auth,
        appSetting,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    profile: UserActions.profile,
    logout: UserActions.logout,
    trackMouse: MouseActions.track,
    loadAppSetting: AppAction.getAppSetting,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));

