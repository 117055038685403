import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import SocketProvider from '../../providers/socketProvider';
import moment from 'moment';
import { Merge } from '../../../../helpers/mergeType';
import { RootState } from '../../../../core/store';
import { IHrState } from '../../store/reducers';
import { IHRDashboard } from '../../interfaces';
import { selectActiveSensorInTree } from '../../../../core/selectors/monitoringTree/minimapGetActiveSensorInTreeSelector';
import { selectMonitoringTreeState, selectMonitoringTreeStateHR } from '../../store/selectors/monitoringTreeSelector';
import { selectScreenWidth } from '../../../../core/selectors/dashboard/dashboardSelector';

interface IIntervals {
    employeeId: string,
    intervals: {
        x: number,
        width: number,
        color: string,
    }[]
}

interface IMinimapHr {
    rangeDate?: Date[] | undefined;
    numberOfDataPoints?: number;
    monitoringTree?: null | IHRDashboard;
    positionOnVerticalLine: number;
    screenWidth?: number;
    enableMinimap?: boolean;
}

interface IMinimapHrState {
    graphs: IIntervals[]
}

class MinimapHr extends React.Component<IMinimapHr, IMinimapHrState> {

    constructor(props: IMinimapHr) {
        super(props);

        this.state = {
            graphs: [],
        };

        this.socket = new SocketProvider();

        this.socketMessageId = 0;

        this.renderMiniature = this.renderMiniature.bind(this);
        this.handlerRef = this.handlerRef.bind(this);
    }

    /**
     * componentDidMount
     */
    componentDidMount() {

        this.socket.listen('getMinimapDataHr', this.processMinimapData.bind(this));

        this.socket.listen('minimapUpdateHr', this.processMinimapData.bind(this));

    }

    /**
     * ComponentDidUpdate
     */
    componentDidUpdate(prevProps: IMinimapHr) {

        if (!this.rangeDate || this.rangeDate !== this.props.rangeDate || this.props.monitoringTree !== prevProps.monitoringTree) {

            this.processSocket();

            this.rangeDate = this.props.rangeDate;
        }

        if (this.miniatureRefHr) {

            this.renderMiniature();
        }

    }

    /**
     * ComponentWillUnmount
     */
    componentWillUnmount() {

        this.socket.disconnect();
    }

    /**
     * Range of Date
     * @type {Date[] | undefined}
     * @private
     */
    private rangeDate: Date[] | undefined;

    /**
     * Array of id employee
     *
     * @type {number[] | undefined}
     * @private
     */
    private idEmployee: number[] | undefined;

    /**
     * Socket Provider
     *
     * @type {SocketProvider}
     * @private
     */
    private socket: SocketProvider;

    /**
     * Socket Message Id
     * @type {number}
     * @private
     */
    private socketMessageId: number;

    /**
     * Canvas canvas reference
     *
     * @type {HTMLCanvasElement | undefined}
     * @private
     */
    private minimapRefHr: HTMLCanvasElement | undefined;

    /**
     * Miniatures canvas reference
     *
     * @type {CanvasRenderingContext2D | null | undefined}
     * @private
     */
    private miniatureRefHr: CanvasRenderingContext2D | null | undefined;

    /**
     * Process socket data
     */
    processSocket() {
        const { numberOfDataPoints, rangeDate, monitoringTree } = this.props;


        this.socketMessageId = Math.floor(100000000 + (Math.random() * 900000000));

        if (numberOfDataPoints && rangeDate && monitoringTree) {

            const [from, to] = rangeDate;


            this.socket.call('getMinimapDataHr', {
                messageId: this.socketMessageId,
                numberOfDataPoints: numberOfDataPoints,
                from: from.toISOString(),
                _to: to.toISOString(),
                employees: this.getActiveEmployee(),
                access_token: localStorage.getItem('auth_token'),
                subscribe: true,
            }, this.processMinimapData.bind(this));
        }
    }

    /**
     * Get active employee
     *
     * @return {number[]}
     */
    getActiveEmployee(): number[] {

        const { monitoringTree } = this.props;

        if (monitoringTree) {

            const idEmployee: number[] = [];

            for (const value of monitoringTree.departments) {
                if (!value.isMinimize && value.id) {
                    for (const employee of value.employees) {
                        if (employee.isVisible) {
                            idEmployee.push(employee.id);
                        }
                    }
                }
            }

            this.idEmployee = idEmployee;

            return idEmployee;
        }

        return [];
    }


    /**
     * Receive data for Minimap and transform it into lines
     *
     * @param {array} response
     */
    processMinimapData(response: { messageId: number, data: any }) {

        if (this.socketMessageId === response.messageId) {

            const { rangeDate, numberOfDataPoints } = this.props;

            if (rangeDate && numberOfDataPoints) {

                const minimapStart = moment(rangeDate[0]);

                const timeInPx = Math.abs(minimapStart.diff(rangeDate[1])) / numberOfDataPoints;

                if (timeInPx > 0) {

                    const graphs = [];

                    for (const employee of response.data) {

                        const intervals: IIntervals = { employeeId: employee.employee, intervals: [] };

                        for (const interval of employee.intervals) {

                            intervals.intervals.push({
                                x: Math.round(Math.abs(minimapStart.diff(interval.startTime)) / timeInPx),
                                width: Math.round(Math.abs(moment(interval.startTime).diff(interval.endTime)) / timeInPx),
                                color: interval.zoneColor,
                            });

                        }

                        graphs.push(intervals);

                    }

                    this.setState({
                        graphs: [...graphs],
                    });
                }
            }
        }
    }

    /**
     * Render Miniature
     */
    renderMiniature() {

        this.miniatureRefHr && this.miniatureRefHr.clearRect(0, 0, this.props.screenWidth || 0, 55);
        this.idEmployee && this.idEmployee.map((value, index) => this.miniatures(value, index));
    }

    /**
     * Creating employee miniatures
     *
     * @param {number} id
     * @param {number} index
     *
     * @return {JSX.Element | null}
     */
    miniatures(id: number, index: number) {

        const { graphs } = this.state,
            { positionOnVerticalLine } = this.props;

        if (positionOnVerticalLine <= 26 || positionOnVerticalLine + index <= 25) {

            const currentGraphData: IIntervals | undefined = graphs.find((value: IIntervals) => value.employeeId.toString() === id.toString());

            if (!currentGraphData) {

                return null;
            }

            currentGraphData.intervals.map((line) => {
                if (this.miniatureRefHr) {
                    this.miniatureRefHr.globalAlpha = 0.5;
                    this.miniatureRefHr.fillStyle = line.color ? line.color : 'rgba(255,255,255,0)';
                    this.miniatureRefHr.fillRect(line.x, ((index + positionOnVerticalLine) * 2) + index + positionOnVerticalLine, line.width > 1 ? line.width : 1, 2);
                }
            });
        }
    }

    /**
     * Handle canvas ref creation
     *
     * @param {Object} ref
     */
    handlerRef(ref: HTMLCanvasElement | null) {

        if (ref) {

            this.minimapRefHr = ref;
            this.miniatureRefHr = ref.getContext('2d');
        }
    }

    render() {
        const { screenWidth, enableMinimap } = this.props;

        const miniaturesHrStyle: CSSProperties = { position: 'absolute' };

        if (!enableMinimap) {
            return <></>
        }

        return (
            <React.Fragment>
                <canvas
                    ref={this.handlerRef}
                    className="miniaturesHr"
                    height={55}
                    width={screenWidth}
                    style={miniaturesHrStyle}
                />
            </React.Fragment>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @returns {Object}
 */
const mapStateToProps = (state: Merge<RootState | {
    hr: IHrState,
}>) => {

    const screenWidth = selectScreenWidth(state);
    const monitoringTree = selectMonitoringTreeStateHR(state)?.monitoringTree;
    const positionOnVerticalLine = selectActiveSensorInTree(state).length;
    const enableMinimap = selectMonitoringTreeState(state)?.minimapVisible;

    return {
        monitoringTree,
        screenWidth,
        positionOnVerticalLine,
        enableMinimap,
    };
};

export default connect(mapStateToProps, null)(MinimapHr);