import React, { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../core/store';
import { isIE, isMobile } from 'react-device-detect';
import { ReactComponent as LocationIcon } from '../../../../modules/Hr/ui/assets/images/icons/layer.svg';
import { ReactComponent as Function } from '../../../../core/ui/assets/images/icons/function-v2.svg';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DeleteDialog, EditMenu } from '../../../../core/ui/components';
import { FormActions, HmiSchemaAction } from '../../../../core/actions';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';
import { IHmiSchema } from '../../../../core/interfaces';

interface OwnProps {
    scrollLeft: number;
    width: number;
}

type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & WithTranslation;

type State = Readonly<{
    menu: {
            title: string;
            action: (...props: any) => void;
            color?: string;
            icon?: React.ReactNode;
            disabled?: boolean;
        }[];
    deleteItem:  IHmiSchema | null;
    openDeletePopup: boolean;
}>;

const stopPropagation = (event: SyntheticEvent) => event.stopPropagation();

class HmiSchemaList extends React.Component<Props, State> {

    constructor(props:Props) {
        super(props);

        const { t } = this.props;

        this.state = {
            menu: [
                {
                    title: t('EDIT'),
                    action: this.editSchemaAction.bind(this),
                    icon: '',
                    disabled: isMobile,
                },
                {
                    title: t('DELETE'),
                    action: this.deleteSchemaAction.bind(this),
                    icon: '',
                    color: 'red',
                },
            ],
            deleteItem: null,
            openDeletePopup: false,
        };

        this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
        this.onDeleteCancel = this.onDeleteCancel.bind(this);
    }

    /**
     * Delete Schema Action
     *
     * @param {Record<string, unknown>} node
     */
    deleteSchemaAction(node: Record<string, unknown>) {

        this.setState({ deleteItem: node.current as IHmiSchema, openDeletePopup: true });

    }

    /**
     * Edit Schema Action
     * @param {Record<string, unknown>} node
     */
    editSchemaAction(node: Record<string, unknown>) {

        const { toggleForm, schemaEdit } = this.props;

        toggleForm(false, 'hmi', node.current);

        schemaEdit(node.current as IHmiSchema);
    }

    onDeleteSuccess() {

        const { schemaDelete } = this.props,
            { deleteItem } = this.state;

        if (deleteItem) {
            schemaDelete(deleteItem);
        }
    }
    onDeleteCancel(){
        this.setState({ deleteItem: null, openDeletePopup: false });
    }


    render() {

        const { schemas, scrollLeft, t, width } = this.props,
            { openDeletePopup, deleteItem } = this.state;

        if (schemas.length === 0) {

            return null;
        }

        return (
            <section className="hmi-list-component-wrap" style={{ width: width }}>
                <div className="title-block-wrap">
                    <div 
                        className="title-block padding-1"
                        style={{
                            position: isIE ? 'relative' : 'sticky',
                            left: isIE ? scrollLeft : 0,
                            height: 40,
                        }}
                    >
                        <Function style={{ height: 24, width: 24 }} />
                        {this.props.t('HMI_SCHEMAS')}
                    </div>
                </div>
                <div className="hmi-list-component-body">
                    {schemas.map(schema =>
                        (
                            <div className="hmi-list-component-item" key={schema.id}>
                                <div
                                    className="left-section-hmi"
                                    style={{
                                        position: isIE ? 'relative' : 'sticky',
                                        left: isIE ? scrollLeft : 0,
                                    }}
                                >
                                    <LocationIcon style={{ height: 25, width: 25 }} />
                                    {schema.name}
                                    <span
                                        className="wrap-edit-menu-node"
                                    >
                                        <EditMenu
                                            menuList={this.state.menu}
                                            dataItem={{ parent: null, current: schema }}
                                            nodeName={'edit-hmi-schema'}
                                        />
                                    </span>
                                </div>
                                <div
                                    className="right-section-hr"
                                    onClick={stopPropagation}
                                    onTouchStart={stopPropagation}
                                />
                            </div>
                        ),
                    )}

                </div>
                {openDeletePopup ?
                    <DeleteDialog
                        open={openDeletePopup}
                        removeId={deleteItem?.id || null}
                        heading={t('REMOVE_THE_SCHEME', { name: `"${deleteItem?.name}"?` || '' })}
                        body={t('THIS_ACTION_WILL_DELETE_HMI_SCHEME')}
                        onAccept={this.onDeleteSuccess}
                        onClose={this.onDeleteCancel}
                    />
                    : null
                }
            </section>
        );
    }
}

const mapStateToProps = (state: RootState) => {

    const { hmiState } = state;
    const { schemas } = hmiState;

    return {
        schemas,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<RootState, void, AnyAction>) => ({
    dispatch,
    ...bindActionCreators({
        toggleForm: FormActions.toggle,
        schemaEdit: HmiSchemaAction.editSchema,
        schemaDelete: HmiSchemaAction.delete,
    }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HmiSchemaList));
