import React  from 'react';
import LayerWrapper from '../LayerWrapper';
import DepartmentItem from '../Items/DepartmentItem';
import EmployeeItem from '../Items/EmployeeItem';
import { IHRDashboard } from '../../../interfaces';
import { connect } from 'react-redux';
import { IHrState } from '../../../store/reducers';
import { statesActions } from '../../../../../core/actions';
import { Merge } from '../../../../../helpers/mergeType';
import { RootState } from '../../../../../core/store';
import { IDashboard } from '../../../../../core/interfaces';

interface OwnProps {
    name?: string;
    sidebarVisible?: boolean;
    sidebarMaxWidth?: number;
    monitoringTree?: null | IHRDashboard;
    searchField: string;
    deselectStates: ()=> void;
    selectionState: Record<string, number>;
    selectedDashboard?: IDashboard;
}

type Props = OwnProps;

class MonitoringTree extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.editable = false;
    }

    componentWillUnmount() {

        if (this.props.selectionState?.duration) {

            this.props.deselectStates();
        }
    }

    /**
     * Ability to edit
     *
     * @type {boolean}
     * @private
     */
    private readonly editable: boolean;

    render() {

        const { sidebarMaxWidth = 320, sidebarVisible = false, monitoringTree, searchField, selectedDashboard } = this.props;

        if (!monitoringTree || monitoringTree.isMinimized) {

            return null;
        }

        return (
            <LayerWrapper
                visibleSideBar={!sidebarVisible}
                maxWidthSideBar={sidebarMaxWidth}
                editable={this.editable}
                expandedDefault={!monitoringTree.isMinimized}
            >
                {monitoringTree && monitoringTree.departments.map((department, index) => {

                    let showLogic = false;

                    showLogic = department.employees.filter(value => value.isVisible).length !== 0;

                    if (department.id && showLogic)
                        return (
                            !department.isMinimize ?
                                <DepartmentItem
                                    key={department.id || index}
                                    department={department}
                                    editable={this.editable}
                                    maxWidthSideBar={sidebarMaxWidth}
                                    visibleSideBar={sidebarVisible}
                                >
                                    {!department.isMinimize && department.employees.map((value, index, array) => {

                                            const firstVisibleState = array.findIndex(employee => employee.isVisible);
                                            const fullName = `${value.firstName} ${value.middleName} ${value.surname}`;
                                            const showSearchResult = searchField.length > 0 ? fullName.toLowerCase().indexOf(searchField.toLowerCase()) : 1;

                                            if (selectedDashboard && !selectedDashboard.graphs_switch && index !== (firstVisibleState !== -1 ? firstVisibleState : 0)) {

                                                return null;
                                            }

                                            if (value.isVisible && showSearchResult !== -1) {

                                                return (
                                                    <EmployeeItem
                                                        key={value.id || index}
                                                        editable={this.editable}
                                                        employee={value}
                                                        maxWidthSideBar={sidebarMaxWidth}
                                                        visibleSideBar={sidebarVisible}
                                                    />
                                                );
                                            }
                                        }
                                    )}
                                </DepartmentItem> : null
                        );
                    }
                )}
            </LayerWrapper>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @returns {Object}
 */
const mapStateToProps = (state: Merge<RootState |{
    hr: IHrState,
}>) => {

    const { monitoringTreeState } = state.hr;
    const { stateSelection } = state;
    const { selectedDashboard } = state.dashboardSelect;

    const { monitoringTree } = monitoringTreeState;

    return {
        monitoringTree,
        selectedDashboard,
        selectionState: stateSelection.state,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    deselectStates: statesActions.deselectAllStates,
});

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringTree);
