import React from 'react';
import { connect, useSelector } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as d3 from 'd3';
import { ScaleTime } from 'd3';

import DeleteDialog from '../../../core/ui/components/Dialog/DeleteDialog';
import HistogramChart from './HistogramChart';
import StateMenuBtn from '../../../core/ui/components/Board/StateMenuBtn';
import { FormActions, HmiPlayerActions, statesActions, statesOverridesActions } from '../../../core/actions';
import {
    IAuthState,
    IMonitoringTreeUnit,
    ISensor,
    IStateData, IStateItem,
    IStatesOverride,
    IBrushRange,
    IUpdateOverrideOptions, IHmiObject,
} from '../../../core/interfaces';

import emptyState from '../../../core/ui/assets/images/stop-unplanned.png';

import './style/StateChart.scss';
import { MenuList, MenuItem, Popover } from '@material-ui/core';
import { appConfig } from '../../../config/appConfig';
import { isIOS, isMobile } from 'react-device-detect';
import { InfoModal } from '../../../core/ui/components';
import { GraphActions } from '../../store/actions';
import { IHrState } from '../../../modules/Hr/store/reducers';
import { RootState } from '../../../core/store';
import { IEmployeeTree } from '../../../modules/Hr/interfaces';
import { Merge } from '../../../helpers/mergeType';
import { selectStateDataBySensorId } from '../../../core/selectors/stateChart/stateDataSelector';
import { selectStateHrDataBySensorId } from '../../../core/selectors/stateChart/stateHrDataSelector';
import { selectHmiPlayerMode, selectHmiPlayerSchema } from '../../../core/selectors/hmi/playerSelector';
import { selectScreenWidth } from '../../../core/selectors/dashboard/dashboardSelector';
import { calcRealTimeIndentation } from '../../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectHmiPlayerVisibility } from '../../../core/selectors/hmi/visibilitySelector';
import { selectDrawerWidth, selectPositionDrawer } from '../../../core/selectors/layout/responsiveDrawerSelector';
import { IHmiObjectWithData } from '../../../hooks/histogramChart/useDataHistogram';


/**
 *  Default interface
 *
 */
interface IState {
    data: IStateData | undefined;
    formattedData: any[];
    colorArray: string[];
    showEditMenu: any | null;
    anchorEl: null | { top: number; left: number; };
    currentElement: any | null;
    removeId: any | null;
    menuChart: {
        title: () => void;
        action: (...props: any) => void;
        color?: string;
        icon?: any;
        permission: string;
        visible: boolean
    }[];
    menuChartAdd: {
        title: () => void;
        action: (...props: any) => void;
        color?: string;
        icon?: any;
        permission: string;
        visible: boolean;
    }[];
    mixMode: boolean;
    selectedState: any | null;
    viewAsATable: boolean;
    noKeyParameterWarn: boolean;
}

interface IProps {
    auth: IAuthState;
    height?: number;
    graphHeight?: number | undefined;
    tableTitle: string;
    dataState: IStateData | undefined;
    openSidebar?: boolean;
    toggleForm?: (opened: boolean, name?: string, model?: any) => void;
    storeFormData?: (data: any) => void;
    barToggleTableView: (visible: boolean, top: number, sensorId?: string | number, unit?: any, ref?: HTMLCanvasElement, hrMode?: boolean) => void;
    toggleStateDetails: (visible: boolean, state?: any) => void;
    currentUnit: IMonitoringTreeUnit;
    stateTableVisibility?: any;
    minimapVisible: boolean;
    selectState: (state: any, position: { left: number, width: number }, states: IStateData, hrMode?: boolean) => void;
    hoveredState: (state: any, position: { left: number, width: number }, sensorId: string | number) => void;
    disableHoverStates: () => void;
    deselectAllStates: () => void;
    deselectState: () => void;
    deselectAlertGraph: () => void;
    stateSelection: any; //IStateSelectionState; //TODO: Check the interface. It does not compatible with the current states
    blockMix?: boolean;
    screenWidth: number;
    selection?: Date[];
    dashboardOnline: boolean;
    descriptionVisibility: boolean;
    removeStateOverride: (stateOverride: IStatesOverride, updateOptions?: IUpdateOverrideOptions) => void;
    maxWidthSideBar?: number;
    peakEnterEmpty: (x: any) => void;
    keySensor?: ISensor;
    sensor: ISensor;
    hrMode: boolean;
    employee: IEmployeeTree;
    brushRange?: IBrushRange,
    HMIPlayerStatus: string,
    schemaObject?: IHmiObject[] | null;
    hmiPlayerActions:  (data: IHmiObjectWithData) => void;
}

/**
 * A D3 Stack chart component
 *
 * @class StateChart
 */
class StateChart extends React.PureComponent<IProps & WithTranslation, IState> {
    private dataStateInMemory: IStateData | undefined;

    /**
     * Constructor
     *
     * @param {IProps & WithTranslation} props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);

        const { hrMode } = this.props;

        this.state = {
            data: this.props.dataState,
            formattedData: [],
            colorArray: [],
            showEditMenu: null,
            anchorEl: null,
            currentElement: null,
            removeId: null,
            menuChart: [
                {
                    title: this.props.t('EDIT'),
                    action: this.editState.bind(this),
                    color: '',
                    permission: 'state-chart:edit-state',
                    visible: !hrMode,
                },
                {
                    title: this.props.t('VIEW_AS_A_TABLE'),
                    action: this.viewAsATable.bind(this),
                    color: '',
                    permission: 'state-chart:view-as-table',
                    visible: true,
                },
                {
                    title: this.props.t('VIEW_AS_GRAPH'),
                    action: this.viewAsAGraph.bind(this),
                    color: '',
                    permission: 'state-chart:view-as-table',
                    visible: hrMode,
                },
                {
                    title: this.props.t('DELETE'),
                    action: this.removeConfirmation.bind(this),
                    icon: '',
                    color: 'red',
                    permission: 'state-chart:delete-state',
                    visible: !hrMode,
                },
            ],
            menuChartAdd: [
                {
                    title: this.props.t('ADD'),
                    action: this.editState.bind(this),
                    color: '',
                    permission: 'state-chart:add-state',
                    visible: !hrMode,
                },
                {
                    title: this.props.t('VIEW_AS_A_TABLE'),
                    action: this.viewAsATable.bind(this),
                    color: '',
                    permission: 'state-chart:view-as-table',
                    visible: true,
                },
                {
                    title: this.props.t('VIEW_AS_GRAPH'),
                    action: this.viewAsAGraph.bind(this),
                    color: '',
                    permission: 'state-chart:view-as-table',
                    visible: hrMode,
                },
            ],
            mixMode: false,
            selectedState: null,
            viewAsATable: false,
            noKeyParameterWarn: false,
        };

        this.sideBarLogic = JSON.parse(localStorage.getItem('sidebar') as string);

        this.scale = d3.scaleTime();

        this.dataStateInMemory = this.props.dataState;

        this.closeNoKeyParameterWarn = this.closeNoKeyParameterWarn.bind(this);

        this.removeState = this.removeState.bind(this);

        this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);

        this.handleStateClick = this.handleStateClick.bind(this);

        this.handleMouseMove = this.handleMouseMove.bind(this);

        this.handleTouchMove = this.handleTouchMove.bind(this);

        this.handleMouseLeave = this.handleMouseLeave.bind(this);

        this.handleStateClickContextMenu = this.handleStateClickContextMenu.bind(this);

        this.longTapFunction = this.longTapFunction.bind(this);

        this.storeDataHmiPlayer = this.storeDataHmiPlayer.bind(this);

    }

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount(): void {

        if (this.props.dataState &&
            this.props.stateTableVisibility.visible &&
            this.props.stateTableVisibility?.states?.sensorId === this.props.dataState.sensorId &&
            this.props.stateTableVisibility?.states?.id === this.props.dataState.id
        ) {

            this.viewAsATable({ data: {} });
        }

        this.updateStateChart();

        if (this.chartRef) {

            this.drawStateChart();

        }
    }

    /**
     * Callback after the component props update
     */
    componentDidUpdate(prevProps: Readonly<IProps>): void {

        this.updateStateChart();

        if (this.props.dataState && this.props.dataState !== prevProps.dataState) {

            this.dataStateInMemory = this.props.dataState;

            this.storeDataHmiPlayer();
        }

        if ((this.props.dataState && this.props.dataState !== prevProps.dataState) || (this.props.schemaObject && this.props.schemaObject.length > 0)) {

            this.storeDataHmiPlayer();
        }

        this.drawStateChart();

        if (
            this.props.selection !== prevProps.selection &&
            this.props.stateSelection && this.props.stateSelection.state &&
            !this.props.stateSelection.state.startTimeOriginal &&
            !this.props.hrMode &&
            this.state.selectedState
        ) {

            this.setState({ selectedState: null });

            if ((!prevProps.stateSelection.state.surname && this.props.stateSelection.state.surname)) {
                this.props.deselectAllStates();
            }
        }

        if ((this.props.height !== prevProps.height
            || this.props.graphHeight !== prevProps.graphHeight
            || this.props.minimapVisible !== prevProps.minimapVisible)
            && this.props.stateTableVisibility.visible
            && this.props.stateTableVisibility.states
            && this.props.dataState
            && this.props.stateTableVisibility.states.sensorId === this.props.dataState.sensorId) {

            this.viewAsATable({ data: {} });
        }

        if (
            this.props.dataState && this.state.viewAsATable &&
            this.props.stateTableVisibility.states &&
            this.props.stateTableVisibility.states.sensorId &&
            this.props.stateTableVisibility.states.sensorId !== this.props.dataState.sensorId
        ) {

            this.setState({ viewAsATable: false });
        }
    }

    private ref: HTMLCanvasElement | undefined | null;

    /**
     * Chart SVG Element reference
     *
     * @type {object}
     */
    private chartRef: any;

    /**
     * Scale time
     * @type {ScaleTime<number, unknown>}
     */
    private readonly scale: ScaleTime<number, number>;

    /**
     * Sidebar status
     */
    private readonly sideBarLogic: any;

    /**
     * Long tap handler timeout
     *
     * @type {NodeJS.Timeout | undefined}
     *
     * @private
     */
    private callContextMenu: NodeJS.Timeout | undefined;

    /**
     *
     */
    storeDataHmiPlayer() {
        const { schemaObject, sensor, hmiPlayerActions } = this.props;

        if (schemaObject && schemaObject.length > 0) {

            const hmiObjectBySensor = schemaObject.find(value => value.sensorId === sensor.id);

            if (hmiObjectBySensor) {

                const data: IHmiObjectWithData = { ...hmiObjectBySensor, dataHistogram: this.dataStateInMemory?.states, sensor: sensor };

                hmiPlayerActions(data);

            }

        }
    }


    createEmptyStatePattern() {

        const imageEmptyState = new Image();

        imageEmptyState.height = 48;
        imageEmptyState.width = 48;

        // Make a temporary canvas to be the template for a pattern
        imageEmptyState.src = emptyState;

        const canvas = document.createElement('canvas');
        const camvasCotext = canvas.getContext('2d');

        canvas.width = 40;
        canvas.height = 40;


        camvasCotext!.drawImage(imageEmptyState, 0, 0);

        return this.chartRef.createPattern(canvas, 'repeat');
    }

    /**
     * Product Line Update Features
     */
    updateStateChart() {

        const { screenWidth, selection } = this.props;

        if (screenWidth && selection) {

            this.scale
                .range([0, screenWidth])
                .domain(selection);
        }
    }

    /**
     * Alert image draw function
     *
     * @param {CanvasRenderingContext2D} ctx
     * @param {number} x
     * @param {number} y
     */
    alertImageCanvas(ctx: CanvasRenderingContext2D, x: number, y: number) {

        ctx.save();

        ctx.strokeStyle = 'rgba(0,0,0,0)';
        ctx.miterLimit = 4;
        ctx.font = '15px';

        ctx.fill();
        ctx.stroke();

        ctx.fillStyle = '#f8e71c';

        ctx.translate(2.5 + x, 3.333344 + y);

        ctx.beginPath();

        ctx.moveTo(6.00454, 0.835322);
        ctx.bezierCurveTo(6.16294, 0.575443, 6.37491, 0.371255, 6.64046, 0.222753);
        ctx.bezierCurveTo(6.90601, 0.0742501, 7.19252, -1.4803e-15, 7.5, 0);
        ctx.bezierCurveTo(7.80748, -1.4803e-15, 8.09399, 0.0742501, 8.35954, 0.222753);
        ctx.bezierCurveTo(8.62509, 0.371255, 8.83706, 0.575443, 8.99546, 0.835322);
        ctx.lineTo(14.7624, 10.716);
        ctx.bezierCurveTo(14.9208, 10.9852, 15, 11.2752, 15, 11.5861);
        ctx.bezierCurveTo(15, 11.897, 14.9231, 12.1871, 14.7694, 12.4562);
        ctx.bezierCurveTo(14.6157, 12.7254, 14.4037, 12.9389, 14.1335, 13.0967);
        ctx.bezierCurveTo(13.8633, 13.2544, 13.5744, 13.3333, 13.2669, 13.3333);
        ctx.lineTo(1.73305, 13.3333);
        ctx.bezierCurveTo(1.42558, 13.3333, 1.13674, 13.2544, 0.866527, 13.0967);
        ctx.bezierCurveTo(0.596318, 12.9389, 0.384347, 12.7254, 0.230608, 12.4562);
        ctx.bezierCurveTo(0.0768686, 12.1871, 0, 11.897, 0, 11.5861);
        ctx.bezierCurveTo(0, 11.2752, 0.0791979, 10.9852, 0.237596, 10.716);
        ctx.lineTo(6.00454, 0.835322);

        ctx.closePath();

        ctx.fill();
        ctx.stroke();

        ctx.translate(2.5 + x, 3.333344 + y);

        ctx.beginPath();

        ctx.moveTo(6.00454, 0.835322);
        ctx.bezierCurveTo(6.16294, 0.575443, 6.37491, 0.371255, 6.64046, 0.222753);
        ctx.bezierCurveTo(6.90601, 0.0742501, 7.19252, -1.4803e-15, 7.5, 0);
        ctx.bezierCurveTo(7.80748, -1.4803e-15, 8.09399, 0.0742501, 8.35954, 0.222753);
        ctx.bezierCurveTo(8.62509, 0.371255, 8.83706, 0.575443, 8.99546, 0.835322);
        ctx.lineTo(14.7624, 10.716);
        ctx.bezierCurveTo(14.9208, 10.9852, 15, 11.2752, 15, 11.5861);
        ctx.bezierCurveTo(15, 11.897, 14.9231, 12.1871, 14.7694, 12.4562);
        ctx.bezierCurveTo(14.6157, 12.7254, 14.4037, 12.9389, 14.1335, 13.0967);
        ctx.bezierCurveTo(13.8633, 13.2544, 13.5744, 13.3333, 13.2669, 13.3333);
        ctx.lineTo(1.73305, 13.3333);
        ctx.bezierCurveTo(1.42558, 13.3333, 1.13674, 13.2544, 0.866527, 13.0967);
        ctx.bezierCurveTo(0.596318, 12.9389, 0.384347, 12.7254, 0.230608, 12.4562);
        ctx.bezierCurveTo(0.0768686, 12.1871, 0, 11.897, 0, 11.5861);
        ctx.bezierCurveTo(0, 11.2752, 0.0791979, 10.9852, 0.237596, 10.716);
        ctx.lineTo(6.00454, 0.835322);

        ctx.closePath();

        ctx.translate(-2.5, -3.333344);

        ctx.clip();

        ctx.restore();

        ctx.save();

        ctx.fill();
        ctx.stroke();

        ctx.fillStyle = '#333333';
        ctx.strokeStyle = 'rgba(0,0,0,0)';

        ctx.translate(9.166667 + x, 7.5 + y);

        ctx.beginPath();

        ctx.moveTo(0.248016, 0.248966);
        ctx.bezierCurveTo(0.0826711, 0.414943, 0, 0.610794, 0, 0.836524);
        ctx.lineTo(0, 3.32937);
        ctx.bezierCurveTo(0, 3.5551, 0.0826711, 3.75095, 0.248016, 3.91693);
        ctx.bezierCurveTo(0.413361, 4.08291, 0.608464, 4.16589, 0.833333, 4.16589);
        ctx.bezierCurveTo(1.0582, 4.16589, 1.25331, 4.08291, 1.41865, 3.91693);
        ctx.bezierCurveTo(1.584, 3.75095, 1.66667, 3.5551, 1.66667, 3.32937);
        ctx.lineTo(1.66667, 0.836524);
        ctx.bezierCurveTo(1.66667, 0.610794, 1.584, 0.414943, 1.41865, 0.248966);
        ctx.bezierCurveTo(1.25331, 0.0829877, 1.0582, 0, 0.833333, 0);
        ctx.bezierCurveTo(0.608464, 0, 0.413361, 0.0829877, 0.248016, 0.248966);

        ctx.closePath();

        ctx.moveTo(0.248016, 5.24802);

        ctx.bezierCurveTo(0.0826711, 5.41336, 0, 5.60846, 0, 5.83333);
        ctx.bezierCurveTo(0, 6.0582, 0.0826711, 6.25331, 0.248016, 6.41865);
        ctx.bezierCurveTo(0.413361, 6.584, 0.608464, 6.66667, 0.833333, 6.66667);
        ctx.bezierCurveTo(1.0582, 6.66667, 1.25331, 6.584, 1.41865, 6.41865);
        ctx.bezierCurveTo(1.584, 6.25331, 1.66667, 6.0582, 1.66667, 5.83333);
        ctx.bezierCurveTo(1.66667, 5.60846, 1.584, 5.41336, 1.41865, 5.24802);
        ctx.bezierCurveTo(1.25331, 5.08267, 1.0582, 5, 0.833333, 5);
        ctx.bezierCurveTo(0.608464, 5, 0.413361, 5.08267, 0.248016, 5.24802);

        ctx.closePath();

        ctx.fill('evenodd');

        ctx.stroke();

        ctx.restore();
    }

    /**
     * Draw state chart function
     */
    drawStateChart() {

        const {
            dataState,
            graphHeight = 40,
            descriptionVisibility = true,
            screenWidth,
            stateSelection,
            hrMode = false,
        } = this.props;

        const { selectedState } = this.state;

        const { state: localState, states: localStates } = stateSelection;

        if (screenWidth <= 0) {

            return null;
        }
        const pattern = this.createEmptyStatePattern();

        this.chartRef.setTransform(window.devicePixelRatio, 0, 0, window.devicePixelRatio, 0, 0);

        if (this.dataStateInMemory && this.dataStateInMemory.states && this.dataStateInMemory.states.length > 0) {

            this.chartRef.clearRect(0, 0, screenWidth, graphHeight);

            for (const state of this.dataStateInMemory.states) {

                const selectedLogic = selectedState &&
                        !selectedState.startTimeOriginal &&
                        localState &&
                        !localState.startTimeOriginal &&
                        selectedState.startTime === state.startTime &&
                        selectedState.endTime === state.endTime &&
                        localStates.sensorId === this.dataStateInMemory?.sensorId &&
                        !hrMode,
                    selectedLogicHr = selectedState && localState &&
                        selectedState.duration === state.duration &&
                        localState.duration === state.duration &&
                        selectedState.startTime === state.startTime &&
                        selectedState.endTime === state.endTime &&
                        localState.startTime === state.startTime &&
                        localState.endTime === state.endTime &&
                        this.dataStateInMemory?.id === this.props.stateSelection.states.id &&
                        hrMode;

                // check on the possibility of drawing
                if (state.startTime === null || state.endTime === null) {

                    continue;
                }


                /*if (new Date(state.endTime) > new Date()) {

                    state.endTime = new Date();

                }*/

                /*if (selection && new Date(state.startTime) < new Date(selection[0])) {
                    state.startTime = selection[0];
                }*/

                const elementPosition = this.getPositionElement(state);

                this.chartRef.globalAlpha = 1;

                if ((state.causeId || state.stateCategoryId) && !hrMode) {

                    this.chartRef.fillStyle = state.color ? state.color : state.causeId && !state.stateCategoryId ? '#F7F7F8' : 'transparent';

                } else {

                    this.chartRef.fillStyle = state.zoneColor ? state.zoneColor : '#F7F7F8';
                }

                if ((!state.causeId && !state.stateCategoryId && !hrMode) || (hrMode && !state.zoneColor && !state.zoneName)) {

                    this.chartRef.fillStyle = '#EEF1F2';

                    this.chartRef.fillRect(elementPosition.left, 0, elementPosition.width, graphHeight - 0.5);

                    this.chartRef.fillStyle = pattern;

                    this.chartRef.fillRect(elementPosition.left, 0, elementPosition.width, graphHeight - 0.5);


                }

                this.chartRef.fillRect(elementPosition.left, 0, elementPosition.width, graphHeight - 0.5);

                if (state.frameColor) {

                    this.chartRef.strokeStyle = state.frameColor;

                    this.chartRef.lineWidth = 3;

                    this.chartRef.strokeRect(elementPosition.left + 1.5, 1.5, elementPosition.width - 3, graphHeight - 4);
                }

                if (state.causeId && !state.stateCategoryId && !state.frameColor) {

                    this.chartRef.strokeStyle = '#979797';

                    this.chartRef.lineWidth = 3;

                    this.chartRef.strokeRect(elementPosition.left + 1.5, 1.5, elementPosition.width - 3, graphHeight - 3.5);

                }

                this.chartRef.globalAlpha = 1;

                this.chartRef.fillStyle = 'rgba(0,0,0,.6)';

                this.chartRef.font = 'normal 10px IBM Plex Sans';

                if (descriptionVisibility && state.causeName && !state.stateCategoryName && elementPosition.width > 41) {

                    this.chartRef.fillText(this.fittingString(this.props.t('SET_THE_CATEGORY_TO_THIS_CAUSE'), elementPosition.width - 30), elementPosition.left + 30, 32);

                }
                if (descriptionVisibility && (state.causeName || state.stateCategoryName) && elementPosition.width > (state.causeName && !state.stateCategoryName ? 41 : 11)) {

                    this.chartRef.fillText(this.fittingString((state.causeName || state.stateCategoryName), elementPosition.width - (state.causeName && !state.stateCategoryName ? 30 : 8)), elementPosition.left + (state.causeId && !state.stateCategoryId ? 30 : 8), 16);
                }

                if (descriptionVisibility && hrMode && state.zoneName) {

                    this.chartRef.fillText(this.fittingString(state.zoneName, elementPosition.width - 8), elementPosition.left + 8, 16);

                }

                if (descriptionVisibility && state.comment && elementPosition.width > 19 && !hrMode && state.stateCategoryId) {

                    this.chartRef.fillText(this.fittingString(state.comment, elementPosition.width - 8), elementPosition.left + 8, 30);
                }

                if (!state.stateCategoryId && state.causeId && descriptionVisibility && elementPosition.width > 30 && !hrMode) {

                    this.alertImageCanvas(this.chartRef as CanvasRenderingContext2D, elementPosition.left + 8, 18);

                }

                if (localState && localStates.sensorId === this.dataStateInMemory?.sensorId &&
                    localState.startTimeOriginal &&
                    localState.startTimeOriginal === state.startTimeOriginal) {

                    this.chartRef.strokeStyle = '#ae0094';

                    this.chartRef.lineWidth = elementPosition.width > 3 ? 3 : elementPosition.width < 1 ? 0.5 : 1.5;

                    this.chartRef.strokeRect(elementPosition.left + (elementPosition.width > 3 ? 1.5 : 0), elementPosition.width > 3 ? 1 : 0.5, elementPosition.width - (elementPosition.width > 3 ? 3 : 1), graphHeight - (elementPosition.width > 3 ? 2.5 : 1));
                }


                if (selectedLogic || selectedLogicHr) {

                    this.chartRef.strokeStyle = '#ae0094';

                    this.chartRef.lineWidth = 3;

                    this.chartRef.strokeRect(elementPosition.left + 1, 1, elementPosition.width - 2.5, graphHeight - 2.5);
                }

                this.chartRef.clearRect(elementPosition.width + elementPosition.left, 0, screenWidth - (elementPosition.width + elementPosition.left), graphHeight);
            }
        }
    }

    /**
     *  Open form 'Edit state'
     *
     * @param {Object} data
     * @param currentUnit
     */
    editState(data: { data: any }, currentUnit: any) {

        this.setState({ currentElement: null });

        const { toggleForm, storeFormData } = this.props;

        if (toggleForm && storeFormData) {

            storeFormData({ data: data, unit: currentUnit });

            toggleForm(false, 'stateForm', { data: data, unit: currentUnit });
        }
    }

    /**
     * Open form 'View as a table'
     *
     * @param {Object} data
     */
    viewAsAGraph() {

        this.toggleMixMode();
    }
    /**
     * Open form 'View as a table'
     *
     * @param {Object} data
     */
    viewAsATable(data: { data: any }) {

        this.setState({ currentElement: null });

        if (this.chartRef && this.ref) {
            const unit = { ...this.props.currentUnit, tableTitle: this.props.tableTitle };

            const { top } = this.ref.getBoundingClientRect();

            const sensorId = this.props.hrMode && this.props.dataState ? this.props.dataState.id : `${this.props.sensor.controllerId}.${this.props.sensor.sensorId}`;

            this.props.barToggleTableView(false, top, sensorId, unit, this.ref, this.props.hrMode);

            this.setState({ viewAsATable: true });

            window.scrollTo(0, 0);
        }
    }

    /**
     * Confirm remove sensor
     *
     * @param {object} data
     */
    removeConfirmation(data: any) {

        this.setState({
            removeId: data,
            currentElement: null,
        });
    }

    /**
     * Remove state by ID
     *
     */
    removeState() {

        const { removeId } = this.state;
        const { removeStateOverride, brushRange, screenWidth, deselectState, sensor } = this.props;

        if (removeId && removeId.id) {

            const updateOptions = brushRange ? { ...brushRange, screenWidth } : undefined;

            deselectState();

            removeStateOverride({ ...removeId as IStatesOverride, sensor: sensor.id }, updateOptions);
        }
    }

    /**
     * Clean up state after closing delete state confirmation dialog
     */
    onDeleteDialogClose() {

        this.setState({
            removeId: null,
        });
    }

    /**
     * Handle canvas ref creation
     *
     * @param {Object} ref
     */
    handleRef(ref: HTMLCanvasElement | null) {

        if (ref) {

            const { devicePixelRatio } = window;

            ref.width = ref.offsetWidth * devicePixelRatio;
            ref.height = ref.offsetHeight * devicePixelRatio;

            this.ref = ref;
            this.chartRef = ref.getContext('2d');
        }
    }

    /**
     * State chart click handler
     *
     * @param {React.MouseEvent} event
     */
    handleStateClick(event: React.MouseEvent) {

        event.stopPropagation();
        event.preventDefault();

        const { maxWidthSideBar = this.sideBarLogic ? 76 : 320, stateSelection, dataState, selection, hrMode = false } = this.props;

        const selectedState = stateSelection && stateSelection.state && dataState &&
        stateSelection.states && stateSelection.states.sensorId === dataState.sensorId ? stateSelection.state : null;

        const index = (event.pageX - maxWidthSideBar);

        const state = index ? this.getCurrentState(index) : undefined;

        if (selection) {

            const [, selectionEnd] = selection;

            if (state && selectedState !== state && new Date(state.startTime) < new Date(selectionEnd) && dataState) {

                this.props.selectState(state, this.getPositionElement(state), dataState, hrMode);

                this.setState({ selectedState: state });
            }

            if (state && new Date(state.startTime) < new Date(selectionEnd)) {

                this.handleMenuBtnClick(event, state);
            }
        }

        this.props.deselectAlertGraph();
    }


    /**
     * State chart click handler
     *
     * @param {React.MouseEvent} event
     */
    handleStateClickContextMenu(event: React.MouseEvent) {

        if (!isIOS) {

            const { maxWidthSideBar = this.sideBarLogic ? 76 : 320, dataState, stateSelection, selection, hrMode = false } = this.props;

            event.preventDefault();

            const index = (event.pageX - maxWidthSideBar);

            const state = index ? this.getCurrentState(index) : undefined;

            if (state && selection && dataState) {

                this.handleMenuBtnClick(event, state, true);

                const [, selectionEnd] = selection;

                const selectedState = stateSelection && stateSelection.state &&
                stateSelection.states && stateSelection.states.sensorId === dataState.sensorId ? stateSelection.state : null;

                if (selectedState !== state && new Date(state.startTime) < new Date(selectionEnd)) {

                    this.props.selectState(state, this.getPositionElement(state), dataState, hrMode);

                    this.setState({ selectedState: state });
                }
            }

            this.props.deselectAlertGraph();
        }
    }

    /**
     * State chart mouse move handler
     *
     * @param {React.MouseEvent} event
     */
    handleMouseMove(event: React.MouseEvent) {

        event.preventDefault();

        const { maxWidthSideBar = this.sideBarLogic ? 76 : 320, dataState, hrMode, screenWidth, selection, HMIPlayerStatus } = this.props;

        const index = (event.pageX - maxWidthSideBar);

        if (!isMobile) {
            const state = index ? this.getCurrentState(index) : null;

            if (state && dataState) {

                this.props.hoveredState(state, this.getPositionElement(state), hrMode ? dataState.id : dataState.sensorId);

                this.showStateDetails(state);

            } else {

                this.showStateDetails(null);
            }
        }

        if (screenWidth - (selection && new Date(selection[1]) > (new Date()) ? 36 : 0)>= index) {

            if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

                this.props.peakEnterEmpty(index);
            }
        }
    }

    /**
     * State chart touch move handler
     *
     * @param {React.TouchEvent<HTMLCanvasElement>} event
     */
    handleTouchMove(event: React.TouchEvent<HTMLCanvasElement>) {

        event.preventDefault();

        const { maxWidthSideBar = this.sideBarLogic ? 76 : 320, HMIPlayerStatus } = this.props;

        const index = (event.touches[0].pageX - maxWidthSideBar);

        if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

            this.props.peakEnterEmpty(index);
        }
    }

    /**
     *  State chart mouse leave handler
     */
    handleMouseLeave() {

        this.props.disableHoverStates();

        this.showStateDetails(null);
    }

    /**
     * State chart menu button click handler
     *
     * @param {React.MouseEvent} event
     * @param {Object} state
     * @param contextMenu
     */
    handleMenuBtnClick(event: React.MouseEvent, state: {
        id?: number,
        color: string;
        endTime: Date;
        endTimeOriginal: Date;
        comment: string;
        startTime: Date;
        startTimeOriginal: Date;
        value: number;
        causeId: number;
        causeName: string;
        stateCategoryId: number
        stateCategoryName: string;
    }, contextMenu = false) {

        const { graphHeight = 40, maxWidthSideBar = this.sideBarLogic ? 76 : 320, hrMode = false } = this.props;

        const statePosition = this.getPositionElement(state);

        const iconSize = 30; //30px of menu icon width and height

        let elementPosition;

        if (state.stateCategoryId || state.causeId || (state.id && hrMode)) {

            const x = (statePosition.left + statePosition.width + maxWidthSideBar);

            elementPosition = {
                left: x - iconSize,
                right: x,
            };

        } else {

            const x = statePosition.left + maxWidthSideBar;

            elementPosition = {
                left: x,
                right: x + iconSize,
            };
        }

        this.showStateDetails(null);

        const position = {
            left: elementPosition.right || 0,
            top: event.currentTarget.getBoundingClientRect().top + graphHeight || 0,
        };

        if (event.pageX > elementPosition.left && event.pageX < elementPosition.right && statePosition.width > iconSize) {

            this.setState({
                currentElement: state,
                anchorEl: position,
            });
        }

        if (contextMenu) {

            this.setState({
                currentElement: state,
                anchorEl: position,
            });
        }

        this.props.deselectAlertGraph();

        if (this.props.toggleForm) {

            this.props.toggleForm(true, '');
        }
    }

    /**
     * Handler close menu
     */
    handleMenuClose() {

        this.setState({ anchorEl: null });
    }

    /**
     * Show state details, when it have a comment
     *
     * @param state
     */
    showStateDetails(state: IStateItem | null) {

        if (state) {

            this.props.toggleStateDetails(true, state);

        } else {

            this.props.toggleStateDetails(false);
        }
    }

    /**
     * Toggle histogram mix mode with state chart
     */
    toggleMixMode() {
        if (this.props.dataState) {
            const { associatedGraphId } = this.props.dataState;

            if (!this.props.blockMix) {

                if (associatedGraphId || this.props.hrMode) {

                    const { mixMode } = this.state;

                    this.handleMenuClose();
                    this.handleMouseLeave();

                    this.props.deselectAllStates();

                    this.setState({
                        mixMode: !mixMode,
                    });

                    return;
                }
                if (!this.props.hrMode) {
                    this.setState({
                        noKeyParameterWarn: true,
                    });
                }
            }
        }
    }

    /**
     * Fitting string
     *
     * @param {string} str
     * @param {number} maxWidth
     */
    fittingString(str: string, maxWidth: number) {

        let width = this.chartRef.measureText(str).width;

        const ellipsis = '…',
            ellipsisWidth = this.chartRef.measureText(ellipsis).width;

        if (width <= maxWidth || width <= ellipsisWidth) {

            return str;

        } else {

            let len = str.length;

            while (width >= maxWidth - ellipsisWidth && len-- > 0) {

                str = str.substring(0, len);

                width = this.chartRef.measureText(str).width;
            }

            return str + ellipsis;
        }
    }

    /**
     * Calculate position for element
     */
    getPositionElement(value: {
        color: string;
        endTime: Date;
        comment: string;
        startTime: Date;
        value: number;
        causeId: number;
        causeName: string;
        stateCategoryId: number;
        stateCategoryName: string;
    }) {
        this.updateStateChart();

        const { selection } = this.props;
        const left = value ? this.scale(new Date(value.startTime)) || 0 : 0;
        const right = this.scale(value.endTime &&
        new Date(value.endTime).getTime() <= new Date().getTime() ? new Date(value.endTime) : selection ? selection[1] : new Date()) || 0;

        return {
            left: left,
            width: value ? right - left : 0,
        };
    }

    /**
     * Get current state
     *
     * @param {number} index
     */
    getCurrentState(index: number) {

        const { dataState, selection } = this.props;

        const cursorDateMoment = new Date(this.scale.invert(index));

        if (selection) {

            const [, selectionEnd] = selection;

            return dataState && dataState.states && dataState.states.find(state => state.endTime !== null && state.startTime !== null && new Date(state.startTime) < new Date(selectionEnd) && cursorDateMoment > new Date(state.startTime) && cursorDateMoment < new Date(state.endTime));
        }
    }

    /**
     * Close no key parameter warning popup
     */
    closeNoKeyParameterWarn() {

        this.setState({
            noKeyParameterWarn: false,
        });
    }

    /**
     * Long tap handler on mobile
     *
     * @param {React.TouchEvent<HTMLCanvasElement>} event
     */
    longTapFunction(event: React.TouchEvent<HTMLCanvasElement>) {

        const touches = { ...event };

        event.preventDefault();

        if (isIOS) {

            if (event.type === 'touchstart') {

                const callMenu = () => {

                    const {
                        maxWidthSideBar = this.sideBarLogic ? 76 : 320,
                        selection,
                        stateSelection,
                        dataState,
                        hrMode = false,
                    } = this.props;
                    const index = (touches.touches[0].pageX - maxWidthSideBar);
                    const state = index ? this.getCurrentState(index) : undefined;

                    if (state && selection && dataState) {

                        this.handleMenuBtnClick(touches as any, state, true);

                        const [, selectionEnd] = selection;

                        const selectedState = stateSelection && stateSelection.state &&
                        stateSelection.states && stateSelection.states.sensorId === dataState.sensorId ? stateSelection.state : null;

                        if (selectedState !== state && new Date(state.startTime) < new Date(selectionEnd)) {

                            this.props.selectState(state, this.getPositionElement(state), dataState, hrMode);

                            this.setState({ selectedState: state });
                        }
                    }

                    this.props.deselectAlertGraph();
                };

                this.callContextMenu = setTimeout(() => callMenu(), 300);
            }

            if (event.type === 'touchend' && this.callContextMenu) {

                clearTimeout(this.callContextMenu);
            }
        }
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const {
                auth,
                graphHeight = 40,
                currentUnit,
                t,
                screenWidth,
                dataState,
                keySensor,
                sensor,
                employee,
                hrMode = false,
            } = this.props,
            { currentElement, menuChart, removeId, menuChartAdd, mixMode, noKeyParameterWarn } = this.state;

        const histogramSensor = keySensor ? keySensor : {
            id: employee ? employee.id : 0,
            isKeyParameter: true,
            controllerId: '',
            sensorId: '',
            um: '',
            comment: '',
            sensorType: '',
            latestValue: 0,
            name: '',
            unit: {},
        };

        const sensorId = employee ? employee.id : `${sensor.controllerId}.${sensor.sensorId}`;

        const canvasStyle = { height: graphHeight, width: screenWidth },
            stateChart = { height: graphHeight };

        return (
            <div className="state-chart" style={stateChart} onDoubleClick={this.toggleMixMode.bind(this)}>
                {!mixMode ?
                    <canvas
                        ref={this.handleRef.bind(this)}
                        className="state-chart"
                        style={canvasStyle}
                        onClick={this.handleStateClick}
                        onMouseMove={this.handleMouseMove}
                        onTouchMove={this.handleTouchMove}
                        onMouseLeave={this.handleMouseLeave}
                        onContextMenu={this.handleStateClickContextMenu}
                        onTouchStart={this.longTapFunction}
                        onTouchEnd={this.longTapFunction}
                    />
                    :
                    <HistogramChart
                        dataState={dataState}
                        forceMix
                        hrMode={hrMode}
                        sensor={histogramSensor}
                    />
                }
                {currentElement && this.state.anchorEl &&
                <Popover
                    id={'menu_id'}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: this.state.anchorEl.top, left: this.state.anchorEl.left }}
                    open={Boolean(this.state.anchorEl)}
                    onClose={() => {
                        this.setState({ anchorEl: null });
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    className={'context-menu'}
                >
                    {currentElement ?

                    <MenuList>
                        {currentElement.causeId ?
                            <div>
                                {menuChart.map((value, index) =>
                                    auth.rbac.can(value.permission) && value.visible ? (
                                        <MenuItem
                                            key={index}
                                            className={value.color ? value.color : 'inherit'}
                                            onClick={() => {
                                                value.action(currentElement, currentUnit);

                                                this.setState({ anchorEl: null });
                                            }}
                                            disabled={value.color === 'red' && !currentElement.id}
                                            style={{ color: value.color }}
                                        >
                                            {value.title}
                                        </MenuItem>
                                    ) : null
                                )}

                            </div>
                            :
                            <div>
                                {menuChartAdd.map((value, index) =>
                                    auth.rbac.can(value.permission) && value.visible ? (
                                        <MenuItem
                                            key={index}
                                            className={value.color ? value.color : 'inherit'}
                                            onClick={() => {
                                                value.action(currentElement, currentUnit);

                                                this.setState({ anchorEl: null });
                                            }}
                                            disabled={value.color === 'red' && !currentElement.id}
                                            style={{ color: value.color }}
                                        >
                                            {value.title}
                                        </MenuItem>
                                    ) : null
                                )}
                            </div>
                        }
                    </MenuList>
                        : null
                    }
                </Popover>
                }
                <DeleteDialog
                    open={removeId !== null}
                    removeId={removeId}
                    heading={t('REMOVE_STATE_Q')}
                    body={t('THIS_ACTION_WILL_DELETE_STATE_AND_CANNOT_BE_UNDONE')}
                    onAccept={this.removeState}
                    onClose={this.onDeleteDialogClose}
                />
                <StateMenuBtn sensorId={hrMode ? employee.id : sensorId} hrMode={hrMode} />
                <InfoModal
                    open={noKeyParameterWarn}
                    onClose={this.closeNoKeyParameterWarn}
                >
                    {t('KEY_PARAMETER_IS_NOT_DEFINED_WARNING')}
                </InfoModal>
            </div>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 * @param ownProps
 *
 * @return {Object}
 */

//TODO: Split the interface IProps into StateProps and OwnProps to prevent using the `any` here
const mapStateToProps = (state: Merge<RootState | { hr?: IHrState }>, ownProps: any) => {

    const {
        auth,
        graphHistogramHeight,
        graphBarHeight,
        form,
        graphMinimapVisibility,
        stateSelection,
        dashboard,
        graphMinimapBrush,
        graphBarDescriptionVisibility,
        graphStructuralTreeVisibility,
        graphBarTableVisibility,
        hr,
    } = state,
    { range } = state.graphPeriod;

    const { sensor, hrMode = false, employee } = ownProps;

    const sensorId = `${sensor.controllerId}.${sensor.sensorId}`;

    let dataState = undefined;

    // Allows you to seamlessly display data from the main span
    if (!hrMode) {

        const sensorDataRangeCurrent = selectStateDataBySensorId(state, sensorId);

        if (sensorDataRangeCurrent) {

            dataState = sensorDataRangeCurrent;
        }

    } else if (hr) {

        const hrDataCurrent = selectStateHrDataBySensorId(state, employee.id) as  IStateData;

        if (hrDataCurrent) {

            dataState = hrDataCurrent;
        }
    }
    const schema = selectHmiPlayerSchema(state),
        anchor: 'right' | 'bottom'  = selectPositionDrawer(state) as 'right' | 'bottom',
        isVisible = selectHmiPlayerVisibility(state) && schema !== null,
        drawWidth = selectDrawerWidth(state);

    const HMIPlayerStatus = selectHmiPlayerMode(state),
        screenWidthOrigin = selectScreenWidth(state) - calcRealTimeIndentation(state) - (isVisible  && anchor === 'right' ? drawWidth : 0);

    return {
        auth,
        height: graphHistogramHeight.height,
        graphHeight: graphBarHeight.height,
        openSidebar: form.formOpened,
        stateTableVisibility: graphBarTableVisibility,
        minimapVisible: graphMinimapVisibility.visible,
        stateSelection,
        screenWidth: screenWidthOrigin,
        selection: graphMinimapBrush.selection,
        dashboardOnline: dashboard.online,
        descriptionVisibility: graphBarDescriptionVisibility.visible,
        maxWidthSideBar: graphStructuralTreeVisibility.maxWidth,
        dataState,
        brushRange: range,
        HMIPlayerStatus,
        schemaObject: schema?.hmiObjects || null,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    toggleForm: FormActions.toggle,
    barToggleTableView: GraphActions.barToggleTableView,
    storeFormData: statesActions.storeEditData,
    toggleStateDetails: statesActions.toggleStateDetails,
    selectState: statesActions.selectState,
    hoveredState: statesActions.hoveredState,
    disableHoverStates: statesActions.unHoveredStates,
    deselectAllStates: statesActions.deselectAllStates,
    deselectState: statesActions.deselectState,
    deselectAlertGraph: GraphActions.deselectAlertGraph,
    removeStateOverride: statesOverridesActions.delete,
    peakEnterEmpty: GraphActions.peakEnterEmptyLine,
    hmiPlayerActions: HmiPlayerActions.setHMIObjectWIthData,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StateChart));


