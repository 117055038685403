import React from 'react';
import Header from '../Header/Header';
import StructuralTree from './StructuralTree/StructuralTree';
import Notification from '../LoadingNotification/LoadingNotification';

/**
 * Dashboard component
 *
 * @class Dashboard
 */
class Dashboard extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Notification/>
                <Header/>
                <StructuralTree/>
            </React.Fragment>
        );
    }
}

export default Dashboard;

