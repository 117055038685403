import React from 'react';
import { CommonError } from '../../../core/ui/components';
import './SensorFormList.scss';
import { ISensor, ISensorFormListProps } from '../../../core/interfaces';
import { ListItemText, Tooltip as TooltipNoStyle, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { SensorActions } from '../../../core/actions';
import TargetValue from './TargetValue';
import { WithTranslation, withTranslation } from 'react-i18next';
import FormList from './FormList';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Default props and state
 */


type State = {
    initialValues: ISensor;
    enableReinitialize: boolean;
    enableInput: {
        um: boolean;
        comment: boolean;
        pIdCode: boolean;
        nameTargetValue: string | null;
    };
    changedData: ISensor | null;
};

/**
 * Base styles for the Components
 *
 * @type {StylesHook<Styles<Theme, {}, string>>}
 */
const Tooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxWidth: 300,
        zIndex: 1,
    },
    popper: {
        zIndex: 1200,
    },
}))(TooltipNoStyle);


/**
 * Sensor Form horizontal
 *
 * @class SensorFormList
 */
class SensorFormList extends React.PureComponent<ISensorFormListProps & WithTranslation, State> {

    constructor(props: ISensorFormListProps & WithTranslation) {

        super(props);

        this.clearArray = (number: number) => Array.from({ length: number }, (v, i) => i).map((value, index) => ({maxTargetValue: null,
            minTargetValue: null,
            productId: index,
            sensorId: value,
        }));

        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleChangeClickInput = this.handleChangeClickInput.bind(this);

        this.handleOnDoubleClick = this.handleOnDoubleClick.bind(this);

        this.handleOnTouchStart = this.handleOnTouchStart.bind(this);

        this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
    }

    /**
     * Default state
     *
     */
    readonly state: State = {
        initialValues: {
            id: 0,
            um: '',
            comment: '',
            latestValue: 0,
            controllerId: '',
            sensorId: '',
            name: '',
            unit: '',
            pIdCode: '',
            sensorType: '',
        },
        enableReinitialize: true,
        enableInput: {
            um: true,
            comment: true,
            pIdCode: true,
            nameTargetValue: null,
        },
        changedData: null,
    };


    /**
     *Component props update handler
     *
     * @param {Readonly<IProps & WithTranslation>} prevProps
     */
    componentDidUpdate(prevProps: Readonly<ISensorFormListProps & WithTranslation>) {

        if (this.props.sensor && this.props.sensor !== prevProps.sensor) {

            this.updateInitValue();

        }
    }

    /**
     * Filling array.
     *
     * @type {(number: number) => {productId: number, maxTargetValue: null, minTargetValue: null, sensorId: number}[]}
     * @private
     */
    private readonly clearArray: (number: number) => { productId: number; maxTargetValue: null; minTargetValue: null; sensorId: number }[];

    /**
     * Handler submit form
     *
     * @param {ISensor} values
     */
    handleSubmit(values: ISensor) {

        const { initialValues } = this.state,
            { update } = this.props;


        if (values !== initialValues) {

            this.setState({ initialValues: values });

            values.unit = this.props.currentUnit ? this.props.currentUnit.id : null;

            values.isNew = false;

            values.um = values.um.trim();

            update(values);

        }
    }

    /**
     * Update sensor form list with new data from sensor.
     */
    updateInitValue() {

        const { sensor } = this.props;

        const newInitValue = {
            id: sensor.id,
            um: sensor.um || '',
            comment: sensor.comment || '',
            latestValue: sensor.latestValue,
            controllerId: sensor.controllerId,
            sensorId: sensor.sensorId,
            name: sensor.name,
            unit: sensor.unit,
            pIdCode: sensor.pIdCode,
            sensorType: sensor.sensorType,
        };


        this.setState({
            initialValues: newInitValue,
        });
    }

    /**
     * Handler for the double click on the input
     *
     * @param { React.MouseEvent<HTMLDivElement, MouseEvent> } event
     */
    handleOnDoubleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {

        event.preventDefault();

        this.handleChangeClickInput(event.currentTarget.id, false);
        
    }
    
     /**
     * Handler for the touchstart
     *
     * @param { React.MouseEvent<HTMLDivElement, MouseEvent> } event
     */
    handleOnTouchStart(event: React.TouchEvent<HTMLDivElement>) {

         this.handleChangeClickInput(event.currentTarget.title, false);
        
    } 
    
    /**
     * Handler for the keypress
     *
     * @param { React.MouseEvent<HTMLDivElement, MouseEvent> } event
     */
    handleOnKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {

        this.handleChangeKeyPressInput(event.currentTarget.title as  'um' | 'comment', event);
        
    }
    
    /**
     * Handler for the click on the input
     *
     * @param {string} nameInput
     * @param {boolean} status
     * @param {number} id
     */
    handleChangeClickInput(nameInput: string, status: boolean, id?: number) {

        const currentEnableInput: any = this.state.enableInput;

        currentEnableInput[nameInput] = status;

        if (id) {

            currentEnableInput.idTargetValue = id;

            currentEnableInput.nameTargetValue = nameInput;

        } else {

            currentEnableInput.idTargetValue = false;

            currentEnableInput.nameTargetValue = false;

        }

        this.setState({ enableInput: currentEnableInput });

        this.forceUpdate();
    }

    /**
     * Keypress handler for confirmation input
     *
     * @param {String} nameInput
     * @param {React.KeyboardEvent<Element>} event
     */
    handleChangeKeyPressInput(nameInput: 'um' | 'comment', event: React.KeyboardEvent<HTMLElement>) {

        if (event.key === 'Enter') {

            const currentEnableInput = this.state.enableInput;

            currentEnableInput[nameInput] = true;

            this.setState({ enableInput: currentEnableInput });
        }
    }

    /**
     * Filling in target values depending on the unit
     *
     * @return {{minTargetValue: number | null, maxTargetValue: number | null, productId: number, sensorId: number}[]}
     */
    correctTargetValue(): { minTargetValue: number | null; maxTargetValue: number | null; productId: number; sensorId: number }[] {

        const { sensor, currentUnit } = this.props;

        const currentUnitProductLength: number = currentUnit?.products.length || 0;

        const clearArray = this.clearArray(currentUnitProductLength),
            targetValue = cloneDeep(sensor.targetValues) || [];

        if (targetValue.length > currentUnitProductLength && currentUnitProductLength !== 0) {

            return targetValue.slice(0, currentUnitProductLength);
        }

        if (currentUnitProductLength === 0) {
            return [];
        }

        if (targetValue.length < currentUnitProductLength && currentUnitProductLength !== 0 && targetValue.length !== 0) {

            return [...targetValue, ...clearArray.slice(0, (currentUnitProductLength - targetValue.length))];
        }

        if (currentUnitProductLength>0 && targetValue.length === 0) {

            return clearArray;
        }

        return targetValue;
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { errors, sensor } = this.props;
        const { initialValues, enableInput } = this.state;

        return (
            <React.Fragment>
                <div className="form-box-list-form form-box-space edit-sensor-list">
                    <CommonError errors={errors} />
                    {(enableInput.um && enableInput.comment && enableInput.pIdCode)?

                        <React.Fragment>
                            <ListItemText className={'description__id'}>
                                {sensor.controllerId && sensor.sensorId && `${sensor.controllerId.slice(sensor.controllerId.length - 3)}.${sensor.sensorId}`}
                            </ListItemText>
                            <ListItemText
                                className={'description__pit-code'}
                                id="pIdCode"
                                onDoubleClick={this.handleOnDoubleClick}
                            >
                                <span className={'value'}>{initialValues.pIdCode}</span>
                            </ListItemText>
                            <ListItemText
                                className={'description__um'}
                                id="um"
                                onDoubleClick={this.handleOnDoubleClick}
                            >
                                {/*<Tooltip*/}
                                {/*    title={initialValues.um ? initialValues.um : ''}*/}
                                {/*    enterDelay={500}*/}
                                {/*    style={{ zIndex: 1200 }}*/}
                                {/*    enterTouchDelay={200}*/}
                                {/*    leaveTouchDelay={2000}*/}
                                {/*>*/}
                                    <span className={'value'}>{initialValues.um}</span>
                                {/*</Tooltip>*/}
                            </ListItemText>
                            <Tooltip
                                title={initialValues.comment ? initialValues.comment : ''}
                                enterDelay={500}
                                enterTouchDelay={200}
                                leaveTouchDelay={2000}
                                disableTouchListener
                            >
                                <ListItemText className={'description__comment'}
                                              id="comment"
                                              onDoubleClick={this.handleOnDoubleClick}
                                              onTouchStart={this.handleOnTouchStart}
                                >
                                    <span className={'value'}>{initialValues.comment}</span>
                                </ListItemText>
                            </Tooltip>
                            <ListItemText className={'description__value'}>
                                {initialValues.latestValue}
                            </ListItemText>
                        </React.Fragment>
                    :
                        <FormList
                            sensor={sensor}
                            initialValues={this.state.initialValues}
                            enableInput={this.state.enableInput}
                            currentUnit={this.props.currentUnit}
                            handleOnKeyPress={this.handleOnKeyPress}
                            handleChangeClickInput={this.handleChangeClickInput}
                            handleSubmit={this.handleSubmit}
                        />
                    }
                    {this.correctTargetValue().map((value) => (
                        <TargetValue
                            key={value.productId}
                            sensorId={value.sensorId}
                            productId={value.productId}
                            targetValue={value}
                        />
                    ))}

                </div>
            </React.Fragment>
        );
    }
}

/**
 * Map dispatch to component props
 *
 * @type {object}
 *
 */
const mapDispatchToProps = ({
    update: SensorActions.update,
});

export default connect(null, mapDispatchToProps)(withTranslation()(SensorFormList));