import ApiProvider from '../providers/apiProvider';
import { IActivateProduct, IActivateProductInCurrentTime } from '../interfaces';
import { trackPromise } from 'react-promise-tracker';
import { AxiosResponse } from 'axios';

/**
 * Service to work with user API resources
 *
 * @class ProductService
 */
export class ActivateProductService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/active-products(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return [];
    }

    /**
     * Get list of active products for unit
     *
     * @params {string} search A search string
     * @params {IOrder} order An order params
     *
     * @return {Promise<Object>}
     */
    getActiveProduct(ProductInCurrentTime: IActivateProductInCurrentTime): Promise<AxiosResponse> {

        return this.http
            .get(this.url({ id: ProductInCurrentTime.id }), {
                params: {
                    startTime: ProductInCurrentTime.startTime,
                    endTime: ProductInCurrentTime.endTime,
                },
                headers: this.headers,
            });
    }

    /**
     * Create new user account
     *
     * @param {IProduct} aProduct
     *
     * @return {Promise<Object>}
     */
    activateProduct(aProduct: IActivateProduct): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .post(this.url(), aProduct, {
                    headers: this.headers,
                })
        );
    }

}