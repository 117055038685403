import React, { FC, useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import MUISlider from '@material-ui/core/Slider';
import moment from 'moment';
import { HmiPlayerActions } from '../../../../core/actions';
import {
    selectHmiPlayerMode, selectHmiPlayerRealTimeStatus,
    selectHmiPlayerSchema,
    selectHmiPlayerValue,
} from '../../../../core/selectors/hmi/playerSelector';
import {
    calcRealTimeIndentation,
    selectBrushSelection,
} from '../../../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import * as d3 from 'd3';
import { selectScreenWidth } from '../../../../core/selectors/dashboard/dashboardSelector';
import { GraphActions } from '../../../../base/store/actions';
import { PlayerMode } from '../../../../core/interfaces';
import { selectHmiPlayerVisibility } from '../../../../core/selectors/hmi/visibilitySelector';
import { selectDrawerWidth, selectPositionDrawer } from '../../../../core/selectors/layout/responsiveDrawerSelector';

const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = withStyles({
    root: {
        color: '#3880ff',
        height: 2,
        padding: '15px 0',
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        boxShadow: iOSBoxShadow,
        marginTop: -10,
        marginLeft: -10,
        '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 12px)',
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
        '@media only screen and (min-device-width: 320px) and (max-device-width: 960px) and (orientation: portrait)': {
            fontSize: '1.0625rem',
        },
    },
    track: {
        height: 2,
    },
    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        marginTop: -3,
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
    markLabel: {
        marginLeft: '50px',
        '&:nth-last-child(2)': {
            marginLeft: '-50px',
        },
    },
})(MUISlider);

/**
 * Format slider value
 *
 * @param {number} value
 *
 * @return {string}
 */
function valueLabelFormat(value: number): string {

    return moment(value).format('DD.MM HH:mm:ss');
}

interface IProps {
    pausePlayer: () => void;
    setPlayerValue: (value: number) => void;
}

/**
 * HMI Player slider component
 *
 * @param {function} setPlayerValue
 * @param {function} pausePlayer
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const Slider: FC<IProps> = ({ pausePlayer, setPlayerValue }: IProps) => {

    const dispatch  = useDispatch();

    const [startDate, endDate] = useSelector(selectBrushSelection);
    const  scale = d3.scaleTime();

    const value = useSelector(selectHmiPlayerValue),
        realTimeIndentation = useSelector(calcRealTimeIndentation),
        schema = useSelector(selectHmiPlayerSchema),
        isVisible =  useSelector(selectHmiPlayerVisibility),
        drawWidth = useSelector(selectDrawerWidth),
        anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom',
        screenWidth = useSelector(selectScreenWidth) - (isVisible  && anchor === 'right' ? drawWidth : 0) - realTimeIndentation,
        playerMode = useSelector(selectHmiPlayerMode),
        selection = useSelector(selectBrushSelection),
        realTimeStatus = useSelector(selectHmiPlayerRealTimeStatus);

    const [from, to] = selection;
    const min = from.getTime() || startDate.getTime();

    const max = (to.getTime() > new Date().getTime() ? new Date().getTime() : to.getTime())
        || (endDate.getTime() > new Date().getTime() ? new Date().getTime() : endDate.getTime());

    const marks: { value: number }[] = [];

    const step = (max - min) / 20;

    for (let i = min; i <= max + 1; i += step) {

        if (i >= max - (step / 2)) {

            i = max;
        }

        const labels = i === min || i === max ? { label: moment(i >= max && realTimeStatus ?
                new Date() : i).format('DD.MM.YYYY HH:mm') } : {};

        marks.push({ value: i, ...labels });
    }

    useEffect(() => {

        if (playerMode === PlayerMode.MODE_PLAY || (isVisible && schema !== null) || realTimeStatus) {

            if (schema) {

                if (value <= selection[1].getTime()) {

                    scale.range([0, screenWidth - 1.5])
                        .domain(selection);

                    const position = Math.ceil(scale(moment(realTimeStatus ?
                        selection[1].getTime()
                        : value).toDate(),
                    ));

                    dispatch(GraphActions.peakEnterEmptyLine(position >= 0 ? position : 0));
                }
            }

        } else {

            dispatch(GraphActions.peakLeave());
        }

    }, [scale, value, dispatch, screenWidth, playerMode, isVisible, realTimeStatus, selection, schema]);

    /**
     * Handle slider change event
     *
     * @param {React.ChangeEvent} event
     * @param {number | number[]} newValue
     */
    const handleSliderChange = useCallback((event: React.ChangeEvent<{}>, newValue: number | number[]) => {

        setPlayerValue(Array.isArray(newValue) ? newValue[0] : newValue);

        if (playerMode !== ('pause' || 'stop')) {

            pausePlayer();
        }

        if (realTimeStatus) {

            dispatch(HmiPlayerActions.setRealTime(false));
        }
    }, [dispatch, setPlayerValue, pausePlayer, playerMode, realTimeStatus]);

    return (
        <IOSSlider
            marks={marks}
            step={1000}
            min={min}
            max={max}
            value={value}
            valueLabelDisplay="on"
            valueLabelFormat={valueLabelFormat}
            onChange={handleSliderChange}
        />
    );
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    pausePlayer: HmiPlayerActions.pause,
    setPlayerValue: HmiPlayerActions.setValue,
});

export default connect(null, mapDispatchToProps)(Slider);