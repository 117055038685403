import store, { RootState } from '../store';
import {
    calcRealTimeIndentation,
    selectBrushSelection,
} from '../selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { isMobileOnly } from 'react-device-detect';
import { selectScreenWidth } from '../selectors/dashboard/dashboardSelector';
import { selectHmiPlayerSchema } from '../selectors/hmi/playerSelector';
import { selectHmiPlayerVisibility } from '../selectors/hmi/visibilitySelector';
import {
    selectDrawerIsResize,
    selectDrawerWidthWithDrawRules,
    selectPositionDrawer,
} from '../selectors/layout/responsiveDrawerSelector';
import { selectHistogramHeight } from '../selectors/graphHistogramHeight/histogramHeightSelector';
import { selectGraphBarHeight } from '../selectors/graphBarHeight/graphBarHeightSelector';
import { IChartDataWithColor } from '../../hooks/histogramChart/useDataHistogram';
import {
    IActiveProducts,
    IAlertItem,
    IChartAlert,
    INotification,
    ISensor,
    IStateData,
    IStateItem,
} from '../interfaces';
import { selectHistogramMode } from '../selectors/graphHistogramMode/graphHistogramModeSelector';
import { Merge } from '../../helpers/mergeType';
import { IHrState } from '../../modules/Hr/store/reducers';
import * as d3 from 'd3';
import { ScaleLinear, ScaleLogarithmic } from 'd3';
import { graphConstants } from '../constants';
import moment from 'moment';
import { selectGraphSelectionAlert } from '../selectors/graphSelection/graphSelectionSelector';
import { commentCanvasPath, unreadCanvasPath } from '../../base/helpers/histogramIconDraw';
import { selectAllNotification } from '../selectors/notification/notificationSelector';


export class HistogramWatcher {

    /**
     * @type {function}
     */
    subscription: () => void;

    private state: Merge<RootState | { hr: IHrState }>;
    private readonly sensor: ISensor;
    private mode: string;
    private readonly mixMode: boolean;
    private hrMode: boolean;
    private readonly scaleLogPositive: ScaleLogarithmic<number, number>;
    private readonly scaleLogNegative: ScaleLogarithmic<number, number>;
    private readonly scaleLinear: ScaleLinear<number, number>;
    private barWidth: number;
    private readonly negativeValuesColor: string;
    private positiveValuesHeight: number;
    private negativeValuesHeight: number;
    private readonly forceMix: boolean;
    private histogramHeight: number;
    private stateHeight: number;
    private realTimeIndentation: number;
    private selection: Date[];
    private readonly scaleTime: d3.ScaleTime<number, number>;
    private bottomY: number;
    private topY: number;
    private graphSelectionAlert: IAlertItem | undefined;
    private notifications: INotification[];
    private readonly states: IStateItem[];

    constructor(
        sensor: ISensor,
        mixMode: boolean,
        hrMode: boolean,
        forceMix: boolean,
        dataState?: IStateData,
    ) {

        this.subscription = store.subscribe(() => {
            this.state = store.getState() as Merge<RootState | { hr: IHrState }>;
            this.mode = selectHistogramMode(this.state);
            this.histogramHeight = selectHistogramHeight(this.state);
            this.stateHeight = selectGraphBarHeight(this.state);
            this.realTimeIndentation = calcRealTimeIndentation(this.state);
            this.selection = selectBrushSelection(this.state);
            this.graphSelectionAlert = selectGraphSelectionAlert(this.state);
            this.notifications = selectAllNotification(this.state);

        });

        this.state = store.getState() as Merge<RootState | { hr: IHrState }>;
        this.sensor = sensor;

        this.mode = selectHistogramMode(this.state);
        this.realTimeIndentation = calcRealTimeIndentation(this.state);
        this.histogramHeight = selectHistogramHeight(this.state);
        this.stateHeight = selectGraphBarHeight(this.state);
        this.selection = selectBrushSelection(this.state);
        this.mixMode = mixMode;
        this.hrMode = hrMode;
        this.forceMix = forceMix;
        this.graphSelectionAlert = selectGraphSelectionAlert(this.state);
        this.notifications = selectAllNotification(this.state);
        this.scaleLogPositive = d3.scaleLog();
        this.scaleLogNegative = d3.scaleLog();
        this.scaleLinear = d3.scaleLinear();
        this.barWidth = 1;
        this.negativeValuesColor = '#bdd7e7';
        this.positiveValuesHeight = this.height;
        this.negativeValuesHeight = this.height - this.positiveValuesHeight;
        this.scaleTime = d3.scaleTime();
        this.bottomY = 0;
        this.topY = 0;
        this.states = dataState?.states || [];

    }

    /**
     *
     * @return {{controllerId: string, sensorId: number}}
     */
    get getSensorId(): { controllerId: string, sensorId: number } {
        return {
            controllerId: `${this.sensor.controllerId}.${this.sensor.sensorId}`,
            sensorId: this.sensor.id,
        };
    }

    /**
     *
     * @return {number}
     */
    get height(): number {

        return this.forceMix ?
            this.stateHeight : this.histogramHeight;
    }

    /**
     *
     * @return {{statesIntervalTotal: number, statesPerInterval: {start: number, state: IStateItem, end: number}[]}}
     */
    get generateStateIntervals():{
        statesIntervalTotal: number,
        statesPerInterval: {start: number, state: IStateItem, end: number}[]
    } {


        let statesIntervalTotal = 0;

        const statesPerInterval = [];

        for (const state of this.states) {

            const interval = {
                start: statesIntervalTotal,
                state: state,
                end: 0,
            };

            statesIntervalTotal += Math.abs((new Date(state.startTime).getTime() - new Date(state.endTime).getTime()) / 1000);

            interval.end = statesIntervalTotal;

            statesPerInterval.push(interval);
        }
        return { statesIntervalTotal, statesPerInterval };
    }

    /**
     *
     * @param {boolean} isResize
     * @param {number} prevDrawWidth
     * @param {number} drawWidth
     * @return {number}
     */
    isResizeWidth(isResize: boolean, prevDrawWidth: number, drawWidth: number): number {

        if (!isResize) {
            prevDrawWidth = drawWidth;
        }

        return isResize ? prevDrawWidth : drawWidth;
    }

    /**
     *
     * @return {number}
     */
    get screenWidth(): number {

        const schema = selectHmiPlayerSchema(this.state);
        const isVisible = selectHmiPlayerVisibility(this.state) && schema !== null;
        const anchor: 'right' | 'bottom' = selectPositionDrawer(this.state) as 'right' | 'bottom';
        const screenWidthOrigin = selectScreenWidth(this.state);
        const prevDrawWidth = 500;
        const isResize = selectDrawerIsResize(this.state);
        const drawWidth = selectDrawerWidthWithDrawRules(this.state);
        return screenWidthOrigin - (!isMobileOnly && isVisible && anchor === 'right' ? this.isResizeWidth(isResize, prevDrawWidth, drawWidth) : 0);
    }

    /**
     *
     * @return {{minY: number | null, maxY: number | null, color: string, scale: string}}
     */
    get sensorPreferences(): {
        minY: null | number,
        maxY: null | number,
        color: string,
        scale: string,
    } {
        if (this.sensor) {
            const currentPreferences = this.sensor.graphPreferences && this.sensor.graphPreferences[0],
                minY = currentPreferences ? currentPreferences.min : null,
                maxY = currentPreferences ? currentPreferences.max : null,
                color = currentPreferences?.color || '#b3de8e',
                scale = currentPreferences?.scale || 'liner';
            return {
                minY,
                maxY,
                color,
                scale,
            };
        }
        return {
            minY: null,
            maxY: null,
            color: '#b3de8e',
            scale: 'liner',
        };
    }

    /**
     * Unsubscribe from store
     */
    unsubscribe(): void {

        this.subscription();
    }

    /**
     *
     * @param {CanvasRenderingContext2D} alertContext
     * @param {IChartAlert[]} alertData
     * @param notification
     * @return {Promise<void>}
     */
    async createWrapRectangle(alertContext: CanvasRenderingContext2D, alertData:  IChartAlert[], notification: any): Promise<void> {

        if (notification && this.selection) {

            this.scaleTime.range([0, this.screenWidth - this.realTimeIndentation])
                .domain(this.selection);

            const { startTime, endTime } = notification as unknown as INotification;

            const x1 = this.scaleTime(new Date(startTime)) || 0, x2 = this.scaleTime(new Date(endTime || this.selection[1])) || 0;
            const width = (x2) - (x1);
            const wrapRectangleLogic = alertData.find(value => notification &&
                (value.notificationId === notification?.id || value.notificationId === notification?.notificationId));

            if (wrapRectangleLogic && alertContext) {

                // alertContext.restore();
                alertContext.moveTo((x1) + 1, 1);
                alertContext.setLineDash([1, 0]);

                alertContext.lineWidth = 2;

                alertContext.strokeStyle = '#ae0094';

                alertContext.strokeRect((x1) + 1, 1, width - 2, this.histogramHeight - 2);

            }

        }

    }

    /**
     *
     * @param {IChartDataWithColor[]} data
     */
    updateD3Scale(data: IChartDataWithColor[]): void {
        const { maxY, minY } = this.sensorPreferences;

        const bottomY = (minY === null || minY === undefined) ? d3.min(data, d => Number(d.value)) || 0 : minY;
        const topY = (maxY === null || maxY === undefined) ? d3.max(data, d => Number(d.value)) || 100 : maxY === 0 ? 0.000000001 : maxY; //TODO  0.000000001 Correction factor at 0 maximum value. Corrects the display with a solid line.

        const modeLinearMinLogic = maxY !== null;

        const modeLinearMaxLogic = minY !== null;

        if (bottomY <= 0 && topY >= 0) {

            const percentage = Math.abs(topY) / (Math.abs(topY) + Math.abs(bottomY));

            this.positiveValuesHeight = Math.floor(percentage > 0 ? this.height * percentage : this.height / 2);
            this.negativeValuesHeight = this.height - this.positiveValuesHeight;

            this.scaleLogPositive
                .domain([1e-6, topY])
                .range([1e-6, this.positiveValuesHeight]);

            this.scaleLogNegative
                .domain([bottomY, -1e-6])
                .range([this.negativeValuesHeight, 1e-6]);

        } else if (topY < 0) {

            this.scaleLogPositive
                .domain([1e-6, 1000])
                .range([this.height, 1000]);

            this.scaleLogNegative
                .domain([-Math.abs(bottomY), -1e-6])
                .range([this.height, 0]);

            this.negativeValuesHeight = Math.ceil(this.height - this.positiveValuesHeight);

        } else {

            this.scaleLogPositive
                .domain([1e-6, topY])
                .range([0, this.height]);

            this.scaleLogNegative
                .domain([-1000, -1e-6])
                .range([1000, this.height]);
        }

        this.scaleLinear
            .domain(topY < 0 ? [bottomY, modeLinearMinLogic ? topY : 0] :
                [modeLinearMaxLogic ? bottomY : 0, topY])
            .range(topY < 0 ? [this.positiveValuesHeight, 0] :
                [bottomY < 0 ? minY && minY !== 0 ? -this.negativeValuesHeight : -0.1 : this.negativeValuesHeight,
                    topY < 0 ? -this.positiveValuesHeight : this.positiveValuesHeight]);

        this.bottomY = bottomY;
        this.topY = topY;
    }

    /**
     * Get Y-coordinate from a dataset value
     *
     * @param {number} value
     *
     * @return {number}
     */
    getYFromValue(value: number): number {

        if ((!this.mixMode && this.mode === graphConstants.histogramModeLogarithmic) ||
            ((!this.mode || this.mode === 'default') && this.sensorPreferences.scale === graphConstants.histogramModeLogarithmic)) {

            if (value > 0) {

                return this.positiveValuesHeight - (this.scaleLogPositive(value) || 0);
            }

            return this.positiveValuesHeight;
        }

        const domain = this.scaleLinear.domain();

        return domain[1] > 0 ? this.positiveValuesHeight - Math.max(0, (this.scaleLinear(value)) || 0) : 0;
    }

    /**
     * Make a given color lighten/darken
     *
     * @param {string} color
     * @param {number} percent
     *
     * @return {string}
     */
    colorTransform(color: string, percent: number): string {

        const original = parseInt(color.substring(1), 16),
            amt = Math.round(2.55 * percent),
            R = (original >> 16) + amt,
            G = ((original >> 8) & 0x00FF) + amt,
            B = (original & 0x0000FF) + amt;

        return '#' + (0x1000000 +
            ((R < 255 ? R < 1 ? 0 : R : 255) * 0x10000) +
            ((G < 255 ? G < 1 ? 0 : G : 255) * 0x100) +
            (B < 255 ? B < 1 ? 0 : B : 255)
        ).toString(16).slice(1);
    }

    /**
     *
     * @TODO: temp method to find a state by peak index. Review once backend will be ready
     */
    getStateByPeak(index: number): IStateItem | null {

        const { statesIntervalTotal, statesPerInterval } = this.generateStateIntervals;

        const peakInterval = index * (statesIntervalTotal / (this.screenWidth - this.realTimeIndentation));

        const result = statesPerInterval.find(s => s.start !== null && s.start <= peakInterval && s.end !== null && s.end > peakInterval);

        return result ? result.state : null;
    }

    /**
     *
     * @param {IChartDataWithColor[]} dataWithAlertColor
     * @param {boolean} isMounted
     * @return {Promise<{index: number, y: number, height: number, color: string}[]>}
     */
    async calcPointForDraw(
        dataWithAlertColor: IChartDataWithColor[],
        isMounted: boolean,
    ): Promise<{ index: number, y: number, height: number, color: string }[]> {
        this.updateD3Scale(dataWithAlertColor);

        return new Promise(resolve => {

            const arrOfPoints: { index: number, y: number, height: number, color: string }[] = [];

            const { minY, maxY, scale } = this.sensorPreferences;

            for (const data of dataWithAlertColor) {
                const index = dataWithAlertColor.indexOf(data);

                // Checking the display of peaks in the wrong perspective
                if (new Date(data.timestamp) > new Date() || !isMounted) {
                    continue;
                }

                let defaultMode = '';

                if (this.sensor.graphPreferences && this.sensor.graphPreferences[0]) {

                    defaultMode = scale;

                }

                let y = this.getYFromValue(data.value),
                    currentPeaksColor = data.color;

                const height = this.forceMix ? this.stateHeight : this.histogramHeight,
                    modeOverlappingLogic = ((this.mode === 'default') && defaultMode === graphConstants.histogramModeOverlapping) ||
                        (!this.mixMode && this.mode === graphConstants.histogramModeOverlapping);

                if ((data.value >= 0 && minY && minY > 0 && data.value < minY) ||
                    (data.value <= 0 && minY && minY > 0 && data.value < minY) ||
                    (data.value <= 0 && minY && maxY && minY < 0 && maxY < 0 && data.value > maxY) ||
                    (data.value > 0 && minY && maxY && minY < 0 && maxY < 0 && !modeOverlappingLogic)
                ) {

                    return null;
                }

                if (this.mixMode) {

                    const state = this.getStateByPeak(index);

                    currentPeaksColor = state && (state.color || state.zoneColor || '#e0e0e0') ?
                        (state.color || state.zoneColor || '#e0e0e0') : '#e0e0e0';

                }

                let peakHeight = 0;

                if ((!this.mixMode && this.mode === graphConstants.histogramModeLogarithmic) || ((this.mode === 'default') && defaultMode === graphConstants.histogramModeLogarithmic)) {

                    if (data.value !== 0) {

                        peakHeight = Math.abs(data.value > 0 ? (this.scaleLogPositive(data.value)) : (this.scaleLogNegative(data.value)));
                    }

                    // For negative range and mode histogramModeLogarithmic
                    if (minY !== null && maxY !== null && minY < 0 && maxY < 0) {

                        y = 0;

                    }

                } else if (data.value) {

                    peakHeight = Math.abs(this.scaleLinear(Math.abs(data.value)));

                }

                if ((!this.mixMode && this.mode !== graphConstants.histogramModeOverlapping) ||
                    ((!this.mode || this.mode === 'default') && defaultMode !== graphConstants.histogramModeOverlapping)) {
                    if (data.value < 0 && minY === null && maxY !== null && peakHeight > this.negativeValuesHeight) {

                        peakHeight -= this.positiveValuesHeight;
                    }
                }

                if ((!this.mixMode && this.mode === graphConstants.histogramModeOverlapping) || modeOverlappingLogic) {

                    // Checking for data display restrictions at a set maximum and minimum range
                    if (
                        (data.value > 0 && maxY !== null && maxY <= 0 && data.value < Math.abs(maxY)) ||
                        (data.value > 0 && maxY !== null && maxY <= 0 && data.value > Math.abs(maxY)) ||
                        (data.value < 0 && minY !== null && minY > 0 && Math.abs(data.value) < minY)) {

                        continue;
                    }

                    const negativeDataPositiveMeaning = data.value > 0 && minY !== null && maxY !== null && minY <= 0 && maxY <= 0,
                        positiveDataPositiveMeaning = data.value < 0 && minY !== null && ((maxY!== null && minY >= 0 && maxY > 0) ||
                            (minY >= 0));

                    const currentValue = negativeDataPositiveMeaning ?
                        data.value - (Math.abs(maxY!) * 2) :
                        positiveDataPositiveMeaning ? data.value + (Math.abs(minY!) * 2) :
                            data.value;

                    peakHeight = Math.abs(this.scaleLinear(currentValue));

                    const negativeHeight = this.negativeValuesHeight !== 0 ?
                            this.negativeValuesHeight :
                            this.positiveValuesHeight,
                        positiveHeight = this.positiveValuesHeight !== 0 ?
                            this.positiveValuesHeight :
                            this.negativeValuesHeight;

                    const overlappingHeight = (data.value <= 0 ? negativeHeight : positiveHeight);

                    if ((this.positiveValuesHeight === height || this.negativeValuesHeight === height) && data.value < 0) {

                        y = 0;

                        if (minY !== null) {

                            currentPeaksColor = this.negativeValuesColor;
                        }

                    }

                    if (this.positiveValuesHeight === height && data.value >= 0) {

                        y = this.positiveValuesHeight - peakHeight;
                    }

                    if (peakHeight > this.positiveValuesHeight || peakHeight > this.negativeValuesHeight) {

                        const overlapCount = Math.floor((peakHeight - overlappingHeight) / overlappingHeight) + 1;

                        let newHeight = overlappingHeight;

                        const unsignedData = Math.abs(data.value);

                        const overlapDrawLogic = ((minY !== null || maxY !== null) &&
                                (unsignedData > (maxY! <= 0 ? Math.abs(maxY!) : Math.abs(minY!)))) ||
                            (minY! < 0 && maxY! > 0);

                        // if maxY = 0 and data negative
                        if (maxY !== null && maxY === 0 && data.value > 0) {

                            y = (data.value < 0 ? negativeHeight : positiveHeight) - peakHeight;
                        }
                        arrOfPoints.push({
                            index: index,
                            y: y,
                            height: peakHeight,
                            color: currentPeaksColor,
                        });

                        if (overlapDrawLogic) {

                            if (data.value <= 0) {

                                currentPeaksColor = this.negativeValuesColor;
                            }

                            for (let i = 0; i < overlapCount; i++) {

                                newHeight = peakHeight - overlappingHeight;

                                if (newHeight >= overlappingHeight) {

                                    newHeight = overlappingHeight;

                                } else if (newHeight < 0) {

                                    newHeight = peakHeight - overlappingHeight;
                                }

                                if (peakHeight !== newHeight && newHeight !== 0 && peakHeight > newHeight) {
                                    peakHeight -= newHeight;
                                }

                                currentPeaksColor = this.colorTransform(currentPeaksColor, -10);

                                y = data.value < 0 ?
                                    this.positiveValuesHeight > 0 ? this.positiveValuesHeight : 0
                                    :
                                    this.negativeValuesHeight >= 0 ?
                                        this.positiveValuesHeight - newHeight
                                        // height - newHeight - this.negativeValuesHeight
                                        :
                                        height - newHeight;

                                if (data.value > 0 && maxY !== null && maxY <= 0) {

                                    y = height - newHeight;
                                }

                                if (maxY !== null && minY !== null && data.value < 0 && minY < maxY) {

                                    if ((minY >= 0 && maxY > 0) || (minY <= 0 && maxY < 0)) {

                                        y = 0;

                                    }
                                }

                                arrOfPoints.push({
                                    index: index,
                                    y: y,
                                    height: newHeight,
                                    color: currentPeaksColor,
                                });
                            }

                        }

                        continue;

                    }
                }

                arrOfPoints.push({
                    index: index,
                    y: y,
                    height: peakHeight,
                    color: currentPeaksColor,
                });

            }

            resolve(arrOfPoints);
        });
    }

    /**
     *
     * @param {boolean} isMounted
     * @param {{activeProducts: IActiveProducts[], targetValues: {maxTargetValue: number | null, minTargetValue: number | null, productId: number, sensorId: number}[]}} sensorTargetValue
     * @return {Promise<{x1: number, x2: number, y: number, color: string}[]>}
     */
    async calcPointTargetValue(
        isMounted: boolean,
        sensorTargetValue?: {
            activeProducts: IActiveProducts[];
            targetValues: {
                maxTargetValue: number | null;
                minTargetValue: number | null;
                productId: number;
                sensorId: number;
            }[];
        },
    ): Promise<{ x1: number, x2: number, y: number, color: string }[]> {

        return new Promise((resolve, reject) => {

            if (!isMounted) {
                reject(null);
            }

            const arrPointTargetValue: { x1: number, x2: number, y: number, color: string }[] = [];

            if (sensorTargetValue) {
                const { scale, minY, maxY } = this.sensorPreferences,
                    defaultMode = this.mode === 'default' ? scale : this.mode;

                if (this.selection) {
                    this.scaleTime.range([0, this.screenWidth - this.realTimeIndentation])
                        .domain(this.selection);

                    if (sensorTargetValue) {

                        for (const activeProduct of sensorTargetValue.activeProducts) {

                            if (!isMounted) {
                                continue;
                            }

                            const currentTarget = sensorTargetValue.targetValues && sensorTargetValue.targetValues.find(targetValue =>
                                targetValue.productId && activeProduct.product && targetValue.productId === activeProduct.product.id,
                            );

                            if (currentTarget) {

                                const { minTargetValue, maxTargetValue } = currentTarget,
                                    startTime = new Date(activeProduct.startTime) > new Date(this.selection[0]) ? new Date(activeProduct.startTime) : new Date(this.selection[0]),
                                    x1 = this.scaleTime(startTime) || 0,
                                    x2 = this.scaleTime(new Date(activeProduct.endTime || this.selection[1])) || 0;

                                if ((defaultMode === graphConstants.histogramModeOverlapping || this.mode === graphConstants.histogramModeOverlapping) && (minY || maxY)) {
                                    if (defaultMode === graphConstants.histogramModeOverlapping) {
                                        if ((maxTargetValue !== null)) {

                                            const checkingTheDisplayOfMaxTargetValue = (this.topY > 0 && this.bottomY >= 0 && Math.abs(maxTargetValue) >= this.bottomY) ||
                                                (this.topY <= 0 && this.bottomY < 0 && Math.abs(maxTargetValue) >= Math.abs(this.topY)) ||
                                                (this.topY > 0 && this.bottomY < 0);

                                            if (checkingTheDisplayOfMaxTargetValue && maxY !== null) {

                                                const positiveDataNegativeMeaning = maxTargetValue > 0 && (minY || 0) <= 0 && maxY < 0,
                                                    negativeDataPositiveMeaning = maxTargetValue < 0 && (minY || 1) > 0 && maxY >= 0;

                                                const maxYLine = Math.abs(this.scaleLinear(
                                                    negativeDataPositiveMeaning ? maxTargetValue + (Math.abs((minY || 1)) * 2) :
                                                        positiveDataNegativeMeaning ? maxTargetValue - Math.abs(maxY) : maxTargetValue,
                                                ) || 0);

                                                const newYLine = this.newYLine(
                                                    x1,
                                                    x2,
                                                    maxYLine,
                                                    this.histogramHeight,
                                                    maxTargetValue,
                                                    true,
                                                );

                                                if (newYLine) {
                                                    arrPointTargetValue.push(newYLine);
                                                }
                                            }
                                        }

                                        if (minTargetValue !== null) {

                                            const checkingTheDisplayOfMinTargetValue = (this.topY > 0 && this.bottomY >= 0 && Math.abs(minTargetValue) >= this.bottomY) ||
                                                (this.topY < 0 && this.bottomY <= 0 && Math.abs(minTargetValue) >= Math.abs(this.topY)) ||
                                                (this.topY > 0 && this.bottomY <= 0);

                                            if (checkingTheDisplayOfMinTargetValue) {

                                                const positiveDataNegativeMeaning = minTargetValue > 0 && (minY || 0) <= 0 && (maxY || -1) < 0,
                                                    negativeDataPositiveMeaning = minTargetValue < 0 && (minY || 1) > 0 && (maxY || 0) >= 0;

                                                const minYLine = Math.abs(this.scaleLinear(
                                                    negativeDataPositiveMeaning ? minTargetValue + (Math.abs(minY || 1) * 2) :
                                                        positiveDataNegativeMeaning ? minTargetValue - Math.abs((maxY || 0)) : minTargetValue,
                                                ) || 0);

                                                const newYLine = this.newYLine(
                                                    x1,
                                                    x2,
                                                    minYLine,
                                                    this.histogramHeight,
                                                    minTargetValue,
                                                    false,
                                                );

                                                if (newYLine) {

                                                    arrPointTargetValue.push(newYLine);
                                                }
                                            }
                                        }
                                    }

                                } else {
                                    if ((maxTargetValue !== null) && (!maxY !== null || (maxY !== null && maxY >= maxTargetValue))) {

                                        if (
                                            (maxY && maxY >= maxTargetValue) ||
                                            maxTargetValue ||
                                            (maxY === null)
                                        ) {

                                            let maxYLine = Math.abs(this.scaleLinear(maxTargetValue) || 0);

                                            if (maxTargetValue < 0 && maxY && minY && maxY > 0 && minY < 0) {

                                                maxYLine = this.getYFromValue(maxYLine);

                                            } else {

                                                maxYLine = maxTargetValue < 0 && this.positiveValuesHeight < this.height ?
                                                    this.positiveValuesHeight + maxYLine
                                                    :
                                                    this.positiveValuesHeight - maxYLine;

                                            }

                                            if (minY && minY > maxTargetValue) {

                                                return null;
                                            }

                                            arrPointTargetValue.push({ x1: x1, x2: x2, y: maxYLine, color: 'red' });
                                        }
                                    }

                                    if ((minTargetValue !== null) && (!minY || (minY <= minTargetValue))) {
                                        if ((minY !== null && minY <= minTargetValue) || minTargetValue || minY === null) {

                                            let minYLine = minTargetValue === 0 ? this.positiveValuesHeight : Math.abs(this.scaleLinear(minTargetValue));

                                            if (this.positiveValuesHeight >= 0 && minTargetValue && minTargetValue > 0) {

                                                minYLine = this.positiveValuesHeight - Math.abs(minYLine);

                                            }

                                            if ((!maxY || (maxY && maxY > 0)) && this.positiveValuesHeight >= 0 && minTargetValue && minTargetValue < 0) {

                                                minYLine = this.positiveValuesHeight + Math.abs(minYLine);

                                            }
                                            arrPointTargetValue.push({ x1: x1, x2: x2, y: minYLine, color: 'yellow' });
                                        }
                                    }
                                }
                            }
                        }

                    }
                }

                resolve(arrPointTargetValue);
            }
        });
    }

    /**
     * Rendering target value in overlap mode
     *
     * @param {number} x1
     * @param {number} x2
     * @param {number} YLine
     * @param {number} histogramHeight
     * @param {number} targetValue
     * @param {boolean} minMaxLogic
     */
    newYLine(
        x1: number,
        x2: number,
        YLine: number,
        histogramHeight: number,
        targetValue: number,
        minMaxLogic: boolean,
    ): { x1: number, x2: number, y: number, color: string } | undefined {

        const negativeHeight = this.negativeValuesHeight !== 0 ?
                this.negativeValuesHeight :
                this.positiveValuesHeight,
            positiveHeight = this.positiveValuesHeight !== 0 ?
                this.positiveValuesHeight :
                this.negativeValuesHeight;

        let maxYLine = Math.abs(this.scaleLinear(targetValue) || 0),
            y: number;

        const overlapCount = Math.floor((maxYLine - (targetValue < 0 ?
            negativeHeight : positiveHeight)) / (targetValue < 0 ?
            negativeHeight : positiveHeight)) + 1;

        if (this.positiveValuesHeight > 0 && this.negativeValuesHeight > 0) {

            y = targetValue > 0 ? positiveHeight - YLine : positiveHeight + YLine;

        } else {

            y = targetValue < 0 ? YLine : positiveHeight - YLine;
        }


        let newYLine = (targetValue <= 0 ? negativeHeight : positiveHeight);

        for (let i = 0; i < overlapCount; i++) {

            newYLine = maxYLine - (i > 0 ? histogramHeight : (targetValue < 0 ? negativeHeight : positiveHeight));

            if (newYLine > histogramHeight) {

                newYLine = histogramHeight;

            } else if (newYLine < 0) {

                newYLine = maxYLine - (targetValue <= 0 ? negativeHeight : positiveHeight);
            }

            maxYLine -= newYLine;

            if (this.positiveValuesHeight > 0 && this.negativeValuesHeight > 0) {

                y = targetValue > 0 ? newYLine ? positiveHeight - newYLine : 0 :
                    newYLine ? positiveHeight + newYLine : positiveHeight;

            } else {

                y = targetValue < 0 ? newYLine : positiveHeight - newYLine;
            }

            if (newYLine < (negativeHeight || positiveHeight)) {

                return { x1: x1, x2: x2, y: y, color: minMaxLogic ? 'red' : 'green' };

            }
        }


        if (overlapCount === 0) {

            if (targetValue !== 0) {

                if (this.topY <= 0 && this.bottomY < 0 && targetValue >= 0) {

                    y = YLine;
                }
                return { x1: x1, x2: x2, y: y, color: minMaxLogic ? 'red' : 'green' };

            } else if (targetValue === 0) {
                return {
                    x1: x1,
                    x2: x2,
                    y: histogramHeight - this.negativeValuesHeight,
                    color: minMaxLogic ? 'red' : 'green',
                };
            }
        }
    }


    /**
     *
     * @param {IChartAlert} value
     * @param {number} endPoint
     * @param {number} histogramHeight
     * @param {CanvasRenderingContext2D} alertContext
     * @param {number} barWidth
     */
    drawAlertIcon(
        value: IChartAlert,
        endPoint: number,
        histogramHeight: number,
        alertContext: CanvasRenderingContext2D,
        barWidth: number,
    ): void {

        const currentNotification = this.notifications.find(value1 => value1.id === value.notificationId);

        if (currentNotification?.comment && currentNotification.comment.trim().length > 0 && alertContext) {

            commentCanvasPath(alertContext, (endPoint + 4) * barWidth, histogramHeight - 16);
        }

    }

    /**
     *
     * @param {IChartAlert} value
     * @param {number} endPoint
     * @param {number} histogramHeight
     * @param {CanvasRenderingContext2D} alertContext
     * @param {number} barWidth
     * @param graphSelection
     */
    unReading(
        value: IChartAlert,
        endPoint: number,
        histogramHeight: number,
        alertContext: CanvasRenderingContext2D,
        barWidth: number,
        graphSelection?: any,
    ): void {

        const currentNotification = this.notifications.find(value1 => value1.id === value.notificationId);

        if (currentNotification && currentNotification.isNew && graphSelection?.id !== value.notificationId && alertContext) {

            unreadCanvasPath(alertContext, (endPoint * barWidth) + (value.comment && value.comment.length > 0 ? 14 : 4), histogramHeight - 16);

        }

    }

    /**
     * Draw dashed line
     *
     * @param {number} from
     * @param {number} to
     * @param {CanvasRenderingContext2D} alertContext
     */
    drawDashedLine(
        from: number,
        to: number,
        alertContext: CanvasRenderingContext2D,
    ): void {

        if (alertContext) {

            // alertContext.beginPath();

            alertContext.moveTo(from, this.histogramHeight - 20);

            alertContext.setLineDash([2, 5]);

            alertContext.lineWidth = 1;

            alertContext.lineTo(to, this.histogramHeight - 20);

            alertContext.strokeStyle = '#ff0000';

            alertContext.stroke();

            // alertContext.closePath();
        }
    }

    /**
     *
     * @param {boolean} isMounted
     * @param {CanvasRenderingContext2D} alertContext
     * @param {IChartAlert[]} alertData
     * @param {number} barWidth
     * @return {Promise<void>}
     */
    calcPointNotificationTitle(
        isMounted: boolean,
        alertContext: CanvasRenderingContext2D,
        alertData: IChartAlert[],
        barWidth: number,
    ): Promise<void> {

        return new Promise((resolve, reject) => {
            if (this.screenWidth <= 0 || !isMounted) {

                reject(null);
            }

            if (alertContext && this.selection) {

                alertContext.clearRect(0, 0, this.screenWidth, this.histogramHeight);

                this.scaleTime.range([0, this.screenWidth - this.realTimeIndentation])
                    .domain(this.selection);

                const [from, to] = this.selection;

                const letteringLogic = moment(to).diff(from, 'd') < 7;

                let prevX1: number | undefined = undefined,
                    prevX2: number | undefined = undefined;

                for (const value1 of alertData) {

                    if (!isMounted) {
                        continue;
                    }

                    const { startTime, endTime } = value1,
                        newDate = new Date();

                    const selectionEndTime = new Date(to).getTime() > newDate.getTime() ? newDate : to;

                    const x1 = this.scaleTime(new Date(startTime)) || 0,
                        x2 = this.scaleTime(new Date(endTime ? endTime : selectionEndTime)) || 0;

                    const currentX1: number = (x1 - 50) * barWidth,
                        currentX2: number = (x2 + 50) * barWidth;

                    this.drawAlertIcon(value1, x2, this.histogramHeight, alertContext, barWidth);

                    this.unReading(value1, x2, this.histogramHeight, alertContext, barWidth, this.graphSelectionAlert);
                    alertContext.moveTo(x1, 0);
                    // alert rectangle
                    alertContext.fillStyle = 'rgba(235, 68, 75, 0.2)';

                    alertContext.fillRect(x1, 0, x2 - x1 >= 1 ? x2 - x1 : 1, this.histogramHeight);

                    // start/end line for Alert
                    alertContext.beginPath();
                    alertContext.setLineDash([]);
                    alertContext.strokeStyle = 'rgba(235, 68, 75, 0.2)';
                    alertContext.lineWidth = 1;
                    alertContext.moveTo(x1, 0);
                    alertContext.lineTo(x1, this.histogramHeight);
                    alertContext.moveTo(x2, 0);
                    alertContext.lineTo(x2, this.histogramHeight);
                    alertContext.stroke();
                    alertContext.closePath();

                    if (letteringLogic) {

                        if ((!prevX1 && !prevX2) || (prevX1 && prevX1 > currentX2)) {

                            this.drawDashedLine(currentX1, currentX2, alertContext);

                            prevX1 = currentX1;
                            prevX2 = currentX2;

                        } else if (prevX1 && prevX2 && prevX2 > currentX2 && prevX1 < currentX2) {

                            this.drawDashedLine(currentX1, prevX1, alertContext);

                            prevX1 = currentX1;
                            prevX2 = currentX2;
                        }

                        // Adding a label over alert
                        alertContext.moveTo(x2 + 10, this.histogramHeight - 22);
                        alertContext.fillStyle = 'rgba(235, 68, 75, 1)';

                        alertContext.font = 'italic normal 12px IBM Plex Sans sans-serif';
                        alertContext.lineWidth = 2;
                        alertContext.fillText(`${value1.value} ${value1.um}`, x2 + 10, this.histogramHeight - 22);
                    }

                }

            }


            resolve();
        });
    }
}