import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { IFlatTreeProcess, IFlatTreeUnit, ISensor } from '../../interfaces';
import { sortOrder } from '../../../helpers/sortOrder';
import isEqual from 'lodash/isEqual';

// The previous value of the array of sensors
let sensorArrayOld: ISensor[] = [];

const getActiveSensor = (state: RootState) => {

    const sensorArray: ISensor[] = [];

    for (const treeItem of state.monitoringTree.monitoringTree) {

        if (treeItem.type === 'unit') {

            if (!(treeItem as IFlatTreeUnit).isMinimized && (treeItem as IFlatTreeUnit).data) {

                sensorArray.push(...sortOrder((treeItem as IFlatTreeUnit).data.filter(value => value.isVisible)));
            }

            if ((treeItem as IFlatTreeUnit).isMinimized && (treeItem as IFlatTreeUnit).data) {

                sensorArray.push(...sortOrder((treeItem as IFlatTreeUnit).data.filter(value => value.isVisible && value.isKeyParameter)));
            }
        }
    }

    if (isEqual(sensorArrayOld, sensorArray) && sensorArrayOld.length > 0) {

        return sensorArrayOld;

    } else {

        sensorArrayOld = sensorArray;

        return sensorArray;

    }
};

export const getAllSensor = (state: RootState) => {

    const sensorArray: ISensor[] = [];

    for (const treeItem of state.monitoringTree.monitoringTree) {

        if (treeItem.type === 'unit') {

            if ((treeItem as IFlatTreeUnit).data) {

                sensorArray.push(...(treeItem as IFlatTreeUnit).data);
            }
        }
    }

    if (isEqual(sensorArrayOld, sensorArray) && sensorArrayOld.length > 0) {

        return sensorArrayOld;

    } else {

        sensorArrayOld = sensorArray;

        return sensorArray;

    }
};


export const selectActiveSensorInTree = createSelector(
    [getActiveSensor],
    (activeSensor) => activeSensor,
);

export const selectAllSensorInTree = createSelector(
    [getAllSensor],
    (activeSensor) => activeSensor,
);

export const selectActiveSensorInTreeForMinimap = createSelector(
    [getActiveSensor],
    (activeSensor) => activeSensor.slice(0, 26),
);
