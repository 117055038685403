import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
    ConfigurationFlatTreeService,
    SensorService,
    UpdateMonitoringTreeService,
    GraphPreferencesService,
    ConfigurationService,
} from '../services';
import { configurationConstants, monitoringTreeConstants, sensorConstants } from '../constants';
import {
    IDashboard,
    IErrors,
    IFilter, IFlatTreeFactory, IFlatTreeProcess, IFlatTreeUnit,
    IGraphPreferences,
    IJoin,
    IOrder,
    ISensor, IUpdateMonitoringTree,
} from '../interfaces';
import { ConfigurationActions } from '.';

/**
 * Sensors related actions
 *
 * @type {Object}
 */
export const SensorActions = {

    /**
     * Get list of sensors
     *
     * @params {String} search Search string
     * @param {String} search
     * @param {Object} order Sort settings
     * @param {IJoin} join
     * @param {IFilter} filter
     *
     * @return {Promise<Object>}
     */
    list: (search = '', order: IOrder = {
        column: 'id',
        dir: 'asc',
    }, join: IJoin = { table: [] }, filter: IFilter = { field: ['unit||$isnull'] }) => {

        //Action creators
        const success = (sensors: ISensor[]) => {

            return {
                type: sensorConstants.LIST_SUCCESS,
                sensors,
            };

        }, failure = ({ errors }: any) => {

            return {
                type: sensorConstants.LIST_FAILURE,
                errors,
            };

        }, service = new SensorService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.list(search, order, join, filter)
                .then(({ data }) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Update sensor
     *
     * @param { ISensor } sensor
     * @param dashboards
     * @param data
     */
    update: (sensor: ISensor, dashboards?: IDashboard[], data?: IUpdateMonitoringTree) => {

        //Action creators
        const success = (sensor: ISensor) => {

            return {
                type: sensorConstants.EDIT_SUCCESS,
                sensor,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: sensorConstants.EDIT_FAILURE,
                errors,
            };
        }, updateConfigurationTree = (data: any[]) => {
            return {
                type: configurationConstants.UPDATE_CONFIGURATION_PRODUCT_SUCCESS,
                payload: {
                    items: data,
                },

            };
        };

        const service = new SensorService(), 
            mtService = new UpdateMonitoringTreeService(), 
            ctService = new ConfigurationFlatTreeService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.update(sensor)
                .then(({ data }) => {

                    dispatch(success(data));
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                }).finally(() => {
                    
                    const updatedConfigurationTree = ctService.updateLocalTree({ type: 'sensor', sensor: sensor, unitId: data? data.moveSensor?.unitId: undefined });

                    dispatch(updateConfigurationTree(updatedConfigurationTree));

                    if (dashboards && data) {

                        const { moveSensor } = data;

                        for (const dashboard of dashboards) {

                            mtService.updateLocalTree(dashboard.id, 'moveSensor', sensor.id, { moveSensor: moveSensor });
                        }
                    }
                });

        };
    },

    /**
     *  Update sensor preference
     *
     * @param { IGraphPreferences } graphPreferences
     */
    updateSensorPreference: (graphPreferences: IGraphPreferences) => {

        //Action creators
        const success = (graphPreferences: IGraphPreferences) => {

            return {
                type: sensorConstants.UPDATE_SENSOR_PREFERENCE_SUCCESS,
                graphPreferences,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: sensorConstants.UPDATE_SENSOR_PREFERENCE_FAILURE,
                errors,
            };

        }, updateMonitoringTree = (data: (IFlatTreeFactory | IFlatTreeProcess | IFlatTreeUnit)[]) => {

            return {
                type: monitoringTreeConstants.UPDATE_LOCAL_MONITORING_TREE_SUCCESS,
                data,
            };

        }, updateMonitoringTreePart = (data: (IFlatTreeFactory | IFlatTreeProcess | IFlatTreeUnit)[], dashboardId: number) => {

            return {
                type: monitoringTreeConstants.GET_MONITORING_TREES_PART_SUCCESS,
                data,
                dashboardId,
            };
        };

        const service = new GraphPreferencesService();

        const updateMonitoringTreeService = new UpdateMonitoringTreeService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            updateMonitoringTreeService.updateLocalTree(
                graphPreferences.dashboard,
                'sensor',
                graphPreferences.sensor,
                { graphPreferences: [graphPreferences] },
            ).then(updatedTree => {
                if (updatedTree) {

                    dispatch(updateMonitoringTree(updatedTree));
                    dispatch(updateMonitoringTreePart(updatedTree, graphPreferences.dashboard));
                }
            });


            service.update(graphPreferences)
                .then(({ data }) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });


        };
    },
};
