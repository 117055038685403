import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { alertConstants, causesConstants, productConstants } from '../constants';
import { IAlertRuleApi, ICause, IErrors, IProduct } from '../interfaces';
import { alertService, ProductService } from '../services';
import { AxiosResponse } from 'axios';

/**
 * Process related actions
 *
 * @type {Object}
 */
export const AlertAction = {

    /**
     * Show/hide state details popover
     *
     * @param {boolean} visible
     * @param {Object} state
     *
     * @return {Promise<Object>}
     */
    toggleStateDetails: (visible: boolean, state?: any) => {

        const show = () => {

            return {
                type: alertConstants.SHOW_DETAILS,
                state: state,
            };

        }, hide = () => {

            return {
                type: alertConstants.HIDE_DETAILS,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(visible ? show() : hide());
        };
    },

    /**
     * Store alert rules
     *
     *
     * @return {Promise<Object>}
     * @param {IAlertRuleApi[]} alerts
     */
    store: (alerts: IAlertRuleApi[]) => {

        const success = (alerts: IAlertRuleApi[]) => {

            return {
                type: alertConstants.BULK_STORE_ALERT_SUCCESS,
                // state: state,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: alertConstants.BULK_STORE_ALERT_FAILURE,
            };
        }, service = new alertService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            // A timeout construct was added for a slight delay in creation. Otherwise, the very creation time may coincide.
            alerts.forEach(alert => {

                const storeTimeout = setTimeout(() => {

                    service.store(alert)
                        .then(({ data }: AxiosResponse) =>
                            dispatch(success(data)),
                        )
                        .catch((error) => {

                            dispatch(failure(service.errorHandler(error)));
                        })
                        .finally(() => clearTimeout(storeTimeout));

                }, 500);

            });
        };
    },

    /**
     * Store alert rules
     *
     *
     * @return {Promise<Object>}
     * @param {IAlertRuleApi[]} alerts
     */
    bulkStore: (alerts: IAlertRuleApi[]) => {

        const success = (response: IAlertRuleApi[]) => {

            return {
                type: alertConstants.BULK_STORE_ALERT_SUCCESS,
                // state: response,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: alertConstants.BULK_STORE_ALERT_FAILURE,
            };
        }, service = new alertService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.bulk(alerts)
                .then(({ data }: AxiosResponse) => {
                    dispatch(success(data));
                    
                    dispatch(AlertAction.reloadProduct());
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },


    /**
     * Updated cause
     *
     * @param { IAlertRuleApi[] } alerts
     *
     *  @return {Promise<Object>}
     */
    update: (alerts: IAlertRuleApi[]) => {

        //Action creators
        const success = (causes: ICause) => {

            return {
                type: causesConstants.UPDATE_SUCCESS,
                causes,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: causesConstants.UPDATE_FAILURE,
                errors,
            };

        }, service = new alertService();

        return async (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            for await (const alert of alerts) {

                await service.update(alert)
                    .then(({ data }: any) => {

                        dispatch(success(data));

                    })
                    .catch((error) => {

                        dispatch(failure(service.errorHandler(error)));
                    });
            }

            dispatch(AlertAction.reloadProduct());
        };
    },

    /**
     * Updated cause
     *
     * @param { IAlertRuleApi[] } alerts
     *
     *  @return {Promise<Object>}
     */
    bulkUpdate: (alerts: IAlertRuleApi[]) => {

        //Action creators
        const success = (causes: ICause) => {

            return {
                type: causesConstants.UPDATE_SUCCESS,
                causes,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: causesConstants.UPDATE_FAILURE,
                errors,
            };

        }, service = new alertService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.bulkUpdate(alerts)
                .then(({ data }: any) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                })
                .finally(() => {
                    dispatch(AlertAction.reloadProduct());
                });
        };
    },

    /**
     * Delete cause by ID
     *
     * @param { IAlertRuleApi[] } alerts
     *
     *  @return {Promise<Object>}
     */
    delete: (alerts: IAlertRuleApi[]) => {

        const deleteReason = (causes: ICause) => {

            return {
                type: causesConstants.DELETE_SUCCESS,
                causes,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: causesConstants.DELETE_FAILURE,
                errors,
            };

        }, service = new alertService();

        return async (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            for await (const alert of alerts) {
                await service.remove(alert)
                    .then(({ data }: any) => {

                        dispatch(deleteReason(data));

                    })
                    .catch((error) => {

                        dispatch(failure(service.errorHandler(error)));
                    });
            }

            dispatch(AlertAction.reloadProduct());
        };
    },

    /**
     * Delete cause by ID
     *
     * @param { IAlertRuleApi[] } alerts
     *
     *  @return {Promise<Object>}
     */
    bulkDelete: (alerts: IAlertRuleApi[]) => {

        const deleteReason = (causes: ICause) => {

            return {
                type: causesConstants.DELETE_SUCCESS,
                causes,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: causesConstants.DELETE_FAILURE,
                errors,
            };

        }, service = new alertService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.bulkRemove(alerts)
                .then(({ data }: any) => {

                    dispatch(deleteReason(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                })
                .finally(() => {
                    dispatch(AlertAction.reloadProduct());
                });

        };
    },

    /**
     * Reload product action
     * 
     * @returns {Promise<Object>}
     */
    reloadProduct: () => {

        const search = '',
            order = { column: 'id', dir: 'asc' },
            join = {
                table: [
                    'alertRules',
                    'alertRules.unit',
                    'alertRules.sensor',
                    'alertRules.ruleCauseContexts',
                    'alertRules.ruleCauseContexts.cause',
                    'category',
                ],
            },
            filter = undefined;

        const success = (products: IProduct[]) => {
            return {
                type: productConstants.LIST_SUCCESS,
                products,
            };
        }, failure = ({ errors }: IErrors) => {
            return {
                type: productConstants.LIST_FAILURE,
                errors,
            };
        }, service = new ProductService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.list(search, order, join, filter)
                .then(({ data }: AxiosResponse) => {
                    dispatch(success(data));
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },
};
