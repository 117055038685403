import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GraphDataLastValueAction } from '../../core/actions/grapDataLastValue';
import { appConfig } from '../../config/appConfig';
import { selectHmiPlayerRealTimeStatus } from '../../core/selectors/hmi/playerSelector';

export const useRealTimeSensorDataSocket = (): void => {


        const dispatch = useDispatch();

    const realtimeStatus = useSelector(selectHmiPlayerRealTimeStatus);


    useLayoutEffect(() => {

        setInterval(() => {

            dispatch(GraphDataLastValueAction.getLastValue());

        }, appConfig.hmiPlayerRealtimeTimeout);

        dispatch(GraphDataLastValueAction.getLastValue());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [realtimeStatus]);

};