import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Droppable,
    DroppableProvided,
} from 'react-beautiful-dnd';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

import { ReactComponent as FunctionIcon } from '../../../core/ui/assets/images/icons/function-v2.svg';
import { ReactComponent as StateIcon } from '../../../core/ui/assets/images/icons/state_icon.svg';
import { ReactComponent as AlertIcon } from '../../../core/ui/assets/images/icons/alert.svg';

import ProcessItem from './ProcessItem/ProcessItem';
import NodeTitle from './NodeTitle';
import Sensor from './Sensor';
import { IFlatTreeUnit, IProduct, IUnit, IUnitDragDropTreeProps } from '../../../core/interfaces';
import { DeleteDialog, InfoModal } from '../../../core/ui/components';
import { 
    ConfigurationActions,
    EditAlertAction,
    FormActions,
    HmiObjectTreeAction,
} from '../../../core/actions';
import { useParamSelector } from '../../../hooks/useParamSelector';
import { selectFlatItemById } from '../../../core/selectors/configurationTree/configurationTreeCollectionSelector';
import {
    selectProductUnit,
    selectUnitVisible,
} from '../../../core/selectors/configurationProduct/configurationProductSelector';


/**
 * Drag'n'drop tree unit node
 *
 * @param unit
 * @param process
 * @param collapse
 * @param filterValue
 * @param scrollLeft
 * @param maxProductInUnit
 * @param setAlertRules
 * @param setDraggedItem
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const Unit: React.FC<IUnitDragDropTreeProps> = (
    {
        unit,
        process,
        filterValue,
        scrollLeft,
        maxProductInUnit,
        setAlertRules,
        setDraggedItem,
    }: IUnitDragDropTreeProps,
) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [alertModel, setAlertModel] = useState<IUnit | null>(null);
    const [removeId, setRemoveId] = useState<{ type: string, id: number } | null>(null);
    const [removeType, setRemoveType] = useState<string | null>(null);
    const [collapseItem, setCollapseItem] = useState<{
        height: number,
        status: boolean,
    }>({ status: false, height: 40 });

    const unitItem = useParamSelector(selectFlatItemById, unit, 'unit') as IFlatTreeUnit,
        productUnit = useSelector(selectProductUnit),
        productUnitVisible = useSelector(selectUnitVisible);

    useEffect(() => {
        if (unitItem) {
            setCollapseItem({
                height: unitItem.data && (
                    unitItem.data?.filter((value) =>
                        value.name.toLowerCase().match(filterValueFormatted),
                    ).length + 2
                ) * 40,
                status: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     *   Open form 'Edit node'
     *
     * @param {Object} node
     * @param {boolean} opened
     * @param {string} name
     *
     */
    const editNode = (node: Record<string, unknown>, opened: boolean, name: string) => {

        dispatch(FormActions.toggle(false, name, node));
    };

    /**
     *   Open form 'Edit KPI'
     *
     * @param {Object} node
     */
    const editKpi = (node: { current: IUnit }) => {

        dispatch(FormActions.toggle(false, 'kpi'));
    };

    /**
     * Edit alert
     *
     * @param {Object} node
     */
    const editAlert = (node: { current: IUnit }) => {

        if (!node.current.alertsEnabled) {

            dispatch(EditAlertAction.store({ unit: node.current, alert: [] }));

            dispatch(FormActions.toggle(false, 'alert'));

        } else {

            setAlertModel(node.current);

        }
    };

    /**
     * Confirm remove Node
     *
     * @param {Object} data
     */
    const removeConfirmationNode = (data: { parent: Record<string, unknown>, current: { id: number, type: string } }) => {

        const { current } = data;

        if (current) {

            setRemoveId(current);
            setRemoveType('node');
        }

    };

    /**
     * Remove Node
     */
    const removeNode = useCallback(() => {

        if (removeId && removeId.type !== 'sensor') {

            dispatch(ConfigurationActions.remove(removeId.type, { id: removeId.id }));

            if (process) {
                dispatch(HmiObjectTreeAction.remove('unit', removeId.id));
            }

        }

        setRemoveId(null);
        setRemoveType(null);

    }, [dispatch, setRemoveId, setRemoveType, removeId]);

    /**
     * Cancel delete. For the popup dialog
     */
    const onDeleteNodeDialogClose = useCallback(() => {

        setRemoveId(null);

    }, [setRemoveId]);


    const [menuUnit] = useState<{
        title: string;
        action: (...props: any) => void;
        color?: string;
        icon?: React.ReactNode;
    }[]>([
        {
            title: t('EDIT_CONFIG'),
            action: editNode,
            color: '',
        },
        {
            title: t('ADD_KPI'),
            action: editKpi,
            color: '',
        },
        {
            title: t('ADD_ALERT'),
            action: editAlert,
            color: '',
        },
        {
            title: t('DELETE'),
            action: removeConfirmationNode,
            icon: '',
            color: 'red',
        },
    ]);


    const filterValueFormatted = filterValue.toLowerCase().trim();
    /**
     * Calculate unit height
     * */
    const changingAccordion = useCallback((event, expanded) => {
        setCollapseItem({
            height: expanded && unitItem.data ? (unitItem.data.length + 2) * 40 : 80,
            status: expanded,
        });
    }, [
        unit,
        unitItem,
    ]);

    /**
     * On close info modal
     */
    const onCloseInfoModal = useCallback(() => {

        setAlertModel(null);

    }, [setAlertModel]);

    const sensorList = useMemo(() => unitItem.data && unitItem.data.length > 0 ?
        unitItem.data
            .filter((value) =>
                value.name.toLowerCase().match(filterValueFormatted),
            )
            .map((sensor, sensorIndex: number) => (
                <Sensor
                    key={`sensor-key-${sensorIndex}`}
                    index={sensorIndex}
                    sensor={sensor}
                    unit={unitItem}
                    scrollLeft={scrollLeft}
                    maxProductInUnit={maxProductInUnit}
                    setDraggedItem={setDraggedItem}
                />
            ))
        :
        null, [unitItem, filterValueFormatted, scrollLeft,
        maxProductInUnit, setDraggedItem]);

    return (
        <React.Fragment>
            <ProcessItem
                btnTitle={null}
                svgIcon={null}
                itemName={'TITLE'}
                processRule={unitItem.products.length > 0}
                productScheme={'left-padding-3 product-scheme'}
                productSchemeTitle={t('PRODUCT')}
                descriptionTargetValue={'product-title-panel'}
                productTitleRule={unitItem.products.length > 0}
                productTitle={unitItem.products}
                currentUnit={unitItem}
                keyParamData={unitItem.data ? unitItem.data.filter((sensor) => Boolean(sensor.isKeyParameter)) : []}
                maxProductInUnit={unitItem.products.length}
                scrollLeft={scrollLeft}
                refWrap={collapseItem}
                panelId={unit}
                searchFilter={filterValueFormatted}
            />
            <Accordion
                id={'unit-' + unit}
                className={`unit-panel ${productUnitVisible && productUnit && productUnit.id === unit && 'selected'} unit-${unit} ${unitItem.alertsEnabled?'alertsEnabled':''}`}
                defaultExpanded={!unitItem?.isMinimized}
                onChange={changingAccordion}
                style={{ margin: 0 }}
            >
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id={'unit' + unit.toString()}
                >
                    <NodeTitle
                        node={unitItem}
                        rightSectionClass={'left-padding-3'}
                        nodeName={'unit'}
                        nodeNameMenu={'units'}
                        menuUnit={menuUnit}
                        parent={process}
                        getOpenStatus={Boolean(collapseItem?.status)}
                        keyParamData={unitItem.data ? unitItem.data.filter((value) => Boolean(value.isKeyParameter)) : undefined}
                        processRule={unitItem.products.length > 0}
                        maxProductInUnit={unitItem.products.length}
                        products={unitItem.products as unknown as IProduct[]}
                        sensorInUnit={unitItem.data ? unitItem.data.length > 0 : false}
                        scrollLeft={scrollLeft}
                        collapseStateItem={collapseItem}
                        filterValue={filterValue}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <Droppable
                        droppableId={`droppable-${unit}`}
                        direction={'vertical'}
                        ignoreContainerClipping
                        isCombineEnabled
                    >
                        {(provided: DroppableProvided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                key={'unit-list-item-' + unit}
                                className={unitItem.products ? 'left-padding-3 process-item with-process' : 'left-padding-3 process-item'}
                                style={{
                                    padding: 0,
                                    height: 'auto',
                                }}
                            >
                                {sensorList}

                                {/* <CustomPlaceholder snapshot={snapshot} /> */}

                                {provided.placeholder}

                                {/*{placeholderProps.clientY && placeholderProps.id === unit.id ?*/}
                                {/*    (*/}
                                {/*        <div*/}
                                {/*            className="placeholder-props"*/}
                                {/*            style={{*/}
                                {/*                position: 'absolute',*/}
                                {/*                top: placeholderProps.clientY + (unit.products ? 80 : 40),*/}
                                {/*                left: scrollLeft + 320 > 812 ? scrollLeft + 320 - 812 : 0,*/}
                                {/*            }}*/}
                                {/*        >*/}
                                {/*            {provided.placeholder}*/}
                                {/*        </div>*/}
                                {/*    )*/}
                                {/*    :*/}
                                {/*    null*/}
                                {/*}*/}

                                {/* <CustomPlaceholder snapshot={snapshot} /> */}

                            </div>
                        )}
                    </Droppable>

                    {unitItem.kpi && unitItem.kpi.length > 0 &&
                    unitItem.kpi.map((value) =>
                        (
                            <ProcessItem
                                key={value.id}
                                btnTitle={null}
                                itemName={t('KPI')}
                                name={'KPI'}
                                svgIcon={
                                    <FunctionIcon
                                        style={{
                                            height: 24,
                                            width: 24,
                                            marginRight: 8,
                                        }}
                                    />
                                }
                                processRule={Boolean(value)}
                                productScheme="left-padding-4"
                                descriptionTargetValue="setting-btn"
                                maxProductInUnit={maxProductInUnit}
                                currentUnit={unitItem}
                                currentKPI={value}
                                productInUnit={unitItem.products.length}
                                scrollLeft={scrollLeft}
                                searchFilter={filterValueFormatted}
                            />
                        )
                    )
                    }

                    <ProcessItem
                        btnTitle={null}
                        name={t('STATE')}
                        itemName={'STATE'}
                        svgIcon={
                            <StateIcon
                                style={{
                                    height: 24,
                                    width: 24,
                                    marginRight: 8,
                                    padding: '0 2px',
                                }}
                            />
                        }
                        processRule={unitItem.state}
                        productScheme="left-padding-4"
                        descriptionTargetValue="setting-btn"
                        maxProductInUnit={maxProductInUnit}
                        productInUnit={unitItem.products.length}
                        scrollLeft={scrollLeft}
                        searchFilter={filterValueFormatted}
                    />

                    <ProcessItem
                        btnTitle={t('SET_RULES')}
                        name={t('ALERT')}
                        itemName={'ALERT'}
                        svgIcon={
                            <AlertIcon
                                style={{
                                    height: 24,
                                    width: 24,
                                    marginRight: 8,
                                }}
                            />
                        }
                        processRule={unitItem.alertsEnabled}
                        action={setAlertRules}
                        productScheme="left-padding-4 alerts"
                        descriptionTargetValue="setting-btn"
                        currentUnit={unitItem}
                        maxProductInUnit={maxProductInUnit}
                        productInUnit={unitItem.products.length}
                        scrollLeft={scrollLeft}
                        searchFilter={filterValueFormatted}
                    />
                </AccordionDetails>
            </Accordion>
            <DeleteDialog
                open={removeId !== null}
                removeId={removeId ? removeId?.id : null}
                heading={t(removeType === 'sensor' ? 'REMOVE_SENSOR_Q' : 'REMOVE_NODE_Q')}
                body={t(removeType === 'sensor' ? 'THIS_ACTION_WILL_DELETE_SENSOR_Q' : 'THIS_ACTION_WILL_DELETE_NODE_AND_CANNOT_BE_UNDONE')}
                onAccept={removeNode}
                onClose={onDeleteNodeDialogClose}
                className={'configuration-popover'}
            />
            {alertModel ?
                <InfoModal
                    onClose={onCloseInfoModal}
                    open={Boolean(alertModel)}
                >
                    {t('YOU_ALREADY_HAVE_AN_ALERT_FOR_THIS_UNIT')}
                </InfoModal>
                : null
            }
        </React.Fragment>
    );
};

export default Unit;
