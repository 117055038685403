import { IChartData, IHistogramData } from '../core/interfaces';

/**
 * Combining data depending on the selected interval. By default 5 minutes
 *
 * @param {IChartData[]} data
 * @param {number} valueRepeatTimeout
 * @return {IChartData[]}
 */
export const poolingGaps= async (data: IHistogramData[], valueRepeatTimeout = 300000)=> {

    let prevData: IChartData | undefined = undefined;

    return Array.from(data, (hData) => {

        if (hData.values) hData.values.map(value => {
            if (value.value !== null) {

                prevData = value;

                return value;

            } else {

                if (prevData) {

                    const newDate = new Date(prevData.timestamp).getTime() + valueRepeatTimeout;
                    const currentDate = new Date(value.timestamp).getTime();

                    if (newDate > currentDate) {

                        value.value = prevData.value;

                        return value;

                    }
                }

                return value;
            }
        });
        return hData;
    });
};
