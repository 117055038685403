import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IErrors, IStatesOverride, IHistogramData } from '../interfaces';
import { StatesOverridesService, GraphDataService } from '../services';
import { stateOverrideConstants } from '../constants/stateOverrideConstants';
import { IUpdateOverrideOptions } from './../../core/interfaces/props';
import { dashboardConstants } from './../constants/dashboardConstants';
import { DashboardService } from './../services';
import {
    selectAllSensorDataForHistogram,
    selectAllSensorDataForHistogramRange,
    selectOverrideSensors,
    selectOverrideSensorsRange,
} from '../selectors/dashboard/dashboardSelector';
import store from '../store';

/**
 * Unit related actions
 *
 * @type {Object}
 */
export const statesOverridesActions = {

    /**
     * store state override
     *
     * @param { IStatesOverride } stateOverride
     * @param { IUpdateOverrideOptions } options
     * 
     * @return {Promise<Object>}
     */
    store: (stateOverride: IStatesOverride, options?: IUpdateOverrideOptions) => {

        //Action creators
        const success = (stateOverride: IStatesOverride) => {

            return {
                type: stateOverrideConstants.STORE_SUCCESS,
                stateOverride,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: stateOverrideConstants.STORE_FAILURE,
                errors,
            };

        }, successSensorData = (sensorsData: IHistogramData[], overrideSensors: number[]) => {

            return {
                type: dashboardConstants.UPDATE_SENSORS_DATA,
                sensorsData,
                overrideSensors,
            };
        }, successSensorDataRange = (sensorsData: IHistogramData[], overrideSensorsRange: number[]) => {

            return {
                type: dashboardConstants.UPDATE_SENSORS_DATA_RANGE,
                sensorsData,
                overrideSensorsRange,
            };
        }, service = new StatesOverridesService(),
         graphDataService = new GraphDataService(),
         dashboardService = new DashboardService();

        const { sensor } = stateOverride;

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            const currentData = selectAllSensorDataForHistogram(store.getState());
            const currentDataRange = selectAllSensorDataForHistogramRange(store.getState());
            const overrideSensors = selectOverrideSensors(store.getState());
            const overrideSensorsRange = selectOverrideSensorsRange(store.getState());

            service.store(stateOverride)
                .then(({ data }: any) => {

                    dispatch(success(data));

                })
                .then(() => {
                    if (options) {

                        graphDataService.item(sensor, options)
                            .then(({ data }: any) => {

                                const [state] = data;

                                dashboardService.updateLocalSensorData(data, currentData)
                                    .then(value => {

                                        const overrides = !overrideSensors.includes(state.id)
                                            ? [...overrideSensors, state.id]
                                            : overrideSensors;

                                        dispatch(successSensorData(value, overrides));
                        
                                    });

                                dashboardService.updateLocalSensorData(data, currentDataRange)
                                    .then(value => {

                                        const overrideRange = !overrideSensorsRange.includes(state.id)
                                            ? [...overrideSensorsRange, state.id]
                                            : overrideSensorsRange;


                                        dispatch(successSensorDataRange(value, overrideRange));
                    
                                    });    
                            });
                    }
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },


    /**
     * Update state override
     *
     * @param { IStatesOverride } stateOverride
     *
     *  @return {Promise<Object>}
     */
    update: (stateOverride: IStatesOverride) => {

        //Action creators
        const success = (stateOverride: IStatesOverride) => {

            return {
                type: stateOverrideConstants.UPDATE_SUCCESS,
                stateOverride,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: stateOverrideConstants.UPDATE_FAILURE,
                errors,
            };

        }, service = new StatesOverridesService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.update(stateOverride)
                .then(({ data }: any) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    /**
     * Remove state override by ID
     *
     * @param { IStatesOverride } stateOverride
     *
     *  @return {Promise<Object>}
     */
    delete: (stateOverride: IStatesOverride, options?: IUpdateOverrideOptions) => {


        const success = (sOverride: IStatesOverride) => {

            return {
                type: stateOverrideConstants.DELETE_SUCCESS,
                sOverride,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: stateOverrideConstants.DELETE_FAILURE,
                errors,
            };

        }, successSensorData = (sensorsData: IHistogramData[]) => {

            return {
                type: dashboardConstants.UPDATE_SENSOR_DATA,
                sensorsData,
            };
        }, successSensorDataRange = (sensorsData: IHistogramData[]) => {

            return {
                type: dashboardConstants.UPDATE_SENSORS_DATA_RANGE,
                sensorsData,
            };
        }, service = new StatesOverridesService(), 
            graphDataService = new GraphDataService(), 
            dashboardService = new DashboardService();

        const { sensor } = stateOverride;

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            const currentData = selectAllSensorDataForHistogram(store.getState());
            const currentDataRange = selectAllSensorDataForHistogramRange(store.getState());

            service.remove(stateOverride)
                .then(({ data }: any) => {

                    dispatch(success(stateOverride));

                })
                .then(() => {
                    if (options) {

                        graphDataService.item(sensor, options)
                            .then(({ data }: any) => {

                                dashboardService.updateLocalSensorData(data, currentData)
                                    .then(value => {

                                        dispatch(successSensorData(value));
                        
                                    });

                                dashboardService.updateLocalSensorData(data, currentDataRange)
                                    .then(value => {

                                    dispatch(successSensorDataRange(value));
                    
                                }); 
                            });
                    }
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

};
