import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { AppRoles } from '../../../rbac/roles';
import { UserActions, FormActions } from '../../../core/actions';
import {
    Button,
    DeleteDialog,
    ConfirmDialog,
    Select,
    ScrollContainer,
} from '../../../core/ui/components';
import {
    IColumn,
    IData, IUserListPageProps, IUserListPageState,
} from '../../../core/interfaces';
import RightSidebar from '../../Sidebar/RightSidebar';
import UserForm from './UserForm';
import './UserList.scss';
import { RootState } from '../../../core/store';
import { isMobile } from 'react-device-detect';
import { ListStickyHeader } from '../../../base/components';


/**
 * UserList component
 *
 * @class UserList
 */
class UserList extends React.Component<IUserListPageProps & WithTranslation, IUserListPageState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IUserListPageProps & WithTranslation) {

        super(props);

        const { t } = this.props;

        this.columns = [
            {
                name: 'username',
                label: t('USER'),
                sortable: true,
                menu: [
                    {
                        title: t('EDIT'),
                        action: this.editUser.bind(this),
                        color: '',
                    },
                    {
                        title: t('DELETE'),
                        action: this.removeConfirmation.bind(this),
                        color: 'red',
                    },
                ],
                width: '60%',
            },
            {
                name: 'role',
                label: t('ROLE'),
                sortable: true,
                mutate: (value: string | number, row: IData): any => {

                    const roles = [
                        { value: AppRoles.SUPER, label: t('SUPER_USER') },
                        { value: AppRoles.ADVANCED, label: t('ADVANCED_USER') },
                        { value: AppRoles.STANDARD, label: t('USER') },
                    ];

                    return (
                        <Select
                            onChange={(e) => this.changeRoleConfirmation(e, row)}
                            value={value}
                            options={roles}
                            name="role"
                        />
                    );
                },
                width: '40%',
            },
        ];

        this.state = {
            editable: null,
            removeId: null,
            changesToConfirm: null,
        };

        this.addUser = this.addUser.bind(this);

        this.onSidebarClose = this.onSidebarClose.bind(this);

        this.removeUser = this.removeUser.bind(this);

        this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);

        this.changeRole = this.changeRole.bind(this);

        this.onConfirmDialogClose = this.onConfirmDialogClose.bind(this);

        this.editUser = this.editUser.bind(this);

        this.removeConfirmation = this.removeConfirmation.bind(this);

        this.isLandscape = this.isLandscape.bind(this);

    }

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {

        this.props.load();
    }

    /**
     * Component props update handler
     *
     * @param {IProps} prevProps Updated component properties
     */
    componentDidUpdate(prevProps: IUserListPageProps) {

        const { userUpdate } = this.props;

        if (userUpdate && prevProps.userUpdate !== userUpdate) {

            this.props.load();
        }
    }

    /**
     * Data table columns
     *
     * @type {IColumn[]}
     */
    private readonly columns: IColumn[];

    /**
     * Chek landscape.
     *
     * @return {boolean}
     */
    isLandscape(){

        return (window.orientation === 90 || window.orientation === -90);
    }


    /**
     * Open add user form
     */
    addUser() {

        this.setState({
            editable: {
                id: '',
                username: '',
                email: '',
                role: '',
            },
        });

        this.props.toggleForm(false, 'user-form');
    }

    /**
     * Open edit user form and fill it with data
     *
     * @param {IData} user
     */
    editUser(user: IData) {

        this.setState({
            editable: {
                id: user.id,
                username: user.username,
                email: user.email,
                role: user.role,
            },
        });

        this.props.toggleForm(false, 'user-form');
    }

    /**
     * Delete user confirmation
     *
     * @param {object} user
     */
    removeConfirmation(user: { id: number }) {

        this.setState({
            removeId: user.id,
        });
    }

    /**
     * Remove user by ID
     *
     * @param {number} userId
     */
    removeUser(userId: number) {

        this.props.remove(userId);
    }

    /**
     * Clean up state after closing delete user confirmation dialog
     */
    onDeleteDialogClose() {

        this.setState({
            removeId: null,
        });
    }

    /**
     * Sidebar close callback
     */
    onSidebarClose() {

        //anything to do?
    }

    /**
     * Change user role confirmation
     *
     * @param {object} event Change event from roles drop-down
     * @param {object} row User data
     */
    changeRoleConfirmation(event: React.ChangeEvent<{name?: string | undefined, value: unknown}>, row: IData) {

        this.setState({
            changesToConfirm: {
                id: row.id,
                username: row.username,
                email: row.email,
                role: event.target.value as string,
            },
        });
    }

    /**
     * Change user role
     */
    changeRole() {

        if (this.state.changesToConfirm) {

            this.props.update(this.state.changesToConfirm);
        }

        this.onConfirmDialogClose();
    }

    /**
     * Clean up state after closing user role change confirmation dialog
     */
    onConfirmDialogClose() {

        this.setState({
            changesToConfirm: null,
        });

        this.props.load();
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t, load, formOpened, formName } = this.props,
            { editable, removeId, changesToConfirm } = this.state;

        return (
            <div className="page-setting">
                <ScrollContainer
                    maxHeightSlider={`calc(100vh - ${isMobile ? this.isLandscape() ? 40 : 220 : 40}px)`}
                    headerScroll={
                        <React.Fragment>
                            <h2 className={'content-title'}>{t('USERS')}</h2>
                            <Button
                                type="button"
                                color={'primary'}
                                onClick={this.addUser}
                            >{t('ADD_USER')}
                            </Button>
                        </React.Fragment>
                    }
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={6} className={'users-listing left column-wrap'}>
                            <ListStickyHeader
                                columns={this.columns}
                                data={this.props.users}
                                refresh={load}
                                searchable
                                selectedParam={'id'}
                                searchableField={'username'}
                                maxHeightSlider={'calc(100vh - 260px)'}
                                defaultOrder={{
                                    column: 'id',
                                    dir: 'desc',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={6} className={'right column-wrap'}>
                            <h3>{t('ROLES_DESCRIPTION')}</h3>
                            <p>
                                <b>{t('SUPER_USER_TT')}</b><br />
                                {t('SUPER_USER_T')}
                            </p>
                            <p>
                                <b>{t('ADVANCED_USER_TT')}</b><br />
                                {t('ADVANCED_USER_T')}
                            </p>
                            <p>
                                <b>{t('STANDARD_USER_TT')}</b><br />
                                {t('STANDARD_USER_T')}
                            </p>
                            <RightSidebar openSidebar={formOpened && formName === 'user-form'} close={this.onSidebarClose}>
                                <UserForm model={editable} />
                            </RightSidebar>
                        </Grid>
                    </Grid>
                </ScrollContainer>

                <DeleteDialog
                    open={removeId !== null}
                    removeId={removeId}
                    heading={t('REMOVE_USER_Q')}
                    body={t('THIS_ACTION_WILL_DELETE_USER_AND_CANNOT_BE_UNDONE')}
                    onAccept={this.removeUser}
                    onClose={this.onDeleteDialogClose}
                />

                <ConfirmDialog
                    open={changesToConfirm !== null}
                    heading={t('CHANGE_USER_ROLE_Q')}
                    body={t('PLEASE_CONFIRM_USER_ROLE_CHANGE')}
                    onAccept={this.changeRole}
                    onClose={this.onConfirmDialogClose}
                />
            </div>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { users, errors } = state.userList,
        { user } = state.userChange,
        { formOpened, formName } = state.form;

    return {
        users,
        errors,
        formOpened,
        userUpdate: user,
        formName,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    load: UserActions.list,
    update: UserActions.update,
    remove: UserActions.remove,
    toggleForm: FormActions.toggle,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserList));