import React from 'react';
import { connect } from 'react-redux';
import { IMonitoringTreeWrapperProps } from '../../interfaces';
import MonitoringTree from './MonitoringTree/MonitoringTree';
import EditMonitoringTree from './EditMonitoringTree/EditMonitoringTree';
import { monitoringTreeDashboardThunks } from '../../store/thunks/monitoringTreeDashboardThunks';
import SocketProvider from '../../providers/socketProvider';
import { RootState } from '../../../../core/store';
import moment from 'moment';
import { Merge } from '../../../../helpers/mergeType';
import { IHrState } from '../../store/reducers';
import { selectSelectedDashboard } from '../../../../core/selectors/dashboardSelect/selectedDashboardSelector';
import { poolingGaps } from '../../../../helpers';
import { IHistogramData } from '../../../../core/interfaces';
import { DashboardActions } from '../../../../core/actions';
import { appConfig } from '../../../../config/appConfig';

interface IMonitoringTreeWrapperState {
    socketId: string | undefined
}

class MonitoringTreeWrapper extends React.Component<IMonitoringTreeWrapperProps, IMonitoringTreeWrapperState> {

    constructor(props: IMonitoringTreeWrapperProps) {

        super(props);

        this.state = {
            socketId: undefined,
        };

        this.socket = new SocketProvider();

        this.socketMessageId = 0;

        this.socketMessageIdForRange = 0;

        this.overrideSensorData = false;

        this.overrideSensorDataRange = false;

        this.getSocketId = this.getSocketId.bind(this);

        this.socket.listen('getGraphDataHr', this.processSocketResponse.bind(this));

        this.socket.listen('graphDataUpdateHr', this.processSocketResponseUpdate.bind(this));
    }

    componentDidMount() {

        const { dashboardId, fetchTree } = this.props;

        if (dashboardId) {
            fetchTree(dashboardId);
        }

        this.socket.getSocketId(this.getSocketId);

        this.callSocketGraphData();

        this.callSocketGraphDataRange();

    }

    componentDidUpdate(prevProps: Readonly<IMonitoringTreeWrapperProps>, prevState: Readonly<IMonitoringTreeWrapperState>) {


        const {
            brushSelection,
            screenWidth,
            dashboardOnline,
            brushRule,
            brushRange,
            dashboardId,
            fetchTree,
        } = this.props;

        if (dashboardId && dashboardId != prevProps.dashboardId) {

            fetchTree(dashboardId);
        }

        if (prevState.socketId && brushSelection && prevState.socketId !== this.state.socketId) {

            const [from, to] = brushSelection;

            this.socket.call('reconnect', {

                access_token: localStorage.getItem('auth_token'),
                mainRange: {
                    startTime: brushRange?.startDate.toISOString(),
                    endTime: brushRange?.endDate.toISOString(),
                    numberOfDataPoints: screenWidth,
                },
                brashRange: {
                    startTime: from.toISOString(),
                    endTime: to.toISOString(),
                    numberOfDataPoints: screenWidth,
                },

            });
        }

        let selectedFromTime = 0, selectedToTime = 0, prevFromTime = 0, prevToTime = 0;


        if (brushSelection) {

            selectedFromTime = brushSelection[0].getTime();
            selectedToTime = brushSelection[1].getTime();
        }

        if (prevProps.brushSelection) {

            prevFromTime = prevProps.brushSelection[0].getTime();
            prevToTime = prevProps.brushSelection[1].getTime();
        }

        if (brushSelection &&
            (
                selectedFromTime !== prevFromTime ||
                selectedToTime !== prevToTime ||
                prevProps.brushRule !== brushRule ||
                (screenWidth && screenWidth !== prevProps.screenWidth)
            )
        ) {

            const [from, to] = brushSelection;

            if (brushRange) {

                this.rangeRule = moment(brushRange.startDate).isSame(from);

            }

                    this.callSocketGraphData();
                    this.callSocketGraphDataRange();
        }

        if (dashboardOnline !== prevProps.dashboardOnline) {

            if (!dashboardOnline) {

                this.socket.stop('getGraphDataHr', this.processSocketResponse.bind(this));

            } else {

                this.socket.listen('getGraphDataHr', this.processSocketResponse.bind(this));
            }
        }

    }

    /**
     * Callback before the component will be removed from DOM
     */
    componentWillUnmount() {

        this.socket.disconnect();
    }

    /**
     * Socket provider instance
     *
     * @type {SocketProvider}
     */
    private socket: SocketProvider;

    /**
     * Socket message Id
     *
     * @type {number}
     */
    private socketMessageId: number;

    /**
     * Socket message Id for range
     *
     * @type {number}
     */
    private socketMessageIdForRange: number;

    /**
     *
     * Override/append sensor data flag
     *
     * @type {boolean}
     */
    private overrideSensorData: boolean;

    /**
     *
     * Override/append sensor data flag for range
     *
     * @type {boolean}
     */
    private overrideSensorDataRange: boolean;

    /**
     * Range rule. Display range data
     *
     * @type {boolean}
     * @private
     */
    private rangeRule = true;

    /**
     * Get socket ID
     *
     * @param {string} id
     */
    getSocketId(id: string): void {

        const { socketId } = this.state;

        if (socketId !== id) {

            this.setState({ socketId: id });

        }
    }

    /**
     * Process response from sockets and call a callback
     *
     * @param {Object} response
     */
    processSocketResponse<T>(response: { messageId: number, type: 'state' | 'graph', values:IHistogramData[],  data: T }) {

        if (this.socketMessageId === response.messageId) {


            if (response.type === 'graph') {

                poolingGaps(response.values as unknown as IHistogramData[], appConfig.valueRepeatTimeout).then(value => {


                    if (this.props.processHrData) {

                        this.props.processHrData({ ...response, values: value }, this.overrideSensorData, this.rangeRule);
                    }

                });

            } else {

                if (this.props.processHrData) {

                    this.props.processHrData(response, this.overrideSensorData, this.rangeRule);
                }

            }



            this.overrideSensorData = false;
        }

        if (this.socketMessageIdForRange === response.messageId) {

            if (response.type === 'graph') {

                poolingGaps(response.values as unknown as IHistogramData[], appConfig.valueRepeatTimeout).then(value => {


                    if (this.props.processHrDataRange) {


                        this.props.processHrDataRange({ ...response, values: value }, this.overrideSensorData, this.rangeRule);
                    }


                });

            } else {

                if (this.props.processHrDataRange) {

                    this.props.processHrDataRange(response, this.overrideSensorDataRange, this.rangeRule);
                }
            }

            this.overrideSensorDataRange = false;
        }

    }

    /**
     * Process response from sockets and call a callback
     *
     * @param {Object} response
     */
    processSocketResponseUpdate<T>(response: { messageId: number, data: T }) {

        if (this.socketMessageId === response.messageId) {
            if (this.props.processHrDataUpdate) {

                this.props.processHrDataUpdate(response.data);
            }
        }

        if (this.socketMessageIdForRange === response.messageId) {

            if (this.props.processHrDataRangeUpdate) {

                this.props.processHrDataRangeUpdate(response.data);
            }
        }

    }

    /**
     * Call Graph Data
     */
    callSocketGraphData() {

        const { screenWidth, brushRange, dashboardOnline } = this.props;

        this.socketMessageId = Math.floor(100000000 + (Math.random() * 900000000));

        this.setPositiveOverride();

        if (brushRange) {

            const getGraphDataArgs = {
                messageId: this.socketMessageId,
                numberOfDataPoints: screenWidth,
                from: brushRange.startDate.toISOString(),
                to: brushRange.endDate.toISOString(),
                access_token: localStorage.getItem('auth_token'),
                subscribe: dashboardOnline,
                employees: this.getActiveEmployee(),
            };

            this.socket.call('getGraphDataHr', getGraphDataArgs, this.processSocketResponse.bind(this));
        }
    }

    /**
     * Call Graph Data Range
     */
    callSocketGraphDataRange() {

        const { screenWidth, brushSelection } = this.props;

        this.socketMessageIdForRange = Math.floor(100000000 + (Math.random() * 900000000));

        this.setPositiveOverride();

        if (brushSelection) {
            const [from, to] = brushSelection;

            const getGraphDataArgsRange = {
                messageId: this.socketMessageIdForRange,
                numberOfDataPoints: screenWidth,
                from: from.toISOString(),
                to: to.toISOString(),
                access_token: localStorage.getItem('auth_token'),
                subscribe: false,
                employees: this.getActiveEmployee(),
            };

            this.socket.call('getGraphDataHr', getGraphDataArgsRange, this.processSocketResponse.bind(this));
        }
    }

    /**
     * Get active employee
     *
     * @return {number[]}
     */
    getActiveEmployee(): number[] {

        // const { monitoringTree } = this.props;

        // if (monitoringTree) {

        //     const idEmployee: number[] = [];

        //     monitoringTree.departments.forEach(value => {
        //         if (!value.isMinimize && value.id) {
        //             value.employees.forEach(employee => {
        //                 if (employee.isVisible) {
        //                     idEmployee.push(employee.id);
        //                 }
        //             });
        //         }
        //     });

        //     return idEmployee;
        // }

        return [];
    }

    /**
     * Set Positive Override
     */
    setPositiveOverride() {
        this.overrideSensorData = true;

        this.overrideSensorDataRange = true;
    }

    render() {

        const { mode, data, searchField = '' } = this.props;

        if (mode === 'monitoringMode' && data) {

            const { visible, maxWidth } = data;

            return (
                <React.Fragment>
                    <MonitoringTree searchField={searchField} sidebarVisible={visible} sidebarMaxWidth={maxWidth} />
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <EditMonitoringTree searchField={searchField} />
            </React.Fragment>
        );
    }
}


/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @returns {Object}
 */
const mapStateToProps = (state: Merge<RootState | { hr: IHrState }>) => {
    
    const { selection, brushRule } = state.graphMinimapBrush,
        { online, lastUpdate, screenWidth } = state.dashboard,
        { currentPeriod, range } = state.graphPeriod;

    const { monitoringTreeState } = state.hr;

    const { monitoringTree } = monitoringTreeState;
    const selectedDashboard = selectSelectedDashboard(state);
    return {
        brushSelection: selection,
        screenWidth,
        dashboardOnline: online,
        dashboardLastUpdate: lastUpdate,
        brushRule,
        selectedPeriod: currentPeriod,
        brushRange: range,
        monitoringTree,
        dashboardId: selectedDashboard?.id,
    };
};

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = ({
    fetchTree: monitoringTreeDashboardThunks.fetchDashboard,
    processHrData: monitoringTreeDashboardThunks.processHrData,
    processHrDataRange: monitoringTreeDashboardThunks.processHrDataRange,
    processHrDataUpdate: monitoringTreeDashboardThunks.processHrDataUpdate,
    processHrDataRangeUpdate: monitoringTreeDashboardThunks.processHrDataRangeUpdate,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MonitoringTreeWrapper);
