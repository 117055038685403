import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema, Shape } from 'yup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';

import { IDashboard, IData, IError, IGraphPreferences } from '../../../../core/interfaces';
import { graphConstants } from '../../../../core/constants';
import { Button, TextInput, ColorPicker } from '../../../../core/ui/components';
import { FormActions, SensorActions } from '../../../../core/actions';

import { ReactComponent as LinearIcon } from '../../../../core/ui/assets/images/icons/linear-off.svg';
import { ReactComponent as LinearActiveIcon } from '../../../../core/ui/assets/images/icons/linear-on.svg';
import { ReactComponent as LogarithmicIcon } from '../../../../core/ui/assets/images/icons/logarithmic-off.svg';
import { ReactComponent as LogarithmicActiveIcon } from '../../../../core/ui/assets/images/icons/logarithmic-on.svg';
import { ReactComponent as CubismIcon } from '../../../../core/ui/assets/images/icons/cubism-off.svg';
import { ReactComponent as CubismActiveIcon } from '../../../../core/ui/assets/images/icons/cubism-on.svg';

import './HistogramSettingsForm.scss';
import { RootState } from '../../../../core/store';

interface IFormValues {
    id?: number;
    min: number | null;
    max: number | null;
    mode: string;
    color: string;
}

interface IProps {
    model: IData | null;
    errors: IError;
    toggleForm: (opened: boolean, name?: string) => void;
    updateSensorPreference: (preference: IGraphPreferences)=>void;
    selectedDashboard?: IDashboard;
}

interface IState {
    initialValues: IFormValues;
}

/**
 * Histogram preferences form
 *
 * @class HistogramSettingsForm
 */
class HistogramSettingsForm extends React.Component<IProps & WithTranslation, IState> {


    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);

        const { model, t } = this.props;

        this.state = {
            initialValues: {
                min: model && model.min,
                max: model && model.max,
                mode: model && (model.scale || graphConstants.histogramModeLinear),
                color: model && (model.color || '#b3de8e'),
            },
        };

        this.validationSchema = yup.object().shape({
            min: yup
                .number()
                .typeError(t('THE_VALUE_IS_NOT_A_NUMBER'))
                .test('min', t('THE_VALUE_CAN_CONTAIN_ONLY_ONE_DECIMAL_PLACE'), min => {

                    if (min || min === 0) {

                        return min === Number(min.toFixed(1)) || min === Number(min.toFixed(0)) || min === 0;
                    }

                    return true;
                })
                .nullable(),
            max: yup
                .number()
                .typeError(t('THE_VALUE_IS_NOT_A_NUMBER'))
                .test('max', t('THE_VALUE_CAN_CONTAIN_ONLY_ONE_DECIMAL_PLACE'), max => {

                    if ((max || max === 0)) {

                        return max === 0 || max === Number(max.toFixed(0)) || max === Number(max.toFixed(1));
                    }

                    return true;
                })
                .test({
                    name: 'max',
                    exclusive: false,
                    params: {},
                    message: '${path} must be less than 10% of the price',
                    test: function (max) {

                        if (this.parent.min) {

                            return this.parent.min < max;
                        }

                        return true;
                    },
                })
                .nullable(),
            mode: yup.string(),
            color: yup.string(),
        });

        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleCancel = this.handleCancel.bind(this);
    }

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<Record<string, unknown>, IFormValues>>;

    /**
     * Form submit handler
     *
     * @param {IFormValues} values
     */
    handleSubmit(values: IFormValues) {

        const { model, selectedDashboard, updateSensorPreference } = this.props;

        if (model && selectedDashboard) {

            const updateData: IGraphPreferences = {
                color: values.color,
                dashboard: selectedDashboard.id,
                sensor: model.sensor,
                id: model.id,
                max: values.max || values.max === 0? Number(String(values.max).replace(/\s/g, '')): null,
                min: values.min || values.min === 0? Number(String(values.min).replace(/\s/g, '')): null,
                scale: values.mode,
            };

            updateSensorPreference(updateData);

            this.props.toggleForm(true);
        }
    }

    /**
     * Cancel handler
     */
    handleCancel() {

        this.props.toggleForm(true);

    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t, errors, model } = this.props,
            { initialValues } = this.state;

        const colors = ['#b3de8e', '#fcbe75', '#cab3d5', '#d0d4d6', '#DD7E6B', '#EA9999', '#F9CB9C', '#FDE599', '#A2C4C9', '#B4A7D6', '#D5A6BD', '#F49831', '#FDF733', '#76F013'];

        return (
            <div className="histogram-settings-form">
                <h3>{t('GRAPH_PREFERENCES')}{' (' + model?.sensorName + ')' || ''}</h3>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit} noValidate style={{ paddingBottom: 80 }}>

                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <TextInput
                                        className={
                                            'form-field ' +
                                            (props.touched.min ? (props.errors.min || errors.min) ? 'error-field' : 'success-field' : '')
                                        }
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        label={t('MIN_VALUE')}
                                        value={props.values.min === null ? '' : props.values.min}
                                        name="min"
                                        type="string"
                                        placeholder={this.props.t('AUTO')}
                                    >
                                        {((props.touched.min && props.errors.min) || errors.min) &&
                                            <div className="validation-massage">{props.errors.min || errors.min}</div>
                                        }
                                    </TextInput>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        className={
                                            'form-field ' +
                                            (props.touched.max ? (props.errors.max || errors.max) ? 'error-field' : 'success-field' : '')
                                        }
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        label={t('MAX_VALUE')}
                                        value={props.values.max === null ? '' : props.values.max}
                                        name="max"
                                        type="string"
                                        placeholder={this.props.t('AUTO')}
                                    >
                                        {((props.touched.max && props.errors.max) || errors.max) &&
                                            <div className="validation-massage">{props.errors.max || errors.max}</div>
                                        }
                                    </TextInput>
                                </Grid>
                            </Grid>
                            <div className="form-field">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{t('SCALE')}</FormLabel>
                                    <RadioGroup aria-label="mode" name="mode" value={props.values.mode} onChange={props.handleChange}>
                                        <FormControlLabel
                                            value={graphConstants.histogramModeLinear}
                                            control={
                                                <Radio color="default"
                                                    icon={<LinearIcon />}
                                                    checkedIcon={<LinearActiveIcon />}
                                                />
                                            }
                                            label={t('LINEAR')}
                                        />
                                        <FormControlLabel
                                            value={graphConstants.histogramModeLogarithmic}
                                            control={
                                                <Radio color="default"
                                                    icon={<LogarithmicIcon />}
                                                    checkedIcon={<LogarithmicActiveIcon />}
                                                />
                                            }
                                            label={t('LOGARITHMIC_BASE_10')}
                                        />
                                        <FormControlLabel
                                            value={graphConstants.histogramModeOverlapping}
                                            control={
                                                <Radio color="default"
                                                    icon={<CubismIcon />}
                                                    checkedIcon={<CubismActiveIcon />}
                                                />
                                            }
                                            label={t('OVERLAPPING')}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className="form-field">
                                <ColorPicker label={t('COLOR')} colors={colors} value={props.values.color} onChange={props.handleChange} />
                            </div>
                            <div className="button-row">
                                <Button
                                    type="reset"
                                    color={'primary'}
                                    onClick={this.handleCancel}
                                >
                                    {t('CANCEL')}
                                </Button>
                                <Button
                                    type="submit"
                                    color={'secondary'}
                                >
                                    {t('SAVE')}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { selectedDashboard } = state.dashboardSelect;

    return {
        errors: {},
        selectedDashboard,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    toggleForm: FormActions.toggle,
    updateSensorPreference: SensorActions.updateSensorPreference,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HistogramSettingsForm));