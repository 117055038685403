import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import { ReactComponent as Hr } from '../../../ui/assets/images/icons/hr-editing-not-selected.svg';
import { ReactComponent as HrSelected } from '../../../ui/assets/images/icons/hr-editing-selected.svg';
import { ReactComponent as HRIcon } from '../../../ui/assets/images/icons/hr-monintorihng.svg';
import { connect, useSelector } from 'react-redux';
import { GraphActions } from '../../../../../base/store/actions';
import { monitoringTreeDashboardThunks } from '../../../store/thunks/monitoringTreeDashboardThunks';
import { IHrState } from '../../../store/reducers';
import { IEmployeeItemProps } from '../../../../interfaces';
import { StateChart } from '../../../../../base/components';
import { Merge } from '../../../../../helpers/mergeType';
import { RootState } from '../../../../../core/store';
import { isMobile } from 'react-device-detect';
import { selectScreenWidth } from '../../../../../core/selectors/dashboard/dashboardSelector';
import { calcRealTimeIndentation } from '../../../../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectHmiPlayerMode } from '../../../../../core/selectors/hmi/playerSelector';


const EmployeeItem: React.FC<IEmployeeItemProps> = (
    {
        employee,
        editable,
        visibleSideBar,
        maxWidthSideBar,
        peakEnterEmpty,
        peakLeave,
        updateDashboard,
        monitoringTree,
        graphHeight,
        barToggleTableView,
    }: IEmployeeItemProps,
) => {

    const [visible, setVisible] = useState<boolean>(employee.isVisible || false);

    const screenWidth = useSelector(selectScreenWidth),
        realTimeIndentation = useSelector(calcRealTimeIndentation),
        HMIPlayerStatus = useSelector(selectHmiPlayerMode);

    useEffect(() => {

        setVisible(employee.isVisible);

    }, [employee]);

    const updateVisibilityCallback=useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{

        event.preventDefault();

        if (editable) {

            barToggleTableView(false, 0);

            if (monitoringTree) {
                updateDashboard({
                    id: monitoringTree.id,
                    isMinimized: monitoringTree.isMinimized,
                    employee: {
                        id: employee.id,
                        isVisible: !visible,
                        position: employee.position,
                    },
                });
            }

            setVisible(!visible);
        }
    }, [visible, setVisible, editable, employee, monitoringTree, updateDashboard, barToggleTableView]);

    /**
     * Callback on peakLeave
     *
     * @type {() => void}
     */
    const peakLeaveCallback = useCallback(() => {

        peakLeave();

    }, [peakLeave]);

    /**
     * Callback on Mouse Move
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onMouseMoveCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        const index = (event.pageX - maxWidthSideBar);

        if (index <= screenWidth - realTimeIndentation) {

            if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {
                peakEnterEmpty(index);
            }
        }

    }, [peakEnterEmpty, maxWidthSideBar, screenWidth, realTimeIndentation, HMIPlayerStatus]);

    /**
     * Stop propagation callback
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const stopPropagationCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.stopPropagation();

    }, []);

    /**
     * Close tables when trying to drag
     *
     * @type {() => void}
     */
    const closeTableView = useCallback(() => {

        if (editable) {

            barToggleTableView(false, 0);
        }

    }, [barToggleTableView, editable]);

    const rightSectionEmployeeStyle = {
        maxWidth: maxWidthSideBar,
        minWidth: maxWidthSideBar,
        transition: 'max-width 0.2s',
        transitionTimingFunction: 'cubic-bezier(0.1, 0.1, 0.1, 0.1)',
        height: 'auto',
    };

    return (
        <div
            className={'item-title no-pointer'}
        >
            <div
                className="wrap-section"
            >
                <div
                    className={visibleSideBar ? 'right-section sensor-name left-padding-2 limit-padding ' : 'right-section sensor-name left-padding-2 '}
                    style={rightSectionEmployeeStyle}
                    onMouseEnter={peakLeaveCallback}
                    onMouseDown={closeTableView}
                    onTouchStart={closeTableView}
                >
                    {editable?
                        <Checkbox
                            icon={
                                <Hr
                                    className="sensor tree-icon-item"
                                />
                            }
                            checkedIcon={
                                <HrSelected
                                    className="tree-icon-item"
                                />
                            }
                            value="factory"
                            checked={visible}
                            className={''}
                            onClick={updateVisibilityCallback}
                        />
                        :
                        <HRIcon
                            className="tree-icon-item employee-icon"
                                style={{
                                    marginRight: 7,
                                    minWidth: 24,
                                }}
                        />
                    }

                    <div
                        className={`right-section-text sensor-name ${isMobile ? 'is-mobile' : ''}`}
                        onMouseEnter={peakLeaveCallback}
                    >
                        {`${employee.firstName} ${employee.middleName} ${employee.surname}`}
                    </div>
                </div>
                <div
                    className={visible ? 'left-section with-chart' : 'left-section'}
                    onMouseMove={onMouseMoveCallback}
                    style={{ height: graphHeight }}
                    onClick={stopPropagationCallback}
                >
                    {/*{visible ? <span style={{ opacity: 0.5 }}>histogram is here</span> : null}*/}

                    {visible && employee?
                        <StateChart sensor={{ }} currentUnit={{}} blockMix={false} tableTitle={''} hrMode employee={employee} />
                        :
                        null
                    }
                </div>
            </div>
        </div>
    );
};


/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @returns {Object}
 */
const mapStateToProps = (state: Merge<RootState | { hr: IHrState }>) => {

    const { monitoringTreeState } = state.hr;

    const { monitoringTree } = monitoringTreeState;

    return {
        monitoringTree,
        graphHeight: state.graphBarHeight.height,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    peakEnterEmpty: GraphActions.peakEnterEmptyLine,
    peakLeave: GraphActions.peakLeave,
    updateDashboard: monitoringTreeDashboardThunks.updateDashboard,
    barToggleTableView: GraphActions.barToggleTableView,
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeItem);
