import { trackPromise } from 'react-promise-tracker';
import ApiProvider from '../providers/apiProvider';
import { IData, IFilter, IJoin, IOrder, IUnit } from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Service to work with unit API resources
 *
 * @class UnitService
 */
export class UnitService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/units(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return ['name'];
    }

    /**
     * Get list of units
     *
     * @params {string} search A search string
     * @params {IOrder} order An order params
     *
     * @return {Promise<Object>}
     */
    list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<AxiosResponse> {

        return this.http
            .get(this.url(), {
                params: this.prepareListParams(search, order, join, filter),
                headers: this.headers,
            });
    }

    /**
     * Bulk units update
     *
     * @param {IData[]} units
     *
     * @return {Promise<AxiosResponse<T>>}
     */
    bulkUpdate(units: IData[]): Promise<AxiosResponse> {

        return this.http
            .patch(this.url() + '/bulk', units, {
                headers: this.headers,
            });
    }

    /**
     * Update unit
     *
     * @param {IUnit} unit
     *
     * @return {Promise<Object>}
     */
    update(unit: IUnit): Promise<AxiosResponse> {

        return this.http
            .patch(this.url({ id: unit.id }), unit, {
                headers: this.headers,
            });

    }
}
