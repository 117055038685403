import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { fabric } from 'fabric';
import { IObjectOptions, Pattern, Point } from 'fabric/fabric-impl';

import { MainMenu } from '../../../../base/components/Editor/panels/MainMenu';
import { LeftPanel } from '../../../../base/components/Editor/panels/LeftPanel';
import {
    FabricObject,
    IEditorTool,
    InteractionMode,
    IObject,
    ITargetObject,
} from '../../../../base/components/Editor/interfaces';

import RightSidebar from '../../../Sidebar/RightSidebar';
import ObjectForm from './components/ObjectForm';
import { FooterPanel } from './panels/FooterPanel';

import { editorConstants } from './constants';
import { Canvas } from './canvas/Canvas';
import CanvasObject from './canvas/CanvasObject';
import { ReactComponent as EngineIcon } from './img/engine-icon.svg';
import { ReactComponent as IndicatorIcon } from './img/indicator-icon.svg';
import { ReactComponent as SensorIcon } from './img/sensor-icon.svg';
import { ReactComponent as ValveIcon } from './img/valve-icon.svg';
import { ReactComponent as PlusIcon } from './img/plus-icon.svg';
import { ReactComponent as RectangleIcon } from './img/rectangle-icon.svg';
import { ReactComponent as TriangleIcon } from './img/triangle-icon.svg';
import { ReactComponent as OvalIcon } from './img/oval-icon.svg';
import { ReactComponent as StarIcon } from './img/star-icon.svg';
import { ReactComponent as PencilIcon } from './img/pencil-icon.svg';

import '../../../../base/components/Editor/styles/Editor.scss';
import { RootState } from '../../../../core/store';
import { IHmiObject, IHmiSchema, ISensor, IStateItem, ITypeObject } from '../../../../core/interfaces';
import { HmiObjectAction } from '../../../../core/actions/hmiObjectAction';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';
import {
    selectHmiObjectErrors,
    selectHmiOpenScheme,
} from '../../../../core/selectors/hmiSchemas/hmiOpenSchemeSelector';
import { FormActions, HmiSchemaAction } from '../../../../core/actions';
import { Button, ConfirmDialog, DeleteDialog } from '../../../../core/ui/components';
import { selectAllSensorItemInConfigurationTree } from '../../../../core/selectors/configurationTree/configurationTreeSelector';
import { maxString } from '../../../../core/helpers/fittingStringHelper';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { changeSizeByTypeObjectWithScale } from './functions/changeSizeByTypeObject';


interface OwnProps {
    toggleForm: (opened: boolean, name?: string) => void;
    schema: IHmiSchema | null;
    picture: File;
}

interface ISensorWithLastState extends ISensor{
    lastState?: IStateItem | null
}

type IProps = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & WithTranslation;

interface IState {
    selectedItem: FabricObject | null;
    selectedTool: string | null;
    zoomRatio: number;
    scale: number;
    editing: boolean;
    grab: boolean;
    dialogOpened: boolean;
    deleteDialogOpened: boolean;
    deleteItem: FabricObject | null;
    formOpened: boolean;
    formTitle: string | null;
    pitCodeLabel: string | undefined;
    targetObject: ITargetObject | null;
    src: string;
    width: number;
    height: number;
    meterWidth: number;
    meterHeight: number;
    canvasReady: boolean;
    workareaWidth: number;
    workareaHeight: number;
    schemaOpacity: number;
    schemaMapShow: boolean;
    schemaObjectShow: boolean;
    closeEditor: boolean;
    closeModalStatus: boolean;
    fontSizeLabel: number;
}

/**
 * Map editor
 *
 * @class Editor
 */
class Editor extends React.PureComponent<IProps, IState> {

    /**
     * Constructor
     *
     * @param props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);

        this.state = {
            selectedItem: null,
            selectedTool: null,
            zoomRatio: 1,
            scale: 10 / 800,
            editing: false,
            grab: true,
            dialogOpened: false,
            deleteDialogOpened: false,
            deleteItem: null,
            formOpened: false,
            formTitle: null,
            targetObject: null,
            width: 1279,
            height: 800,
            meterWidth: 10,
            meterHeight: 10,
            src: '',
            canvasReady: false,
            workareaWidth: 0,
            workareaHeight: 0,
            pitCodeLabel: undefined,
            schemaMapShow: true,
            schemaObjectShow: true,
            schemaOpacity: 1,
            closeEditor: false,
            closeModalStatus: false,
            fontSizeLabel: 12,
        };

        this.canvasRef = null;

        this.firstZoomToFit = false;

        this.transformedPoints = null;

        const { t } = this.props;

        this.menuTools = [
            {
                id: v4(),
                title: t('ADD_A_STANDARD_OBJECT'),
                superType: 'label',
                type: 'label',
                style: {
                    marginLeft: '20px',
                },
            },
            {
                id: editorConstants.objects.engine,
                title: t('DRAW_ENGINE'),
                superType: 'drawing',
                type: editorConstants.interaction.modeEngine,
                icon: (<EngineIcon title={t('DRAW_ENGINE')} />),
            },
            {
                id: editorConstants.objects.sensor,
                title: t('DRAW_SENSOR'),
                superType: 'drawing',
                type: editorConstants.interaction.modeSensor,
                icon: (<SensorIcon title={t('DRAW_SENSOR')} />),
                options: {
                    strokeWidth: 5,
                    stroke: '#33bbff',
                    fill: 'rgba(0, 0, 0, 0)',
                    opacity: 1,
                },
            },
            {
                id: editorConstants.objects.valve,
                title: t('DRAW_VALVE'),
                superType: 'drawing',
                type: editorConstants.interaction.modeValve,
                icon: (<ValveIcon title={t('DRAW_VALVE')} />),
                options: {
                    strokeWidth: 5,
                    stroke: '#33bbff',
                    fill: 'rgba(0, 0, 0, 0)',
                    opacity: 1,
                },
            },
            {
                id: editorConstants.objects.indicator,
                title: t('DRAW_INDICATOR'),
                superType: 'drawing',
                type: editorConstants.interaction.modeIndicator,
                icon: (<IndicatorIcon title={t('DRAW_INDICATOR')} />),
                options: {
                    strokeWidth: 5,
                    stroke: '#33bbff',
                    fill: 'rgba(0, 0, 0, 0)',
                    opacity: 1,
                },
            },
            {
                id: v4(),
                title: '',
                superType: 'separator',
                type: 'separator',
            },
            {
                id: v4(),
                title: t('ADD_A_CUSTOM_OBJECT'),
                superType: 'label',
                type: 'label',
            },
            {
                id: v4(),
                title: '',
                superType: 'dropdown',
                type: 'dropdown',
                icon: (<PlusIcon title={t('ADD_A_CUSTOM_OBJECT')} />),
                items: [
                    {
                        id: editorConstants.objects.rectangle,
                        title: t('RECTANGLE'),
                        superType: 'drawing',
                        type: editorConstants.interaction.modeRectangle,
                        icon: (<RectangleIcon title={t('DRAW_A_RECTANGLE')} />),
                    },
                    {
                        id: editorConstants.objects.oval,
                        title: t('OVAL'),
                        superType: 'drawing',
                        type: editorConstants.interaction.modeOval,
                        icon: (<OvalIcon title={t('DRAW_AN_OVAL')} />),
                    },
                    {
                        id: editorConstants.objects.triangle,
                        title: t('TRIANGLE'),
                        superType: 'drawing',
                        type: editorConstants.interaction.modeTriangle,
                        icon: (<TriangleIcon title={t('DRAW_A_TRIANGLE')} />),
                    },
                    {
                        id: editorConstants.objects.star,
                        title: t('STAR'),
                        superType: 'drawing',
                        type: editorConstants.interaction.modeStar,
                        icon: (<StarIcon title={t('DRAW_A_STAR')} />),
                    },
                    {
                        id: editorConstants.objects.draw,
                        title: t('DRAW'),
                        superType: 'drawing',
                        type: editorConstants.interaction.modePolygon,
                        icon: (<PencilIcon title={t('DRAW_A_FREE_FIGURE')} />),
                        options: {
                            strokeWidth: 5,
                            stroke: '#74797d',
                            fill: '#cdd0d4',
                            opacity: 1,
                        },
                    },
                ],
            },
        ];

        this.sliderZoomChange = this.sliderZoomChange.bind(this);
        this.switchGrab = this.switchGrab.bind(this);
        this.selectTool = this.selectTool.bind(this);
        this.setCanvasRef = this.setCanvasRef.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onZoom = this.onZoom.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
        this.onFormCancel = this.onFormCancel.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onToggleObject = this.onToggleObject.bind(this);
        this.lockEditObj = this.lockEditObj.bind(this);
        this.mapEditorClose = this.mapEditorClose.bind(this);
        this.mapEditorSave = this.mapEditorSave.bind(this);
        this.onSaveObjectForm = this.onSaveObjectForm.bind(this);
        this.objectTypeCheck = this.objectTypeCheck.bind(this);
        this.drawSchemaObject = this.drawSchemaObject.bind(this);
        this.removeObjectItemAfterUpdate = this.removeObjectItemAfterUpdate.bind(this);
        this.mapHmiObjects = this.mapHmiObjects.bind(this);
        this.onCloseObjectForm = this.onCloseObjectForm.bind(this);
        this.onChangeObjectForm = this.onChangeObjectForm.bind(this);
        this.onEditScheme = this.onEditScheme.bind(this);
        this.onOpenObjectForm = this.onOpenObjectForm.bind(this);
        this.onChangePositionActiveObject = this.onChangePositionActiveObject.bind(this);
        this.deleteHmiObject = this.deleteHmiObject.bind(this);
        this.onDeleteCancel = this.onDeleteCancel.bind(this);
        this.mapOpacityChange = this.mapOpacityChange.bind(this);
        this.changeVisibleObject = this.changeVisibleObject.bind(this);
        this.onShowMap = this.onShowMap.bind(this);
        this.onShowObjects = this.onShowObjects.bind(this);
        this.capitalize = this.capitalize.bind(this);
        this.onCancelPopup = this.onCancelPopup.bind(this);
        this.confirmCloseForm = this.confirmCloseForm.bind(this);
        this.cancelCloseForm = this.cancelCloseForm.bind(this);
        this.openCloseModal = this.openCloseModal.bind(this);
        this.sortingByPosition = this.sortingByPosition.bind(this);
    }

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount(): void {

        window.addEventListener('resize', this.updateCanvasSize.bind(this));
        window.addEventListener('orientationchange', this.updateCanvasSize.bind(this));

        this.updateCanvasSize();

        this.canvasRef && this.canvasRef.handler.interactionHandler.grab();
    }

    /**
     * Component props update handler
     *
     * @param {IProps & WithTranslation} prevProps
     * @param {IState} prevState
     */
    componentDidUpdate(prevProps: Readonly<IProps & WithTranslation>, prevState: Readonly<IState>): void {

        const { schema, picture } = this.props;

        if (this.state.canvasReady) {

            if (this.state.selectedTool !== editorConstants.interaction.modeEdit) {

                this.lockEditObj(true);
            }

        }

        if (schema && schema?.hmiObjects && this.canvasRef && !this.hmiObjectsLoaded) {

            this.mapHmiObjects(schema.hmiObjects);

            this.hmiObjectsLoaded = true;

            this.canvasRef.handler.interactionHandler.grab(true);
        }

        if (schema && schema?.hmiObjects && this.canvasRef && this.hmiObjectsLoaded && schema !== prevProps.schema) {

            this.removeObjectItemAfterUpdate();

            this.setState({ targetObject: null });

            this.mapHmiObjects(Array.from(new Set(schema.hmiObjects)));

            this.lockEditObj(false);
        }


        if (schema && schema.picture !== prevProps.schema?.picture) {

            const currentSchemeImg = new Image();

            currentSchemeImg.onload = () => {

                this.setState({
                    src: picture,
                    width: currentSchemeImg.width,
                    height: currentSchemeImg.height,
                    schemaOpacity: schema.opacity !== undefined ? this.firstZoomToFit ? this.state.schemaOpacity: schema.opacity : 1,
                    schemaObjectShow: schema.showObjects !== undefined ?
                        this.firstZoomToFit? this.state.schemaObjectShow
                            : Boolean(schema.showObjects) : true,
                    schemaMapShow: schema.showMinimap !== undefined ? Boolean(schema.showMinimap) : true,
                    fontSizeLabel: schema?.fontSize || 12,
                });
            };

            currentSchemeImg.src = picture;

        }

        if (!this.firstZoomToFit && this.canvasRef) {

            this.canvasRef.canvas.selection = false;
            this.canvasRef.handler.zoomHandler.zoomToFit();

            if (this.props.schema?.scale) {

                this.canvasRef.handler.zoomHandler.zoomToValue(this.props.schema.scale);
            }

            this.firstZoomToFit = true;
        }

        if (this.canvasRef) {

            this.canvasRef.canvas.selection = false;
        }


        if (this.state.scale === prevState.scale) {

            this.sortingByPosition();
        }
    }

    /**
     * Callback before the component will be removed from DOM
     */
    componentWillUnmount(): void {

        const { src } = this.state;

        if (src) {

            URL.revokeObjectURL(src);

            this.setState({ src: '' });
        }

        window.removeEventListener('resize', this.updateCanvasSize.bind(this));
        window.removeEventListener('orientationchange', this.updateCanvasSize.bind(this));

        if (!this.state.closeEditor) {

            this.mapEditorClose();
        }
        this.props.loadSchemas();
    }

    /**
     * Canvas component reference
     *
     * @type {Canvas}
     */
    private canvasRef: Canvas | null;

    /**
     * Array of drawn objects
     *
     * @type {Record<string, unknown>[]}
     */
    private objects: IObject[] = [];

    /**
     * Application header height in pixels
     *
     * @type {number}
     */
    headerHeight = 40;

    /**
     * Left sidebar width in pixels
     *
     * @type {number}
     */
    leftSidebarWidth = 320;

    /**
     *
     * @type {any}
     */
    savedObject: FabricObject | null = null;

    /**
     *
     *
     * @type {Point[] | null}
     */
    transformedPoints: null | Point[];

    /**
     * Main menu tools
     *
     * @type {IEditorTool[]}
     */
    menuTools: IEditorTool[];

    /**
     * First loaded object for scheme HMI
     *
     * @type {Boolean}
     * @private
     */
    private hmiObjectsLoaded = false;

    /**
     * First zoom to fit
     *
     * @type {boolean}
     * @private
     */
    private firstZoomToFit: boolean;

    /**
     * Origin element
     *
     * @type {(Object & IObjectOptions & {id?: string, parentId?: string, originOpacity?: number, originTop?: number, originLeft?: number, originScaleX?: number, originScaleY?: number, originAngle?: number, originFill?: string | Pattern, originStroke?: string, editable?: boolean, class?: string, deletable?: boolean, dblclick?: boolean, cloneable?: boolean, locked?: boolean, [p: string]: any}) | undefined}
     * @private
     */
    // eslint-disable-next-line react/sort-comp
    private targetElOrigin: (Object & IObjectOptions & { id?: string; parentId?: string; originOpacity?: number; originTop?: number; originLeft?: number; originScaleX?: number; originScaleY?: number; originAngle?: number; originFill?: string | Pattern; originStroke?: string; editable?: boolean; class?: string; deletable?: boolean; dblclick?: boolean; cloneable?: boolean; locked?: boolean; [p: string]: any; }) | undefined;

    /**
     * Origin pid-code label
     *
     * @type {FabricObject | undefined}
     * @private
     */
    private targetElLiableOrigin: FabricObject | undefined;

    /**
     * Last width of  active object at last moment
     *
     * @type {number | undefined}
     * @private
     */
    private lastWidthObject: number | undefined;

    /**
     * Last height of  active object at last moment
     *
     * @type {number | undefined}
     * @private
     */
    private lastHeightObject: number | undefined;

    /**
     * Last scaleY of  active object at last moment
     *
     * @type {number | undefined}
     * @private
     */
    private lastScaleYObject: number | undefined;

    /**
     * Last scaleX of  active object at last moment
     *
     * @type {number | undefined}
     * @private
     */
    private lastScaleXObject: number | undefined;

    /**
     *
     * @param {IHmiObject[]} hmiObjects
     */
    mapHmiObjects(hmiObjects: IHmiObject[]) {

        hmiObjects
            .filter((value, index) => hmiObjects.findIndex(obj=>obj.id === value.id) === index)
            .sort((a, b) => parseInt((a.zIndex || 0).toString()) < parseInt((b.zIndex || 0) .toString()) ? -1 : 1)
            .map(value => {

                this.objectTypeCheck(value);

            });

        this.sortingByPosition();

        //After Update, overwrites the entity for the open form
        if (this.state.targetObject && this.state.formOpened && this.canvasRef) {

            const afterChange = this.canvasRef.canvas.getObjects().find((value: FabricObject) =>
                value.objectId === (this.state.targetObject as unknown as FabricObject).objectId &&
                value.id !== (this.state.targetObject as unknown as FabricObject).id);

            if (afterChange) {

                this.setState({ targetObject: afterChange as unknown as ITargetObject });
            }
        }
    }

    /**
     *
     */
    removeObjectItemAfterUpdate() {

        if (this.canvasRef) {

            const allEngine = this.canvasRef.canvas.getObjects().filter((item: FabricObject) => item.id !== 'workarea');

            allEngine.map(item => {

                this.canvasRef && this.canvasRef.canvas.remove(item);
            });
            this.canvasRef.canvas.renderAll();

        }
    }

    getFormatDataValue(sensor?: ISensor): number | string {
        const formatData = sensor?.latestValue === undefined || sensor?.latestValue === null ? 'No data' : Number(sensor.latestValue).toFixed(2);

        if (sensor?.sensorType && sensor.sensorType === 'state') {

            return (sensor as ISensorWithLastState).lastState?.name || formatData;
        }

        return typeof sensor?.latestValue === 'number' ?
            (Number(formatData)).toString().length >= 10 ?
                maxString((Number(formatData)).toString(), 10)
                :
                formatData
                + ' ' + sensor.um : 'No data';
    }

    /**
     * Adding schematic objects to the canvas
     *
     * @param {string} type
     * @param {IHmiObject} item
     */
    drawSchemaObject(type: string, item: IHmiObject) {

        const optionsMapping = {
            [editorConstants.interaction.modeRectangle]: {
                strokeWidth: 3,
                stroke: '#74797d',
                fill: '#cdd0d4',
                opacity: 1,
                width: 18,
                height: 18,
            },
            [editorConstants.interaction.modeOval]: {
                strokeWidth: 1,
                stroke: '#74797d',
                fill: '#cdd0d4',
                opacity: 1,
                radius: 9,
            },
            [editorConstants.interaction.modeTriangle]: {
                strokeWidth: 1,
                stroke: '#74797d',
                fill: '#cdd0d4',
                opacity: 1,
                width: 18,
                height: 18,
            },
            [editorConstants.interaction.modePolygon]: {
                stroke: '#999999',
                fill: '#cccccc',
                strokeUniform: true,
                strokeWidth: 3 / this.state.zoomRatio,
                strokeWidthInitial: 3,
                opacity: 1,
                objectCaching: false,
                superType: 'drawing',
            },
        };

        if (this.canvasRef) {
            const currentPitCodeLabel: ISensorWithLastState | undefined = this.props.allSensorInTree.find(sensor => sensor.id === item.sensorId),
                { schema } = this.props,
                { fontSizeLabel } = this.state;


            const dataValue = this.getFormatDataValue(currentPitCodeLabel);

            if (currentPitCodeLabel) {
                const itemPoints = item.points ?
                    item.points.map((point: number[]) => {

                        const [x = 0, y = 0] = point;

                        return { x: x, y: y };
                    }) : [];

                const itemOption = {
                    id: v4(),
                    type: type,
                    left: item.position?.x || 0,
                    top: item.position?.y || 0,
                    objectId: item.id,
                    unitId: item.unit,
                    factoryId: item.fabric,
                    schemaId: schema?.id || 0,
                    zIndex: 20 + (item.zIndex || 1),
                    layoutNumber: item.zIndex || 1,
                    suppressCallback: true,
                    dataValue: currentPitCodeLabel.lastState? currentPitCodeLabel.lastState.name: dataValue,
                    pidCode: currentPitCodeLabel.pIdCode || currentPitCodeLabel.name,
                    angle: item.angle,
                    fontSize: typeof item.fontSize === 'number' ? item.fontSize : 12,
                    pidCodePosition: item.pidCodePosition,
                    points: itemPoints,
                    visible: this.firstZoomToFit? this.state.schemaObjectShow : Boolean(schema!.showObjects),
                    sensorId: currentPitCodeLabel?.id || null,
                    textPositionInObject: item.textPositionInObject,
                    fontSizeLabel: fontSizeLabel,
                };

                const drawScale = item.scale && (type === 'sensor' ||
                        type === 'engine' ||
                        type === 'star' ||
                        type === 'oval' ||
                        type === 'triangle' ||
                        type === 'valve') ? {
                        scaleX: item.scale.x,
                        scaleY: item.scale.y,
                    } : {
                        height: item.height || 18,
                        width: item.width || 18 * 3,
                        scaleX: 1,
                        scaleY: 1,
                    },
                    labelItemOption = {
                        id: v4(),
                        type: 'pitCode',
                        label: currentPitCodeLabel ? maxString(currentPitCodeLabel.pIdCode || currentPitCodeLabel.name, 15) : '',
                        left: item.position?.x || 0,
                        top: item.position?.y || 0,
                        objectId: item.id,
                        modeEditor: false,
                        unitId: item.unit,
                        factoryId: item.fabric,
                        objectType: type,
                        layoutNumber: item.zIndex || 1,
                        zIndex: 20 + (item.zIndex || 1),
                        dataValue: dataValue,
                        suppressCallback: true,
                        itemId: itemOption.id,
                        pidCodePosition: item.pidCodePosition,
                        schemaId: schema?.id || 0,
                        fontSize: fontSizeLabel,
                        visible: this.firstZoomToFit? this.state.schemaObjectShow : Boolean(schema!.showObjects),
                    };


                this.canvasRef.handler.add({ ...itemOption, ...optionsMapping[type], ...drawScale });

                // Provides correct positioning for label
                // const addLabelTimeout = setTimeout(() => {

                    this.canvasRef && this.canvasRef.handler.add(labelItemOption);

                    // clearTimeout(addLabelTimeout);
                    //
                // }, 40);
            }
        }
    }

    /**
     * Object type check
     *
     * @param {IHmiObject} item
     */
    objectTypeCheck(item: IHmiObject) {

        const {
            modePolygon,
            modeEngine,
            modeSensor,
            modeValve,
            modeIndicator,
            modeRectangle,
            modeOval,
            modeTriangle,
            modeStar,
        } = editorConstants.interaction;

        const {
            engine,
            sensor,
            valve,
            indicator,
            rectangle,
            oval,
            triangle,
            star,
            draw,
        } = editorConstants.typeToId;

        if (this.canvasRef) {

            switch ((item.type as ITypeObject).id) {

                case engine:

                    this.drawSchemaObject(modeEngine, item);

                    break;

                case indicator:

                    this.drawSchemaObject(modeIndicator, item);

                    break;

                case valve:

                    this.drawSchemaObject(modeValve, item);

                    break;

                case sensor:

                    this.drawSchemaObject(modeSensor, item);

                    break;

                case rectangle:

                    this.drawSchemaObject(modeRectangle, item);

                    break;

                case oval:

                    this.drawSchemaObject(modeOval, item);

                    break;

                case triangle:

                    this.drawSchemaObject(modeTriangle, item);

                    break;

                case star:

                    this.drawSchemaObject(modeStar, item);

                    break;

                case draw:

                    this.drawSchemaObject(modePolygon, item);

                    break;
            }
        }
    }

    /**
     * Update canvas size on resize window
     */
    updateCanvasSize() {
        const configureDraggable = document.getElementById('hmi-editor-container');

        if (configureDraggable) {
            const heightOffsetValue = 215,
                widthOffsetValue = 120;


            const editorWidth = configureDraggable.clientWidth - widthOffsetValue,
                editorHeight = configureDraggable.clientHeight - heightOffsetValue;

            this.setState({ workareaWidth: editorWidth, workareaHeight: editorHeight });

            if (this.canvasRef) {

                this.canvasRef.canvas.setHeight(editorHeight);

                this.canvasRef.canvas.setWidth(editorWidth);

                this.canvasRef.canvas.requestRenderAll();

                this.forceUpdate();
            }
        }
    }

    /**
     * Set canvas reference
     *
     * @param {Canvas} ref
     */
    setCanvasRef(ref: Canvas | null) {

        this.canvasRef = ref;
    }

    /**
     * Select a tool
     *
     * @param {IEditorTool} tool
     */
    selectTool(tool: IEditorTool) {

        const { selectedTool } = this.state;

        const {
            modeGrab,
            modePolygon,
            modeEngine,
            modeSensor,
            modeValve,
            modeIndicator,
            modeRectangle,
            modeOval,
            modeTriangle,
            modeStar,
            modeDelete,
            modeEdit,
        } = editorConstants.interaction;

        if (this.canvasRef) {

            this.onCloseObjectForm();

            //TODO: add check for an active shape
            if (this.canvasRef.handler.interactionHandler.isDrawingMode()) {

                if (this.canvasRef.handler.activeLine) {

                    this.canvasRef.handler.remove(this.canvasRef.handler.activeLine);
                }

                this.canvasRef.handler.drawingHandler.polygon.cancel();

                if (this.canvasRef.handler.onCancel) {

                    this.canvasRef.handler.onCancel();
                }
            }

            if (this.canvasRef.handler.interactionMode === modeGrab) {
                this.switchGrab();
            }

            if (tool.superType === 'drawing') {

                switch (tool.type) {

                    case modePolygon:

                        this.canvasRef.handler.drawingHandler.polygon.activate({ ...tool.options, strokeWidth: 3 });

                        break;

                    case modeEngine:

                        this.canvasRef.handler.interactionHandler.drawing(modeEngine as InteractionMode);

                        break;

                    case modeSensor:

                        this.canvasRef.handler.interactionHandler.drawing(modeSensor as InteractionMode);

                        break;

                    case modeValve:

                        this.canvasRef.handler.interactionHandler.drawing(modeValve as InteractionMode);

                        break;

                    case modeIndicator:

                        this.canvasRef.handler.interactionHandler.drawing(modeIndicator as InteractionMode);

                        break;

                    case modeRectangle:

                        this.canvasRef.handler.interactionHandler.drawing(modeRectangle as InteractionMode);

                        break;

                    case modeOval:

                        this.canvasRef.handler.interactionHandler.drawing(modeOval as InteractionMode);

                        break;

                    case modeTriangle:

                        this.canvasRef.handler.interactionHandler.drawing(modeTriangle as InteractionMode);

                        break;

                    case modeStar:

                        this.canvasRef.handler.interactionHandler.drawing(modeStar as InteractionMode);

                        break;

                }
            }

            if (tool.id === modeDelete && this.canvasRef.canvas.getActiveObjects().length) {

                this.setState({ dialogOpened: true, formTitle: null });
            }

            if (tool.id === selectedTool) {

                this.canvasRef.handler.interactionHandler.selection();
            }

            if (tool.id === modeEdit && this.canvasRef.canvas.getActiveObject()?.type === 'activeSelection') {
                this.canvasRef.canvas.discardActiveObject();
            }

            this.canvasRef.handler.menuActionMode = selectedTool !== tool.id ? tool.id : '';

            const lock = !(tool.id === modeEdit && selectedTool !== tool.id);

            this.lockEditObj(lock);
        }

        const deleteTool = tool.id === modeDelete && this.canvasRef?.canvas.getActiveObject();

        this.lockEditObj(false);

        this.setState({
            selectedTool: (tool.id !== selectedTool && tool.id !== modeDelete) || deleteTool ? tool.id : null,
            // targetObject: null,
        });
    }

    /**
     * On toggle object switch object active mode
     *
     * @param object
     */
    onToggleObject(object: IObject) {

        const { canvasRef } = this;

        this.objects.forEach((o, i) => o.id === object.id ? this.objects[i] = { ...o, active: !o.active } : null);

        if (object?.id && canvasRef) {

            const obj = canvasRef.handler.findById(object.id);

            if (!obj) {

                const layer = canvasRef.canvas.getObjects()
                    .filter((object: FabricObject) => object.id?.search(/^editor-layer-/) !== -1)
                    .find((item: any) => item.id === object.id);

                if (layer) layer.visible = !object.active;
            }

            if (obj?.opacity) obj.visible = !object.active;

            canvasRef.canvas.discardActiveObject();

            canvasRef.canvas.renderAll();

            this.forceUpdate();
        }
    }

    /**
     * Handler for slider zoom change event
     *
     * @param {React.ChangeEvent} event
     * @param {number | number[]} value
     */
    sliderZoomChange(event: React.ChangeEvent<Record<string, unknown>> | null, value: number | number[]) {

        if (this.canvasRef && !Array.isArray(value)) {

            this.canvasRef.handler.zoomHandler.zoomToValue(value / 100);
        }
    }

    /**
     * Switch between selection and grab modes
     *
     * @returns {boolean}
     */
    switchGrab() {

        if (this.canvasRef && !this.canvasRef.handler.interactionHandler.isDrawingMode()) {

            if (this.canvasRef.handler.interactionMode === editorConstants.interaction.modeSelection) {

                this.setState({
                    grab: true,
                });

                this.onCloseObjectForm();

                this.canvasRef.handler.interactionHandler.grab();

            } else {

                this.canvasRef.handler.interactionHandler.selection();

                this.setState({
                    grab: false,
                });
            }

            this.sortingByPosition();
        }
    }

    /**
     * Create new object callback
     *
     * @param target
     */
    onAdd(target: FabricObject) {

        const { selectedTool } = this.state;
        const { t } = this.props;

        this.setState({ selectedItem: target });

        if (
            selectedTool === 'sensor'
            || selectedTool === 'engine'
            || selectedTool === 'star'
            || selectedTool === 'valve'
            || selectedTool === 'triangle'
        ) {

            target.setControlsVisibility({
                tl: true, //top-left
                mt: false, // middle-top
                tr: true, //top-right
                ml: false, //middle-left
                mr: false, //middle-right
                bl: true, // bottom-left
                mb: false, //middle-bottom
                br: true, //bottom-right
            });
        }

        if (changeSizeByTypeObjectWithScale(selectedTool || 'default')) {

            target.set({
                dirty: true,
                scaleX: !this.lastScaleXObject  && this.lastWidthObject ? this.lastWidthObject / (target.width || 1) : (this.lastScaleXObject || target.scaleX),
                scaleY: !this.lastScaleXObject && this.lastHeightObject ? this.lastHeightObject / (target.height || 1) : (this.lastScaleXObject || target.scaleY),
                fontSizeLabel: this.state.fontSizeLabel,
                // objectCaching: false,
            });

            target.setCoords();
            // target.set({objectCaching: true});
            this.targetElOrigin = target;

        } else if (target?.type !== 'polygon') {

            target.set({
                dirty: true,
                height: this.lastHeightObject || target.height,
                width: this.lastWidthObject || target.width,
                fontSizeLabel: this.state.fontSizeLabel,
                scaleX: 1,
                scaleY: 1,
                // objectCaching: false,
            });

            target.setCoords();

            this.targetElOrigin = target;

            // target.set({objectCaching: true});
        }

        if (target?.type === 'polygon') {

            target.set({
                fontSizeLabel: this.state.fontSizeLabel,
            });

            target.setCoords();

            this.targetElOrigin = target;
        }

        if (!this.state.editing) {

            this.setState({
                editing: true,
            });
        }

        if (target.type === 'activeSelection') {

            this.onSelect(null);
        }

        if (this.canvasRef) {

            this.canvasRef.handler.select(target);
        }

        let formTitle = null;

        if (selectedTool) {

            if ([editorConstants.objects.engine,
                editorConstants.objects.sensor,
                editorConstants.objects.valve,
                editorConstants.objects.indicator,
            ].includes(selectedTool)) {

                formTitle = t('STANDARD_OBJECT');

            } else {

                formTitle = t('CUSTOM_OBJECT');
            }
        }

        this.setState({
            selectedTool: null,
            formOpened: true,
            formTitle: formTitle,
            targetObject: {
                ...target,
                id: target.id || '',
                type: selectedTool,
                active: true,
                points: target.points?.map((point: { x: number, y: number }) => {

                    return [point.x, point.y];
                }),
                center: target.getCenterPoint(),
            },
        });
    }

    /**
     * Remove object callback
     */
    onRemove(el: FabricObject) {

        if (!this.state.editing) {

            this.setState({
                editing: true,
            });
        }

        if (this.canvasRef) {

            const removeItemLabel = this.canvasRef.canvas.getObjects().find((obj: FabricObject) => obj.itemId === el.id);

            if (removeItemLabel) {

                this.canvasRef.canvas.add(removeItemLabel);
            }

            this.canvasRef.canvas.add(el);
        }

        this.setState({
            deleteItem: el,
            deleteDialogOpened: true,
        });

        this.onSelect(null);
    }

    /**
     * On delete success.
     */
    deleteHmiObject() {

        const { deleteHmiObject, schema } = this.props,
            { deleteItem } = this.state;

        if (deleteItem && deleteItem?.objectId) {

            const sendData: IHmiObject = {
                id: deleteItem.objectId,
                fabric: deleteItem.factoryId,
                unit: deleteItem.unitId,
                angle: deleteItem.angle || 0,
                points: (deleteItem as unknown as ITargetObject)?.points?.map(value => ({
                    x: value[0],
                    y: value[1],
                }))[0] as any || null,
                position: {
                    x: 0,
                    y: 0,
                },
                zIndex: 1,
                type: Object(editorConstants.typeToId)[`${deleteItem?.type}`],
                pidCodePosition: deleteItem.position,
                hmiSchema: schema?.id || 0,
                scale: null,
                sensorId: null,
                textPositionInObject: (deleteItem as unknown as ITargetObject)?.textPositionInObject || 'left',
            };

            deleteHmiObject(sendData);
        }

        if (deleteItem && !deleteItem?.objectId && this.canvasRef) {

            this.onCloseObjectForm();

            this.setState({
                deleteItem: null,
                formOpened: false,
            });

        }
    }

    /**
     * On delete dialog close
     */
    onDeleteCancel() {

        if (this.canvasRef) {


            this.setState({
                deleteDialogOpened: false,
                deleteItem: null,
                formOpened: false,
            });
        }
    }
    /**
     * On delete dialog close
     */
    onCancelPopup() {

        if (this.canvasRef) {

            this.setState({
                deleteDialogOpened: false,
            });

            this.lockEditObj(false);
        }
    }

    /**
     * Select object callback
     *
     * @param {Object} target
     */
    onSelect(target: FabricObject | null) {

        const { selectedItem } = this.state;

        if (target && target.id && target.id !== 'workarea' && target.id.search(/^editor-layer-/) === -1 && target.type !== 'activeSelection') {

            if (selectedItem && target.id === selectedItem.id) {

                return;
            }

            this.setState({
                selectedItem: target,
            });

            return;
        }

        this.setState({
            selectedItem: null,
        });
    }

    /**
     * Drawing cancel callback
     */
    onCancel() {

        this.setState({
            selectedTool: null,
        });
    }

    /**
     * Zoom callback
     *
     * @param {number} zoom
     */
    onZoom(zoom: number) {

        // const { height, width, meterHeight, meterWidth } = this.state;

        // calc scale on editord depends from coordinates and zoom value
        const scale = (this.state.meterWidth / this.state.width) * zoom;

        this.setState({
            zoomRatio: zoom,
            scale: scale,
        });
    }

    /**
     * Form submit handler
     *
     * @param {} model
     */
    onFormSave(model: any) {

        return 1;
    }

    /**
     * Cancel form handler
     */
    onFormCancel(): void {

        const { selectedItem, selectedTool } = this.state;

        if (this.canvasRef && selectedTool !== editorConstants.interaction.modeEdit) {

            this.canvasRef.handler.remove(selectedItem || undefined);
        }

        this.setState({ dialogOpened: false, targetObject: null });

        this.transformedPoints = null;
    }

    /**
     * Enable editable and selectable properties to objects
     *
     * @param mode
     */
    lockEditObj(mode: boolean) {

        if (this.canvasRef) {

            this.canvasRef.handler.getObjects().forEach((object: FabricObject) => {

                if (object.type !== 'pitCode') {
                    object.lockMovementX = mode;
                    object.lockMovementY = mode;
                    object.lockScalingX = mode;
                    object.lockScalingY = mode;
                    object.lockRotation = mode;
                }
            });

            this.canvasRef.canvas.renderAll();
        }
    }

    sortingByPosition() {

        if (this.canvasRef) {

            this.canvasRef.canvas._objects.sort((a: FabricObject, b: FabricObject) => parseInt(a.zIndex) < parseInt(b.zIndex) ? -1 : 1).forEach((value: FabricObject, index) => {

                if (value.id !== 'workarea') {

                    value.set({ dirty: true });
                    value.moveTo(index);

                    if (value.type !== 'polygon' && value.itemId) {

                        value.setCoords();
                    }
                }

                return value;
            });

            this.canvasRef.canvas.renderAll();
        }
    }

    /**
     * On edit object attach callback to scale, move, rotate and click actions
     *
     * @param targetEl
     */
    onEdit(targetEl: FabricObject) {

        const { interaction, objects } = editorConstants,
            { allSensorInTree } = this.props,
            { formOpened } = this.state;

        if (targetEl && targetEl.type !== 'polygon') {
            if (changeSizeByTypeObjectWithScale(targetEl?.type || 'default')) {

                this.lastScaleYObject = targetEl?.scaleY || 1;

                this.lastScaleXObject = targetEl?.scaleX || 1;
                this.lastWidthObject = targetEl.width! * targetEl.scaleX!;
                this.lastHeightObject = targetEl.height! * targetEl.scaleY!;
            } else if (targetEl && targetEl.id !== 'workarea') {

                if (targetEl?.type !== 'polygon') {
                    this.lastScaleYObject = targetEl?.scaleY || 1;
                    this.lastScaleXObject = targetEl?.scaleX || 1;
                    this.lastWidthObject = targetEl.width! * targetEl.scaleX!;
                    this.lastHeightObject = targetEl.height! * targetEl.scaleY!;

                }

            }
        }

        if (targetEl?.type === 'polygon' && this.canvasRef) {
            //Smooth movement of the pid-code behind the object
            this.canvasRef.canvas.forEachObject((newObject: FabricObject) => {

                if (targetEl && (targetEl as FabricObject).id === newObject.itemId && this.canvasRef) {

                    const position = this.canvasRef?.handler.getPositionRelativeToObject(targetEl, newObject);

                    newObject.set(position);
                    newObject.setCoords();

                }
            });
        }

        if (this.targetElOrigin && this.targetElOrigin.id !== targetEl?.id && this.canvasRef) {

            this.onCloseObjectForm(false);

            if (this.canvasRef && this.targetElOrigin) {
                const activeObj: FabricObject | undefined = this.canvasRef.canvas.getObjects().find((obj: FabricObject) => obj.objectId === targetEl?.objectId && obj.type !== 'workarea');

                if (activeObj && activeObj.type !== 'image') {

                    this.setState({
                        formTitle: activeObj?.type ? this.props.t(activeObj.type) : '',
                        targetObject: { ...activeObj as unknown as IObject },
                    });

                    this.canvasRef.canvas.setActiveObject(activeObj);
                    this.targetElOrigin = undefined;
                    this.targetElLiableOrigin = undefined;
                }
            }
        }

        if (this.state.targetObject?.id !== targetEl?.id && !this.state.targetObject?.objectId && targetEl) {

            if (this.canvasRef) {

                const removeItem = this.canvasRef.canvas.getObjects().find((value: FabricObject) => value.id === this.state.targetObject?.id);

                if (removeItem) {

                    this.canvasRef.canvas.remove(removeItem);
                }
            }

        }

        const targetObject = this.objects.find(obj => obj.id === targetEl?.id),
        targetLiablePitCode = targetEl && allSensorInTree.find(sensor=> sensor.id === targetEl.sensorId || sensor.id === parseInt(targetEl.pidCode));

        if (targetEl?.type === 'polygon' && !targetEl.objectId) {

            const labelPolygon = this.canvasRef && this.canvasRef.canvas.getObjects().find((obj: FabricObject) => obj.itemId === targetEl.id);
            if (!labelPolygon && this.canvasRef) {
                targetEl.set('pidCodePosition', 'top');
                const labelItemOption = {
                    id: v4(),
                    type: 'pitCode',
                    label: 'Select sensor',
                    left: targetEl.left || 0,
                    top: targetEl.top || 0,
                    objectId: 0,
                    modeEditor: false,
                    unitId: undefined,
                    factoryId: undefined,
                    objectType: targetEl.type,
                    layoutNumber: 1,
                    zIndex: this.canvasRef.canvas.getObjects().length + 1000,
                    dataValue: 'No data',
                    suppressCallback: true,
                    itemId: targetEl.id,
                    pidCodePosition: 'top',
                    schemaId: 0,
                    visible: true,
                    fontSize: this.state.fontSizeLabel,
                };

                this.canvasRef.handler.add(labelItemOption);
            }
        }

        if (this.state.selectedTool === interaction.modeEdit && targetObject) {

            // temporary disabled
            // let transformedPoints = null;

            if (targetEl.type === 'polygon') {

                // temporary disabled
                // const matrix = targetEl.calcTransformMatrix();
                //
                // transformedPoints = targetEl.get('points')
                //     .map((p: { x: number, y: number }) => {
                //
                //         return fabric.util.transformPoint(
                //             new fabric.Point(
                //                 p.x - targetEl.pathOffset.x,
                //                 p.y - targetEl.pathOffset.y),
                //             matrix,
                //         );
                //     });
            }

            this.setState({
                pitCodeLabel: targetEl ? targetLiablePitCode?.pIdCode || targetLiablePitCode?.name : undefined,
                formTitle: targetObject.type ? this.capitalize(targetObject.type) : null,
                targetObject: { ...targetObject, center: targetEl.getCenterPoint() },
            });

            // temporary disabled
            // this.transformedPoints = transformedPoints;
        }

        if (targetEl && targetEl.objectId && this.canvasRef) {

            const activeObj: FabricObject | undefined = this.canvasRef.canvas.getObjects().find((obj: FabricObject) => obj.objectId === targetEl?.objectId);

            if (activeObj && activeObj.type !== 'workarea') {

                this.setState({
                    formTitle: activeObj?.type ? this.props.t(activeObj.type) : '',
                    targetObject: { ...activeObj as unknown as IObject },
                    pitCodeLabel: targetLiablePitCode?.pIdCode || targetLiablePitCode?.name,
                    formOpened: false,
                });
            }

            if (this.canvasRef?.canvas) {

                this.canvasRef.canvas.forEachObject((item: FabricObject) => {

                    if (!item.objectId && this.canvasRef && item.id !== 'workarea') {

                        this.canvasRef.canvas.remove(item);
                    }

                });
            }
            if (this.targetElOrigin && this.canvasRef) {

                this.onCloseObjectForm();

                this.setState({
                    formTitle: targetEl?.type ? this.props.t(targetEl.type) : '',
                    pitCodeLabel: targetLiablePitCode?.pIdCode || targetLiablePitCode?.name,
                });

                this.targetElLiableOrigin = undefined;

                this.targetElOrigin = undefined;
            }
        }

        if (!targetEl && !formOpened) {

            this.setState({ targetObject: null });
        }
        if (!targetEl && formOpened) {

            this.onCloseObjectForm();
        }

        this.sortingByPosition();
    }

    /**
     * Capitalize text string
     *
     * @param {string} word
     * @return {string}
     */
    capitalize(word: string): string {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }

    /**
     * Get rotate point accord to the passed angle value
     *
     * @param {fabric.Point} center
     * @param {number} angle
     *
     * @return fabric.Point
     */
    getRotatedPoint(center: fabric.Point, angle: number): fabric.Point {

        if (this.canvasRef?.handler && this.canvasRef.handler.workarea) {

            const { workarea } = this.canvasRef.handler;

            if (workarea.width && workarea.height && center) {

                const rads = fabric.util.degreesToRadians(360 - angle);

                const objOrigin = new fabric.Point(center.x, center.y);

                const canvasCenter = workarea.getCenterPoint();

                return fabric.util.rotatePoint(objOrigin, canvasCenter, rads);
            }
        }

        return center;
    }

    /**
     * Get target object data model
     */
    getTargetObjectModel() {

        const { targetObject } = this.state;

        if (targetObject) {

            const object: any = this.objects.find(obj => obj.id === targetObject.id) || {};

            return object;
        }

        return null;
    }

    getObjById = (id: string): IObject | null => {

        const result = this.objects.find(obj => obj.id === id);

        return result ? result : null;
    };

    /**
     * Get object by type method
     * @param type
     */
    getObjectsByType = (type: string) => this.objects.filter(object => object.type === type);

    /**
     * On delete method responsible to delete object from array of objects and from canvas object
     *
     * @param single
     * @param multiple
     */
    onDelete(single?: boolean, multiple?: boolean) {

        this.setState({ dialogOpened: false, selectedTool: null });
    }

    /**
     *
     *
     * @param {number} value
     */
    mapOpacityChange(value: number) {

        const i = value;

        if (this.canvasRef && this.canvasRef?.canvas) {

            const activeObjectsFabric: FabricObject[] | undefined = this.canvasRef.canvas.getObjects();

            const workareaObject = activeObjectsFabric ? activeObjectsFabric.find(obj => obj.id === 'workarea') : null;

            if (workareaObject) {

                (workareaObject as unknown as FabricObject).opacity = i;

                this.canvasRef.canvas.requestRenderAll();

            }
            this.setState({ schemaOpacity: i });

        }
    }

    /**
     *
     *
     * @param {boolean} schemaMain
     * @param {boolean} visible
     */
    changeVisibleObject(schemaMain: boolean,  visible: boolean) {

        if (this.canvasRef && this.canvasRef?.canvas) {

            const activeObjectsFabric: FabricObject[] | undefined = this.canvasRef.canvas.getObjects();

            const workareaObject = activeObjectsFabric ? activeObjectsFabric.find(obj => obj.id === 'workarea') : null;

            const activeObject = activeObjectsFabric ? activeObjectsFabric.filter(obj => obj.id !== 'workarea') : null;

            if (activeObject && !schemaMain) {

                (activeObject as unknown as FabricObject[]).map(obj => {

                    obj.visible = visible;

                });

                this.canvasRef.canvas.requestRenderAll();

                this.setState({ schemaObjectShow: visible });

            }

            if (schemaMain && workareaObject) {

                workareaObject.visible = visible;

                this.canvasRef.canvas.requestRenderAll();

                this.setState({ schemaMapShow: visible });
            }

        }
    }

    /**
     *
     *
     * @param {boolean} value
     */
    onShowMap(value: boolean) {

        this.changeVisibleObject(true, value);
    }

    /**
     *
     *
     * @param {boolean} value
     */
    onShowObjects(value: boolean) {

        this.changeVisibleObject(false, value);
    }

    /**
     * close editor
     */
    mapEditorClose() {

        const { src } = this.state;

        if (src) {

            URL.revokeObjectURL(src);

            this.setState({ src: '', closeEditor: true, closeModalStatus: false });
        }


        if (this.props.schema) {

            this.props.editSchema(this.props.schema, true);
        }

        this.props.toggleForm(true);
    }

    /**
     * close editor
     */
    mapEditorSave() {

        const { src } = this.state;

        if (src) {

            URL.revokeObjectURL(src);

            this.setState({ src: '', closeEditor: true });
        }

        if (this.props.schema) {

            const saveData: IHmiSchema = {
                ...this.props.schema,
                opacity: this.state.schemaOpacity,
                showObjects: this.state.schemaObjectShow ? '1' : '0',
                showMinimap: this.state.schemaMapShow ? '1' : '0',
                fontSize: this.state.fontSizeLabel,
            };

            this.props.editSchema(saveData, true);

            this.props.updateSchema(saveData);
        }

        this.props.toggleForm(true);
    }

    /**
     * Action on form close
     */
    onCloseObjectForm(clearOrigin = true) {


        const { targetObject } = this.state,
            { schema } = this.props;

        if (this.canvasRef && targetObject) {


            if (schema && schema?.hmiObjects && this.canvasRef && this.hmiObjectsLoaded) {

                this.removeObjectItemAfterUpdate();

                this.mapHmiObjects(Array.from(new Set(schema.hmiObjects)));

                if (clearOrigin) {

                    this.targetElOrigin = undefined;

                    this.targetElLiableOrigin = undefined;
                }

                this.lockEditObj(false);

                this.setState({ formOpened: false, targetObject: null });
            }
        }

    }

    /**
     * Action on save form.
     *
     * @param {{values: {id?: number, name: string, factory: number, unit: number, angle: number, sensor_id: number, position: string}, targetObject: ITargetObject}} arg
     */
    onSaveObjectForm(arg: {
        values: {
            id?: number;
            name: string;
            factory: number;
            unit: number;
            angle: number;
            sensor_id: {
                label: string;
                value: string | number;
                type: string;
            };
            fontSize: number;
            fontSizeLabel: number;
            position: string;
        }, targetObject: ITargetObject
    }) {
        const { storeObjet, schema, updateObjet, updateSchema } = this.props;
        const { values, targetObject } = arg;


        const objectWithTheSameSensor = this.canvasRef && this.canvasRef.handler.canvas.getObjects().find((value: FabricObject) =>
            (value.sensorId === values.sensor_id.value) &&
            value.id !== targetObject.id && value.objectId !== targetObject.objectId);

        if (targetObject) {
            const objectForSendData = this.canvasRef && this.canvasRef.handler.canvas.getObjects().find((value: FabricObject) => value.id === targetObject.id);
            const objectForSendDataLabel = this.canvasRef && this.canvasRef.handler.canvas.getObjects().find((value: FabricObject) => value.itemId === targetObject.id);

            const currentScale = objectForSendData?.scaleX && objectForSendData?.scaleY ? {
                x: objectForSendData.scaleX,
                y: objectForSendData.scaleY,
            } : null;

            const currentSensorForPidCode = this.props.allSensorInTree.find(sensor => sensor.id === values.sensor_id.value);

            const newZIndex = (objectForSendData as unknown as FabricObject)?.layoutNumber ?
                parseInt((objectForSendData as unknown as FabricObject)?.layoutNumber) : 1;

            if (!values.id && objectForSendData) {

                const sendDataPoints = targetObject.type === 'draw' || targetObject.type === 'polygon' ?
                    (objectForSendData as unknown as FabricObject).points.map((value: { x: number, y: number }) => ([value.x, value.y])) || null :
                    (objectForSendData as unknown as FabricObject).points?.map((value: number[]) => ({ x: value[0], y: value[1] }))[0] as any || null;

                this.canvasRef && this.canvasRef.canvas.add((objectForSendData as unknown as FabricObject));
                this.canvasRef && objectForSendDataLabel && this.canvasRef.canvas.add((objectForSendDataLabel as unknown as FabricObject));

                const objectForSize = (objectForSendData as unknown as FabricObject);

                const sendData: IHmiObject = {
                    fabric: values.factory,
                    unit: values.unit,
                    angle: Math.ceil((objectForSendData as unknown as FabricObject)?.angle || values.angle),
                    points: sendDataPoints,
                    position: {
                        x: objectForSendData.left || targetObject.center!.x,
                        y: objectForSendData.top || targetObject.center!.y,
                    },
                    zIndex: newZIndex,
                    height: objectForSize?.height || null,
                    width: objectForSize?.width || null,
                    type: Object(editorConstants.typeToId)[`${targetObject.type}`],
                    pidCodePosition: values.position,
                    hmiSchema: schema?.id || 0,
                    fontSize: parseInt(arg.values.fontSize.toString()) || 12,
                    scale: currentScale,
                    textPositionInObject: (objectForSendData as unknown as FabricObject)?.textPositionInObject || 'left',
                    sensorId: currentSensorForPidCode?.id || (targetObject as unknown as FabricObject).sensorId,
                };

                storeObjet(sendData);

                this.targetElOrigin = undefined;
                this.targetElLiableOrigin = undefined;

                const addItemAfterRemoveFromCanvas =  setTimeout(()=> {

                    this.canvasRef && this.canvasRef.canvas.add((objectForSendData as unknown as FabricObject));
                    this.canvasRef && objectForSendDataLabel && this.canvasRef.canvas.add((objectForSendDataLabel as unknown as FabricObject));

                    clearTimeout(addItemAfterRemoveFromCanvas);

                }, 0);
            }

            if (values.id) {

                const sendDataPoints = (objectForSendData as unknown as FabricObject).type === 'polygon' ?
                    (objectForSendData as unknown as FabricObject).points
                        ?.map((value: { x: number, y: number }) => ([value.x, value.y])) as any || null
                    : null;

                const sendData: IHmiObject = {
                    id: values.id,
                    fabric: values.factory,
                    unit: values.unit,
                    angle: values.angle,
                    points: sendDataPoints,
                    position: {
                        x: (objectForSendData as unknown as FabricObject).left || 0,
                        y: (objectForSendData as unknown as FabricObject).top || 0,
                    },
                    zIndex: newZIndex,
                    height: (objectForSendData as unknown as FabricObject).height || null,
                    width: (objectForSendData as unknown as FabricObject).width || null,
                    type: Object(editorConstants.typeToId)[`${targetObject.type}`],
                    pidCodePosition: values.position,
                    hmiSchema: schema?.id || 0,
                    fontSize: parseInt(arg.values.fontSize.toString()) || 12,
                    scale: currentScale,
                    sensorId: currentSensorForPidCode?.id || null,
                    textPositionInObject: (objectForSendData as unknown as FabricObject)?.textPositionInObject || 'left',
                };

                updateObjet(sendData);

                this.targetElOrigin = undefined;
                this.targetElLiableOrigin = undefined;
            }
            if (!objectWithTheSameSensor) {

                this.setState({ formOpened: false, targetObject: null });
            }
        }

        if (this.state.fontSizeLabel !== arg.values.fontSizeLabel && arg.values.fontSizeLabel && schema) {

            updateSchema({ ...schema, fontSize: arg.values.fontSizeLabel });

            this.setState({
                fontSizeLabel: arg.values.fontSizeLabel,
            });
        }

        if (!objectWithTheSameSensor) {

            /**
             * Reset origin objects
             */
            this.targetElOrigin = undefined;
            this.targetElLiableOrigin = undefined;

            this.setState({ formOpened: false, targetObject: null });

        }
    }

    /**
     *
     *
     * @param {{values: {id?: number, name: string, factory: number, unit: number, angle: number, sensor_id: {label: string, value: string | number, type: string}, position: string}, targetObject: ITargetObject}} arg
     */
    onChangeObjectForm(arg: {
        values: {
            id?: number;
            name: string;
            factory: number;
            unit: number;
            angle: number;
            sensor_id: {
                label: string;
                value: string | number;
                type: string;
            };
            zIndex: number;
            fontSize: number;
            fontSizeLabel: number;
            position: string;
            textPositionInObject: 'top' | 'topRight' | 'right' | 'rightBottom' | 'bottom' | 'bottomLeft' | 'left' | 'topLeft';
            heightObject: number;
            widthObject: number;
        }, targetObject: ITargetObject
    }) {


        if (this.canvasRef && this.state.formOpened) {

            let currentTargetObject: FabricObject | undefined = undefined;
            let currentTargetLabelObject: FabricObject | undefined = undefined;

            const objectWithTheSameSensor = this.canvasRef && this.canvasRef.handler.canvas.getObjects().find((value: FabricObject) =>
                (value.sensorId === arg.values.sensor_id?.value) &&
                value.id !== arg.targetObject?.id && value.objectId !== arg.targetObject.objectId);

            const currentSensor = this.props.allSensorInTree.find(sensor => sensor.id === arg.values.sensor_id?.value);

            const dataValue = this.getFormatDataValue(currentSensor);

            this.canvasRef.handler.canvas.forEachObject((obj: FabricObject) => {

                if (obj.type === 'pitCode' && arg.values.fontSizeLabel !== this.state.fontSizeLabel &&  arg.values.fontSizeLabel) {

                    obj.set({
                        dirty: true,
                        fontSize: parseInt(arg.values.fontSizeLabel.toString()),
                        // objectCaching: false,
                    });

                    const objectForLabel = this.canvasRef && this.canvasRef.canvas.getObjects().find((objForLabel: FabricObject) => objForLabel.id === obj.itemId);

                    if (objectForLabel && this.canvasRef) {

                        const position = this.canvasRef.handler.getPositionRelativeToObject(objectForLabel, obj);

                        if (position) {

                            obj.set(position);
                            obj.setCoords();
                        }
                    }

                } else if (obj?.type !== 'workarea') {

                    obj.set({
                        fontSizeLabel: parseInt(arg.values.fontSizeLabel.toString()),
                    });
                }

                if (obj.type === 'pitCode' && obj.itemId === arg.targetObject.id && currentSensor && !objectWithTheSameSensor) {

                    const label = maxString(currentSensor.pIdCode || currentSensor.name || '', 15);

                    obj.set({
                        dirty: true,
                       sensorType: currentSensor.sensorType,
                       text: label,
                       label: label,
                       dataValue: dataValue,
                       dataLabel: dataValue,
                    });

                    currentTargetLabelObject = obj;

                }

                if (obj.type === 'pitCode' && arg.values.name === null && arg.values.sensor_id === null && !currentSensor
                    && ((obj.id === this.targetElLiableOrigin?.id) ||
                        (!this.targetElLiableOrigin && !obj.objectId))) {

                    obj.set({
                        dirty: true,
                        text: 'Select sensor',
                        label: 'Select sensor',
                        dataValue: 'No Data',
                        dataLabel: 'No Data',
                    });

                }

                if (obj.type === 'indicator' && obj.id === arg.targetObject.id && arg.values.fontSize >= 0) {

                    obj.set({
                        fontSize: arg.values.fontSize,
                        textPositionInObject: arg.values.textPositionInObject,
                    });

                }

                if (obj.id === arg.targetObject.id) {
                    currentTargetObject = obj;

                    const sensorId = objectWithTheSameSensor ?
                        arg.targetObject.sensorId
                        : arg.values.sensor_id?.value || arg.targetObject.sensorId;

                    obj.set({
                        dirty: true,
                        pidCodePosition: arg.values.position,
                        sensorId: sensorId,
                        factoryId: arg.values.factory,
                        unitId: arg.values.unit,
                    });

                    if (currentSensor) {

                       obj.set('dataValue', dataValue);
                    }

                    if (obj.layoutNumber !== arg.values.zIndex && arg.values.zIndex >= 1) {

                        obj.set({
                            dirty: true,
                            zIndex: 20 + arg.values.zIndex,
                            layoutNumber: arg.values.zIndex,
                        });

                        this.canvasRef && this.canvasRef.canvas.moveTo(obj, 20 + arg.values.zIndex);
                    }

                    if (arg.values.fontSizeLabel === obj.fontSizeLabel && arg.values.fontSizeLabel && arg.values.angle === obj.get('angle')) {

                        if (changeSizeByTypeObjectWithScale(obj?.type || 'default')) {

                            /**
                             * Setting the proportional scale
                             */
                            const newScaleX = arg.values.heightObject / (obj.height || 1),
                                newScaleY = arg.values.widthObject / (obj.width || 1);

                            this.lastScaleYObject = newScaleY;
                            this.lastScaleXObject = newScaleX;

                            if (this.state.formOpened && (obj.get('scaleX') !== newScaleX || obj.get('scaleY') !== newScaleY)) {

                                obj.set({
                                    dirty: true,
                                    scaleX: obj?.type === 'triangle' ? newScaleY : newScaleX,
                                    scaleY: obj?.type === 'triangle' ? newScaleX : newScaleY,
                                    // height: obj.height,
                                    // width: obj.width,
                                    // objectCaching: false,
                                });

                                obj.setCoords();
                            }

                            // obj.set({objectCaching: true});

                        } else if (obj?.type !== 'polygon') {

                            /**
                             * Setting the  resize
                             */
                            const newHeight = obj.height !== arg.values.heightObject ?
                                    arg.values?.heightObject * obj.scaleY!
                                    : obj.height! * obj.scaleY!,
                                newWidth = obj.width !== arg.values.widthObject ?
                                    arg.values?.widthObject * obj.scaleX!
                                    : obj.width! * obj.scaleX!;

                            this.lastWidthObject = newWidth;
                            this.lastHeightObject = newHeight;

                            obj.set({
                                dirty: true,
                                height: newHeight,
                                width: newWidth,
                                scaleX: 1,
                                scaleY: 1,
                                // objectCaching: false,
                            });

                            obj.setCoords();


                            // obj.set({objectCaching: true});

                        }

                    }
                    obj.rotate(arg.values.angle);

                    obj.setCoords(false, true);

                    if (!objectWithTheSameSensor) {

                        this.setState({
                            targetObject: { ...obj as unknown as ITargetObject },
                        });
                    }

                }

                if (obj.itemId === arg.targetObject.id && currentTargetObject) {

                    currentTargetLabelObject = obj;
                }

                obj.moveTo(obj.zIndex);

            }).renderAll();

            if (currentTargetObject && currentTargetLabelObject) {

                const position = this.canvasRef.handler.getPositionRelativeToObject(currentTargetObject, currentTargetLabelObject);

                if (position) {
                    (currentTargetLabelObject as FabricObject).set(position);
                    (currentTargetLabelObject as FabricObject).setCoords();
                }
            }

            this.sortingByPosition();

            this.canvasRef && this.canvasRef.canvas.renderAll();
        }
    }

    /**
     * Action to open edit scheme form.
     */
    onEditScheme() {

        const { schema, toggleForm } = this.props;

        this.setState({ closeEditor: true });

        const toggleFormTimeout = setTimeout(()=> {

            toggleForm(false, 'hmi', schema);

            clearTimeout(toggleFormTimeout);

        }, 300);

    }

    /**
     * Action to open object form
     */
    onOpenObjectForm() {

        if (this.canvasRef) {

            /**
             * Getting the original object item
             *
             * @type {Object}
             */
            this.targetElOrigin = cloneDeep(this.canvasRef.canvas.getActiveObject());


            /**
             * Getting the original object label
             *
             * @type {Object}
             */
            this.targetElLiableOrigin = cloneDeep(this.canvasRef.canvas.getObjects().find((value: FabricObject) => value.type === 'pitCode' && value.itemId === this.targetElOrigin?.id));


        }

        this.setState({ formOpened: true });

    }

    /**
     * Action on change position object on scheme
     *
     * @param {FabricObject} targetEl
     */
    onChangePositionActiveObject(targetEl: FabricObject) {

        const { updateObjet, schema } = this.props;
        const { targetObject } = this.state;

        if (targetEl && 'objectId' in targetEl && schema) {

            const { hmiObjects } = schema;

            const originObject = hmiObjects?.find(obj=>obj.id === parseInt(targetEl.objectId));

            const currentScale = targetEl.scaleX && targetEl.scaleY ? {
                x: targetEl.scaleX,
                y: targetEl.scaleY,
            } : null;


            const sendDataPoints = targetEl.type === 'polygon' ?
                targetEl.points
                    ?.map((value: { x: number, y: number }) => ([value.x, value.y])) as any || null
                : null;

            const sendData: IHmiObject = {
                id: targetEl.objectId,
                fabric: targetEl.factoryId,
                unit: targetEl.unitId,
                angle: targetEl.angle!,
                points: sendDataPoints,
                position: {
                    x: targetEl.left || 0,
                    y: targetEl.top || 0,
                },
                zIndex: targetEl.layoutNumber,
                height: targetEl.height || targetEl.getScaledHeight() || null,
                width: targetEl.width || targetEl.getScaledWidth() || null,
                type: Object(editorConstants.typeToId)[`${targetEl.type}`],
                pidCodePosition: targetEl.pidCodePosition,
                hmiSchema: this.props.schema?.id || 0,
                scale: currentScale,
                sensorId: targetEl.sensorId,
                fontSize: targetEl?.fontSize? parseInt(targetEl.fontSize): 12,
                textPositionInObject: targetEl?.textPositionInObject ||  'left',
            };

            if (originObject &&
                (Math.floor(originObject.position.y) !== Math.floor(sendData.position.y)
                    || Math.floor(originObject.position.x) !== Math.floor(sendData.position.x)
                    || !isEqual(originObject?.points, sendData.points)
                    || originObject?.scale?.x.toFixed(1) !== sendData?.scale?.x.toFixed(1)
                    || originObject?.scale?.y.toFixed(1) !== sendData?.scale?.y.toFixed(1)
                    || originObject?.height !== sendData?.height
                    || originObject?.width !== sendData?.width
                    || Math.floor(originObject.angle) !== Math.floor(sendData.angle))) {

                this.setState({ targetObject: {
                        ...targetObject as  ITargetObject,
                        ...targetEl,
                        angle: targetEl.angle || 0,
                        sensorId: targetEl?.sensorId || null,
                        pidCodePosition: targetEl?.pidCodePosition || 'top',
                        textPositionInObject: targetEl?.textPositionInObject || 'left',
                    },
                });

                updateObjet(sendData);
            }
        }

        if (targetEl && !('objectId' in targetEl) && schema) {

            this.setState({ targetObject: {
                ...targetObject as  ITargetObject,
                    ...targetEl,
                    angle: targetEl.angle || 0,
                    sensorId: targetEl?.sensorId || null,
                    pidCodePosition: targetEl?.pidCodePosition || 'top',
                    textPositionInObject: targetEl?.textPositionInObject || 'left',
                },
            });
        }

        this.sortingByPosition();
    }

    /**
     * close HMI form
     */
    confirmCloseForm() {
        this.mapEditorClose();
    }

    /**
     * Close confirm modal
     */
    cancelCloseForm() {
        this.setState({
            closeModalStatus: false,
        });
    }

    /**
     * Open close modal
     */
    openCloseModal() {

        if (this.props.schema) {
            const saveData: IHmiSchema = {
                ...this.props.schema,
                opacity: this.state.schemaOpacity,
                showObjects: this.state.schemaObjectShow ? '1' : '0',
                showMinimap: this.state.schemaMapShow ? '1' : '0',
            };

            const closeLogic = saveData.opacity !== this.props.schema.opacity ||
                saveData.showObjects.toString() !== this.props.schema.showObjects.toString() ||
                saveData.showMinimap.toString() !== this.props.schema.showMinimap.toString();

            if (closeLogic) {
                this.setState({
                    closeModalStatus: true,
                });
            } else {
                this.mapEditorClose();
            }
        }
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const {  schema, hmiObjectErrors, t } = this.props;
        const {
            selectedTool,
            grab,
            zoomRatio,
            formOpened,
            formTitle,
            width,
            height,
            src,
            targetObject,
            workareaWidth,
            workareaHeight,
            pitCodeLabel,
            deleteDialogOpened,
            deleteItem,
            schemaOpacity,
            schemaMapShow,
            schemaObjectShow,
            closeModalStatus,
        } = this.state;

        const sliderOptions = {
            value: zoomRatio * 100,
            min: 30,
            max: 300,
        };

        const { modeDelete } = editorConstants.interaction;

        const activeObjectFabric: FabricObject | undefined = this.canvasRef?.canvas.getActiveObject();

        const activeObject = activeObjectFabric?.id ? this.getObjById(activeObjectFabric.id) : null;

        return src.length > 0 ? (
            <React.Fragment>
                <div id="map-editor-hmi" className="map-editor hmi">
                    <div className="font-preloader">.</div>
                    <LeftPanel
                        slider={sliderOptions}
                        grabActive={grab}
                        onZoomChange={this.sliderZoomChange}
                        onGrabSwitch={this.switchGrab}
                    />
                    <div className="map-editor-canvas-container">
                        <div style={{ display: 'flex' }}>
                            <MainMenu tools={this.menuTools} selectedTool={selectedTool} onSelect={this.selectTool} />
                            <div className="edit-scheme" onClick={this.onEditScheme}>{this.props.t('EDIT_SCHEME_HMI')}</div>
                        </div>
                        <div className="map-editor-canvas hmi">
                            <div className="map-editor-title">{schema?.name || ''}</div>
                            {targetObject && targetObject.id === activeObjectFabric?.id && pitCodeLabel?
                                <div className="map-editor-object-edit-group">
                                    <div className="objet-title">{this.props.t('SELECTED')} <span>{pitCodeLabel}</span></div>
                                    <Button
                                        color="primary"
                                        size="small"
                                        onClick={this.onOpenObjectForm}
                                    >
                                        {this.props.t('EDIT_OBJECT')}
                                    </Button>
                                </div>
                                : null
                            }
                            <Canvas ref={this.setCanvasRef}
                                    minZoom={30}
                                    maxZoom={300}
                                    workareaOptions={{
                                        src: src,
                                        width: width,
                                        height: height,
                                        opacity: schemaOpacity,
                                        visible: schemaMapShow,
                                    }}
                                    width={workareaWidth}
                                    height={workareaHeight}
                                    fabricObjects={CanvasObject}
                                    onAdd={this.onAdd}
                                    onRemove={this.onRemove}
                                    onSelect={this.onSelect}
                                    onZoom={this.onZoom}
                                    onEdit={this.onEdit}
                                    onMouseUp={this.onChangePositionActiveObject}
                                    onCancel={this.onCancel}
                            />
                        </div>
                    </div>
                    <FooterPanel
                        panelSetting={{
                            showMap: schemaMapShow,
                            showObjects: schemaObjectShow,
                            opacity: schemaOpacity,
                        }}
                        onSlide={this.mapOpacityChange}
                        onShowMap={this.onShowMap}
                        onShowObjects={this.onShowObjects}
                        onClose={this.openCloseModal}
                        onSave={this.mapEditorSave}
                    />
                    <DeleteDialog
                        open={deleteDialogOpened}
                        removeId={deleteItem?.objectId || deleteItem?.id || null}
                        heading={t('REMOVE_THE_OBJECT')}
                        body={t('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_OBJECT')}
                        onAccept={this.deleteHmiObject}
                        onClose={this.onCancelPopup}
                    />
                    <ConfirmDialog
                        heading={t('ARE_YOU_SURE_YOU_WANT_TO_CLOSE')}
                        body={t('CHANGES_WILL_NOT_BE_SAVED')}
                        open={closeModalStatus}
                        onAccept={this.confirmCloseForm}
                        onClose={this.cancelCloseForm}
                    />
                </div>
                <RightSidebar openSidebar={formOpened}>
                    <ObjectForm
                        title={formTitle}
                        openSidebar={formOpened}
                        targetObject={activeObject || targetObject}
                        originSensorId={this.targetElOrigin?.sensorId}
                        onSave={this.onSaveObjectForm}
                        onChange={this.onChangeObjectForm}
                        onCancel={this.onCloseObjectForm}
                    />
                </RightSidebar>
            </React.Fragment>
        ) : null;
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @returns {Object}
 */
const mapStateToProps = (state: RootState) => {
    const { hmiSchemaFormState } = state;
    const { picture } = hmiSchemaFormState;
    const schema = selectHmiOpenScheme(state),
        hmiObjectErrors = selectHmiObjectErrors(state),
        allSensorInTree = selectAllSensorItemInConfigurationTree(state);

    return {
        schema,
        picture,
        hmiObjectErrors,
        allSensorInTree,
    };
};

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = (dispatch: Dispatch<RootState, void, AnyAction>) => ({
    dispatch,
    ...bindActionCreators({
        storeObjet: HmiObjectAction.store,
        updateObjet: HmiObjectAction.update,
        updateSchema: HmiSchemaAction.update,
        toggleForm: FormActions.toggle,
        editSchema: HmiSchemaAction.editSchema,
        loadSchemas: HmiSchemaAction.list,
        deleteHmiObject: HmiObjectAction.delete,
    }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Editor));

