import { fabric } from 'fabric';
import { v4 } from 'uuid';
import { Handler } from './Handler';
import { FabricObject, IFabricEvent } from '../../interfaces';
import { editorConstants } from '../../constants';
import i18next from 'i18next';

/**
 * Event handler
 *
 * @class EventHandler
 */
export class EventHandler {

    /**
     * Main handler instance
     *
     * @type {Handler}
     */
    protected readonly handler: Handler;

    /**
     * Panning flag
     *
     * @type {boolean}
     */
    protected panning = false;

    /**
     * Constructor
     *
     * @param {Handler} handler
     */
    constructor(handler: Handler) {

        this.handler = handler;
    }

    /**
     * Attach events to canvas
     */
    attachEvents(): void {

        if (this.handler.editable) {

            this.handler.canvas.on({
                'mouse:wheel': this.onMouseWheel.bind(this),
                'mouse:down': this.onMouseDown.bind(this),
                'mouse:move': this.onMouseMove.bind(this),
                'mouse:up': this.onMouseUp.bind(this),
                'object:modified': this.onEdit?.bind(this),
                'object:moving': this.onObjectMove?.bind(this),
                'object:scaling': this.onObjectScaling.bind(this),
            });

        } else {

            this.handler.canvas.on({
                'mouse:wheel': this.onMouseWheel.bind(this),
                'mouse:down': this.onMouseDown.bind(this),
                'mouse:move': this.onMouseMove.bind(this),
                'mouse:up': this.onMouseUp.bind(this),
            });
        }

        if (this.handler.canvas.wrapperEl) {

            this.handler.canvas.wrapperEl.tabIndex = 1000;
            this.handler.canvas.wrapperEl.addEventListener('keydown', this.onWrapperKeyDown.bind(this), false);
        }
    }

    /**
     * On edit object call in case scale, move, rotate and click action
     *
     * @param evt
     */
    onEdit(evt: IFabricEvent) {

        const target = this.handler.canvas.getActiveObject() as FabricObject;

        if (this.handler.onEdit) {
            this.handler.onEdit(target);
        }
    }

    /**
     * Detach events from canvas
     */
    detachEvents(): void {

        if (this.handler.editable) {

            this.handler.canvas.off({
                'mouse:wheel': this.onMouseWheel.bind(this),
                'mouse:down': this.onMouseDown.bind(this),
                'mouse:move': this.onMouseMove.bind(this),
                'touch:drag': this.onMouseMove.bind(this),
                'mouse:up': this.onMouseUp.bind(this),
            });

        } else {

            this.handler.canvas.off({
                'mouse:wheel': this.onMouseWheel.bind(this),
                'mouse:down': this.onMouseDown.bind(this),
                'mouse:move': this.onMouseMove.bind(this),
                'touch:drag': this.onMouseMove.bind(this),
                'mouse:up': this.onMouseUp.bind(this),
            });
        }

        if (this.handler.canvas.wrapperEl) {

            this.handler.canvas.wrapperEl.removeEventListener('keydown', this.onWrapperKeyDown.bind(this), false);
        }
    }

    /**
     * Mouse wheel event handler
     *
     * @param {IFabricEvent} evt
     */
    onMouseWheel(evt: IFabricEvent): void {

        const event = evt as IFabricEvent<WheelEvent>;
        const delta = event.e.deltaY;

        let zoomRatio = this.handler.canvas.getZoom();

        if (delta > 0) {

            zoomRatio -= 0.05;

        } else {

            zoomRatio += 0.05;
        }

        this.handler.zoomHandler.zoomToPoint(
            new fabric.Point(this.handler.canvas.getWidth() / 2, this.handler.canvas.getHeight() / 2),
            zoomRatio,
        );

        event.e.preventDefault();
        event.e.stopPropagation();
    }

    /**
     * Mouse down event handler
     *
     * @param {IFabricEvent} evt
     */
    onMouseDown(evt: IFabricEvent): void {

        if (this.handler.interactionMode === editorConstants.interaction.modeGrab) {

            this.panning = true;

            return;
        }

        const event = evt as IFabricEvent<MouseEvent>;

        const { editable } = this.handler;

        const { target } = event;

        if (editable) {

            if (this.handler.interactionMode === editorConstants.interaction.modePolygon) {

                this.handler.canvas.discardActiveObject();

                if (target && this.handler.pointArray.length && target.id === this.handler.pointArray[0].id) {

                    if (this.handler.pointArray.length < 3) {

                        return;
                    }

                    this.handler.drawingHandler.polygon.finish(this.handler.pointArray);

                } else {

                    this.handler.drawingHandler.polygon.addPoint(event);
                }
            }
        }
    }

    /**
     * Mouse move event handler
     *
     * @param {IFabricEvent} evt
     */
    onMouseMove(evt: IFabricEvent): void {
        const event = evt as IFabricEvent<MouseEvent>;

        const activeObject: FabricObject<fabric.Object> = this.handler.canvas.getActiveObject();

        if (this.handler.interactionMode === editorConstants.interaction.modeSelection && activeObject && activeObject.type === 'layer') {

            if (activeObject.lockMovementX && this.handler.menuActionMode === editorConstants.interaction.modeEdit) {

                activeObject.lockMovementX = false;
                activeObject.lockMovementY = false;
                activeObject.lockRotation = false;
                activeObject.lockScalingX = false;
                activeObject.lockScalingY = false;

                this.handler.canvas.setActiveObject(activeObject);

            }

            if (!activeObject.lockMovementX && this.handler.menuActionMode !== editorConstants.interaction.modeEdit) {

                activeObject.lockMovementX = true;
                activeObject.lockMovementY = true;
                activeObject.lockRotation = true;
                activeObject.lockScalingX = true;
                activeObject.lockScalingY = true;

                this.handler.canvas.setActiveObject(activeObject);
            }
        }

        if (this.handler.interactionMode === editorConstants.interaction.modeGrab && this.panning) {

            this.handler.interactionHandler.moving(event.e);
            this.handler.canvas.requestRenderAll();
        }

        if (this.handler.interactionMode === editorConstants.interaction.modePolygon && this.handler.activeLine) {

            const pointer = this.handler.canvas.getPointer(event.e);

            this.handler.activeLine.set({ x2: pointer.x, y2: pointer.y });

            if (this.handler.activeShape) {

                const points = this.handler.activeShape.get('points');

                points[this.handler.pointArray.length] = {
                    x: pointer.x,
                    y: pointer.y,
                };

                this.handler.activeShape.set({
                    points,
                });
            }

            this.handler.canvas.requestRenderAll();
        }
    }

    /**
     * Scaling event handler
     *
     * @param {IFabricEvent} evt
     */
    onObjectScaling(evt: IFabricEvent): void {

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const event = evt as IFabricEvent;

    }

    /**
     * Move object handler
     *
     * @param {IFabricEvent} evt
     */
    onObjectMove(evt:IFabricEvent): void {

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const event = evt as IFabricEvent<MouseEvent>;

        const activeObject: FabricObject<fabric.Object> = this.handler.canvas.getActiveObject();

        if (activeObject && this.handler.interactionMode === editorConstants.interaction.modeSelection) {

            this.handler.canvas.forEachObject((obj: FabricObject) => {

                if (obj.itemId === activeObject.id && obj.type === 'pitCode') {

                    const position = this.handler.getPositionRelativeToObject(activeObject, obj);

                    if ((position.left !== obj.left || position.top !== obj.top)) {

                        obj.set(position);
                    }

                }
            });

        }
    }

    /**
     * Mouse up event handler
     */
    onMouseUp(): void {

        if (this.handler.interactionMode === editorConstants.interaction.modeGrab) {

            this.panning = false;

            return;
        }

        if (this.handler.onEdit) {
            const target = this.handler.canvas.getActiveObject() as FabricObject;

            this.handler.onEdit(target);
        }


        if (this.handler.onMouseUp) {

            const target = this.handler.canvas.getActiveObject() as FabricObject;

            this.handler.onMouseUp(target);
        }

        this.handler.canvas.renderAll();
    }

    /**
     * Key down event handler
     *
     * @param {KeyboardEvent} event
     */
    onWrapperKeyDown(event: KeyboardEvent): void {

        if (this.handler.shortcutHandler.isEscape(event)) {

            switch (this.handler.interactionMode) {

                case editorConstants.interaction.modePolygon:

                    // if not last point was removed, skip following instructions
                    if (!this.handler.drawingHandler.polygon.removeLastPoint()) {
                        return;
                    }

                    break;

                case editorConstants.interaction.modeSelection:

                    this.handler.canvas.discardActiveObject();
                    this.handler.canvas.renderAll();

                    break;
            }

            const { onCancel } = this.handler;

            if (onCancel) {

                onCancel();
            }
        }

        if (this.handler.canvas.wrapperEl !== document.activeElement) {

            return;
        }

        if (this.handler.editable) {

            if (this.handler.shortcutHandler.isDelete(event)) {

                this.handler.remove();

            } else if (this.handler.shortcutHandler.isArrow(event)) {

                //TODO: add here something if needed
            }
        }
    }
}
