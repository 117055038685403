import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    DraggingStyle,
    NotDraggingStyle,
} from 'react-beautiful-dnd';
import SensorItem from './SensorItem/SensorItem';
import { ISensorDragDropTreeProps } from '../../../core/interfaces';
import { ReactComponent as KeyIcon } from '../../../core/ui/assets/images/icons/key.svg';
import { ReactComponent as StateIcon } from '../../../core/ui/assets/images/icons/state_icon.svg';
import { ReactComponent as SensorIcon } from '../../../core/ui/assets/images/icons/parameter-v-2.svg';
import { EditMenu } from '../../../core/ui/components';
import SensorFormList from '../SensorFormList/SensorFormList';


/**
 * Drag'n'drop tree sensor node
 *
 * @param sensor
 * @param unit
 * @param index
 * @param activeSensor
 * @param scrollLeft
 * @param maxProductInUnit
 * @param setDraggedItem
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Sensor: React.FC<ISensorDragDropTreeProps> = ({
    sensor,
    unit,
    index,
    scrollLeft,
    maxProductInUnit,
    setDraggedItem,
}: ISensorDragDropTreeProps) => {

    const [delay, setDelay] = useState(true);

    const isLastItem = unit.data.length - 1 === index;

    /**
     * Rendering delay
     */
    useEffect(() => {

        const delayTimeout = setTimeout(() => setDelay(false), 10);

        return ()=>clearTimeout(delayTimeout);

    }, []);

    /**
     * Set dragged item
     *
     * @type {() => void}
     */
    const setDraggedItemCallback = useCallback(()=>{

        setDraggedItem(sensor, unit.data);

    }, [setDraggedItem, sensor, unit]);

    const getDraggableStyle = (style:  DraggingStyle | NotDraggingStyle | undefined): React.CSSProperties  => {
        return {
            userSelect: 'none',
            width: '100%',
            minHeight: 40,
            ...style,
        };
    };

    const keyStyle: CSSProperties = {
            height: 24,
            width: 24,
            position: 'absolute',
            left: 80,
            transform: 'translateY(-50%)',
            top: '50%',
        }, stateStyle: CSSProperties = {
            height: 24,
            width: 24,
            minWidth: 24,
            marginRight: 8,
        },
        sensorStyle: CSSProperties = {
            height: 24,
            width: 24,
            minWidth: 24,
            marginRight: 8,
        };

    /**
     * Rendering delay
     */
    if (delay) {

        return (
            <div
                className={'sensor-item'}
            >
                <div
                    className={`right-section left-padding-4  ${isLastItem ? 'last-child' : ''}`}
                >
                    <div>
                        {sensor.isKeyParameter ?
                            <KeyIcon
                                className={'key-param'}
                                style={keyStyle}
                            />
                            : null
                        }
                        {(sensor.sensorType === 'state') || (sensor.type === 'state') ?
                            <StateIcon
                                style={stateStyle}
                            />
                            :
                            <SensorIcon
                                style={sensorStyle}
                            />
                        }
                        <div className="right-section-text">
                            {sensor.name ? ' ' + sensor.name : ' ' + sensor.id}
                        </div>
                        {sensor.isNew && <span className={'new-sensor'} />}

                    </div>
                </div>
                <div className="left-section" />
            </div>
        );

    }

    return (
        <Draggable
            index={index}
            draggableId={sensor.id.toString()}
            disableInteractiveElementBlocking={false}
            shouldRespectForcePress
            key={'sensor-key-' + sensor.id}
        >
            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                <React.Fragment>
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={getDraggableStyle(provided.draggableProps.style)}
                        className={isLastItem ? 'last-child' : ''}
                        // onMouseEnter={handlerNodeMouseLeave}
                        onMouseDown={setDraggedItemCallback}
                        onTouchStart={setDraggedItemCallback}
                    >
                        <SensorItem
                            sensor={sensor}
                            unit={unit}
                            unitData={unit.data}
                            leftPadding={`left-padding-4 ${snapshot.isDragging && 'is-dragging'} ${isLastItem ? 'last-child' : ''}`}
                            processRule={unit.products.length > 0}
                            maxProductInUnit={maxProductInUnit}
                            scrollLeft={snapshot.isDragging ? -scrollLeft : scrollLeft}
                            products={unit.products}
                        />

                    </div>
                    {/*{snapshot.isDragging ? (*/}
                    {/*    <div className={isLastItem ? 'last-child' : ''}>*/}
                    {/*        <SensorItem*/}
                    {/*            sensor={sensor}*/}
                    {/*            unit={unit}*/}
                    {/*            unitData={unit.data}*/}
                    {/*            menuSensor={menuSensor}*/}
                    {/*            leftPadding={`left-padding-4 clone-when-drag ${isLastItem ? 'last-child' : ''}`}*/}
                    {/*            processRule={unit.products.length > 0}*/}
                    {/*            maxProductInUnit={maxProductInUnit}*/}
                    {/*            scrollLeft={snapshot.isDragging ? -scrollLeft : scrollLeft}*/}
                    {/*            products={unit.products}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*): null}*/}
                </React.Fragment>
            )}
        </Draggable>
    );
};

export default React.memo(Sensor);
