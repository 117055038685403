import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { isAndroid, isChrome, isIOS, isSafari } from 'react-device-detect';
import { v4 } from 'uuid';
import { fabric } from 'fabric';
import Button from '@material-ui/core/Button';
import { Point } from 'fabric/fabric-impl';
import FontFaceObserver from 'fontfaceobserver';

import { MainMenu } from '../../../../base/components/Editor/panels/MainMenu';
import { LeftPanel } from '../../../../base/components/Editor/panels/LeftPanel';
import { ScalePanel } from '../../../../base/components/Editor/panels/ScalePanel';
import { IEditorTool, InteractionMode, FabricObject } from '../../../../base/components/Editor/interfaces';
import { getVh, getVw } from '../../../../base/helpers/screen-size.helper';

import { editorConstants } from './constants';
import { Canvas } from './canvas/Canvas';
import { IBuilding, IGateway, IZone, IBarrier, IObject, ITargetObject } from './interfaces';
import { IJoin, ILocation } from '../../interfaces';
import { locationThunks } from '../../store/thunks/locationThunks';
import { buildingThunks } from '../../store/thunks/buildingThunks';
import { gatewayThunks } from '../../store/thunks/gatewayThunks';
import { zoneThunks } from '../../store/thunks/zoneThunks';
import { barrierThunks } from '../../store/thunks/barrierThunks';
import { IBuildingApiModel } from '../../services/buildingService';
import { IGatewayApiModel } from '../../services/gatewayService';
import { IZoneApiModel } from '../../services/zoneService';
import { IBarrierApiModel, IBarrierIndentOnly } from '../../services/barrierService';
import { selectEditorBuildings } from '../../store/selectors/buildingSelector';
import { selectEditorGateways } from '../../store/selectors/gatewaySelector';
import { selectEditorZones } from '../../store/selectors/zoneSelector';
import { selectEditorBarriers } from '../../store/selectors/barrierSelector';
import { IHrState } from '../../store/reducers';
import FormDialog from '../../ui/components/Dialog/FormDialog';

//TODO: remove the dependency from the main application. The HR module should be independent as much as possible
import { FormActions } from '../../../../core/actions';
import { IFilter, IOrder } from '../../../../core/interfaces';


import BuildingForm from './components/Form/BuildingForm';
import GatewayForm from './components/Form/GatewayForm';
import ZoneForm from './components/Form/ZoneForm';
import BarrierForm from './components/Form/BarrierForm';
import DeleteForm from './components/Form/DeleteForm';
import LayersList from './panels/LayersList';
import CanvasObject from './canvas/CanvasObject';

import { assignGatewayToZone, assignZoneToGateway, deleteBarrierRelations, deleteZoneRelations, deleteGatewayRelations } from './helpers/location-objects.helper';
import { getRotatedPoint, getPointLatLng, calcZoomScale, calcImageMeterCoef } from './helpers/gateway-location.helper';

import { ReactComponent as BarrierIcon } from './img/barrier-icon.svg';
import { ReactComponent as BuildingIcon } from './img/building-icon.svg';
import { ReactComponent as GatewayIcon } from './img/gateway-icon.svg';
import { ReactComponent as ZoneIcon } from './img/zone-icon.svg';
import { ReactComponent as DeleteIcon } from './img/delete-icon.svg';
import { ReactComponent as EditIcon } from './img/edit-icon.svg';

import './styles/Editor.scss';

interface IProps {
    location?: ILocation | null;
    buildings?: IBuildingApiModel[];
    gateways?: IGatewayApiModel[];
    zones?: IZoneApiModel[];
    barriers?: IBarrierApiModel[];
    workareaWidth: number;
    workareaHeight: number;
    closeEditor: () => void;
    clearZones: () => void;
    clearBuildings: () => void;
    clearGateways: () => void;
    clearBarriers: () => void;
    fetchBuildings: (location: ILocation, search?: string, order?: IOrder) => void;
    saveBuilding: (building: IBuildingApiModel) => void;
    deleteBuilding: (building: IBuildingApiModel) => void;
    fetchGateways: (location: ILocation, search?: string, order?: IOrder, join?: IJoin, filter?: IFilter) => void;
    saveGateway: (gateway: IGatewayApiModel) => void;
    deleteGateway: (gateway: Pick<IGatewayApiModel, 'id'>) => void;
    fetchZones: (location: ILocation, search?: string, order?: IOrder, join?: IJoin, filter?: IFilter) => void;
    saveZone: (zone: IZoneApiModel) => void;
    deleteZone: (zone: Pick<IZoneApiModel, 'id'>) => void;
    fetchBarriers: (location: ILocation, search?: string, order?: IOrder) => void;
    saveBarrier: (barrier: IBarrierApiModel) => void;
    deleteBarrier: (barrier: IBarrierIndentOnly) => void;
    toggleForm: (opened: boolean, name?: string) => void;
}

interface IState {
    selectedItem: FabricObject | null;
    selectedTool: string | null;
    zoomRatio: number;
    scale: number;
    editing: boolean;
    grab: boolean;
    dialogOpened: boolean;
    dialogFormName: string | null;
    targetObject: ITargetObject | null;
    src: string;
    width: number;
    height: number;
    meterWidth: number;
    meterHeight: number;
    canvasReady: boolean;
    buildings: IBuildingApiModel[];
    gateways: IGatewayApiModel[];
    zones: IZoneApiModel[];
    barriers: IBarrierApiModel[];
}

/**
 * Location map editor
 *
 * @class Editor
 */
class Editor extends React.Component<IProps & WithTranslation, IState> {

    /**
     * Constructor
     *
     * @param props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);

        this.state = {
            buildings: [],
            gateways: [],
            zones: [],
            barriers: [],
            selectedItem: null,
            selectedTool: null,
            zoomRatio: 1,
            scale: 10 / 800,
            editing: false,
            grab: true,
            dialogOpened: false,
            dialogFormName: null,
            targetObject: null,
            width: 800,
            height: 800,
            meterWidth: 10,
            meterHeight: 10,
            src: '',
            canvasReady: false,
        };

        this.canvasRef = null;

        this.transformedPoints = null;

        const { t } = this.props;

        this.menuTools = [
            {
                id: editorConstants.objects.building,
                title: t('DRAW_BUILDING'),
                superType: 'drawing',
                type: editorConstants.interaction.modePolygon,
                icon: (<BuildingIcon title={t('DRAW_BUILDING')} />),
                options: {
                    strokeWidth: 5,
                    stroke: '#33bbff',
                    fill: 'rgba(0, 0, 0, 0)',
                    opacity: 1,
                },
            },
            {
                id: editorConstants.objects.gateway,
                title: t('PUT_GATEWAY'),
                superType: 'drawing',
                type: editorConstants.interaction.modeMarker,
                icon: (<GatewayIcon title={t('PUT_GATEWAY')} />),
            },
            {
                id: editorConstants.objects.zone,
                title: t('DRAW_ZONE'),
                superType: 'drawing',
                type: editorConstants.interaction.modePolygon,
                icon: (<ZoneIcon title={t('DRAW_ZONE')} />),
                options: {
                    strokeWidth: 5,
                    stroke: '#bbff33',
                    fill: 'rgba(0, 0, 0, 0)',
                    opacity: 1,
                },
            },
            {
                id: editorConstants.objects.barrier,
                title: t('DRAW_BARRIER'),
                superType: 'drawing',
                type: editorConstants.interaction.modePolygon,
                icon: (<BarrierIcon title={t('DRAW_BARRIER')} />),
                options: {
                    strokeWidth: 5,
                    stroke: '#fa5252',
                    fill: 'rgba(0, 0, 0, 0)',
                    opacity: 1,
                },
            },
            {
                id: editorConstants.interaction.modeEdit,
                title: t('EDIT_MODE'),
                superType: 'drawing',
                type: editorConstants.interaction.modeEdit,
                icon: (<EditIcon title={t('EDIT_MODE')} />),
                options: {
                    strokeWidth: 5,
                    stroke: '#fa5252',
                    fill: 'rgba(0, 0, 0, 0)',
                    opacity: 1,
                },
            },
            {
                id: editorConstants.interaction.modeDelete,
                title: t('DELETE_MODE'),
                superType: 'drawing',
                type: editorConstants.interaction.modeDelete,
                icon: (<DeleteIcon title={t('DELETE_MODE')} />),
                options: {
                    strokeWidth: 5,
                    stroke: '#fa5252',
                    fill: 'rgba(0, 0, 0, 0)',
                    opacity: 1,
                },
            },
        ];

        this.sliderZoomChange = this.sliderZoomChange.bind(this);
        this.switchGrab = this.switchGrab.bind(this);
        this.selectTool = this.selectTool.bind(this);
        this.setCanvasRef = this.setCanvasRef.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onZoom = this.onZoom.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
        this.onFormCancel = this.onFormCancel.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onLoadLayers = this.onLoadLayers.bind(this);
        this.onToggleObject = this.onToggleObject.bind(this);
        this.createEditorObjects = this.createEditorObjects.bind(this);
        this.lockEditObj = this.lockEditObj.bind(this);
        this.barrierCreator = this.barrierCreator.bind(this);
        this.createGatewayObjects = this.createGatewayObjects.bind(this);
        this.mapEditorClose = this.mapEditorClose.bind(this);
    }

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount(): void {

        const { location } = this.props;

        if (location) {

            this.createUrlFromLocationImage();

            this.loadObjects();
        }

        window.addEventListener('resize', this.updateCanvasSize.bind(this));
    }

    /**
     * Component props update handler
     *
     * @param {IProps & WithTranslation} prevProps
     */
    componentDidUpdate(prevProps: Readonly<IProps & WithTranslation>): void {

        const { location, buildings, gateways, zones, barriers } = this.props;

        if (location && location !== prevProps.location) {

            this.createUrlFromLocationImage();

            if (this.canvasRef) {

                this.canvasRef.canvas.clear();
            }

            this.loadObjects();
        }

        if (!this.state.canvasReady && this.canvasRef) {

            if (location?.scale) {
                this.setState({ zoomRatio: location.scale });

                this.canvasRef.handler.zoomHandler.zoomToFit();
                this.canvasRef.handler.zoomHandler.zoomToValue(location.scale);
            }

            const workarea = this.canvasRef.handler.workarea;

            if (workarea && location?.angle) {

                workarea.rotate(location.angle);
            }

            if (workarea && location?.measure) {

                const { workareaHeight, workareaWidth } = this.props;

                const { height, width } = this.state;

                workarea.set({
                    scaleX: location.measure.scaleX,
                    scaleY: location.measure.scaleY,
                    top: (workareaHeight / 2) * (height / workareaHeight),
                    left: (workareaWidth / 2) * (width / workareaWidth),
                    originY: 'center',
                    originX: 'center',
                });

                //
                // if (isIOS) {
                //     this.canvasRef.canvas.enableRetinaScaling = false;
                //
                //     this.canvasRef.canvas.setWidth(getVw(100) - this.leftSidebarWidth);
                //
                //     this.canvasRef.canvas.setHeight(getVh(100));
                // }
            }

            this.setState({ canvasReady: true });
        }

        if (this.state.canvasReady) {

            if (buildings && buildings !== this.state.buildings) {

                this.setState({ buildings });
                this.createEditorObjects(buildings, '#33bbff', this.buildingCreator);
            }

            if (zones && zones !== this.state.zones) {

                this.createEditorObjects(zones, '#bbff33', this.zoneCreator);
                this.setState({ zones });
            }

            if (barriers && barriers !== this.state.barriers) {
                this.createEditorObjects(barriers, '#fa5252', this.barrierCreator);
                this.setState({ barriers });
            }

            if (gateways && gateways !== this.state.gateways) {

                const gatewayList = gateways.filter((gateway: IGatewayApiModel) => gateway.plan?.id === location?.id);

                this.createGatewayObjects(gatewayList);
                this.setState({ gateways });
            }

            if (this.state.selectedTool !== editorConstants.interaction.modeEdit) {

                this.lockEditObj(true);
            }
        }

        if (this.canvasRef) {

            const { workareaHeight, workareaWidth } = this.props;

            if (this.canvasRef.canvas.height !== workareaHeight) {

                // 40 app header height factor
                this.canvasRef.canvas.setHeight(workareaHeight - 40);

                this.canvasRef.canvas.setWidth(workareaWidth);
            }
        }
    }

    /**
     * Callback before the component will be removed from DOM
     */
    componentWillUnmount(): void {

        const { src } = this.state;

        if (src) {

            URL.revokeObjectURL(src);

            this.setState({ src: '' });
        }

        this.props.clearBuildings();

        this.props.clearGateways();

        this.props.clearZones();

        this.props.clearBarriers();

        this.props.closeEditor();

        window.removeEventListener('resize', this.updateCanvasSize.bind(this));
    }

    /**
     * Canvas component reference
     *
     * @type {Canvas}
     */
    private canvasRef: Canvas | null;

    /**
     * Array of drawn objects
     *
     * @type {Record<string, unknown>[]}
     */
    private objects: IObject[] = [];

    /**
     * Application header height in pixels
     *
     * @type {number}
     */
    headerHeight = 40;

    /**
     * Left sidebar width in pixels
     *
     * @type {number}
     */
    leftSidebarWidth = 320;

    /**
     *
     * @type {any}
     */
    savedObject: FabricObject | null = null;

    /**
     *
     *
     * @type {Point[] | null}
     */
    transformedPoints: null | Point[];

    /**
     * Main menu tools
     *
     * @type {IEditorTool[]}
     */
    menuTools: IEditorTool[];

    /**
     * Update canvas size on resize window
     */
    updateCanvasSize() {

        if (this.canvasRef) {

            this.canvasRef.canvas.setWidth(getVw(100) - this.leftSidebarWidth);

            this.canvasRef.canvas.setHeight(getVh(100));

            this.canvasRef.canvas.requestRenderAll();

            this.forceUpdate();
        }
    }

    /**
     * Load saved editor objects
     */
    loadObjects() {

        const { location, fetchBuildings, fetchGateways, fetchZones, fetchBarriers } = this.props;

        if (location) {

            fetchBuildings(location);
            fetchZones(location, '', undefined, { table: ['gateways'] });
            fetchBarriers(location);
            fetchGateways(location, '', undefined, { table: ['plan', 'zone'] });
        }
    }

    /**
     * Load layers method
     *
     * @param objects
     */
    onLoadLayers(objects: FabricObject[]) {

        if (objects.length) {

            const layers = objects.filter((object: FabricObject) => object.id && object.id.search(/^editor-layer-/) !== -1);

            const layerObjects = layers.map((object: FabricObject, i: number) => {
                return {
                    id: String(object.id),
                    active: true,
                    type: editorConstants.objects.layer,
                    name: object.name ? object.name : `editor-layer-${i}`,
                };
            });

            if (layerObjects.length) {
                this.objects = [...this.objects, ...layerObjects];
                this.forceUpdate();
            }
        }
    }

    /**
     * Creating marker name based on the passed name
     * 
     * @param name 
     */
    makerName(name: string): string {

        const parts = name.split(' ');

        if (parts.length > 1) {

            return parts[0].substr(0, 1).toUpperCase() + parts[1].substr(0, 1).toUpperCase();
        } else {

            return parts[0].substr(0, 1).toUpperCase();
        }
    }

    createGatewayObjects(objects: IGatewayApiModel[]) {

        objects.forEach(object => {

            const id = v4();

            const text = this.makerName(object.name);

            if (this.canvasRef) {

                if (!this.objects.find(obj => obj.value === object.id)) {

                    const font = new FontFaceObserver('Font Awesome 5 Free');

                    let loaded = false;

                    font.load().then(() => {

                        loaded = true;

                    });

                    const canvasObj: FabricObject | undefined = this.canvasRef.handler.add({
                        id,
                        text: text,
                        type: 'marker',
                        left: object.position?.x || 0,
                        top: object.position?.y || 0,
                        zIndex: this.canvasRef.canvas.getObjects().length * 2,
                        loaded: loaded,
                        font: font,
                    });


                    if (canvasObj) {
                        this.canvasRef.canvas.bringToFront(canvasObj);
                    }

                    this.objects.push({
                        id,
                        name: object.name,
                        comment: object.comment,
                        longitude: object.location?.long,
                        latitude: object.location?.lat,
                        active: true,
                        type: editorConstants.objects.gateway,
                        value: object.id,
                        points: [],
                        zone: object.zone as unknown as IZone,
                        pictures: object.pictures ? object.pictures : [],
                    });

                    this.canvasRef && this.canvasRef.canvas.renderAll();
                }
            }
        });
    }

    /**
     * Create saved objects in editor
     */
    createEditorObjects<T extends { points: Array<Array<number>> }>(
        objects: T[],
        color: string,
        objectsCreator: (id: string, object: T) => IObject,
    ) {
        //TODO: move these options to constant to use both in the main menu and here
        const defaultObjectProps = {
            suppressCallback: true,
            type: 'polygon',
            strokeWidth: 3 / this.state.zoomRatio,
            strokeWidthInitial: 3,
            fill: 'rgba(0, 0, 0, 0)',
            opacity: 1,
            objectCaching: false,
            superType: 'drawing',
            stroke: color,
        };

        const { canvasRef } = this;

        const maxZIndex = this.props.location ? this.props.location?.layers.length + this.objects.length : this.objects.length;

        objects.forEach((object: T) => {

            const id = v4();

            const points = object.points.map((point: number[]) => {

                const [x = 0, y = 0] = point;

                return { x: x, y: y };
            });

            const createdObj = objectsCreator(id, object);

            const objIndex = this.objects.findIndex(obj =>
                (obj.name == createdObj.name && obj.type === createdObj.type) || obj.model_id === createdObj.model_id
            );

            if (objIndex !== -1 && this.objects[objIndex] && canvasRef) {

                this.objects[objIndex] = { ...this.objects[objIndex], model_id: createdObj.model_id };

                const canvasObj: FabricObject | undefined = canvasRef.handler.findById(this.objects[objIndex].id);

                if (canvasObj) {

                    canvasObj.setOptions({ zIndex: maxZIndex + objIndex });

                    /**
                     *  Uncomment if there is a need to bring the last edited elements to the top position
                      */
                    // canvasRef.canvas.bringToFront(canvasObj);
                }

            } else {

                this.objects.push(createdObj);
            }

            if (!this.objects[objIndex]) {

                // add canvas element in load condition and add new element
                canvasRef && canvasRef.handler.add({
                    id,
                    points,
                    modelType: createdObj.type,
                    zIndex: this.objects.findIndex(obj => obj.name == createdObj.name && obj.type === createdObj.type) +
                        (this.props.location && this.props.location?.layers ? this.props.location?.layers.length : 0) + 2,
                    ...defaultObjectProps,
                });
            }
        });

        this.canvasRef && this.canvasRef.canvas.renderAll();

        this.savedObject = null;
    }

    buildingCreator(id: string, building: IBuildingApiModel): IObject {
        return {
            id,
            name: building.name,
            active: true,
            type: editorConstants.objects.building,
            model_id: building.id,
            points: building.points,
        };
    }

    zoneCreator(id: string, zone: IZoneApiModel): IObject {

        const gateways: string[] = zone.gateways ? zone.gateways.map((value: any) => (value.id)) : [];

        return {
            id,
            name: zone.name,
            active: true,
            type: editorConstants.objects.zone,
            model_id: zone.id,
            points: zone.points,
            gateways,
        };
    }

    barrierCreator(id: string, barrier: IBarrierApiModel): IObject {
        return {
            id,
            name: barrier.name,
            active: true,
            type: editorConstants.objects.barrier,
            model_id: barrier.id,
            points: barrier.points,
            zoneId: barrier.zone,
        };
    }

    /**
     * Set canvas reference
     *
     * @param {Canvas} ref
     */
    setCanvasRef(ref: Canvas | null) {

        this.canvasRef = ref;
    }

    /**
     * Select a tool
     *
     * @param {IEditorTool} tool
     */
    selectTool(tool: IEditorTool) {

        const { selectedTool } = this.state;

        const { modeGrab, modePolygon, modeMarker, modeDelete, modeEdit } = editorConstants.interaction;

        if (this.canvasRef) {

            //TODO: add check for an active shape
            if (this.canvasRef.handler.interactionHandler.isDrawingMode()) {

                if (this.canvasRef.handler.activeLine) {

                    this.canvasRef.handler.remove(this.canvasRef.handler.activeLine);
                }

                this.canvasRef.handler.drawingHandler.polygon.cancel();

                if (this.canvasRef.handler.onCancel) {

                    this.canvasRef.handler.onCancel();
                }
            }

            if (this.canvasRef.handler.interactionMode === modeGrab) {
                this.switchGrab();
            }

            if (tool.superType === 'drawing') {

                switch (tool.type) {

                    case modePolygon:
                        this.canvasRef.handler.drawingHandler.polygon.activate({ ...tool.options, strokeWidth: 3 });

                        break;

                    case modeMarker:

                        this.canvasRef.handler.interactionHandler.drawing(modeMarker as InteractionMode);

                        break;
                }
            }

            if (tool.id === modeDelete && this.canvasRef.canvas.getActiveObjects().length) {

                this.setState({ dialogOpened: true, dialogFormName: null });
            }

            if (tool.id === selectedTool) {

                this.canvasRef.handler.interactionHandler.selection();
            }

            if (tool.id === modeEdit && this.canvasRef.canvas.getActiveObject()?.type === 'activeSelection') {
                this.canvasRef.canvas.discardActiveObject();
            }

            this.canvasRef.handler.menuActionMode = selectedTool !== tool.id ? tool.id : '';

            const lock = !(tool.id === modeEdit && selectedTool !== tool.id);

            this.lockEditObj(lock);
        }

        const deleteTool = tool.id === modeDelete && this.canvasRef?.canvas.getActiveObject();

        this.setState({
            selectedTool: (tool.id !== selectedTool && tool.id !== modeDelete) || deleteTool ? tool.id : null,
        });
    }

    /**
     * On toggle object switch object active mode
     *
     * @param object
     */
    onToggleObject(object: IObject) {

        const { canvasRef } = this;

        this.objects.forEach((o, i) => o.id === object.id ? this.objects[i] = { ...o, active: !o.active } : null);

        if (object?.id && canvasRef) {

            const obj = canvasRef.handler.findById(object.id);

            if (!obj) {

                const layer = canvasRef.canvas.getObjects()
                    .filter((object: FabricObject) => object.id?.search(/^editor-layer-/) !== -1)
                    .find((item: any) => item.id === object.id);

                if (layer) layer.visible = !object.active;
            }

            if (obj?.opacity) obj.visible = !object.active;

            canvasRef.canvas.discardActiveObject();

            canvasRef.canvas.renderAll();

            this.forceUpdate();
        }
    }

    /**
     * Handler for slider zoom change event
     *
     * @param {React.ChangeEvent} event
     * @param {number | number[]} value
     */
    sliderZoomChange(event: React.ChangeEvent<Record<string, unknown>> | null, value: number | number[]) {

        if (this.canvasRef && !Array.isArray(value)) {

            this.canvasRef.handler.zoomHandler.zoomToValue(value / 100);
        }
    }

    /**
     * Switch between selection and grab modes
     *
     * @returns {boolean}
     */
    switchGrab() {

        if (this.canvasRef && !this.canvasRef.handler.interactionHandler.isDrawingMode()) {

            if (this.canvasRef.handler.interactionMode === editorConstants.interaction.modeSelection) {

                this.canvasRef.handler.interactionHandler.grab();

                this.setState({
                    grab: true,
                });

            } else {

                this.canvasRef.handler.interactionHandler.selection();

                this.setState({
                    grab: false,
                });
            }
        }
    }

    /**
     * Create new object callback
     *
     * @param target
     */
    onAdd(target: FabricObject) {

        const { selectedTool } = this.state;

        this.setState({ selectedItem: target });

        if (!this.state.editing) {

            this.setState({
                editing: true,
            });
        }

        if (target.type === 'activeSelection') {

            this.onSelect(null);
        }

        if (this.canvasRef) {

            //this.canvasRef.handler.select(target);
        }

        let dialogOpened = false, dialogFormName = null;

        if (selectedTool && [
            editorConstants.objects.building,
            editorConstants.objects.gateway,
            editorConstants.objects.zone,
            editorConstants.objects.barrier,
        ].includes(selectedTool)) {

            dialogOpened = true;
            dialogFormName = selectedTool;
        }

        this.setState({
            selectedTool: null,
            dialogOpened: dialogOpened,
            dialogFormName: dialogFormName,
            targetObject: {
                id: target.id || '',
                type: selectedTool,
                active: true,
                points: target.points?.map((point: { x: number, y: number }) => {

                    return [point.x, point.y];
                }),
                center: target.getCenterPoint(),
            },
        });
    }

    /**
     * Remove object callback
     */
    onRemove(el: FabricObject) {

        if (!this.state.editing) {

            this.setState({
                editing: true,
            });
        }

        const objects = [...this.objects];

        if (objects.length) {

            const { deleteBuilding, deleteGateway, deleteZone, deleteBarrier } = this.props;

            const index = objects.findIndex((object: IObject) => object.id === el.id);

            const object = objects[index];

            if (object) {

                objects.splice(index, 1);

                if (object.type === editorConstants.objects.building) {

                    deleteBuilding({
                        id: object.model_id,
                        name: object.name,
                        points: [],
                        plan: 0,
                    });
                }

                if (object.type === editorConstants.objects.zone) {
                    deleteZoneRelations(objects, object);

                    if (object.model_id !== undefined) {

                        deleteZone({
                            id: object.model_id,
                        });
                    }
                }

                if (object.type === editorConstants.objects.gateway) {
                    deleteGatewayRelations(objects, object);
                    deleteGateway({
                        id: object.value,
                    });
                }

                if (object.type === editorConstants.objects.barrier) {

                    if (object.model_id !== undefined) {
                        deleteBarrier({
                            id: object.model_id,
                        });
                    }
                }

                this.objects = objects;
            }
        }

        this.onSelect(null);
    }

    /**
     * Select object callback
     *
     * @param {Object} target
     */
    onSelect(target: FabricObject | null) {

        const { selectedItem } = this.state;

        if (target && target.id && target.id !== 'workarea' && target.id.search(/^editor-layer-/) === -1 && target.type !== 'activeSelection') {

            if (selectedItem && target.id === selectedItem.id) {

                return;
            }

            this.setState({
                selectedItem: target,
            });

            return;
        }

        this.setState({
            selectedItem: null,
        });
    }

    /**
     * Drawing cancel callback
     */
    onCancel() {

        this.setState({
            selectedTool: null,
        });
    }

    /**
     * Zoom callback
     *
     * @param {number} zoom
     */
    onZoom(zoom: number) {

        const { workareaHeight, workareaWidth, location } = this.props;

        const { height, width, meterHeight, meterWidth } = this.state;

        const scaleX = location?.measure?.scaleX || 1;
        const scaleY = location?.measure?.scaleY || 1;

        const { imageHypot, meterPixelCoef, canvToImagePixel } = calcImageMeterCoef(
            { meterHeight, meterWidth }, 
            { 
                workareaHeight:workareaHeight - 40, // 40 app header height factor
                workareaWidth
            },
            { height, width },
            { scaleX, scaleY },
        );

        // extreem points of visible segment
        const vpsCoords = this.canvasRef?.canvas.vptCoords;

        // calc scale on editor depends from coordinates and zoom value
        const scale = vpsCoords
            ? calcZoomScale(vpsCoords, imageHypot, meterPixelCoef, canvToImagePixel)
            : (this.state.meterWidth / this.state.width) * zoom

        this.setState({
            zoomRatio: zoom,
            scale: scale,
        });
    }

    /**
     * Form submit handler
     *
     * @param {IBuilding | IGateway |  IZone | IBarrier} model
     */
    onFormSave(model: IBuilding | IGateway | IZone | IBarrier) {

        const { targetObject, selectedTool } = this.state;

        const { gateway, zone } = editorConstants.objects;

        if (targetObject) {

            const index = this.objects.findIndex(item => item.id === targetObject?.id);

            let object: IObject;

            if (index === -1) {
                object = { ...targetObject, ...model };
                this.objects.push(object);
            } else {
                object = { ...this.objects[index], ...model };
                this.objects[index] = object;
            }

            // assign gateway to zone
            if (targetObject.type === gateway && object.zone) {

                const zoneIndex = this.objects.findIndex(obj => obj.type === zone && obj.id === object.zone);

                if (zoneIndex !== -1) {

                    const zoneObj = this.objects[zoneIndex];

                    assignGatewayToZone(this.objects, object);

                    const gateways = zoneObj.gateways ? zoneObj.gateways?.concat(object.id) : [object.id];

                    this.objects[zoneIndex] = { ...zoneObj, gateways };
                }
            }

            // assign zone to gateway
            if (targetObject.type === zone && object.gateways) {

                assignZoneToGateway(this.objects, object);


            }

            // change gateway maker label
            if (targetObject.type === gateway && this.canvasRef) {

                const makerName = this.makerName(object.name);

                this.canvasRef.handler.getObjects().forEach(obj => {
                    if (obj.type === 'marker' && this.canvasRef) {

                        if (obj.id === object.id) {
                            obj.set({ label: makerName });
                        }

                        obj.bringToFront();
                    }
                });

                this.canvasRef.canvas.renderAll();
            }
        }

        if (selectedTool !== editorConstants.interaction.modeEdit) {

            this.lockEditObj(true);
        }

        this.setState({ dialogOpened: false, targetObject: null });

        const { saveBuilding, saveGateway, saveZone, saveBarrier, location } = this.props;

        if (!location || !targetObject) {
            return;
        }

        if (targetObject.type === editorConstants.objects.building) {

            const { points = [] } = targetObject;
            const activeBuilding: any = this.canvasRef && this.canvasRef.canvas?.getActiveObject();

            saveBuilding({
                id: model.model_id,
                name: model.name,
                plan: location.id,
                points: this.transformedPoints ? this.transformedPoints.map((point: { x: number, y: number }) => [point.x, point.y]) : points,
            });

            this.savedObject = activeBuilding ? activeBuilding : targetObject;
        }

        if (targetObject.type === editorConstants.objects.gateway && this.canvasRef) {
            const gatewayModel = model as IGateway;

            const canvasGateway = this.canvasRef.handler.findById(targetObject.id);

            const zoneId = gatewayModel.zone;
            const zone = this.objects.find(object => object.model_id === zoneId || object.id === zoneId);
            const index = this.objects.findIndex(item => item.id === targetObject?.id);
            const canvasObject = this.objects[index];
            canvasObject.zoneId = zone?.model_id || null;

            if (!zone?.model_id) {

                this.objects.forEach(obj => {

                    if (obj.gateways) {

                        const indexGateway = obj.gateways.findIndex(value => value === gatewayModel.value || gatewayModel.id);

                        if (indexGateway > -1) obj.gateways.splice(indexGateway, 1);

                    }
                });
            }

            const gatewayToSave: IGatewayApiModel = {
                name: gatewayModel.name,
                position: {
                    x: canvasGateway?.left || 0,
                    y: canvasGateway?.top || 0,
                },
                location: {
                    lat: gatewayModel.latitude,
                    long: gatewayModel.longitude,
                },
                comment: gatewayModel.comment,
                plan: { id: location.id } as ILocation,
                zone: zone ? zone.model_id : null,
            };

            if (gatewayModel.value !== undefined) {
                gatewayToSave.id = gatewayModel.value;
            }

            if (gatewayModel.pictures && gatewayModel.pictures.length > 0) {
                gatewayToSave.pictures = gatewayModel.pictures;
            }


            if (gatewayModel.deleteModel) {

                this.props.deleteGateway({

                    id: gatewayModel.deleteModel,
                });
            }

            saveGateway(gatewayToSave);
        }

        if (targetObject.type === editorConstants.objects.zone) {

            const { points = [] } = targetObject;
            const activeZone: any = this.canvasRef && this.canvasRef.canvas?.getActiveObject();
            const zoneModel = model as IZone;
            const gateways = zoneModel.gateways?.map(gateway => this.objects.find(value => value.id === gateway)?.value || '').filter(value => value);

            this.objects.forEach(obj => {
                if (obj.type === editorConstants.objects.gateway &&
                    gateways &&
                    gateways.findIndex(value => value === obj.value) === -1
                ) {

                    if (obj.zone && typeof obj.zone !== 'string') {

                        const zone = obj.zone as IZone;

                        if (zone.id === model.model_id) obj.zone = undefined;
                    }
                }
            });

            const sendData: IZoneApiModel = {
                id: zoneModel.model_id || 0,
                name: zoneModel.name,
                gateways: gateways && gateways.length > 0 ? gateways : [''],
                barrier: null,
                plan: location.id,
                points: this.transformedPoints ? this.transformedPoints.map((point: { x: number, y: number }) => [point.x, point.y]) : points,
            };

            saveZone(sendData);

            this.savedObject = activeZone ? activeZone : targetObject;
        }

        if (targetObject.type === editorConstants.objects.barrier) {

            const { points = [] } = targetObject;

            const barrierModel = model as IBarrier;
            const zoneId = barrierModel.zone;
            const activeBarrier: any = this.canvasRef && this.canvasRef.canvas?.getActiveObject();
            const zone = this.objects.find(object => object.id === zoneId);

            const index = this.objects.findIndex(item => item.id === targetObject?.id);
            const canvasObject = this.objects[index];

            canvasObject.zoneId = zone?.model_id || null;

            const barrierToSave = {
                id: barrierModel.model_id || 0,
                name: barrierModel.name,
                zone: canvasObject.zoneId,
                points: this.transformedPoints ? this.transformedPoints.map((point: { x: number, y: number }) => [point.x, point.y]) : points,
                plan: location.id,
            };

            saveBarrier(barrierToSave);

            this.savedObject = activeBarrier ? activeBarrier : targetObject;
        }

        if (this.canvasRef) {

            this.canvasRef.handler.getObjects().forEach(obj => {
                if (obj.type === 'marker' && this.canvasRef) {

                    obj.bringToFront();
                }
            });
        }

        this.transformedPoints = null;
    }

    /**
     * Cancel form handler
     */
    onFormCancel(): void {

        const { selectedItem, selectedTool } = this.state;

        if (this.canvasRef && selectedTool !== editorConstants.interaction.modeEdit) {

            this.canvasRef.handler.remove(selectedItem || undefined);
        }

        this.setState({ dialogOpened: false, targetObject: null });

        this.transformedPoints = null;
    }

    /**
     * Enable editable and selectable properties to objects
     *
     * @param mode
     */
    lockEditObj(mode: boolean) {

        if (this.canvasRef) {

            this.canvasRef.handler.getObjects().forEach((object: FabricObject) => {
                object.lockMovementX = mode;
                object.lockMovementY = mode;
                object.lockScalingX = mode;
                object.lockScalingY = mode;
                object.lockRotation = mode;
            });

            this.canvasRef.canvas.renderAll();
        }
    }

    /**
     * On edit object attach callback to scale, move, rotate and click actions
     *
     * @param targetEl
     */
    onEdit(targetEl: FabricObject) {

        const { interaction, objects } = editorConstants;
        const targetObject = this.objects.find(obj => obj.id === targetEl?.id);

        if (this.state.selectedTool === interaction.modeEdit && targetObject && targetObject.type !== objects.layer) {

            let transformedPoints = null;

            if (targetEl.type === 'polygon') {

                const matrix = targetEl.calcTransformMatrix();

                transformedPoints = targetEl.get('points')
                    .map((p: { x: number, y: number }) => {

                        return fabric.util.transformPoint(
                            new fabric.Point(
                                p.x - targetEl.pathOffset.x,
                                p.y - targetEl.pathOffset.y),
                            matrix
                        );
                    });
            }

            this.setState({
                dialogOpened: true,
                dialogFormName: targetObject.type,
                targetObject: { ...targetObject, center: targetEl.getCenterPoint() },
            });

            this.transformedPoints = transformedPoints;
        }
    }


    /**
     * Get rotate point accord to the passed angle value
     * 
     * @param {fabric.Point} center
     * @param {number} angle
     * 
     * @return fabric.Point
     */
    getRotatedPoint(center: fabric.Point, angle: number): fabric.Point {

        if (this.canvasRef?.handler && this.canvasRef.handler.workarea) {

            const { workarea } = this.canvasRef.handler;

            if (workarea.width && workarea.height && center) {

                const rads = fabric.util.degreesToRadians(360 - angle);

                const objOrigin = new fabric.Point(center.x, center.y);

                const canvasCenter = workarea.getCenterPoint();

                return fabric.util.rotatePoint(objOrigin, canvasCenter, rads);
            }
        }

        return center;
    }

    /**
     * Get rotated center
     * 
     * @param {fabric.Point} center
     * @param {number} angle
     * 
     * @returns {object}
     */
    getRotatedGateway(center: fabric.Point, angle = 0) {

        if (this.props.location && this.canvasRef?.handler) {

            const handler = this.canvasRef.handler;

            const { location } = this.props;

            const { width, height } = this.state;

            if (angle) {

                const newLoc = getRotatedPoint(center, angle, handler);

                return getPointLatLng(newLoc, handler, location, { width, height });
            }

            return getPointLatLng(center, handler, location, { width, height });
        }

        return { lat: 0, lng: 0 };
    }

    /**
     * Get target object data model
     */
    getTargetObjectModel() {

        const { targetObject } = this.state;

        const { location } = this.props;

        if (targetObject) {

            const object: any = this.objects.find(obj => obj.id === targetObject.id) || {};

            const { center, type } = targetObject;

            if (type === editorConstants.objects.gateway && center) {

                const coords = this.getRotatedGateway(center, location?.angle || 0);

                object.latitude = coords.lat;
                object.longitude = coords.lng;
            }

            return object;
        }

        return null;
    }

    getObjById = (id: string): IObject | null => {

        const result = this.objects.find(obj => obj.id === id);

        return result ? result : null;
    };

    /**
     * Get object by type method
     * @param type
     */
    getObjectsByType = (type: string) => this.objects.filter(object => object.type === type);

    /**
     * Get available gateways list
     */
    getAvailableGateways() {

        const { gateway } = editorConstants.objects;

        const { targetObject, gateways } = this.state;

        // list of gateways which already used
        const used = this.objects.filter(obj => obj.type === gateway)
            .map(obj => obj.value);

        const gatewayOptions = [...gateways].map(option => {

            const item = {
                value: (option.id || v4()) as string,
                label: (option.name || option.id) as string,
                pictures: option.pictures ? option.pictures : [],
            };

            // update label in case if gateway already had added with changed name
            if (used.includes(item.value)) {
                const gatewayObj = this.objects.find(obj => obj.value === item.value && obj.type === gateway);

                if (gatewayObj) item.label = gatewayObj.name;
            }

            return item;
        });

        return gatewayOptions
            .filter(gateway => !used.includes(gateway.value) || targetObject?.value === gateway.value);
    }

    /**
     * On delete method responsible to delete object from array of objects and from canvas object
     *
     * @param single
     * @param multiple
     */
    onDelete(single?: boolean, multiple?: boolean) {

        if ((single || multiple) && this.canvasRef) {

            const activeObjects = this.canvasRef.canvas.getActiveObjects();

            const handler = this.canvasRef?.handler ? this.canvasRef.handler : null;

            if (activeObjects.length && this.objects.length) {

                const { zone, gateway, barrier } = editorConstants.objects;

                const objects = [...this.objects];

                activeObjects.forEach((object: FabricObject) => {

                    const index = objects.findIndex(item => item.id === object.id);

                    const obj = objects[index];

                    objects.splice(index, 1);

                    if (object.id && this.canvasRef) {

                        this.canvasRef.handler.remove(object);
                    }

                    if (obj.type === zone) {
                        deleteZoneRelations(objects, obj, multiple ? handler : null);
                    }

                    if (obj.type === gateway && obj.zone) {
                        deleteGatewayRelations(objects, obj, multiple ? handler : null);
                    }

                    if (obj.type === barrier && obj.zoneId && multiple && handler) {
                        deleteBarrierRelations(objects, obj, handler);
                    }
                });

                this.objects = objects;
            }
        }

        this.setState({ dialogOpened: false, selectedTool: null });
    }

    /**
     * Create URL from location SVG image
     */
    createUrlFromLocationImage(): void {

        const { location } = this.props;

        this.setState({
            src: '',
        });
        if (location && location.pictureContent) {

            const blob = new Blob([location.pictureContent], { type: 'image/svg+xml' });

            const url = URL.createObjectURL(blob);

            const image = new Image();

            image.onload = () => {

                //size in meters
                let { width, height } = location;

                if (!width) {

                    // set default width equal to 10 like in location preview
                    width = 10;
                }

                if (!height) {

                    height = 10;
                }

                this.setState({
                    scale: width / image.width,
                    width: image.width,
                    height: image.height,
                    src: url,
                    meterWidth: width,
                    meterHeight: height,
                });

                if (this.canvasRef) {

                    // this.canvasRef.handler.zoomHandler.zoomToFit();
                }
            };

            image.src = url;
        }
    }

    /**
     * close editor
     */
    mapEditorClose() {

        const { src } = this.state;

        if (src) {

            URL.revokeObjectURL(src);

            this.setState({ src: '' });
        }

        this.props.closeEditor();

        this.props.toggleForm(true);
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t, workareaWidth, workareaHeight, location } = this.props;
        const {
            selectedTool,
            grab,
            zoomRatio,
            scale,
            dialogOpened,
            dialogFormName,
            width,
            height,
            src,
        } = this.state;

        const sliderOptions = {
            value: zoomRatio * 100,
            min: 30,
            max: 300,
        };

        const { building, gateway, zone, barrier } = editorConstants.objects;

        const { modeDelete } = editorConstants.interaction;

        const activeObjectFabric: FabricObject | undefined = this.canvasRef?.canvas.getActiveObject();

        const activeObject = activeObjectFabric?.id ? this.getObjById(activeObjectFabric.id) : null;

        const zones = this.getObjectsByType(zone).map(zone => ({ label: zone.name, value: zone.id, zonesId: zone.model_id }));

        const gateways = this.getObjectsByType(gateway).map(gateway => ({ label: gateway.name, value: gateway.id }));

        const formTitleMapping: { [key: string]: string } = {};

        formTitleMapping[building] = t('BUILDING');
        formTitleMapping[gateway] = t('GATEWAY');
        formTitleMapping[zone] = t('ZONE');
        formTitleMapping[barrier] = t('BARRIER');

        const errorsLocation = location?.error?.errors as unknown as any;

        return src ? (
            <React.Fragment>
                <div className="map-editor">
                    <div className="font-preloader">.</div>
                    <LeftPanel
                        slider={sliderOptions}
                        grabActive={grab}
                        onZoomChange={this.sliderZoomChange}
                        onGrabSwitch={this.switchGrab}
                    />
                    <ScalePanel scale={scale} />
                    <div className="map-editor-canvas-container">
                        <div style={{ display: 'flex', paddingTop: 5 }}>
                            <MainMenu tools={this.menuTools} selectedTool={selectedTool} onSelect={this.selectTool} />
                            <LayersList objects={this.objects} onToggleObject={this.onToggleObject} />
                        </div>
                        <div className="map-editor-canvas">
                            <div className="map-editor-title">{location?.name}</div>
                            <Canvas ref={this.setCanvasRef}
                                minZoom={30}
                                maxZoom={300}
                                workareaOptions={{
                                    src: src,
                                    layers: location?.layers,
                                    width: width,
                                    height: height - 40,
                                }}
                                width={workareaWidth}
                                height={workareaHeight - 40}
                                fabricObjects={CanvasObject}
                                onAdd={this.onAdd}
                                onRemove={this.onRemove}
                                onSelect={this.onSelect}
                                onZoom={this.onZoom}
                                onEdit={this.onEdit}
                                onCancel={this.onCancel}
                                onLoadLayers={this.onLoadLayers}
                            />
                        </div>
                    </div>
                    <div
                        className={`map-editor-close 
                            ${isIOS && isSafari ? 'ios-style-safari' : isIOS && isChrome ? 'ios-style-chrome' : ''} ${isAndroid ? 'android-style' : ''}`
                        }
                    >
                        <Button
                            variant="outlined"
                            onClick={this.mapEditorClose}
                        >
                            {t('CLOSE')}
                        </Button>
                    </div>
                </div>
                <FormDialog
                    open={dialogOpened}
                    heading={dialogFormName ? formTitleMapping[dialogFormName] : undefined}
                    TransitionProps={{ exit: false }}
                    fullWidth={false}
                >
                    {dialogFormName === building && selectedTool !== modeDelete &&
                        <BuildingForm
                            onSave={this.onFormSave}
                            onCancel={this.onFormCancel}
                            object={this.getTargetObjectModel()}
                            buildings={this.getObjectsByType(building)}
                        />
                    }
                    {dialogFormName === gateway && selectedTool !== modeDelete &&
                        <GatewayForm
                            onSave={this.onFormSave}
                            onCancel={this.onFormCancel}
                            object={this.getTargetObjectModel()}
                            zones={zones}
                            gateways={this.getAvailableGateways()}
                            gatewaysList={this.getObjectsByType(gateway)}
                            objects={this.objects}
                        />
                    }
                    {dialogFormName === zone && selectedTool !== modeDelete &&
                        <ZoneForm
                            onSave={this.onFormSave}
                            onCancel={this.onFormCancel}
                            object={this.getTargetObjectModel()}
                            gateways={gateways}
                            zones={this.getObjectsByType(zone)}
                            objects={this.objects}
                        />
                    }
                    {dialogFormName === barrier && selectedTool !== modeDelete &&
                        <BarrierForm
                            onSave={this.onFormSave}
                            onCancel={this.onFormCancel}
                            object={this.getTargetObjectModel()}
                            zones={zones}
                            barriers={this.getObjectsByType(barrier)}
                            zoneObjects={this.getObjectsByType(zone)}
                        />
                    }
                    {selectedTool === modeDelete && activeObject &&
                        <DeleteForm
                            onDelete={this.onDelete}
                            object={activeObject}
                        />
                    }
                </FormDialog>
            </React.Fragment>
        ) : errorsLocation ?
            errorsLocation.common.map((errorString: string) =>
                <div className="common-error editor-error" key={errorString}>{t(errorString)}</div>
            ) : null;
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @returns {Object}
 */
const mapStateToProps = (state: {
    hr: IHrState,
}) => {

    const { location } = state.hr.locationEditorState;
    const buildings = selectEditorBuildings(state);
    const gateways = selectEditorGateways(state);
    const zones = selectEditorZones(state);
    const barriers = selectEditorBarriers(state);

    return {
        location,
        buildings,
        gateways,
        zones,
        barriers,
    };
};

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = ({
    fetchBuildings: buildingThunks.fetchBuildings,
    saveBuilding: buildingThunks.saveBuilding,
    deleteBuilding: buildingThunks.deleteBuilding,
    clearBuildings: buildingThunks.clearBuildings,
    fetchGateways: gatewayThunks.fetchGateways,
    saveGateway: gatewayThunks.saveGatewayFormEditor,
    deleteGateway: gatewayThunks.deleteGatewayFromEditor,
    clearGateways: gatewayThunks.clearGateways,
    fetchZones: zoneThunks.fetchZones,
    saveZone: zoneThunks.saveZone,
    deleteZone: zoneThunks.deleteZone,
    clearZones: zoneThunks.clearZones,
    fetchBarriers: barrierThunks.fetchBarriers,
    saveBarrier: barrierThunks.saveBarrier,
    deleteBarrier: barrierThunks.deleteBarrier,
    clearBarriers: barrierThunks.clearBarriers,
    closeEditor: locationThunks.closeEditor,
    toggleForm: FormActions.toggle,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Editor));
