import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Button, DeleteDialog, Select, TextInput } from '../../../../../core/ui/components';
import { ReactComponent as DropdownArrowIcon } from '../../../../../core/ui/assets/images/icons/dropdown-arrow.svg';
import '../styles/ObjectForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAllFactoryItemInTree,
    selectAllProcessItemInTree,
    selectAllSensorItemInConfigurationTree,
    selectAllUnitItemInTree,
} from '../../../../../core/selectors/configurationTree/configurationTreeSelector';
import {
    IFlatTreeFactory, IFlatTreeProcess,
    IFlatTreeUnit, IHmiObject,
    ISensor,
} from '../../../../../core/interfaces';
import { FabricObject, ITargetObject } from '../../../../../base/components/Editor/interfaces';
import { HmiObjectAction } from '../../../../../core/actions/hmiObjectAction';
import { editorConstants } from '../constants';
import {
    selectHmiObjectErrors,
    selectHmiOpenSchemeObjects,
} from '../../../../../core/selectors/hmiSchemas/hmiOpenSchemeSelector';
import { Accordion, AccordionDetails, AccordionSummary, InputLabel, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { changeSizeByTypeObjectWithScale } from '../functions/changeSizeByTypeObject';
import cloneDeep from 'lodash/cloneDeep';

interface IProps {
    title: string | null;
    targetObject: ITargetObject | FabricObject | null;
    openSidebar: boolean;
    originSensorId?: number;
    onSave?: (model: any) => void;
    onChange?: (model: any) => void;
    onCancel?: () => void;
}

interface IFormValues {
    id?: number;
    name: string;
    factory: any;
    unit: any;
    angle: number;
    sensor_id: IHMIOptions | null;
    position: string;
    textPositionInObject: string;
    objectId?: number;
    fontSize: number;
    fontSizeLabel: number;
    zIndex: number;
    heightObject: number;
    widthObject: number;
}

interface ICurrentSensor extends ISensor{
    factoryId: number | null;
    unitId: number | null;
}

interface IHMIOptions {
    label: string;
    value: string | number;
    type: string;
}

const defaultInitialValues = {
    name: '',
    factory: '',
    unit: '',
    angle: 0,
    sensor_id: null,
    position: 'top',
    textPositionInObject: 'left',
    fontSize: 12,
    fontSizeLabel: 12,
    zIndex: 1,
    heightObject: 12,
    widthObject: 12,
};

const ObjectForm: React.FC<IProps> = (
    {
        title,
        onSave,
        onCancel,
        onChange,
        targetObject,
        originSensorId,
        openSidebar,
    }: IProps,
) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const allFactory = useSelector(selectAllFactoryItemInTree),
        allProcess = useSelector(selectAllProcessItemInTree),
        allUnit = useSelector(selectAllUnitItemInTree),
        allSensorInTree = useSelector(selectAllSensorItemInConfigurationTree),
        hmiObjectErrors = useSelector(selectHmiObjectErrors),
        hmiObjects = useSelector(selectHmiOpenSchemeObjects);

    const [initialValues, setInitialValues] = useState<IFormValues>(defaultInitialValues);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

    const [unitForOption, setUnitForOption] = useState<IFlatTreeUnit[]>([]);
    const [sensorForOption, setSensorForOption] = useState<ISensor[]>([]);
    const [sizeSetting, setSizeSetting] = useState(false);
    const [labelSetting, setLabelSetting] = useState(false);

    const [validationSchema] = useState(yup.object().shape({
        name: yup.string()
            .trim(),
        picture: yup.string()
            .trim(),
        factory: yup.number()
            .required(t('FIELD_IS_REQUIRED')),
        unit: yup.number()
            .required(t('FIELD_IS_REQUIRED')),
        sensor_id: yup.object()
            .nullable(true)
            .required(t('FIELD_IS_REQUIRED')),
        fontSize: yup.number()
            .min(1, t('THE_MINIMUM_VALUE_MUST_BE_GREATER_THAN_OR_EQUAL_TO', { value: 1 }))
            .max(100, t('THE_MAXIMUM_VALUE_MUST_BE_GREATER_THAN_OR_EQUAL_TO', { value: 100 }))
            .integer(t('THE_NUMBER_MUST_BE_AN_INTEGER'))
            .required(t('FIELD_IS_REQUIRED')),
        fontSizeLabel: yup.number()
            .min(1, t('THE_MINIMUM_VALUE_MUST_BE_GREATER_THAN_OR_EQUAL_TO', { value: 1 }))
            .max(100, t('THE_MAXIMUM_VALUE_MUST_BE_GREATER_THAN_OR_EQUAL_TO', { value: 100 }))
            .integer(t('THE_NUMBER_MUST_BE_AN_INTEGER'))
            .required(t('FIELD_IS_REQUIRED')),
        zIndex: yup.number()
            .min(1, t('THE_MINIMUM_VALUE_MUST_BE_GREATER_THAN_OR_EQUAL_TO', { value: 1 }))
            .integer(t('THE_NUMBER_MUST_BE_AN_INTEGER'))
            .required(t('FIELD_IS_REQUIRED')),
        heightObject: yup.number()
            .min(1, t('THE_MINIMUM_VALUE_MUST_BE_GREATER_THAN_OR_EQUAL_TO', { value: 1 }))
            .required(t('FIELD_IS_REQUIRED')),
        widthObject: yup.number()
            .min(1, t('THE_MINIMUM_VALUE_MUST_BE_GREATER_THAN_OR_EQUAL_TO', { value: 1 }))
            .required(t('FIELD_IS_REQUIRED')),
    }));

    /**
     * Action on submit form.
     *
     * @type {(values: IFormValues) => void}
     */
    const handleSubmit = useCallback((values: IFormValues) => {

        setInitialValues(values);
        
        if (onSave && !('messages' in hmiObjectErrors)) {

            onSave({ values: values, targetObject: targetObject });
        }
        setLabelSetting(false)
        setSizeSetting(false);

    }, [setInitialValues, targetObject, hmiObjectErrors]);


    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: handleSubmit,
    });

    useEffect(() => {

        setSizeSetting(false);
        setLabelSetting(false);

    }, [openSidebar]);


    useEffect(() => {

        const currentSensor: ICurrentSensor | undefined = sensorForOption.find(value => value.id === (targetObject as FabricObject)?.sensorId) as ICurrentSensor;
        dispatch(HmiObjectAction.clearErrorsInForm());
        const heightObject = changeSizeByTypeObjectWithScale((targetObject as FabricObject)?.type || 'default') ?
                (((targetObject as FabricObject)?.height || 1) * ((targetObject as FabricObject)?.scaleY || 1)) || initialValues.heightObject
                : (targetObject as FabricObject)?.height || initialValues.heightObject,
            widthObject = changeSizeByTypeObjectWithScale((targetObject as FabricObject)?.type || 'default') ?
                (((targetObject as FabricObject)?.width || 1) * ((targetObject as FabricObject)?.scaleX || 1)) || initialValues.widthObject
                : (targetObject as FabricObject)?.width || initialValues.widthObject;

        const sensor_id = currentSensor ? {
            value: currentSensor.id,
            label: currentSensor?.pIdCode || currentSensor.name,
            type: currentSensor.sensorType,
        } : null;

        if (targetObject?.objectId) {

                setInitialValues({
                    ...initialValues,
                    id: targetObject.objectId,
                    unit: targetObject.unitId,
                    factory: targetObject.factoryId,
                    sensor_id: sensor_id,
                    position: (targetObject as FabricObject).pidCodePosition,
                    textPositionInObject: (targetObject as FabricObject)?.textPositionInObject || 'left',
                    angle: (targetObject as FabricObject).angle !== undefined ? (targetObject as FabricObject).angle || 0 : initialValues.angle,
                    objectId: targetObject.objectId,
                    fontSize: (targetObject as FabricObject)?.type === 'indicator' ? (targetObject as FabricObject)?.fontSize : 12,
                    fontSizeLabel: (targetObject as FabricObject)?.fontSizeLabel || 12,
                    zIndex: (targetObject as FabricObject)?.layoutNumber || 1,
                    heightObject: Math.round(heightObject),
                    widthObject: Math.round(widthObject),
                });
        } else {

            formik.resetForm();
            if (targetObject) {

                setInitialValues({
                    ...initialValues,
                    position: (targetObject as FabricObject)?.pidCodePosition || 'top',
                    textPositionInObject: (targetObject as FabricObject)?.textPositionInObject || 'left',
                    sensor_id: sensor_id,
                    id: undefined,
                    objectId: undefined,
                    angle: (targetObject as FabricObject).angle !== undefined ? (targetObject as FabricObject)?.angle || 0 : defaultInitialValues.angle,
                    fontSize: (targetObject as FabricObject)?.type === 'indicator' ? (targetObject as FabricObject)?.fontSize : 12,
                    fontSizeLabel: (targetObject as FabricObject)?.fontSizeLabel || 12,
                    zIndex: (targetObject as FabricObject)?.layoutNumber || 1,
                    heightObject: Math.round(heightObject),
                    widthObject: Math.round(widthObject),
                });

                if (!openSidebar) {
                    const clearAngle = setTimeout(() => {

                        setInitialValues({
                            ...initialValues,
                            sensor_id: sensor_id,
                            position: (targetObject as FabricObject)?.pidCodePosition || 'top',
                            textPositionInObject: (targetObject as FabricObject)?.textPositionInObject || 'left',
                            objectId: undefined,
                            angle: defaultInitialValues.angle,
                            fontSize: (targetObject as FabricObject)?.type === 'indicator' ? (targetObject as FabricObject)?.fontSize : 12,
                            fontSizeLabel: (targetObject as FabricObject)?.fontSizeLabel || 12,
                            zIndex: (targetObject as FabricObject)?.layoutNumber || 1,
                            heightObject: Math.round(heightObject),
                            widthObject: Math.round(widthObject),
                        });

                        setSizeSetting(false);
                        setLabelSetting(false);

                        clearTimeout(clearAngle);

                    }, 150);
                }
            }
        }

    }, [targetObject]);

    /**
     * Convert item to select options
     *
     * @param item
     * @return {IOptions}
     */
    const itemToOption = (item: any, options?: any): IHMIOptions => {

        const itemType = item.sensorType === 'graph' ? 'Sensor' : 'State';

        return { value: item.id, label: item.pIdCode || item.name, type: itemType };
    };

    useEffect(() => {

        const sensorForOptionData = allSensorInTree.map(value => {

            const currentUnit = allUnit.find(value1 => (value1.data as ISensor[]).find(value2 => value2.id === value.id));

            if (currentUnit) {
                const currentProcess = allProcess.find(value1 => (value1.data as number[]).find(value2 => value2 === currentUnit.id));
                const currentFactory = allFactory.find(value1 => (value1.data as number[]).find(value2 => currentProcess && value2 === currentProcess.id));

                const test = { unitId: currentUnit.id, factoryId: currentFactory?.id || null };

                return { ...value, ...test };
            }

            return value;
        });

        setSensorForOption(sensorForOptionData);

        const unitForOptionData = (allUnit as IFlatTreeUnit[]).map(unit => {

            const currentProcess = (allProcess as IFlatTreeProcess[]).find(process => (process.data as number[]).find(value => value === unit.id)),
                currentFactory = (allFactory as IFlatTreeFactory[]).find(factory => (factory.data as number[]).find(value => currentProcess && currentProcess.id === value));

            return { ...unit, factoryId: currentFactory?.id || null };
        });

        setUnitForOption(unitForOptionData);

    }, [allFactory, allSensorInTree, allUnit, allProcess]);

    /**
     * Action on form change
     *
     * @type {(event: React.ChangeEvent<{name?: string | undefined, value: unknown}>) => void}
     */
    const onChangeSelect = useCallback((event: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => {

        const cloneObj = cloneDeep(targetObject) as FabricObject,
            cloneHeight = (cloneObj?.height || 1),
            cloneWidth = (cloneObj?.width || 1),
            aspectRatio = cloneWidth/cloneHeight;

        formik.handleChange(event);

        if (event.target.name === 'factory' && event.target.value) {

            setInitialValues({ ...initialValues, factory: parseInt(event.target.value as string), unit: '', sensor_id: null });
        }

        if (event.target.name === 'unit' && event.target.value) {

            const currentUnit = unitForOption.find(unit=> unit.id === parseInt(event.target.value as string)) as any;

            setInitialValues({ ...initialValues, unit: parseInt(event.target.value as string), sensor_id: null, factory: currentUnit.factoryId || null });

        }

        if (event.target.name === 'angle') {

            setInitialValues({ ...initialValues, angle: event.target.value as number });

            onChange && onChange({
                values: { ...initialValues, angle: event.target.value },
                targetObject: targetObject,
            });
        }

        if (event.target.name === 'position') {

            setInitialValues({ ...initialValues, position: event.target.value as string });

            onChange && onChange({
                values: { ...initialValues, position: event.target.value },
                targetObject: targetObject,
            });
        }

        if (event.target.name === 'textPositionInObject') {

            setInitialValues({ ...initialValues, textPositionInObject: event.target.value as string });

            onChange && onChange({
                values: { ...initialValues, textPositionInObject: event.target.value },
                targetObject: targetObject,
            });
        }

        if (event.target.name  === 'fontSize') {

            setInitialValues({ ...initialValues, fontSize: event.target.value as number });

            onChange && onChange({
                values: { ...initialValues, fontSize: event.target.value as number },
                targetObject: targetObject,
            });
        }

        if (event.target.name  === 'fontSizeLabel') {

            setInitialValues({ ...initialValues, fontSizeLabel: event.target.value as number });

            if (event.target.value && parseInt(event.target.value as string) > 0) {
                onChange && onChange({
                    values: { ...initialValues, fontSizeLabel: event.target.value as number },
                    targetObject: targetObject,
                });
            }
        }

        if (event.target.name  === 'layoutNumber') {

            setInitialValues({ ...initialValues, zIndex: event.target.value as number });
            if (event.target.value && parseInt(event.target.value as string) > 0) {
                onChange && onChange({
                    values: { ...initialValues, zIndex: event.target.value as number || 1 },
                    targetObject: targetObject,
                });
            }
        }

        if (event.target.name  === 'heightObject') {

            const heightObject = (event.target.value as string).length > 0 ?
                    Number(event.target.value as number).toFixed(0)
                : event.target.value as number,
                widthObject = changeSizeByTypeObjectWithScale((targetObject as FabricObject)?.type || 'default') ?
                    (heightObject as number) * aspectRatio
                    :
                    initialValues.widthObject;

            if (!isNaN(parseInt(event.target.value as string))) {

                setInitialValues({ ...initialValues, heightObject: heightObject as number, widthObject: widthObject });

                onChange && onChange({
                    values: { ...initialValues, heightObject: heightObject, widthObject: widthObject },
                    targetObject: targetObject,
                });
            }
        }

        if (event.target.name  === 'widthObject') {

            const widthObject = (event.target.value as string).length > 0 ?
                Number(event.target.value as number).toFixed(0)
                : event.target.value as number,
                heightObject = changeSizeByTypeObjectWithScale((targetObject as FabricObject)?.type || 'default') ?
                    (widthObject as number) / aspectRatio : initialValues.heightObject;

            if (!isNaN(parseInt(event.target.value as string))) {

                setInitialValues({ ...initialValues, heightObject: heightObject, widthObject: widthObject as number });

                onChange && onChange({
                    values: { ...initialValues, heightObject: heightObject, widthObject: widthObject },
                    targetObject: targetObject,
                });
            }
        }


    }, [formik, setUnitForOption, setSensorForOption, sensorForOption]);

    /**
     * Action on open confirm delete modal
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onDeleteObject = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        setOpenDeleteModal(true);

    }, [setOpenDeleteModal]);

    /**
     * Action on cancel delete.
     *
     * @type {() => void}
     */
    const onDeleteCancel = useCallback(()=>{

        setOpenDeleteModal(false);

    }, [setOpenDeleteModal]);

    /**
     * Action on delete object on scheme.
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onDeleteSuccess = useCallback(() => {


        if (initialValues.id) {
            const sendData: IHmiObject = {
                id: initialValues.id,
                fabric: initialValues.factory,
                unit: initialValues.unit,
                angle: initialValues.angle,
                points: (targetObject as ITargetObject)?.points?.map(value => ({ x: value[0], y: value[1] }))[0] as any || null,
                position: {
                    x: 0,
                    y: 0,
                },
                zIndex: 1,
                type: Object(editorConstants.typeToId)[`${targetObject?.type}`],
                pidCodePosition: initialValues.position,
                hmiSchema: (targetObject as ITargetObject)?.schemaId || 0,
                scale: null,
                sensorId: null,
                textPositionInObject: (targetObject as ITargetObject)?.textPositionInObject || 'left',
            };

            dispatch(HmiObjectAction.delete(sendData));

            if (onCancel) {

                onCancel();
            }
        }
    }, [dispatch, initialValues, targetObject, onCancel]);

    /**
     * Filter function for sensor
     * @type {(sensor: any) => (boolean | any)}
     */
    const filterFunctionForSensor = useCallback((sensor: any) =>{

            if (!parseInt(formik.values.unit) && typeof formik.values.factory === 'number') {

                return formik.values.factory === sensor.factoryId;
            }

            if (typeof formik.values.unit === 'number') {

                return formik.values.unit === sensor.unitId;
            }

            return sensor;

    }, [formik]);

    /**
     * Filter function for unit.
     *
     * @type {(unit) => (boolean | any)}
     */
    const filterFunctionForUnit = useCallback((unit) => {

        if (typeof initialValues.factory === 'number') {

            return unit.factoryId === formik.values.factory;

        } else {

            return unit;
        }

    }, [formik]);

    /**
     * On Cancel changes in form
     *
     * @type {() => void}
     */
    const onCancelAction = useCallback(() => {

        onChange && onChange({
            values: {
                ...initialValues,
                position: (targetObject as FabricObject).pidCodePosition,
                textPositionInObject: (targetObject as FabricObject).textPositionInObject,
                angle: (targetObject as FabricObject).angle,
                factory: (targetObject as FabricObject).factoryId,
                unit: (targetObject as FabricObject).unitId,
                sensor_id: (targetObject as FabricObject).sensorId || (targetObject as FabricObject).pidCode || null,
            },
            targetObject: targetObject,
        });

        if (onCancel) {

            dispatch(HmiObjectAction.clearErrorsInForm());

            onCancel();
        }

        setSizeSetting(false);
        setLabelSetting(false);

        setInitialValues({ ...initialValues, sensor_id: null });

    }, [
        onCancel,
        targetObject,
        onChange,
        initialValues,
        dispatch,
        setSizeSetting,
        setLabelSetting,
    ]);

    /**
     * Filtering and sorting sensor data for selection option.
     *
     * @type {ISensor[]}
     */
    const sensorDataForSelect = sensorForOption
        .filter(filterFunctionForSensor)
        .sort((a, b) => {

            if (a.sensorType < b.sensorType) {

                return -1;
            }

            if (a.sensorType > b.sensorType) {

                return 1;
            }

            return 0;
        });

    /**
     * Render input for autocomplete field
     *
     * @type {(params: Object) => React.ReactNode}
     */
    const renderInputAutocomplete = useCallback((params: Object): React.ReactNode => {

        return (
            <>
                <InputLabel>{t('SENSOR_HMI')}</InputLabel>
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder={t('SELECT')}
                />
                {formik.touched.sensor_id && formik.errors.sensor_id &&
                <div
                    className="validation-massage"
                >
                    {formik.errors.sensor_id}
                </div>
                }
            </>
        );
    }, [formik, t]);

    /**
     * On change autocomplete field.
     *
     * @type {(event: any, value: any, reason: any, detai: any) => void}
     */
    const onChangeAutocomplete = useCallback((event: any, value: any, reason: any, detai: any)=> {

        setInitialValues({ ...initialValues, sensor_id: value });

        const currentSensor: ICurrentSensor | undefined = sensorForOption.find(sensor => sensor.id === value?.value) as ICurrentSensor;

        if (reason === 'clear') {

            setSizeSetting(false);
            setLabelSetting(false);

            onChange && onChange({
                values: {
                    ...initialValues,
                    name: null,
                    sensor_id: null,
                },
                targetObject: targetObject,
            });
        }

        if (currentSensor) {

            setInitialValues({
                ...initialValues,
                name: currentSensor.pIdCode || currentSensor.name,
                sensor_id: { value: currentSensor.id, label: currentSensor.pIdCode || currentSensor.name, type: currentSensor.sensorType },
                factory: currentSensor.factoryId,
                unit: currentSensor.unitId,
            });

            onChange && onChange({
                values: {
                    ...initialValues,
                    name: currentSensor.pIdCode || currentSensor.name,
                    sensor_id: { value: currentSensor.id, label: currentSensor.pIdCode || currentSensor.name, type: currentSensor.sensorType },
                    factory: currentSensor.factoryId,
                    unit: currentSensor.unitId,
                },
                targetObject: targetObject,
            });
        }

        dispatch(HmiObjectAction.clearErrorsInForm());

    }, [dispatch, setInitialValues, initialValues, sensorForOption]);

    /**
     * Group by type
     *
     * @type {(option: IHMIOptions) => string}
     */
    const groupBy = useCallback((option: IHMIOptions) => {

        return option.type;
    }, []);

    /**
     * Validation on key press
     *
     * @type {(event: React.KeyboardEvent<HTMLDivElement>) => void}
     */
    const validationOnKeyPress = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {

        event = event || window.event;

        const charCode = (typeof event.which == 'undefined') ? event.keyCode : event.which;
        const charStr = String.fromCharCode(charCode);

        if (!charStr.match(/^[0-9]+$/)) {

            event.preventDefault();
        }

    }, []);

    /**
     * Get option label
     *
     * @type {(option: IHMIOptions) => string}
     */
    const getOptionLabel = useCallback((option: IHMIOptions) => {

        return option.label;
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit} noValidate>
                <div className="section edit-object-form wrap-form-node">
                    <div className="table-header">
                        <div className="title">
                            <h3>{title}</h3>
                        </div>
                    </div>
                    {'messages' in hmiObjectErrors && hmiObjectErrors.messages ?
                        <div className="common-error">
                            {t(hmiObjectErrors.messages[0])}
                        </div>
                        : null
                    }
                    <div className="table-body">
                        <div className="form-group">
                            <Select
                                name="factory"
                                className={`form-field ${formik.touched.factory ? formik.errors.factory ? 'error-field' : 'success-field' : ''}`}
                                onChange={onChangeSelect}
                                label={t('FACTORY')}
                                placeholder={t('SELECT')}
                                value={formik.values.factory}
                                options={allFactory.map(itemToOption) || []}
                            >
                                {formik.touched.factory && formik.errors.factory &&
                                <div
                                    className="validation-massage"
                                >
                                    {formik.errors.factory}
                                </div>
                                }
                            </Select>
                        </div>
                        <div className="form-group">
                            <Select
                                name="unit"
                                className={`form-field ${formik.touched.unit ? formik.errors.unit ? 'error-field' : 'success-field' : ''}`}
                                onChange={onChangeSelect}
                                label={t('UNIT')}
                                value={formik.values.unit}
                                placeholder={t('SELECT')}
                                options={unitForOption
                                    .filter(filterFunctionForUnit)
                                    .map(itemToOption) || []}
                            >
                                {formik.touched.unit && formik.errors.unit &&
                                <div
                                    className="validation-massage"
                                >
                                    {formik.errors.unit}
                                </div>
                                }
                            </Select>
                        </div>
                        <div className="form-group">
                            <Autocomplete
                                renderInput={renderInputAutocomplete}
                                onChange={onChangeAutocomplete}
                                className={`autocomplete-field form-field ${formik.touched.sensor_id ? 
                                    (formik.errors.sensor_id || 
                                        ('messages' in hmiObjectErrors && hmiObjectErrors.messages)) ? 'error-field' : 'success-field' : ''}`}
                                placeholder={t('SELECT')}
                                options={sensorDataForSelect
                                    .filter((value: any) => value.unitId && value.factoryId && hmiObjects.findIndex(obj => obj.sensorId === value.id && obj.sensorId !== originSensorId) === -1)
                                    .map(itemToOption) || []
                                }
                                value={formik.values.sensor_id}
                                groupBy={groupBy}
                                getOptionLabel={getOptionLabel}
                                noOptionsText={t('NO_OPTIONS')}
                            />
                        </div>
                        <hr />
                        <div className="form-group">
                            <TextInput
                                name="layoutNumber"
                                label={t('LAYOUT')}
                                className={`form-field ${formik.touched.zIndex ? formik.errors.zIndex ? 'error-field' : 'success-field' : ''}`}
                                onChange={onChangeSelect}
                                placeholder={t('LAYOUT')}
                                value={formik.values.zIndex}
                                onKeyPress={validationOnKeyPress}
                                type="number"
                                inputProps={{ 'aria-valuemin': 1 }}
                            >
                                {formik.touched.zIndex && formik.errors.zIndex &&
                                    <div
                                        className="validation-massage"
                                    >
                                        {formik.errors.zIndex}
                                    </div>
                                }
                            </TextInput>
                        </div>
                        <div className="form-group">
                            <Select
                                name="angle"
                                className="form-field"
                                onChange={onChangeSelect}
                                placeholder={t('SELECT')}
                                label={t('OBJECT_ICON_POSITION')}
                                value={formik.values.angle}
                                options={[
                                    { value: 0, label: t('0°') },
                                    { value: 90, label: t('90°') },
                                    { value: 180, label: t('180°') },
                                    { value: 270, label: t('270°') },
                                ]}
                            />
                        </div>

                        {targetObject?.type === 'indicator' ?
                            <div className="form-group">
                                <InputLabel className="font-setting-for-object-label" >{t('FONT_SETTINGS')}</InputLabel>
                                <Accordion
                                    className="font-setting-for-object"
                                >
                                    <AccordionSummary
                                        expandIcon={<DropdownArrowIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        {t('FONT')}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="form-group">
                                            <TextInput
                                                name="fontSize"
                                                label={t('FONT_SIZE_LABEL')}
                                                className={`form-field ${formik.touched.fontSize ? formik.errors.fontSize ? 'error-field' : 'success-field' : ''}`}
                                                onChange={onChangeSelect}
                                                placeholder={t('FONT_SIZE')}
                                                value={formik.values.fontSize}
                                                type="number"
                                                onKeyPress={validationOnKeyPress}
                                                inputProps={{ 
                                                    'aria-valuemax': 100, 
                                                    'aria-valuemin': 0,
                                                }}
                                            >
                                                {formik.touched.fontSize && formik.errors.fontSize &&
                                                <div
                                                    className="validation-massage"
                                                >
                                                    {formik.errors.fontSize}
                                                </div>
                                                }
                                            </TextInput>
                                        </div>
                                        <div className="form-group">
                                            <Select
                                                name="textPositionInObject"
                                                className="form-field"
                                                onChange={onChangeSelect}
                                                label={t('POSITION_OF_THE_TEXT')}
                                                value={formik.values.textPositionInObject}
                                                placeholder={t('SELECT')}
                                                options={[
                                                    { value: 'top', label: t('TOP') },
                                                    { value: 'topRight', label: t('TOP_RIGHT') },
                                                    { value: 'right', label: t('RIGHT') },
                                                    { value: 'rightBottom', label: t('RIGHT_BOTTOM') },
                                                    { value: 'bottom', label: t('BOTTOM') },
                                                    { value: 'bottomLeft', label: t('BOTTOM_LEFT') },
                                                    { value: 'left', label: t('LEFT') },
                                                    { value: 'topLeft', label: t('TOP_LEFT') },
                                                ]}
                                            />
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            : null
                        }
                        {targetObject?.type !== 'polygon' ?
                            <div className="form-group">
                                <InputLabel className="size-setting-for-object-label">{t('OBJECT_SIZE')}</InputLabel>
                                <Accordion
                                    className="size-setting-for-object"
                                    expanded={sizeSetting}
                                    disabled={!formik.values.sensor_id}
                                    onChange={(event, expanded) => {
                                        setSizeSetting(expanded);
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<DropdownArrowIcon />}
                                        aria-controls="panel1a-content-size"
                                        id="panel1a-header-size"
                                    >
                                        {t('HEIGHT_WIDTH')}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="form-group">
                                            <TextInput
                                                name="heightObject"
                                                label={t('OBJECT_HEIGHT')}
                                                className={`form-field ${formik.touched.heightObject ? formik.errors.heightObject ? 'error-field' : 'success-field' : ''}`}
                                                onChange={onChangeSelect}
                                                placeholder={t('OBJECT_HEIGHT')}
                                                value={formik.values.heightObject}
                                                type="number"
                                                disabled={!formik.values.sensor_id}
                                                inputProps={{ 'aria-valuemin': 0 }}
                                                onKeyPress={validationOnKeyPress}
                                            >
                                                {formik.touched.heightObject && formik.errors.heightObject &&
                                                <div
                                                    className="validation-massage"
                                                >
                                                    {formik.errors.heightObject}
                                                </div>
                                                }
                                            </TextInput>
                                        </div>
                                        <div className="form-group">
                                            <TextInput
                                                name="widthObject"
                                                label={t('OBJECT_WIDTH')}
                                                className={`form-field ${formik.touched.widthObject ? formik.errors.widthObject ? 'error-field' : 'success-field' : ''}`}
                                                onChange={onChangeSelect}
                                                placeholder={t('OBJECT_WIDTH')}
                                                value={formik.values.widthObject}
                                                type="number"
                                                disabled={!formik.values.sensor_id}
                                                onKeyPress={validationOnKeyPress}
                                                inputProps={{ 'aria-valuemin': 0 }}
                                            >
                                                {formik.touched.widthObject && formik.errors.widthObject &&
                                                <div
                                                    className="validation-massage"
                                                >
                                                    {formik.errors.widthObject}
                                                </div>
                                                }
                                            </TextInput>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            : null
                        }
                        <hr />
                        <div className="form-group">
                            <InputLabel className="font-setting-for-object-label" >{t('PID_CODE_SETTINGS')}</InputLabel>
                            <Accordion
                                className="font-setting-for-object"
                                expanded={labelSetting}
                                disabled={!formik.values.sensor_id}
                                onChange={(event, expanded) => {
                                    setLabelSetting(expanded);
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<DropdownArrowIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {t('PID_CODE')}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="form-group">
                                        <TextInput
                                            name="fontSizeLabel"
                                            label={t('FONT_SIZE_FOR_PID_CODE')}
                                            className={`form-field ${formik.touched.fontSizeLabel ? formik.errors.fontSizeLabel ? 'error-field' : 'success-field' : ''}`}
                                            onChange={onChangeSelect}
                                            placeholder={t('FONT_SIZE')}
                                            value={formik.values.fontSizeLabel}
                                            disabled={!formik.values.sensor_id}
                                            onKeyPress={validationOnKeyPress}
                                            type="number"
                                            inputProps={{ 'aria-valuemax': 100, 'aria-valuemin': 0 }}
                                        >
                                            {formik.touched.fontSizeLabel && formik.errors.fontSizeLabel &&
                                                <div
                                                    className="validation-massage"
                                                >
                                                    {formik.errors.fontSizeLabel}
                                                </div>
                                            }
                                        </TextInput>
                                    </div>
                                    <div className="form-group">
                                        <Select
                                            name="position"
                                            className="form-field"
                                            onChange={onChangeSelect}
                                            label={t('PID_CODE_POSITION')}
                                            value={formik.values.position}
                                            placeholder={t('SELECT')}
                                            disabled={!formik.values.sensor_id}
                                            options={[
                                                { value: 'top', label: t('TOP') },
                                                { value: 'topRight', label: t('TOP_RIGHT') },
                                                { value: 'right', label: t('RIGHT') },
                                                { value: 'rightBottom', label: t('RIGHT_BOTTOM') },
                                                { value: 'bottom', label: t('BOTTOM') },
                                                { value: 'bottomLeft', label: t('BOTTOM_LEFT') },
                                                { value: 'left', label: t('LEFT') },
                                                { value: 'topLeft', label: t('TOP_LEFT') },
                                            ]}
                                        />
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                        </div>
                        <hr />
                        {initialValues?.objectId ?
                            <div
                                className="form-btn-delete"
                                onClick={onDeleteObject}
                            >
                                {t('DELETE_OBJECT')}
                            </div>
                            :
                            null}
                        <div className="button-row">
                            <Button
                                type="button"
                                color="primary"
                                onClick={onCancelAction}
                            >
                                {t('CANCEL')}
                            </Button>
                            <Button type="submit" color="secondary">
                                {t('SAVE')}
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
            <DeleteDialog
                open={openDeleteModal}
                removeId={initialValues.id || null}
                heading={t('REMOVE_THE_OBJECT')}
                body={t('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_OBJECT')}
                onAccept={onDeleteSuccess}
                onClose={onDeleteCancel}
            />
        </>
    );
};

export default React.memo(ObjectForm);
