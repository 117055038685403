import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    ConfigurationActions,
    EditKpiAction,
    EditSensorActions,
    FactoryActions,
    FormActions,
    HmiSchemaAction,
    ProcessActions,
    ProductActions,
    SensorActions,
} from '../../core/actions';
import {
    IConfigurationTreeProps,
    IFlatTreeFactory,
    IFlatTreeProcess,
    IFlatTreeUnit,
    IStateConfigurationTree,
} from '../../core/interfaces';
import Header from '../Header/Header';
import RightSidebar from '../Sidebar/RightSidebar';
import SensorForm from './SensorForm/SensorForm';
import DragDropTree from './DragDropTree';
import AlertForm from './Alert/AlertForm';
import FormKPI from '../FormKpi/FormKpi';
import FormFactory from './FormNode/FormFactory';
import FormProcess from './FormNode/FormProcess';
import FormUnits from './FormNode/FormUnits';
import FormHmi from './HMI/FormHmi';
import Editor from './HMI/Editor/Editor';
import { ConfirmDialog } from '../../core/ui/components';

import { modules } from '../../modules';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';
import { RootState } from '../../core/store';
import { getVh } from '../../base/helpers/screen-size.helper';

/**
 * Configure page component
 *
 * @class Configuration
 */
class ConfigurationTree extends React.Component<IConfigurationTreeProps, IStateConfigurationTree> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IConfigurationTreeProps) {

        super(props);

        this.state = {
            resultObject: null,
            editStoreKPI: false,
            moduleMount: false,
            editorHeight: 0,
            editorWidth: 0,
        };

        this.resultKpi = this.resultKpi.bind(this);

        this.reload = this.reload.bind(this);

        this.onConfirmDialog = this.onConfirmDialog.bind(this);

        this.handleCancel = this.handleCancel.bind(this);

        this.changeOrientation = this.changeOrientation.bind(this);

    }

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {

        this.loadData(this.props.items.length > 0);

        this.changeOrientation();

        window.addEventListener('resize', this.changeOrientation);
        window.addEventListener('orientationchange', this.changeOrientation);

    }

    /**
     * Component props update handler
     *
     * @param {IProps} prevProps
     */
    componentDidUpdate(prevProps: Readonly<IConfigurationTreeProps>) {


        this.moduleMount();

        if (this.props.items !== prevProps.items) {

            this.loadDataFromTree();
        }

        if (this.props.refresh && this.props.refresh !== prevProps.refresh
            && (!this.props.errorsFactoryChange || Object.keys(this.props.errorsFactoryChange).length == 0)
            && (!this.props.errorsConfigurationTree || Object.keys(this.props.errorsConfigurationTree).length === 0)
            && (!this.props.errorsProcess || Object.keys(this.props.errorsProcess).length === 0)
            && (!this.props.errorsUnit || Object.keys(this.props.errorsUnit).length === 0)) {

            this.loadData(true);

            this.props.toggleForm(true);
        }

    }

    /**
     * Callback before the component will be removed from DOM
     */
    componentWillUnmount() {

        this.props.toggleForm(true);

        window.removeEventListener('resize', this.changeOrientation);
        window.removeEventListener('orientationchange', this.changeOrientation);

    }

    private elementInTree: { process: any[]; unit: any[]; } | undefined;

    /**
     * Application header height in pixels
     *
     * @type {number}
     */
    headerHeight = 40;

    /**
     * Left sidebar width in pixels
     *
     * @type {number}
     */
    leftSidebarWidth = 320;


    /**
     * Change orientation of device
     */
    changeOrientation() {

        const configureDraggable = document.getElementsByClassName('configure-draggable');

        if (configureDraggable) {

            const editorWidth = configureDraggable[0]?.clientWidth - this.leftSidebarWidth,
                editorHeight = configureDraggable[0]?.clientHeight;

            this.setState({ editorWidth: editorWidth, editorHeight: editorHeight });
        }
    }

    /**
     * Checking Permission to Mount a Module
     */
    moduleMount() {
        const { settings, isLoaded } = this.props,
            { moduleMount } = this.state;

        if (!moduleMount && isLoaded) {

            //TODO: make a check for enabled HR block on backend inside the module
            modules.forEach((module) => {

                /**
                 * optional parameter for control from the main api
                 */
                if (module.id === 'qv-hr') {

                    module.setModuleStatus(settings.qvhr.isEnabled);
                }
            });

            this.setState({ moduleMount: true });
        }
    }

    /**
     * Load configuration tree data
     */
    loadData(update = false) {

        const loadTreeTimeout = setTimeout(() => {

            this.props.loadTree(update);

            clearTimeout(loadTreeTimeout);

        }, update ? 300 : 0);

        this.props.loadSensor('', { column: 'id', dir: 'asc' }, { table: ['type,unit||id,name'] });

        this.props.loadCategoryProduct();

        this.props.loadHmiSchemas();

        this.props.loadProduct(
            '',
            {
                column: 'id',
                dir: 'asc',
            },
            {
                table: [
                    'alertRules',
                    'alertRules.unit',
                    'alertRules.sensor',
                    'alertRules.ruleCauseContexts',
                    'alertRules.ruleCauseContexts.cause',
                    'category'],
            },
            undefined);

    }

    /**
     * Retrieving data from a tree
     *
     */
    loadDataFromTree() {

        const { items } = this.props,
            droppableMap: IFlatTreeFactory[] = [],
            elementInTree: {
                process: IFlatTreeProcess[];
                unit: IFlatTreeUnit[];
            } = {
                process: [],
                unit: [],

            };

        if (items) {

            for (const value of items) {

                if (value.type === 'factory') {

                    droppableMap.push(value as unknown as IFlatTreeFactory);
                    //
                    //     elementInTree.process.push(...value.data as []);
                    //
                    //     value.data.forEach((process) => {
                    //
                    //         elementInTree.unit.push(...process.data as []);
                    //
                    //     });
                }

                if (value.type === 'process') {

                    elementInTree.process.push(value as unknown as IFlatTreeProcess);
                }

                if (value.type === 'unit') {

                    elementInTree.unit.push(value as unknown as IFlatTreeUnit);
                }
            }
        }

        this.elementInTree = elementInTree;
    }

    /**
     *  Result KPI Editor
     *
     * @param {Object} resultObject
     */
    resultKpi(resultObject: { formResult: Record<string, unknown> }) {

        this.setState({ resultObject: resultObject, editStoreKPI: Boolean(resultObject.formResult.id) });

    }

    /**
     * Handler create KPI
     */
    onConfirmDialog() {

        // TODO add action create KPI

        // this.props.toggleForm(true, '');
    }

    /**
     * KPI form cancel handler
     */
    handleCancel() {

        this.setState({ resultObject: null });

    }

    reload() {

        setTimeout(() => {
            this.loadData();
        }, 300);

    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { formName } = this.props,
            { editorHeight, editorWidth } = this.state;

        const editorStyle = {
            marginTop: -25,
            marginLeft: -32,
            marginRight: -32,
        };

        return (
            <React.Fragment>
                <Header />
                <DragDropTree />
                <RightSidebar
                    data={{}}
                    openSidebar={this.props.openSidebar && formName !== 'alert-sidebar'}
                    close={this.props.close}
                >
                    {formName && formName === 'sensor' ?
                        <SensorForm
                            openSidebar={this.props.openSidebar}
                            elementInTree={this.elementInTree}
                        /> : null
                    }
                    {formName && formName === 'alert' ?
                        <AlertForm model={null} />
                        : null
                    }
                    {formName && formName === 'kpi' ?
                        <FormKPI
                            goalRangeHidden={false}
                            result={this.resultKpi}
                            unitsInTree={this.elementInTree ? this.elementInTree.unit : []}
                        /> : null
                    }
                    {formName && formName === 'factory' ?
                        <FormFactory
                            openSidebar={this.props.openSidebar}
                        /> : null
                    }
                    {formName && formName === 'process' ?
                        <FormProcess
                            openSidebar={this.props.openSidebar}
                            elementInTree={this.elementInTree}
                        /> : null
                    }
                    {formName && formName === 'units' ?
                        <FormUnits
                            openSidebar={this.props.openSidebar}
                            elementInTree={this.elementInTree}
                        /> : null
                    }
                    {formName && formName === 'hmi' ?
                        <FormHmi
                            openSidebar={this.props.openSidebar}
                            toggleForm={this.props.toggleForm}
                        /> : null
                    }
                    {formName && formName === 'hmi-editor' ?
                        <div id="hmi-editor-container" className="hmi-editor-container"
                             style={{
                                 width: 'calc(100vw - 320px)',
                                 height: `${getVh(100) - 40}px`,
                                 overflow: 'hidden', ...editorStyle,
                             }}
                        >
                            <Editor />
                        </div> : null
                    }
                    {
                        modules.map((module, index) => {
                            return module.renderConfigurationTreeSidebars({
                                index,
                                formName,
                                openSidebar: this.props.openSidebar,
                                toggleForm: this.props.toggleForm,
                                reloadData: this.reload,
                            });
                        })
                    }
                    {formName && formName === 'editor' ?
                        modules.map((module, index) => module.renderMapEditor(
                            index,
                            editorWidth,
                            editorHeight,
                            editorStyle,
                        ))
                        : null
                    }
                </RightSidebar>
                <ConfirmDialog
                    heading={this.props.t(this.state.editStoreKPI ? 'CHANGE_KPI_Q' : 'ADD_KPI_Q')}
                    onAccept={this.onConfirmDialog}
                    onClose={this.handleCancel}
                    open={Boolean(this.state.resultObject)}
                />
            </React.Fragment>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {
    const { sensor, form, configurationTree, appSetting } = state;
    const { errors } = sensor,
        { formOpened, formName } = form,
        { items, refresh } = configurationTree,
        { settings, isLoaded } = appSetting;

    return {
        errors: errors,
        sensor: state.editSensor.sensor,
        openSidebar: formOpened,
        formName: formName,
        items: items,
        settings,
        refresh,
        errorsFactoryChange: state.factoryChange.errors,
        errorsProcess: state.processChange.errors,
        errorsUnit: state.unitChange.errors,
        errorsConfigurationTree: state.configurationTree.errors,
        isLoaded,
    };
};

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = (dispatch: Dispatch<RootState, void, AnyAction>) => ({
    dispatch,
    ...bindActionCreators({
        loadSensor: SensorActions.list,
        loadTree: ConfigurationActions.list,
        loadFactory: FactoryActions.list,
        loadProcess: ProcessActions.list,
        toggleForm: FormActions.toggle,
        close: EditSensorActions.edit,
        storeKPI: EditKpiAction.store,
        removeItemFromTree: ConfigurationActions.remove,
        loadProduct: ProductActions.list,
        loadHmiSchemas: HmiSchemaAction.list,
        loadCategoryProduct: ProductActions.getAllProductSortedByCategory,
    }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConfigurationTree));

