import { RootState } from '../../store';
import { createSelector } from 'reselect';

const selectGraphDataLastValueState = (state: RootState) => state.graphDataLastValue;

export const selectGraphDataLastValues = createSelector(
    selectGraphDataLastValueState,
    (graphDataLastValue) => graphDataLastValue.items,
);

export const selectGraphDataLastValuesErrors = createSelector(
    selectGraphDataLastValueState,
    (graphDataLastValue) => graphDataLastValue.errors,
);

const getSensorId = (state: RootState, sensorId: number) => sensorId;

export const selectGraphDataLastValuesById = createSelector(
    selectGraphDataLastValues,
    getSensorId,
    (items, id) => {
        return items && items.find(value => value.id === id)?.latestValue;
    },
);