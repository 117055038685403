import React, { useCallback, useState } from 'react';
import { ReactComponent as DropdownArrowIcon } from '../../../../core/ui/assets/images/icons/dropdown-arrow.svg';
import { Checkbox } from '@material-ui/core';
import { IStructuralTreeTitleProps } from '../../../../core/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { GraphActions } from '../../../../base/store/actions';
import { selectMaxWidthSideBar } from '../../../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import {
    selectHmiPlayerMode,
    selectHmiPlayerSchema,
    selectHmiPlayerValue
} from '../../../../core/selectors/hmi/playerSelector';
import { selectDashboardOnline, selectScreenWidth } from '../../../../core/selectors/dashboard/dashboardSelector';
import {
    calcRealTimeIndentation,
    selectBrushSelection
} from '../../../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectDrawerWidth, selectPositionDrawer } from '../../../../core/selectors/layout/responsiveDrawerSelector';
import * as d3 from 'd3';
import { selectHmiPlayerVisibility } from '../../../../core/selectors/hmi/visibilitySelector';

const StructuralTreeTitle: React.FC<IStructuralTreeTitleProps> = (
    {
        node,
        getOpenStatus,
        handleClick,
        checkedIcon,
        icon,
        checkBoxValue,
        factorySelectAction,
        checkboxDisabled,
        processSelectAction,
        processSelectActionParam,
        rightSectionClass,
    }: IStructuralTreeTitleProps,
) => {

    const [openStatus, setOpenStatus] = useState<boolean>(getOpenStatus(node.id, checkBoxValue as 'process' | 'factory' | 'unit'));

    const dispatch = useDispatch();
    const maxWidthSideBar = useSelector(selectMaxWidthSideBar),
        HMIPlayerStatus = useSelector(selectHmiPlayerMode);

    const schema = useSelector(selectHmiPlayerSchema);
    const isVisibleSchema = useSelector(selectHmiPlayerVisibility) && schema !== null;

    const dashboardRealTime = useSelector(selectDashboardOnline);
    const screenWidth = useSelector(selectScreenWidth) + useSelector(selectMaxWidthSideBar);
    const realTimeIndentation = useSelector(calcRealTimeIndentation),
        anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom',
        value = useSelector(selectHmiPlayerValue),
        selection = useSelector(selectBrushSelection),
        drawWidth = useSelector(selectDrawerWidth),
        histogramWidth = useSelector(selectScreenWidth) - (isVisibleSchema  && anchor === 'right' ? drawWidth : 0) - realTimeIndentation;

    const scale = d3.scaleTime().range([0, histogramWidth - 1.5])
        .domain(selection);

    /**
     * Click handler callback
     *
     * @type {() => void}
     */
    const handleClickCallback = useCallback((event) => {

        event.preventDefault();

        handleClick(node.id, checkBoxValue);

        setOpenStatus(getOpenStatus(node.id, checkBoxValue as 'process' | 'factory' | 'unit'));

    }, [handleClick, node, checkBoxValue, getOpenStatus]);

    /**
     * On change callback
     *
     * @type {(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void}
     */
    const onChangeCallback = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean)=>{

        event.preventDefault();

        if (factorySelectAction) {

            factorySelectAction(node.id, checked);

        }

        if (processSelectAction && processSelectActionParam) {

            processSelectAction(
                processSelectActionParam.factoryId,
                processSelectActionParam.processId,
                checked,
                processSelectActionParam.processShow_kpi,
                processSelectActionParam.processShow_state,
            );
        }

    }, [factorySelectAction, processSelectAction, processSelectActionParam, node]);


    /**
     * On Mouse Move callback.
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onMouseMoveCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

            if (dashboardRealTime && screenWidth - realTimeIndentation - 1 >= event.pageX) {

                dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));
            }
            if (!dashboardRealTime) {

                dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));

            }
        }

    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, screenWidth, realTimeIndentation]);

    /**
     * On touch start callback
     *
     * @type {(event: React.TouchEvent<HTMLDivElement>) => void}
     */
    const onTouchStartCallback = useCallback((event: React.TouchEvent<HTMLDivElement>)=> {

        event.preventDefault();

        if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

            if (dashboardRealTime && screenWidth - realTimeIndentation - 1 >= event.touches[0].pageX) {

                dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
            }

            if (!dashboardRealTime) {

                dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

            }

        }

    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, screenWidth, realTimeIndentation]);

    /**
     * On touch move callback.
     *
     * @type {(event: React.TouchEvent<HTMLDivElement>) => void}
     */
    const onTouchMoveCallback = useCallback((event: React.TouchEvent<HTMLDivElement>)=> {

        event.preventDefault();

        if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

            if (dashboardRealTime && screenWidth - realTimeIndentation - 1 >= event.touches[0].pageX) {

                dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
            }
            if (!dashboardRealTime) {

                dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

            }

        }

    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, screenWidth, realTimeIndentation, dashboardRealTime]);

    /**
     * On mouse leave callback
     *
     * @type {() => void}
     */
    const onMouseLeaveCallback = useCallback((event)=> {

        event.preventDefault();

        if (HMIPlayerStatus === 'stop') {

            dispatch(GraphActions.peakLeave());

        }

        if (HMIPlayerStatus === 'pause') {

            dispatch(GraphActions.peakEnterEmptyLine(Math.ceil(scale(new Date(value)))));
        }

    }, [dispatch, HMIPlayerStatus, scale, value]);

    return (
        <div className="item-title">
            <div className="wrap-section">
                <div
                    className={openStatus ? 'right-section active ' + rightSectionClass : 'right-section ' + rightSectionClass}
                    onClick={handleClickCallback}
                >
                    <DropdownArrowIcon />
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={checkBoxValue}
                        checked={!node.isMinimized}
                        className={''}
                        onChange={onChangeCallback}
                        disabled={checkboxDisabled}
                    />
                    {node.name}

                </div>
                <div className="left-section no-chart"
                     onMouseMove={onMouseMoveCallback}
                     onTouchStart={onTouchStartCallback}
                     onTouchMove={onTouchMoveCallback}
                     onMouseLeave={onMouseLeaveCallback}
                />
            </div>
        </div>
    );
};

export default React.memo(StructuralTreeTitle);