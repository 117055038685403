import React from 'react';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import moment from 'moment';

import { DashboardActions, HmiPlayerActions } from '../../../actions';
import SocketProvider from '../../../providers/socketProvider';

import './styles/MiniMap.scss';
import { ISensor } from '../../../interfaces';
import { RootState } from '../../../store';
import { GraphActions } from '../../../../base/store/actions';
import { modules } from '../../../../modules';
import {
    selectActiveSensorInTree,
    getAllSensor,
} from '../../../selectors/monitoringTree/minimapGetActiveSensorInTreeSelector';
import { selectRBAC } from '../../../selectors/auth/authSelector';
import { selectGraphPeriodRange } from '../../../selectors/graphPeriod/graphPeriodSelector';
import {
    selectMaxWidthSideBar,
    selectVisibleSideBar,
} from '../../../selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import {
    calcRealTimeIndentation,
    selectBrushSelection,
} from '../../../selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { 
    selectDashboardOnline, 
    selectScreenWidth,
    selectAllSensorDataForHistogramRange,
} from '../../../selectors/dashboard/dashboardSelector';
import { selectMonitoringTree } from '../../../selectors/monitoringTree/monitoringTreeSelector';
import { selectAppSettings } from '../../../selectors/appSetting/appSettingSelector';
import { selectHmiPlayerSchema } from '../../../selectors/hmi/playerSelector';
import { selectHmiPlayerVisibility } from '../../../selectors/hmi/visibilitySelector';
import {
    selectDrawerWidthWithDrawRules,
    selectPositionDrawer,
} from '../../../selectors/layout/responsiveDrawerSelector';
import { appConfig } from '../../../../config/appConfig';
import { isBrowser,  isMobile } from 'react-device-detect';

export interface IIntervals {
    sensorId: string;
    intervalsAlerts: number[];
    intervals: {
        x: number;
        width: number;
        color: string;
        warning: boolean;
    }[];
}

interface IProps {
    [key: string]: any;
}

interface IState {
    [key: string]: any;
}

/**
 * A D3 Bar chart component
 *
 * @class MiniMap
 */
class MiniMap extends React.PureComponent<IProps, IState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps) {

        super(props);

        this.brushCoords = [];
        this.brushDates = [];
        this.brushIsMoving = false;

        this.state = {
            graphs: [],
            sensorArray: null,
            socketId: undefined,
            sensorInTree: [],
            sensorsLoaded: false,
            responseData: [],
        };

        const { startDate, endDate } = this.props.minimapRange;

        this.scale = d3.scaleTime()
            .range([0, this.props.screenWidth]).domain([startDate, endDate]);

        this.socket = new SocketProvider();

        this.socketMessageId = 0;

        this.getSocketId = this.getSocketId.bind(this);

        this.onPointerDown = this.onPointerDown.bind(this);

        this.onPointerUp = this.onPointerUp.bind(this);

        this.onClick = this.onClick.bind(this);

        this.onMouseLeave = this.onMouseLeave.bind(this);

        this.handlerRef = this.handlerRef.bind(this);

        this.updateMiniatureCanvas = this.updateMiniatureCanvas.bind(this);

        this.callSocketData = this.callSocketData.bind(this);

        this.checkLoadActiveSensors = this.checkLoadActiveSensors.bind(this);
        this.drawMinimap = this.drawMinimap.bind(this);
    }

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {

        this.initBrush();

        this.customizeBrushHandles();

        this.updateMiniatureCanvas();

        this.socket.listen('getMinimapData', this.processMinimapData.bind(this));

        this.socket.listen('minimapUpdate', this.processMinimapData.bind(this));

        this.socket.getSocketId(this.getSocketId);

        this.updateMinimap();
    }

    /**
     * Callback after data update
     *
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {

        const { minimapRange, selection } = this.props;

        if (minimapRange !== prevProps.minimapRange || selection !== prevProps.selection) {

            this.updateMinimap();
        }

        if (prevState.socketId && this.rangeDate && prevState.socketId !== this.state.socketId) {
            const { screenWidth, dashboardOnline } = this.props;
            const [from, to] = this.rangeDate;

            this.socket.call('reconnect', {

                access_token: localStorage.getItem('auth_token'),
                from: from.toISOString(),
                to: to.toISOString(),
                numberOfDataPoints: screenWidth,
                realtime: dashboardOnline,
                messageIdMinimap: this.socketMessageId,
            });
        }

        if (prevProps.screenWidth !== this.props.screenWidth) {

            // updating position for resetting chart brush
            this.scale.range([0, this.props.screenWidth]);

            // New init brush
            // this.initBrush();

            if (prevProps.screenWidth <= 0 && this.props.screenWidth) {

                this.customizeBrushHandles();
            } else {

                this.initBrush();
            }
        }

        if (this.props.currentPeriod !== prevProps.currentPeriod || this.props.currentPeriodRefresh !== prevProps.currentPeriodRefresh) {

            this.brushSelection.call(this.brushX.clear, [0, this.props.screenWidth]);

        }

        if (
            (this.props.minimapRange && this.props.minimapRange !== prevProps.minimapRange) 
            || this.props.screenWidth !== prevProps.screenWidth 
            || (prevState.socketId && prevState.socketId !== this.state.socketId) 
        ) {

            this.brushX.extent([[0, 0], [this.props.screenWidth, 55]]);

            if (this.brushCoords[0] !== 0 && this.brushCoords[1] !== this.props.screenWidth) {

                const [leftBrushDate, rightBrushDate] = this.brushDates;

                this.brushSelection.call(this.brushX.move, [this.scale(leftBrushDate), this.scale(rightBrushDate)]);

            } else {

                this.brushSelection.call(this.brushX.clear, [0, this.props.screenWidth]);
            }

            this.socketMessageId = Math.floor(100000000 + (Math.random() * 900000000));

            this.setState({ sensorsLoaded: false });

            // this.callSocketData();
        }

        // check if data sensors was not loaded or minimap interval was changed
        if (!prevState.sensorsLoaded && !this.state.sensorsLoaded) {
            this.checkLoadActiveSensors();
        }

        // check if data sensors was loaded the call data by minimap
        if (!prevState.sensorsLoaded && this.state.sensorsLoaded) { 
           
            this.callSocketData();
        }

        if (this.props.sensorInTree !== prevProps.sensorInTree) {

            this.updateMiniatureCanvas();
        }
        if ((prevState.responseData !== this.state.responseData) || (prevProps.screenWidth !== this.props.screenWidth )) {
            this.drawMinimap().then(value => value);
        }
    }

    /**
     * Callback before the component will be removed from DOM
     */
    componentWillUnmount() {

        this.socket.disconnect();
    }

    /**
     * The brush reference
     */
    private brush: any;

    /**
     * The X scale
     */
    private readonly scale: d3.ScaleTime<number, number>;

    /**
     * The brush handles selection
     */
    private brushHandles: any;

    /**
     *  Brush init
     */
    private brushX: any;

    /**
     *  Brush selection
     */
    private brushSelection: any;

    /**
     * Brush coordinates in pixels
     */
    private brushCoords: number[];

    /**
     * Brush selected dates
     */
    private brushDates: Date[];

    /**
     * Flag to check if brush is moving
     */
    private brushIsMoving: boolean;

    /**
     * Socket provider instance
     *
     * @type {SocketProvider}
     */
    private socket: SocketProvider;

    /**
     * Socket Message Id
     * @type {number}
     * @private
     */
    private socketMessageId: number;

    /**
     * Range of Date
     *
     * @type {any[] | undefined}
     * @private
     */
    private rangeDate: any[] | undefined;

    /**
     * Canvas canvas reference
     *
     * @type {HTMLCanvasElement | undefined}
     * @private
     */
    private minimapRef: HTMLCanvasElement | undefined;

    /**
     * Miniatures canvas reference
     *
     * @type {CanvasRenderingContext2D | null | undefined}
     * @private
     */
    private miniatureRef: CanvasRenderingContext2D | null | undefined;

    /**
     * First init brash
     * @type {boolean}
     * @private
     */
    private firstInit = true;
    

    callSocketData() {

        const [from, to] = this.scale.range().map(this.scale.invert, this.scale);
        this.rangeDate = [from, to];

        // if (this.state.sensorsLoaded && !appConfig.hideMinimap) {
        if (!appConfig.hideMinimap) {

            this.socket.call('getMinimapData', {
                messageId: this.socketMessageId,
                numberOfDataPoints: this.props.screenWidth,
                from: from.toISOString(),
                to: to.toISOString(),
                units: [],
                access_token: localStorage.getItem('auth_token'),
                subscribe: this.props.dashboardOnline,
            }, this.processMinimapData.bind(this));
        }

    }

    /**
     * Check load data by active sensors
     */
     checkLoadActiveSensors() {

        const { allSensorData = [], sensorInTree = [], minimapRange } = this.props;

        const startDate = new Date(minimapRange.startDate), endDate = new Date(minimapRange.endDate);

        startDate.setSeconds(0); // clear seconds

        startDate.setMilliseconds(0); // clear milliseconds

        // check length of active sensors on dashboard and sensors with loaded data
        if (sensorInTree?.length > 0 && !this.state.sensorsLoaded && allSensorData?.length > 0) {

            const activeSensorIds = sensorInTree.map((item: any) => item.id);

            const receivedSensorIds: any[] = [];

            if (
                allSensorData?.length >= activeSensorIds.length 
                && receivedSensorIds.length !== activeSensorIds.length
            ) {

                for (const sensor of allSensorData) {

                    let sensorStartDate;

                    if (sensor.type === 'graph') {
                        const graphDate = new Date(sensor?.values[0]?.timestamp);

                        graphDate.setSeconds(0); // clear seconds
                        graphDate.setMilliseconds(0); // clear milliseconds

                        sensorStartDate = graphDate;

                    } else {
                        const stateDate = new Date(sensor?.states[0].startTime);

                        stateDate.setSeconds(0); // clear seconds
                        stateDate.setMilliseconds(0); // clear milliseconds

                        sensorStartDate = stateDate;
                    }

                    // check diff between sensor value start time and minimap interval
                    const diffTime = Math.abs((sensorStartDate.getTime() - startDate.getTime()) / 1000);

                    const duration = Math.abs(Math.round((endDate.getTime() - startDate.getTime()) / 1000));

                    const dayDurations = Math.round(duration / (3600 * 24));

                    if (sensor?.id && activeSensorIds.includes(sensor.id) && diffTime <= 90 * dayDurations) {

                        receivedSensorIds.push(sensor.id);
                    }
                }

                // check that 50% of active sensors receive data
                const sensorsLoaded = Math.round((receivedSensorIds.length / activeSensorIds.length) * 100) > 50;
    
                if (sensorsLoaded) {

                    this.setState({ sensorsLoaded });
                }
            }
        }
    }


    /**
     * Get socket ID
     *
     * @param {string} id
     */
    getSocketId(id: string): void {

        const { socketId } = this.state;

        if (socketId !== id) {

            this.setState({ socketId: id });

        }
    }

    /**
     * Customize brush handles
     */
    customizeBrushHandles() {

        const g = this.brushHandles = this.brushSelection.selectAll('.brush-handle')
            .data([{ type: 'w' }, { type: 'e' }])
            .enter()
            .append('g')
            .attr('fill', 'none')
            .attr('fill-rule', 'evenodd')
            .attr('cursor', 'ew-resize')
            .attr('class', 'brush-handle');

        g.append('path')
            .attr('fill', '#4092f5')
            .attr('d', 'M14 0h2v55h-2zM4 16h10v23H4a4 4 0 0 1-4-4V20a4 4 0 0 1 4-4z');

        g.append('path')
            .attr('fill', '#ffffff')
            .attr('d', 'M5 22h2v11H5zM9 22h2v11H9z')
            .attr('opacity', '.3');

        this.brushSelection.call(this.brushX.clear, [0, this.props.screenWidth]);
    }

    /**
     * Init brush
     */
    initBrush() {
        //init brush (Посмотреть)
        this.brushX = d3.brushX()
            .extent([[0, 0], [this.props.screenWidth, 55]])
            .on('brush end', this.brushed.bind(this));

        this.brushSelection = d3.select(this.brush)
            .call(this.brushX);
    }

    /**
     * Chart brush move event handler
     */
    brushed() {

        const selection = d3.event.selection || this.scale.range();

        if (!d3.event.selection && this.props.dashboardCurrentTime) {

            this.props.setDashboardOnline();

        } else if (this.props.dashboardCurrentTime && d3.event.selection) {

            this.props.setDashboardOffline();

            if (this.props.HMIIsEnable) {
                this.props.realTime(false);
                this.props.stopPlayer();
            }
        }

        this.brushHandles.attr('transform', (d: any, i: number) => {

            if (i > 0) {

                return `translate(${selection[i] + 16}, 0) scale(-1, 1)`;
            }

            return `translate(${selection[i] - 16}, 0)`;
        });

        const [start, end] = selection;

        if (start === 0 && end === this.props.screenWidth) {

            this.brushHandles.attr('style', 'display: none');
        } else {

            this.brushHandles.attr('style', 'display: auto');
        }

        this.brushCoords = [...selection];

        this.brushDates = selection.map(this.scale.invert, this.scale);

        // // const { minimapRange } = this.props;
        //
        // if (d3.event.selection && this.scale.range()) {
        //     // if (minimapRange.startDate !== this.brushDates[0] || minimapRange.endDate !== this.brushDates[1])
        //     this.props.brushed(this.brushDates, !this.brushIsMoving);
        //     this.firstInit = false;
        //
        // } else {
        //     if (!this.firstInit && !d3.event.selection) {
        //
        //         // if (minimapRange.startDate !== this.brushDates[0] || minimapRange.endDate !== this.brushDates[1])
        //         this.props.brushed(this.brushDates, !this.brushIsMoving);
        //         this.firstInit = true;
        //     }
        // }


        if (isMobile || isBrowser) {

            if (!this.brushIsMoving) {

                this.props.brushed(this.brushDates, !this.brushIsMoving);

            }
        } else {

            this.props.brushed(this.brushDates, !this.brushIsMoving);

        }
    }

    /**
     *  Update all axis and brushed range
     */
    updateMinimap() {

        const domain = this.generateScaleDates();

        this.scale.range([0, this.props.screenWidth]).domain(domain);
    }

    /**
     *  Logic output alert color.
     *
     * @param {{start_time: string, end_time: string}} interval
     * @param {{endTime: string, notificationId: number, startTime: string}[]} alerts
     * @return {boolean}
     */
    warningColor(
        interval: {
            start_time: string,
            end_time: string,
        },
        alerts: {
            endTime: string;
            notificationId: number
            startTime: string
        }[],
    ): any {

        return alerts && alerts.find(value => {

            if (new Date(interval.start_time).getTime() > new Date(value.startTime).getTime() &&
                new Date(interval.end_time).getTime() < new Date(value.endTime || new Date()).getTime()) {

                return value;
            }
        });
    }

    processMinimapData(response: { messageId: number, data: any }) {
        if (this.socketMessageId === response.messageId) {

            this.setState({ responseData: response.data || [] });
        }
    }

    /**
     * Receive data for Minimap and transform it into lines
     *
     */
    async drawMinimap() {
        const { responseData } = this.state;

        if (responseData) {

            const { minimapRange } = this.props;

            const minimapStart: Date = minimapRange.startDate;

            const timeInPx = Math.abs(new Date(minimapRange.endDate).getTime() - new Date(minimapStart).getTime()) / this.props.screenWidth;

            if (timeInPx > 0) {

                const graphs: IIntervals[] = [];

                for (const sensor of responseData) {

                    const alertSensor: {
                        endTime: string;
                        notificationId: number
                        startTime: string
                    }[] = sensor?.alerts;

                    const intervals: IIntervals = { sensorId: sensor.sensorId, intervals: [], intervalsAlerts: [] };

                    for (const interval of sensor.intervals) {

                        intervals.intervals.push({
                            x: Math.round(Math.abs(new Date(interval.start_time).getTime() - new Date(minimapStart).getTime()) / timeInPx),
                            width: Math.round(Math.abs(new Date(interval.end_time).getTime() - new Date(interval.start_time).getTime()) / timeInPx),
                            color: interval.color,
                            warning: alertSensor && alertSensor.length > 0 ? this.warningColor(interval, alertSensor) : false,
                        });
                    }

                    if (alertSensor && alertSensor.length > 0) {

                        for (const alert of alertSensor) {


                            const intervalsAlertPoint = Math.round(Math.abs(new Date(alert.startTime).getTime() - new Date(minimapStart).getTime()) / timeInPx);

                            if (intervals.intervalsAlerts.indexOf(intervalsAlertPoint) === -1) {

                                intervals.intervalsAlerts.push(intervalsAlertPoint);
                            }
                        }
                    }

                    graphs.push(intervals);
                }

                this.setState({
                    graphs: [...graphs],
                });

                this.updateMiniatureCanvas();
            }
        }
    }

    /**
     * Get top & bottom axis params
     *
     * @return {Object}
     */
    axisParam() {

        const smallScreen = window.innerWidth <= 960;

        switch (this.props.scaleDates) {

            case 1:

                return {
                    axisTop: {
                        tick: d3.timeDay.every(1),
                        timeFormat: d3.timeFormat('%d'),
                    },
                    axisBottom: {
                        tick: d3.timeHour.every(smallScreen ? 3 : 1),
                        timeFormat: d3.timeFormat('%H:%M'),
                    },
                };

            case 7:

                return {
                    axisTop: {
                        tick: d3.timeDay.every(1),
                        timeFormat: d3.timeFormat('%d'),
                    },
                    axisBottom: {
                        tick: smallScreen ? d3.timeDay.every(1) : d3.timeHour.every(9),
                        timeFormat: d3.timeFormat('%H:%M'),
                    },
                };

            case moment().daysInMonth():

                return {
                    axisTop: {
                        tick: d3.timeDay.every(moment().daysInMonth()),
                        timeFormat: d3.timeFormat('%B'),
                    },
                    axisBottom: {
                        tick: d3.timeDay.every(smallScreen ? 2 : 1),
                        timeFormat: d3.timeFormat('%d '),
                    },
                };

            case 365:

                return {
                    axisTop: {
                        tick: d3.timeYear.every(1),
                        timeFormat: d3.timeFormat('%Y'),
                    },
                    axisBottom: {
                        tick: d3.timeMonth.every(1),
                        timeFormat: d3.timeFormat('%B'),
                    },
                };

            default:

                return {
                    axisTop: {
                        tick: d3.timeDay.every(1),
                        timeFormat: d3.timeFormat('%d'),
                    },
                    axisBottom: {
                        tick: d3.timeHour.every(smallScreen ? 3 : 1),
                        timeFormat: d3.timeFormat('%H:%M'),
                    },
                };
        }
    }

    /**
     * Generate dates for the X-axis scale
     *
     * @return {Date[]}
     */
    generateScaleDates() {

        moment.locale('ru'); //TODO: Any reason why is this needed?

        if (this.props.minimapRange) {

            if (moment(this.props.minimapRange.endDate).add({ h: 23.98 }).isBefore(new Date())) {

                return [
                    new Date(this.props.minimapRange.startDate),
                    new Date(this.props.minimapRange.endDate),
                ];
            }

            return [
                new Date(this.props.minimapRange.startDate),
                new Date(this.props.minimapRange.endDate),
            ];
        }

        return [
            moment().subtract({ d: this.props.scaleDates ? this.props.scaleDates : 1 }).toDate(),
            new Date(),
        ];
    }

    /**
     * Get ticks for the axis
     *
     * @param params
     *
     * @return {Object[]}
     */
    getTicks(params: any) {

        const output = [];

        const ticks = this.scale.ticks(params.tick);

        for (let i = 0; i < ticks.length; i++) {

            const position = this.scale(ticks[i]);

            output.push({
                value: params.timeFormat(ticks[i]),
                position: position,
                width: i < ticks.length - 1 ? this.scale(ticks[i + 1]) - position : 'auto',
            });
        }

        return output;
    }

    /**
     * Get sensor id
     *
     * @param {ISensor} data
     *
     * @return {string}
     */
    getSensorId(data: ISensor): string {

        return `${data.controllerId}.${data.sensorId}`;
    }

    onPointerDown() {

        this.brushIsMoving = true;
    }

    onPointerUp() {

        this.brushIsMoving = false;
    }

    onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {

        event.stopPropagation();

        this.brushIsMoving = false;
    }

    onMouseLeave() {

        this.brushIsMoving = false;
    }

    /**
     * Position On Vertical Line
     *
     * @return {number}
     */
    positionOnVerticalLine(): number {

        const { sensorInTree } = this.props;

        return sensorInTree ? sensorInTree.length : 26;
    }

    /**
     * Update miniature on canvas.
     */
    updateMiniatureCanvas() {

        const { sensorInTree, screenWidth } = this.props,
            { graphs } = this.state;

        this.miniatureRef && this.miniatureRef.clearRect(0, 0, screenWidth, 55);

        for (const sensor of sensorInTree) {
            const index: number = sensorInTree.indexOf(sensor);

            this.miniMapMiniature(sensor, index, graphs);
        }

    }

    /**
     * Drawing thumbnails for alerts
     *
     * @param {any[]} line
     */
    miniMapAlertMiniature(line: any[] = []) {
        line.forEach((value) => {
            if (this.miniatureRef) {
                this.miniatureRef.beginPath();
                this.miniatureRef.strokeStyle = '#ff3b30';
                this.miniatureRef.rect(value, 0, 1, 55);
                this.miniatureRef.stroke();
            }
        });
    }

    /**
     * Make a given color opacity
     *
     *
     * @return {string}
     *
     * @param hex
     */
    hexToRgbA(hex: string): string {
        let c: any;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.5)';
        }
        return hex;
    }

    /**
     * Drawing thumbnails for minimap
     *
     * @param {ISensor} sensor
     * @param {number} index
     * @param {any[]} graphs
     */
    miniMapMiniature(sensor: ISensor, index: number, graphs: IIntervals[]) {

        const currentGraphData: IIntervals | undefined = graphs.find((value: IIntervals) => value.sensorId === this.getSensorId(sensor));

        if (this.miniatureRef && currentGraphData) {

            if (index <= 26) {

                for (const line of currentGraphData.intervals) {

                    const fillStyle = line.warning ? '#ff3b30' :
                        sensor.graphPreferences && sensor.graphPreferences[0].color ?
                            sensor.graphPreferences[0].color : line.color ? line.color : '#e0f1df';

                    if (this.miniatureRef) {
                        
                        this.miniatureRef.moveTo(line.x, (index * 2) + index);
                        this.miniatureRef.fillStyle = this.hexToRgbA(fillStyle);

                        this.miniatureRef.fillRect(line.x, (index * 2) + index, line.width > 1 ? line.width : 1, 2);
                    }

                }
            }

            if (currentGraphData.intervalsAlerts.length > 0) {

                this.miniMapAlertMiniature(currentGraphData.intervalsAlerts);
            }
        }
    }

    /**
     * Handle canvas ref creation
     *
     * @param {Object} ref
     */
    handlerRef(ref: HTMLCanvasElement | null) {

        if (ref) {

            this.minimapRef = ref;
            const { screenWidth } = this.props;

            ref.width = screenWidth;
            ref.height = 55;

            this.miniatureRef = ref.getContext('2d');
        }
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { minimapVisible = true, screenWidth, rbac } = this.props,
            style = {
                display: minimapVisible ? 'flex' : 'none',
            };
        if (screenWidth <= 0) {
            return null;
        }
        const axisParams = this.axisParam();

        return (
            <div className="mini-map"
                 style={style}
                 onPointerDown={this.onPointerDown}
                 onPointerUp={this.onPointerUp}
                 onClick={this.onClick}
                 onMouseLeave={this.onMouseLeave}
            >
                <svg className="brush" width={screenWidth < 0 ? 0 : screenWidth}>
                    <g ref={brush => this.brush = brush} />
                </svg>
                <canvas
                    ref={this.handlerRef}
                    className="miniatures"
                    height={55}
                    width={screenWidth}
                />
                {
                    modules.map((module) => {

                        if (rbac.can('hr:monitoring-tree:manage')) {
                            return module.getMinimap({
                                rangeDate: this.rangeDate,
                                numberOfDataPoints: this.props.screenWidth,
                                positionOnVerticalLine: this.positionOnVerticalLine(),
                            });
                        }
                    })
                }
                <div className="axis top">
                    {this.getTicks(axisParams.axisTop).map((tick, index) => (
                        <div key={index}>
                            <div className="tick" style={{ left: tick.position, width: tick.width }} title={tick.value}>
                                <p>{tick.value}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="axis bottom">
                    {this.getTicks(axisParams.axisBottom).map((tick, index) => (
                        <div key={index}>
                            <div className="tick" style={{ left: tick.position, width: tick.width }} title={tick.value}>
                                <p>{tick.value}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const {
        graphMinimapVisibility,
        graphPeriod,
        dashboard,
    } = state;

    const allSensorData = selectAllSensorDataForHistogramRange(state);
    
    const allSensorInTree = getAllSensor(state);

    const schema = selectHmiPlayerSchema(state);
    const isVisible = selectHmiPlayerVisibility(state) && schema !== null;
    const drawWidth = selectDrawerWidthWithDrawRules(state);

    const sensorInTree = selectActiveSensorInTree(state),
        rbac = selectRBAC(state),
        minimapRange= selectGraphPeriodRange(state),
        visibleSideBar = selectVisibleSideBar(state),
        maxWidthSideBar = selectMaxWidthSideBar(state),
        selection = selectBrushSelection(state),
        dashboardOnline = selectDashboardOnline(state),
        anchor: 'right' | 'bottom' = selectPositionDrawer(state) as 'right' | 'bottom',
        screenWidthOrigin = selectScreenWidth(state),
        realTimeIndentation = calcRealTimeIndentation(state),
        screenWidth = screenWidthOrigin - (isVisible && anchor === 'right' ? drawWidth : 0) - realTimeIndentation - appConfig.correctionFactorForDrawingTheTimeline,
        monitoringTree = selectMonitoringTree(state),
        appSetting = selectAppSettings(state);

    return {
        minimapVisible: graphMinimapVisibility.visible,
        minimapRange,
        currentPeriod: graphPeriod.currentPeriod,
        currentPeriodRefresh: graphPeriod.currentPeriodRefresh,
        scaleDates: graphPeriod.scaleDates,
        visibleSideBar,
        maxWidthSideBar,
        dashboardOnline,
        dashboardCurrentTime: dashboard.currentTime,
        screenWidth: screenWidth > 0 ? screenWidth : 1,
        selection,
        monitoringTree,
        rbac,
        sensorInTree,
        HMIIsEnable: appSetting.hmi.isEnabled,
        allSensorData,
        allSensorInTree,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    brushed: GraphActions.minimapBrushed,
    setDashboardOnline: DashboardActions.setOnline,
    setDashboardOffline: DashboardActions.setOffline,
    realTime: HmiPlayerActions.setRealTime,
    stopPlayer: HmiPlayerActions.stop,
});

export default connect(mapStateToProps, mapDispatchToProps)(MiniMap);
