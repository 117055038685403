import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { TextInput, Select, Button, DeleteDialog, Tooltip } from '../../../core/ui/components';
import { IAlertRule, ICause, IOptions, ISensor } from '../../../core/interfaces';
import ListMultiple from './ListMultiple';
import { selectAllTargetValue } from '../../../core/selectors/product/allTargetValueSelector';
import { useStore } from 'react-redux';

const messageParams: any[] = [];

interface IRuleCauseContexts {
    id: number;
    cause: ICause;
}
/**
 * Alert form rule component
 *
 * @param {function} push
 * @param {function} remove
 * @param {object} form
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const AlertFormRule: React.FC<any> = (
    {
        push,
        remove,
        form,
    }: any,
) => {

    const { t } = useTranslation();

    const store = useStore();

    const allSensor = selectAllTargetValue(store.getState());

    const [deleteRuleIndex, setDeleteRuleIndex] = useState<number | null>(null);

    const { rules, productName } = form.values;

    const { touched, errors } = form;

    const operands = [
        { value: '=', label: '=' },
        { value: '!=', label: '≠' },
        { value: '>', label: '>' },
        { value: '<', label: '<' },
        { value: '>=', label: '≥' },
        { value: '<=', label: '≤' },
    ];

    const emailList = [
        { value: 'email', label: t('E-MAIL') },
    ];

    if (!rules || rules.length !== messageParams.length) {

        messageParams.splice(0, messageParams.length);
    }

    const getSensorOrigin=(index: number): ISensor | undefined=> {

        return form.values.sensorOrigin.find((s: any) => s.id === index);
    };

    /**
     * Generate email message from rule params
     *
     * @param {number} index
     * @param {string} field
     * @param {mixed} value
     */
    const generateMessage = useCallback((index: number, field: string, value: any) => {

        if (!messageParams[index]) {

            messageParams[index] = {
                operand: rules[index]?.operand || '<',
                time: rules[index]?.time || '60',
            };
        }

        messageParams[index][field] = value;

        if (!rules[index].time && rules[index].send_notifications) {

            messageParams[index]['time'] = 10;
        }

        if (field==='send_notifications' && !rules[index].subject) {

            if (messageParams[index]['sensor']) {
                updateElementChain(index);
            } else {
                form.setFieldValue(`rules.${index}.subject`, productName);
            }
        }

        if (field === 'sensor' && messageParams[index]['sensor']) {
            updateElementChain(index);
        }

        if ((messageParams[index].sensor || rules[index].sensor) && (messageParams[index].value || rules[index].value) && form.values.sensors.length > 0) {
        
            const sensor = form.values.sensors.find((s: any) => s.value === (messageParams[index].sensor || rules[index].sensor)),
                operand = operands.find(o => o.value === messageParams[index].operand);

            const newValueForMessage = messageParams[index].value ?
                messageParams[index].value.replace(/[^\d.-]/g, '')
                :
                rules[index].value && !messageParams[index].value ?
                    parseInt(rules[index].value)
                    : '';

            const sensorLabel = sensor ? sensor.label : '',
                operandLabel = operand ? operand.label.trim() : '';
            const textUm = getSensorOrigin(sensor.value)?.um;

            const message = `${productName}
${t('DEFAULT_MESSAGE_COMMENT_IN_ALERT_ONE', {
    sensorLabel: sensorLabel.trim(),
})} ${operandLabel} ${t('DEFAULT_MESSAGE_COMMENT_IN_ALERT_TWO', {
                value: `${newValueForMessage} ${textUm? `(${textUm})`: ''}`,
                time: messageParams[index].time || 10,
            })}`;

            form.setFieldValue(`rules.${index}.message`, message);
        }

    }, [form, operands, productName, form.values.sensors]);

    /**
     * Hide delete confirmation dialog
     */
    const onDeleteDialogClose = useCallback(() => {

        setDeleteRuleIndex(null);
    }, [setDeleteRuleIndex]);

    /**
     * Remove rule after confirmation
     *
     * @param {number} index
     */
    const removeRule = useCallback((index: number) => {

        remove(index);

        onDeleteDialogClose();
    }, [onDeleteDialogClose, remove]);


    /**
     *
     * @param {IRuleCauseContexts[]} args
     * @return {{id: number, name: string}[]}
     */
    const ruleCauseSelect = useCallback((args: IRuleCauseContexts[]): { id: number; name: string; }[] => {

        const ruleCauseArr: { id: number; name: string; }[] = [];
        if (args) args.forEach(value => {

            if (value.cause) ruleCauseArr.push(value.cause as any);
        });

        return ruleCauseArr;
    }, []);

    const validatedClass = useCallback((name: string, index: number): string => {

        const validateClassString = touched.rules && touched.rules[index] && touched.rules[index][name] ?
            errors.rules && errors.rules[index] && errors.rules[index][name] ?
                'error-field' : 'success-field' : '';

        return `form-field ${validateClassString}`;
    }, [touched, errors]);

    const validateSensorOutput = (rule:IAlertRule): { validationRule: boolean,
        disabledValue?: IOptions, }=>{

        const validationRule = form.values.sensors && form.values.sensors.some((sensor: Record<string, unknown>) => sensor.value === rule.sensor);

        if (!validationRule) {

            if (allSensor) {
                const existingSensor = allSensor.findIndex((sensor: ISensor)=> sensor.id === parseInt(rule.sensor));

                const disabledValue = {
                    label: allSensor[existingSensor]?.name || '',
                    value: allSensor[existingSensor]?.id || 'empty',
                };

                return {
                    validationRule: validationRule,
                    disabledValue: disabledValue,
                };
            }
        }
        return {
            validationRule: validationRule,
        };
    };

    const updateElementChain = (index: number) => {
        const [unit, product] = productName.split(' - ');

        const sensor = form.values.sensors.find((s: any) => s.value === (messageParams[index].sensor || rules[index].sensor)); 
        
        const elementChain = `${unit} - ${sensor.label} - ${product}`;

        form.setFieldValue(`rules.${index}.subject`, elementChain);

        form.setFieldValue(`rules.${index}.message`, elementChain);
    } 

    const umRenderFunction = (index: number): React.ReactNode => {

        if (!getSensorOrigin(index)?.um) {

            return null;
        }

        const umText = getSensorOrigin(index)?.um || '';

        return (
            <Tooltip
                disableTouchListener
                title={umText}
            >
                <span className="inscription-um-wrapper">(<div className="inscription-um" >{umText}</div>)
                </span>
            </Tooltip>
        );
    };

    const filedSensorStyle: React.CSSProperties = {
        maxWidth: 'calc(100% + 40px)',
        width: 'calc(100% + 40px)',
        marginRight: 5,
    };

    return (
        <div className="row-wide">
            {rules && rules.map((rule: IAlertRule, index: number) => (
                <React.Fragment key={index}>
                    <Grid container spacing={2}>
                        <Grid item className={'field-remove'}>
                            <IconButton className={'red-btn'} onClick={() => setDeleteRuleIndex(index)}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                        <Grid item className={'field-content'}>
                            <Grid container  spacing={2} style={filedSensorStyle}>
                                <Grid item className={'field-sensor'}>
                                    <Select
                                        className={validatedClass('sensor', index)}
                                        options={validateSensorOutput(rule).disabledValue ?
                                            [...form.values.sensors, validateSensorOutput(rule).disabledValue] :
                                            form.values.sensors}
                                        name={`rules.${index}.sensor`}
                                        disabledselectoption={validateSensorOutput(rule)?.disabledValue}
                                        value={rule.sensor ? rule.sensor : ''}
                                        placeholder={t('PARAMETER_TO_WATCH')}
                                        onChange={(e) => {

                                            form.handleChange(e);
                                            generateMessage(index, 'sensor', e.target.value);
                                        }}
                                    >
                                        {(touched.rules && touched.rules[index] && touched.rules[index].sensor &&
                                            errors.rules && errors.rules[index] && errors.rules[index].sensor) &&
                                        <div className="validation-massage">{errors.rules[index].sensor}</div>
                                        }
                                    </Select>
                                </Grid>
                                <Grid item className="form-fields-inline">
                                    {t('IS')}
                                </Grid>
                                <Grid item className={'field-oparands'}>
                                    <Select
                                        className={validatedClass('operand', index)}
                                        options={operands}
                                        name={`rules.${index}.operand`}
                                        value={rule.operand}
                                        onChange={(e) => {

                                            form.handleChange(e);

                                            generateMessage(index, 'operand', e.target.value);

                                        }}
                                    >
                                        {(touched.rules && touched.rules[index] && touched.rules[index].operand &&
                                            errors.rules && errors.rules[index] && errors.rules[index].operand) &&
                                        <div className="validation-massage">{errors.rules[index].operand}</div>
                                        }
                                    </Select>
                                </Grid>
                                <Grid item className="field-value field-value-with-um"  >
                                    <TextInput
                                        className={validatedClass('value', index)}
                                        name={`rules.${index}.value`}
                                        type="text"
                                        placeholder={t('VALUE')}
                                        value={
                                            rule.value
                                        }
                                        onChange={(e) => {

                                            form.handleChange(e);

                                            generateMessage(index, 'value', e.target.value);

                                        }}
                                        onBlur={form.handleBlur}
                                        inputProps={{ maxLength: 20 }}
                                    >

                                        {(touched.rules && touched.rules[index] && touched.rules[index].value &&
                                            errors.rules && errors.rules[index] && errors.rules[index].value) &&
                                        <div className="validation-massage">{errors.rules[index].value}</div>
                                        }
                                    </TextInput>
                                    {rule.sensor ? umRenderFunction(parseInt(rule.sensor)) : null}
                                </Grid>
                                <Grid item className="form-fields-inline">
                                    {t('FOR')}
                                </Grid>
                                <Grid item className={'field-time'}>
                                    <TextInput
                                        className={validatedClass('time', index)}
                                        name={`rules.${index}.time`}
                                        type="text"
                                        placeholder={t('TIME')}
                                        value={rule.time}
                                        onChange={(e) => {

                                            form.handleChange(e);

                                            generateMessage(index, 'time', e.target.value);

                                        }}
                                        onBlur={form.handleBlur}
                                        inputProps={{ maxLength: 20 }}
                                    >
                                        {(touched.rules && touched.rules[index] && touched.rules[index].time &&
                                            errors.rules && errors.rules[index] && errors.rules[index].time) &&
                                        <div className="validation-massage">{errors.rules[index].time}</div>
                                        }
                                    </TextInput>
                                </Grid>
                                <Grid item className="form-fields-inline">
                                    {t('SEC')}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item className="form-fields-inline left">
                                    {t('FOR_STATUS')}
                                </Grid>
                                <Grid item className={'filed-status'}>
                                    <div
                                        className={validatedClass('ruleCauseContexts', index)}
                                    >
                                        <ListMultiple
                                            selected={ruleCauseSelect(rule.ruleCauseContexts as unknown as IRuleCauseContexts[])}
                                            name={`$rules.${[index]}.ruleCauseContexts`}
                                            onChange={selected => {
                                                rule.ruleCauseContexts = selected as any;
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className={'field-comment'}>
                                    <TextInput
                                        className={validatedClass('comment', index)}
                                        name={`rules.${index}.comment`}
                                        type="text"
                                        placeholder={t('COMMENT') + '...'}
                                        value={rule.comment}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                        multiline
                                        rows="4"
                                        inputProps={{ maxLength: 600 }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <FormControlLabel
                                        className={'field-checkbox'}
                                        control={
                                            <Checkbox
                                                name={`rules.${index}.send_notifications`}
                                                checked={rule.send_notifications}
                                                onChange={(e) => {

                                                    form.handleChange(e);

                                                    generateMessage(index, 'send_notifications', e.target.value);

                                                }}
                                                value={rule.send_notifications}
                                                color="primary"
                                                icon={<span className="checkbox-icon" />}
                                            />
                                        }
                                        label={t('SEND_NOTIFICATIONS_VIA')}
                                    />
                                </Grid>
                                <Grid item className={'field-email'}>
                                    <Select
                                        className="form-field"
                                        options={emailList}
                                        value={'email'}
                                        disabled={!rule.send_notifications}
                                    />
                                </Grid>
                            </Grid>
                            {
                                rule.send_notifications && (
                                    <React.Fragment>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} className={'field-comment'}>
                                                <TextInput
                                                    className={validatedClass('address', index)}
                                                    name={`rules.${index}.address`}
                                                    type="text"
                                                    value={rule.address}
                                                    onChange={form.handleChange}
                                                    onBlur={form.handleBlur}
                                                    label={t('ADDRESS')}
                                                    placeholder={t('E-MAIL')}
                                                >
                                                    {(touched.rules && touched.rules[index] && touched.rules[index].address &&
                                                        errors.rules && errors.rules[index] && errors.rules[index].address) &&
                                                    <div
                                                        className="validation-massage"
                                                    >{errors.rules[index].address}
                                                    </div>
                                                    }
                                                </TextInput>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} className={'field-comment'}>
                                                <TextInput
                                                    className={validatedClass('subject', index)}
                                                    name={`rules.${index}.subject`}
                                                    type="text"
                                                    value={rule.subject}
                                                    onChange={form.handleChange}
                                                    onBlur={form.handleBlur}
                                                    label={t('SUBJECT')}
                                                    placeholder={productName || t('SUBJECT')}
                                                    inputProps={{ maxLength: 60 }}
                                                >
                                                    {(touched.rules && touched.rules[index] && touched.rules[index].subject &&
                                                        errors.rules && errors.rules[index] && errors.rules[index].subject) &&
                                                    <div
                                                        className="validation-massage"
                                                    >{errors.rules[index].subject}
                                                    </div>
                                                    }
                                                </TextInput>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} className={'field-comment'}>
                                                <TextInput
                                                    className={validatedClass('message', index)}
                                                    name={`rules.${index}.message`}
                                                    type="text"
                                                    value={rule.message}
                                                    onChange={form.handleChange}
                                                    onBlur={form.handleBlur}
                                                    label={t('MESSAGE')}
                                                    multiline
                                                    rows="4"
                                                    inputProps={{ maxLength: 600 }}
                                                >
                                                    {(touched.rules && touched.rules[index] && touched.rules[index].message &&
                                                        errors.rules && errors.rules[index] && errors.rules[index].message) &&
                                                        <div
                                                            className="validation-massage"
                                                        >
                                                            {errors.rules[index].message}
                                                        </div>
                                                    }
                                                </TextInput>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                        </Grid>
                    </Grid>
                    <hr />
                </React.Fragment>
            ))
            }
            <Grid container className="btn-add-holder">
                <Button
                    type="button"
                    color="secondary"
                    variant="outlined"
                    onClick={() => push({
                        sensor: '',
                        operand: '<',
                        value: '',
                        time: '60',
                        ruleCauseContexts: '',
                        comment: '',
                        send_notifications: false,
                        address: '',
                        subject: '',
                        message: productName,
                        createdAt: new Date(),
                    })}
                >
                    {t('ADD_RULE')}
                </Button>
            </Grid>

            <DeleteDialog
                open={deleteRuleIndex !== null}
                removeId={deleteRuleIndex}
                heading={t('REMOVE_THE_RULE')}
                body={t('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_RULE')}
                onAccept={removeRule}
                onClose={onDeleteDialogClose}
            />
        </div>
    );
};

export default React.memo(AlertFormRule);
