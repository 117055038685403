import { IHMIDashboardDataAction, IHmiDashboardState, IHMIStateAction } from '../../interfaces';
import { dashboardConstants } from '../../constants';

/**
 * Process reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function hmiDashboardDataState(state: IHmiDashboardState = {
    errors: {},
    sensorData: [],
    sensorDataRange: [],
}, action: IHMIDashboardDataAction): IHmiDashboardState {

    switch (action.type) {

        case dashboardConstants.RECEIVE_SENSORS_NARROW_DATA:

            return {
                ...state,
                sensorData: action.sensorsData,
            };

        case dashboardConstants.RECEIVE_SENSORS_NARROW_DATA_RANGE:

            return {
                ...state,
                sensorDataRange: action.sensorsDataRange,
            };

        default:

            return state;
    }

}