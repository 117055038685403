import React, { ChangeEvent, Component } from 'react';
import { Button, ConfirmDialog, DeleteDialog, ScrollContainer } from '../../../core/ui/components';
import { DraggableList } from '../../../core/ui/components';
import { withTranslation, WithTranslation } from 'react-i18next';

import './Product.scss';
import { ProductActions, ProductCategoryActions } from '../../../core/actions';
import { connect } from 'react-redux';
import {
    IProduct,
    IProductCategory,
    IProductsSPProps, IProductSettingsProps,
} from '../../../core/interfaces';
import { DraggableLocation } from 'react-beautiful-dnd';
import { isMobile, withOrientationChange } from 'react-device-detect';
import { RootState } from '../../../core/store';

/**
 *  interface for dragged component
 */
interface Item {
    id?: number;
    name: string;
    color?: string;
}

interface IMove {
    source: Item[];
    destination: Item[];
    droppableSource: DraggableLocation;
    droppableDestination: DraggableLocation;
    sourceIndex: string | number;
    Destination: string | number;
    destinationCategory: any[];
    moveCause: any;
}
interface IReorder {
    draggable: Item[];
    draggableUnsorted: Item[];
    droppable:Item[];
    endIndex: number;
    index: number;
    moved: IProduct;
}

/**
 *  List Products component
 *
 *  @class Products
 */
class Products extends Component<IProductsSPProps & WithTranslation, IProductSettingsProps> {

    constructor(props: IProductsSPProps & WithTranslation) {

        super(props);

        this.addItem = this.addItem.bind(this);

        this.addCategory = this.addCategory.bind(this);

        this.onDragEndStates = this.onDragEndStates.bind(this);

        this.handleCancel = this.handleCancel.bind(this);

        this.onEditAction = this.onEditAction.bind(this);

        this.handleCancelEditAction = this.handleCancelEditAction.bind(this);

        this.removeItem = this.removeItem.bind(this);

        this.onDeleteItemDialogClose = this.onDeleteItemDialogClose.bind(this);

        this.onConfirmCreateProduct = this.onConfirmCreateProduct.bind(this);

        this.onConfirmCreateCategory = this.onConfirmCreateCategory.bind(this);

        this.nonCategoryFilter = this.nonCategoryFilter.bind(this);

        this.isLandscape = this.isLandscape.bind(this);



    }
    /**
     *  Default state component
     */
    readonly state: IProductSettingsProps = {
        addInput: false,
        addCategory: false,
        option: {
            addEvent: {
                inputHandle: this.handleItem.bind(this),
                confirmCreate: this.confirmCreateProduct.bind(this),
            },
            addCategoryEvent: {
                inputHandle: this.handleCategory.bind(this),
                confirmCreate: this.confirmCreateCategory.bind(this),
            },
            editItem: {
                category: null,
                product: null,
                unsorted: null,
                editAction: this.editAction.bind(this),
            },
            search: {
                value: '',
                handleSearch: this.handleSearch.bind(this),
            },
            menu: [
                {
                    title: this.props.t('EDIT'),
                    action: this.editItem.bind(this),
                    color: '',
                },
                {
                    title: this.props.t('DELETE'),
                    action: this.removeConfirmation.bind(this),
                    color: 'red',
                },
            ],
            errorStatus: false,
            matchNameCategory: [],
            matchNameProduct: [],
        },
        newProduct: '',
        typeItem: null,
        confirm: false,
        confirmDelete: false,
        confirmName: null,
        removeItem: null,
    };

    componentDidMount(): void {

        this.dataLoad();

    }

    componentDidUpdate(prevProps: Readonly<IProductsSPProps & WithTranslation>) {

        if (this.props.refresh && prevProps.refresh && this.props.refresh !== prevProps.refresh) {

            this.dataLoad();
        }
    }

    /**
     *
     */
    dataLoad() {

        this.props.loadCategoryProduct();
        this.props.loadProduct('', { column: 'id', dir: 'asc' }, { table: ['category'] }, { field: ['category||$isnull'] });
    }

    /**
     * Handler 'add Item'. Change visibility 'add input'
     */
    addItem() {

        this.setState({
            addInput: true,
            addCategory: false,
            newProduct: '',
            typeItem: null,
            removeItem: null,
            confirmName: null,
        });

        this.handleCancelEditAction();
    }

    /**
     * Handler 'add Category'. Change visibility 'add input'
     */
    addCategory() {

        this.setState({
            addCategory: true,
            addInput: false,
            newProduct: '',
            typeItem: null,
            removeItem: null,
            confirmName: null,
        });

        this.handleCancelEditAction();
    }

    /**
     *  Edit current category
     *
     * @param {Object} dataItem
     * @param {boolean} status
     * @param {string} nodeName
     */
    editItem(dataItem: Record<string, any>, status: boolean, nodeName: 'category' | 'unsorted' | 'list') {

        // Update request
        const { option } = this.state;

        if (nodeName === 'category') {
            option.editItem.category = { ...dataItem as IProductCategory };
            option.editItem.unsorted = null;
            option.editItem.product = null;
        }
        if (nodeName === 'unsorted') {
            option.editItem.category = null;
            option.editItem.unsorted = { ...dataItem as IProduct };
            option.editItem.product = null;
        }
        if (nodeName === 'list') {
            option.editItem.category = null;
            option.editItem.unsorted = null;
            option.editItem.product = { ...dataItem as IProduct };
        }
        option.errorStatus = false;

        this.setState({ option: option, addInput: false, addCategory: false });
    }

    /**
     * Edit action handler
     *
     * @param event
     * @param status
     * @param nodeName
     */
    editAction(event: ChangeEvent<HTMLInputElement>, status: boolean, nodeName: 'category'| 'unsorted' | 'product'| 'clean') {

        const { typeItem, option } = this.state;
        let newTypeItem = typeItem;
        if (!status && event && nodeName !== 'clean') {

            option.editItem[nodeName]!.name = event.target.value;
            option.errorStatus = event.target.value.trim().length <= 0;
            newTypeItem = nodeName;

        }
        if (nodeName === 'clean') {

            this.handleCancelEditAction();
        }


        this.setState({ typeItem: newTypeItem, option: option, confirm: (status && event.target.value.trim().length > 0) });

    }

    /**
     *  Action after confirming changes
     */
    onEditAction() {

        const { typeItem, option } = this.state;

        if (typeItem === 'category' && option.editItem.category && option.editItem.category.name.trim().length > 0) {

            option.editItem.category.name = option.editItem.category.name.trim();

            this.props.updateCategoryProduct(option.editItem.category);

        }

        if (typeItem === 'product' && option.editItem.product && option.editItem.product.name.trim().length > 0) {

            option.editItem.product.name = option.editItem.product.name.trim();

            this.props.updateProduct(option.editItem.product);

        }

        if (typeItem === 'unsorted' && option.editItem.unsorted && option.editItem.unsorted.name.trim().length > 0) {

            option.editItem.unsorted.name = option.editItem.unsorted.name.trim();

            this.props.updateProduct(option.editItem.unsorted);

        }

    }

    /**
     * Action after cancel confirming changes
     */
    handleCancelEditAction() {
        const { option } = this.state;
        option.editItem.category = null;
        option.editItem.unsorted = null;
        option.editItem.product = null;
        option.errorStatus = false;

        this.setState({
            confirm: false,
            typeItem: null,
            removeItem: null,
            confirmName: null,
            option: { ...option },
        });
    }

    /**
     *  Remove current category
     *
     * @param dataItem
     * @param status
     * @param nodeName
     */
    removeConfirmation(dataItem: { id: number, name: string }, status: boolean, nodeName: string) {

        this.setState({
            typeItem: nodeName,
            removeItem: dataItem,
        });

    }

    /**
     * action fot remove item
     */
    removeItem() {
        const { typeItem, removeItem } = this.state;

        if ((typeItem === 'unsorted' || typeItem === 'list') && removeItem) {

            this.props.removeProduct(removeItem.id);

        }

        if (typeItem === 'category' && removeItem) {

            this.props.removeCategoryProduct(removeItem.id);
        }

        this.setState({
            removeItem: null,
            typeItem: null,
        });

    }

    /**
     * Action after cancel deleting
     */
    onDeleteItemDialogClose() {

        this.setState({ removeItem: null, typeItem: null });

    }

    /**
     *  Handler 'add item'
     *
     * @param { ChangeEvent<HTMLInputElement>,} event - change event element
     */
    handleItem(event: ChangeEvent<HTMLInputElement>) {

        this.setState({
            newProduct: event.target.value.trim(),
            option: {
                ...this.state.option,
                errorStatus: (event.target.value.trim().length <= 0 || this.matchCheckingProduct(event.target.value.trim())),
            },
        });

    }

    /**
     *  Handler 'add item'
     *
     * @param { ChangeEvent<HTMLInputElement>,} event - change event element
     */
    handleCategory(event: ChangeEvent<HTMLInputElement>) {

        this.setState({
            newProduct: event.target.value.trim(),
            option: {
                ...this.state.option,
                errorStatus: event.target.value.trim().length <= 0 || this.matchCheckingCategory(event.target.value.trim()),
            },
        });

    }

    /**
     *  press the ENTER key after entering the name of the element
     */
    confirmCreateProduct() {

        if (this.state.newProduct.length > 0 && !this.state.option.errorStatus) {

            this.setState({ confirm: true, confirmName: 'item' });
        }
    }

    /**
     *  press the ENTER key after entering the name of the element
     */
    onConfirmCreateProduct() {

        if (this.state.newProduct) {

            const { newProduct } = this.state;

            this.props.storeProduct({
                name: newProduct,
            });

        } else {

            this.setState({ addInput: !this.state.addInput });

        }

    }

    /**
     *  press the ENTER key after entering the name of the element
     */
    confirmCreateCategory() {

        if (this.state.newProduct.length > 0 && !this.state.option.errorStatus) {

            this.setState({ confirm: true, confirmName: 'category' });

        }
    }

    /**
     *  press the ENTER key after entering the name of the element
     */
    onConfirmCreateCategory() {

        if (this.state.newProduct && !this.state.option.errorStatus) {

            const { newProduct } = this.state;

            this.props.storeCategoryProduct({ name: newProduct });

        } else {

            this.setState({ addCategory: !this.state.addCategory });

        }

    }

    /**
     *  Handler for the search
     *
     * @param {ChangeEvent<HTMLInputElement>} event
     */
    handleSearch(event: ChangeEvent<HTMLInputElement>) {

        const { option } = this.state;

        option.search.value = event.target.value;

        this.setState({ option: option });

        if (event.target.value.length === 0) {

            this.dataLoad();
        }

    }

    /**
     *  Sort product by orderInCategory
     *
     * @param {IProduct[]} value
     * @return {IProduct[]}
     */
    sortOrderInCategory(value: IProduct[]) {

         return value.sort((a, b) => {

            if (a.position === undefined) {

                a.position = 0;
            }

            if (b.position === undefined) {

                b.position = 0;
            }

            return a.position < b.position ? -1 : 1;
        });

    }

    /**
     *  Filter data for Item
     */
    filterFunctionCategory(data: IProductCategory[]) {

            return data.map(value => {

                if (value.products) {

                    const sorted = this.sortOrderInCategory(value.products);

                    if (this.state.option.search.value.length > 0) {

                        value.products = sorted.filter((item: { name: string }) => item.name.toLowerCase().match(this.state.option.search.value.toLowerCase()));

                        return value;
                    }

                    value.products = sorted;

                }

                return value;

            });

    }

    /**
     *  Filter data for Item
     */
    filterFunctionProduct(data: IProduct[]) {

        const { option } = this.state;

        if (option.search.value.length > 0) {

            return this.sortOrderInCategory(data).filter(value => value.name.toLowerCase().includes(option.search.value.toLowerCase()));

        } else {

            return this.sortOrderInCategory(data);

        }
    }

    matchCheckingProduct(name: string) {

        const { products, category } = this.props;

        const matchNameProduct = products.filter(value => value.name.trim().toLowerCase() === name.trim().toLowerCase());

        category.forEach(value => {

            if (value.products?.filter((item: { name: string }) => item.name.trim().toLowerCase() === name.trim().toLowerCase())[0]) {

                matchNameProduct.push(value.products?.filter((item: { name: string }) => item.name.trim().toLowerCase() === name.trim().toLowerCase())[0]);
            }
        });

        return matchNameProduct.length !== 0;

    }

    matchCheckingCategory(name: string) {

        const { category } = this.props;

        const matchNameCategory = category.filter(value => value.name.toLowerCase() === name.toLowerCase());

        return matchNameCategory.length !== 0;
    }

    /**
     * State item form cancel handler
     */
    handleCancel() {

        this.setState({
            confirm: false,
            newProduct: '',
            addCategory: false,
            addInput: false,
            typeItem: null,
            removeItem: null,
            confirmName: null,
            option: { ...this.state.option, errorStatus: false },
        });
    }

    /**
     * 'Drag end' handler
     *
     * @param { Object } state
     * @param { string } action
     */
    onDragEndStates(state: IMove | IReorder, action: string) {


        if (action === 'move') {
            const move = state as IMove;
            if (move.destinationCategory.length > 0) {

                this.props.updateProduct({
                    id: move.moveCause.id,
                    name: move.moveCause.name,
                    category: move.destinationCategory[0].id,
                    position: move.droppableDestination.index,
                });

            } else {

                this.props.updateProduct({
                    id: move.moveCause.id,
                    name: move.moveCause.name,
                    category: null,
                    position: move.droppableDestination.index,
                });

            }

        }
        if (action === 'reorder') {

            const move = state as IReorder;

            this.props.updateProduct({
                id: move.moved.id,
                name: move.moved.name,
                position: move.endIndex,
                category: move.index.toString() !== 'unsorted'  ? move.index: null,
            });
        }

    }

    /**
     * Chek landscape.
     *
     * @return {boolean}
     */
    isLandscape(){

        return (window.orientation === 90 || window.orientation === -90);
    }

    /**
     * Filter products without category
     *
     * @param {IProduct[]} products
     *
     * @return {IProduct[]}
     */
    nonCategoryFilter(products: IProduct[]): IProduct[] {

        return products.filter(product => !product.category);
    }

    /**
     * Component render
     *
     * @returns {JSX.Element}
     */
    render() {

        const { t, products, category } = this.props;

        return (
            <React.Fragment>
                <div className="product-page">
                    <ScrollContainer
                        maxHeightSlider={`calc(100vh - ${isMobile ? this.isLandscape()? 40 : 220 : 40}px)`}
                        headerScroll={
                            <React.Fragment>
                                <h2 className={'content-title'}>{t('PRODUCTS')}</h2>
                                <div className="btn-wrap">
                                    <Button
                                        type="button"
                                        color={'primary'}
                                        onClick={this.addItem}
                                    >{this.props.t('ADD_PRODUCT')}
                                    </Button>
                                    <Button
                                        type="button"
                                        color={'primary'}
                                        onClick={this.addCategory}
                                    >{t('ADD_CATEGORY')}
                                    </Button>
                                </div>
                            </React.Fragment>
                        }
                        bodyStyle={{
                            paddingTop: 0,
                        }}
                    >
                        <DraggableList
                            droppable={category ? [...this.filterFunctionCategory(category)] : []}
                            draggableUnsorted={[...this.filterFunctionProduct(this.nonCategoryFilter(products))]}
                            draggable={category ? [...this.filterFunctionCategory(category)] : []}
                            addInput={this.state.addInput}
                            option={this.state.option}
                            addCategory={this.state.addCategory}
                            onDragEnd={this.onDragEndStates}
                            table={'products'}
                        />
                    </ScrollContainer>
                </div>
                {this.state.confirm && Boolean(this.state.confirmName) && <ConfirmDialog
                    heading={t(this.state.confirmName === 'item' ? 'ADD_PRODUCT_Q' : 'ADD_CATEGORY_Q')}
                    onAccept={this.state.confirmName && this.state.confirmName === 'item' ?
                        this.onConfirmCreateProduct : this.onConfirmCreateCategory}
                    onClose={this.handleCancel}
                    open={this.state.confirm && Boolean(this.state.confirmName)}
                                                                          />}
                {this.state.confirm && Boolean(this.state.typeItem) && <ConfirmDialog
                    heading={t(this.state.typeItem === 'category' ? 'CHANGE_CATEGORY_Q' : 'CHANGE_PRODUCT_Q')}
                    onAccept={this.onEditAction}
                    onClose={this.handleCancelEditAction}
                    open={this.state.confirm && Boolean(this.state.typeItem)}
                                                                       />}
                {this.state.removeItem !== null &&
                <DeleteDialog
                    open
                    removeId={this.state.removeItem ? this.state.removeItem.id : null}
                    heading={this.state.typeItem === 'category' ?
                        t('DELETE_CATEGORY_Q', { name: this.state.removeItem?.name })
                        :
                        t('DELETE_PRODUCT_Q', { name: this.state.removeItem?.name })}
                    body={this.state.typeItem === 'category' ? this.props.t('THIS_ACTION_WILL_DELETE_CATEGORY_AND_CANNOT_BE_UNDONE') : this.props.t('THIS_ACTION_WILL_DELETE_PRODUCT_AND_CANNOT_BE_UNDONE')}
                    onAccept={this.removeItem}
                    onClose={this.onDeleteItemDialogClose}
                />}
            </React.Fragment>

        );
    }

}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {
    const { products, errors } = state.product,
        { category, refresh } = state.productCategory;
    return {
        products,
        category,
        errors,
        refresh,
    };
};
/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    loadProduct: ProductActions.list,
    storeProduct: ProductActions.store,
    updateProduct: ProductActions.update,
    removeProduct: ProductActions.remove,
    loadCategoryProduct: ProductCategoryActions.list,
    storeCategoryProduct: ProductCategoryActions.store,
    updateCategoryProduct: ProductCategoryActions.update,
    removeCategoryProduct: ProductCategoryActions.remove,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Products));