

/**
 * Draw comment image.
 *
 * @param {CanvasRenderingContext2D} cx
 * @param {number} x
 * @param {number} y
 */
export const commentCanvasPath = (cx:CanvasRenderingContext2D,  x: number, y: number)=> {
    cx.moveTo(x,y);
    cx.fillStyle='rgba(0,0,0,0)';
    cx.fillRect(x, y, 1, 1);
    cx.fillRect(1 + x, y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.008)';
    cx.fillRect(2 + x, y, 1, 1);
    cx.fillRect(3 + x, y, 1, 1);
    cx.fillRect(4 + x, y, 1, 1);
    cx.fillRect(5 + x, y, 1, 1);
    cx.fillRect(6 + x, y, 1, 1);
    cx.fillRect(7 + x, y, 1, 1);
    cx.fillRect(8 + x, y, 1, 1);
    cx.fillRect(9 + x, y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0)';
    cx.fillRect(10 + x, y, 1, 1);
    cx.fillRect(11 + x, y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.016)';
    cx.fillRect(x, 1, 1 + y, 1);
    cx.fillStyle='rgba(245,229,28,0.717)';
    cx.fillRect(1 + x, 1 + y, 1, 1);
    cx.fillStyle='rgba(248,231,28,1)';
    cx.fillRect(2 + x, 1 + y, 1, 1);
    cx.fillRect(3 + x, 1 + y, 1, 1);
    cx.fillRect(4 + x, 1 + y, 1, 1);
    cx.fillRect(5 + x, 1 + y, 1, 1);
    cx.fillRect(6 + x, 1 + y, 1, 1);
    cx.fillRect(7 + x, 1 + y, 1, 1);
    cx.fillRect(8 + x, 1 + y, 1, 1);
    cx.fillRect(9 + x, 1 + y, 1, 1);
    cx.fillStyle='rgba(245,229,28,0.717)';
    cx.fillRect(10 + x, 1 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.016)';
    cx.fillRect(11 + x, 1 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.055)';
    cx.fillRect(x, 2, 1 + y, 1);
    cx.fillStyle='rgba(248,231,28,1)';
    cx.fillRect(1 + x, 2 + y, 1, 1);
    cx.fillRect(2 + x, 2 + y, 1, 1);
    cx.fillRect(3 + x, 2 + y, 1, 1);
    cx.fillRect(4 + x, 2 + y, 1, 1);
    cx.fillRect(5 + x, 2 + y, 1, 1);
    cx.fillRect(6 + x, 2 + y, 1, 1);
    cx.fillRect(7 + x, 2 + y, 1, 1);
    cx.fillRect(8 + x, 2 + y, 1, 1);
    cx.fillRect(9 + x, 2 + y, 1, 1);
    cx.fillRect(10 + x, 2 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.055)';
    cx.fillRect(11 + x, 2 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.087)';
    cx.fillRect(x, 3, 1 + y, 1);
    cx.fillStyle='rgba(248,231,28,1)';
    cx.fillRect(1 + x, 3 + y, 1, 1);
    cx.fillRect(2 + x, 3 + y, 1, 1);
    cx.fillRect(3 + x, 3 + y, 1, 1);
    cx.fillRect(4 + x, 3 + y, 1, 1);
    cx.fillRect(5 + x, 3 + y, 1, 1);
    cx.fillRect(6 + x, 3 + y, 1, 1);
    cx.fillRect(7 + x, 3 + y, 1, 1);
    cx.fillRect(8 + x, 3 + y, 1, 1);
    cx.fillRect(9 + x, 3 + y, 1, 1);
    cx.fillRect(10 + x, 3 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.087)';
    cx.fillRect(11 + x, 3 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.11)';
    cx.fillRect(x, 4 + y, 1, 1);
    cx.fillStyle='rgba(248,231,28,1)';
    cx.fillRect(1 + x, 4 + y, 1, 1);
    cx.fillRect(2 + x, 4 + y, 1, 1);
    cx.fillRect(3 + x, 4 + y, 1, 1);
    cx.fillRect(4 + x, 4 + y, 1, 1);
    cx.fillRect(5 + x, 4 + y, 1, 1);
    cx.fillRect(6 + x, 4 + y, 1, 1);
    cx.fillRect(7 + x, 4 + y, 1, 1);
    cx.fillRect(8 + x, 4 + y, 1, 1);
    cx.fillRect(9 + x, 4 + y, 1, 1);
    cx.fillRect(10 + x, 4 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.11)';
    cx.fillRect(11 + x, 4 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.118)';
    cx.fillRect(x, 5 + y, 1, 1);
    cx.fillStyle='rgba(248,231,28,1)';
    cx.fillRect(1 + x, 5 + y, 1, 1);
    cx.fillRect(2 + x, 5 + y, 1, 1);
    cx.fillRect(3 + x, 5 + y, 1, 1);
    cx.fillRect(4 + x, 5 + y, 1, 1);
    cx.fillRect(5 + x, 5 + y, 1, 1);
    cx.fillRect(6 + x, 5 + y, 1, 1);
    cx.fillRect(7 + x, 5 + y, 1, 1);
    cx.fillRect(8 + x, 5 + y, 1, 1);
    cx.fillRect(9 + x, 5 + y, 1, 1);
    cx.fillRect(10 + x, 5 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.118)';
    cx.fillRect(11 + x, 5 + y, 1, 1);
    cx.fillRect(x, 6 + y, 1, 1);
    cx.fillStyle='rgba(248,231,28,1)';
    cx.fillRect(1 + x, 6 + y, 1, 1);
    cx.fillRect(2 + x, 6 + y, 1, 1);
    cx.fillRect(3 + x, 6 + y, 1, 1);
    cx.fillRect(4 + x, 6 + y, 1, 1);
    cx.fillRect(5 + x, 6 + y, 1, 1);
    cx.fillRect(6 + x, 6 + y, 1, 1);
    cx.fillRect(7 + x, 6 + y, 1, 1);
    cx.fillRect(8 + x, 6 + y, 1, 1);
    cx.fillRect(9 + x, 6 + y, 1, 1);
    cx.fillRect(10 + x, 6 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.118)';
    cx.fillRect(11 + x, 6 + y, 1, 1);
    cx.fillRect(x, 7 + y, 1, 1);
    cx.fillStyle='rgba(248,231,28,1)';
    cx.fillRect(1 + x, 7 + y, 1, 1);
    cx.fillRect(2 + x, 7 + y, 1, 1);
    cx.fillRect(3 + x, 7 + y, 1, 1);
    cx.fillRect(4 + x, 7 + y, 1, 1);
    cx.fillRect(5 + x, 7 + y, 1, 1);
    cx.fillRect(6 + x, 7 + y, 1, 1);
    cx.fillRect(7 + x, 7 + y, 1, 1);
    cx.fillRect(8 + x, 7 + y, 1, 1);
    cx.fillRect(9 + x, 7 + y, 1, 1);
    cx.fillRect(10 + x, 7 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.118)';
    cx.fillRect(11 + x, 7 + y, 1, 1);
    cx.fillRect(x, 8 + y, 1, 1);
    cx.fillStyle='rgba(248,231,28,1)';
    cx.fillRect(1 + x, 8 + y, 1, 1);
    cx.fillRect(2 + x, 8 + y, 1, 1);
    cx.fillRect(3 + x, 8 + y, 1, 1);
    cx.fillRect(4 + x, 8 + y, 1, 1);
    cx.fillRect(5 + x, 8 + y, 1, 1);
    cx.fillRect(6 + x, 8 + y, 1, 1);
    cx.fillRect(7 + x, 8 + y, 1, 1);
    cx.fillRect(8 + x, 8 + y, 1, 1);
    cx.fillRect(9 + x, 8 + y, 1, 1);
    cx.fillStyle='rgba(228,212,26,0.772)';
    cx.fillRect(10 + x, 8 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.118)';
    cx.fillRect(11 + x, 8 + y, 1, 1);
    cx.fillRect(x, 9 + y, 1, 1);
    cx.fillStyle='rgba(248,231,28,1)';
    cx.fillRect(1 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(196,181,23,0.638)';
    cx.fillRect(2 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.291)';
    cx.fillRect(3 + x, 9 + y, 1, 1);
    cx.fillRect(4 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.283)';
    cx.fillRect(5 + x, 9 + y, 1, 1);
    cx.fillRect(6 + x, 9 + y, 1, 1);
    cx.fillRect(7 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.276)';
    cx.fillRect(8 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.252)';
    cx.fillRect(9 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.181)';
    cx.fillRect(10 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.087)';
    cx.fillRect(11 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.102)';
    cx.fillRect(x, 10 + y, 1, 1);
    cx.fillStyle='rgba(211,196,25,0.591)';
    cx.fillRect(1 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.205)';
    cx.fillRect(2 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.181)';
    cx.fillRect(3 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.173)';
    cx.fillRect(4 + x, 10 + y, 1, 1);
    cx.fillRect(5 + x, 10 + y, 1, 1);
    cx.fillRect(6 + x, 10 + y, 1, 1);
    cx.fillRect(7 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.165)';
    cx.fillRect(8 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.142)';
    cx.fillRect(9 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.102)';
    cx.fillRect(10 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.047)';
    cx.fillRect(11 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.063)';
    cx.fillRect(x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.118)';
    cx.fillRect(1 + x, 11 + y, 1, 1);
    cx.fillRect(2 + x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.094)';
    cx.fillRect(3 + x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.087)';
    cx.fillRect(4 + x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.079)';
    cx.fillRect(5 + x, 11 + y, 1, 1);
    cx.fillRect(6 + x, 11 + y, 1, 1);
    cx.fillRect(7 + x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.071)';
    cx.fillRect(8 + x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.063)';
    cx.fillRect(9 + x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.039)';
    cx.fillRect(10 + x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.024)';
    cx.fillRect(11 + x, 11 + y, 1, 1);
};



/**
 * Draw unread image
 *
 * @param {CanvasRenderingContext2D} cx
 * @param {number} x
 * @param {number} y
 */
export const unreadCanvasPath = (cx: CanvasRenderingContext2D, x: number, y: number) => {
    cx.moveTo(x, y);
    cx.fillStyle='rgba(0,0,0,0)';
    cx.fillRect(x, y, 1, 1);
    cx.fillRect(1 + x, y, 1, 1);
    cx.fillRect(2 + x, y, 1, 1);
    cx.fillRect(3 + x, y, 1, 1);
    cx.fillRect(4 + x, y, 1, 1);
    cx.fillRect(5 + x, y, 1, 1);
    cx.fillRect(6 + x, y, 1, 1);
    cx.fillRect(7 + x, y, 1, 1);
    cx.fillRect(8 + x, y, 1, 1);
    cx.fillRect(9 + x, y, 1, 1);
    cx.fillRect(10 + x, y, 1, 1);
    cx.fillRect(11 + x, y, 1, 1);
    cx.fillRect(x, 1+ y, 1, 1);
    cx.fillRect(1 + x, 1 + y, 1, 1);
    cx.fillRect(2 + x, 1 + y, 1, 1);
    cx.fillRect(3 + x, 1 + y, 1, 1);
    cx.fillRect(4 + x, 1 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.008)';
    cx.fillRect(5 + x, 1 + y, 1, 1);
    cx.fillRect(6 + x, 1 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0)';
    cx.fillRect(7 + x, 1 + y, 1, 1);
    cx.fillRect(8 + x, 1 + y, 1, 1);
    cx.fillRect(9 + x, 1 + y, 1, 1);
    cx.fillRect(10 + x, 1 + y, 1, 1);
    cx.fillRect(11 + x, 1 + y, 1, 1);
    cx.fillRect(x, 2 + y, 1, 1);
    cx.fillRect(1 + x, 2 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.008)';
    cx.fillRect(2 + x, 2 + y, 1, 1);
    cx.fillStyle='rgba(215,215,215,0.126)';
    cx.fillRect(3 + x, 2 + y, 1, 1);
    cx.fillStyle='rgba(249,245,245,0.685)';
    cx.fillRect(4 + x, 2 + y, 1, 1);
    cx.fillStyle='rgba(254,248,248,0.937)';
    cx.fillRect(5 + x, 2 + y, 1, 1);
    cx.fillRect(6 + x, 2 + y, 1, 1);
    cx.fillStyle='rgba(249,245,245,0.685)';
    cx.fillRect(7 + x, 2 + y, 1, 1);
    cx.fillStyle='rgba(215,215,215,0.126)';
    cx.fillRect(8 + x, 2 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.008)';
    cx.fillRect(9 + x, 2 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0)';
    cx.fillRect(10 + x, 2 + y, 1, 1);
    cx.fillRect(11 + x, 2 + y, 1, 1);
    cx.fillRect(x, 3 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.008)';
    cx.fillRect(1 + x, 3 + y, 1, 1);
    cx.fillStyle='rgba(197,197,197,0.134)';
    cx.fillRect(2 + x, 3 + y, 1, 1);
    cx.fillStyle='rgba(253,248,248,0.945)';
    cx.fillRect(3 + x, 3 + y, 1, 1);
    cx.fillStyle='rgba(255,164,158,0.984)';
    cx.fillRect(4 + x, 3 + y, 1, 1);
    cx.fillStyle='rgba(255,98,89,1)';
    cx.fillRect(5 + x, 3 + y, 1, 1);
    cx.fillRect(6 + x, 3 + y, 1, 1);
    cx.fillStyle='rgba(255,164,158,0.984)';
    cx.fillRect(7 + x, 3 + y, 1, 1);
    cx.fillStyle='rgba(253,248,248,0.945)';
    cx.fillRect(8 + x, 3 + y, 1, 1);
    cx.fillStyle='rgba(197,197,197,0.134)';
    cx.fillRect(9 + x, 3 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.008)';
    cx.fillRect(10 + x, 3 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0)';
    cx.fillRect(11 + x, 3 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.008)';
    cx.fillRect(x, 4 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.031)';
    cx.fillRect(1 + x, 4 + y, 1, 1);
    cx.fillStyle='rgba(242,238,238,0.701)';
    cx.fillRect(2 + x, 4 + y, 1, 1);
    cx.fillStyle='rgba(255,164,158,0.984)';
    cx.fillRect(3 + x, 4 + y, 1, 1);
    cx.fillStyle='rgba(255,81,72,1)';
    cx.fillRect(4 + x, 4 + y, 1, 1);
    cx.fillRect(5 + x, 4 + y, 1, 1);
    cx.fillRect(6 + x, 4 + y, 1, 1);
    cx.fillRect(7 + x, 4 + y, 1, 1);
    cx.fillStyle='rgba(255,164,158,0.984)';
    cx.fillRect(8 + x, 4 + y, 1, 1);
    cx.fillStyle='rgba(244,239,239,0.701)';
    cx.fillRect(9 + x, 4 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.031)';
    cx.fillRect(10 + x, 4 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.008)';
    cx.fillRect(11 + x, 4 + y, 1, 1);
    cx.fillRect(x, 5 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.063)';
    cx.fillRect(1 + x, 5 + y, 1, 1);
    cx.fillStyle='rgba(253,247,247,0.945)';
    cx.fillRect(2 + x, 5 + y, 1, 1);
    cx.fillStyle='rgba(255,98,89,1)';
    cx.fillRect(3 + x, 5 + y, 1, 1);
    cx.fillStyle='rgba(255,81,72,1)';
    cx.fillRect(4 + x, 5 + y, 1, 1);
    cx.fillRect(5 + x, 5 + y, 1, 1);
    cx.fillRect(6 + x, 5 + y, 1, 1);
    cx.fillRect(7 + x, 5 + y, 1, 1);
    cx.fillStyle='rgba(255,98,89,1)';
    cx.fillRect(8 + x, 5 + y, 1, 1);
    cx.fillStyle='rgba(253,247,247,0.945)';
    cx.fillRect(9 + x, 5 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.063)';
    cx.fillRect(10 + x, 5 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.008)';
    cx.fillRect(11 + x, 5 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.016)';
    cx.fillRect(x, 6 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.087)';
    cx.fillRect(1 + x, 6 + y, 1, 1);
    cx.fillStyle='rgba(252,245,245,0.945)';
    cx.fillRect(2 + x, 6 + y, 1, 1);
    cx.fillStyle='rgba(255,98,89,1)';
    cx.fillRect(3 + x, 6 + y, 1, 1);
    cx.fillStyle='rgba(255,81,72,1)';
    cx.fillRect(4 + x, 6 + y, 1, 1);
    cx.fillRect(5 + x, 6 + y, 1, 1);
    cx.fillRect(6 + x, 6 + y, 1, 1);
    cx.fillRect(7 + x, 6 + y, 1, 1);
    cx.fillStyle='rgba(255,98,89,1)';
    cx.fillRect(8 + x, 6 + y, 1, 1);
    cx.fillStyle='rgba(252,245,245,0.945)';
    cx.fillRect(9 + x, 6 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.087)';
    cx.fillRect(10 + x, 6 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.016)';
    cx.fillRect(11 + x, 6 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.024)';
    cx.fillRect(x, 7 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.094)';
    cx.fillRect(1 + x, 7 + y, 1, 1);
    cx.fillStyle='rgba(229,225,225,0.74)';
    cx.fillRect(2 + x, 7 + y, 1, 1);
    cx.fillStyle='rgba(254,163,158,0.992)';
    cx.fillRect(3 + x, 7 + y, 1, 1);
    cx.fillStyle='rgba(255,81,72,1)';
    cx.fillRect(4 + x, 7 + y, 1, 1);
    cx.fillRect(5 + x, 7 + y, 1, 1);
    cx.fillRect(6 + x, 7 + y, 1, 1);
    cx.fillRect(7 + x, 7 + y, 1, 1);
    cx.fillStyle='rgba(254,163,158,0.992)';
    cx.fillRect(8 + x, 7 + y, 1, 1);
    cx.fillStyle='rgba(229,225,225,0.74)';
    cx.fillRect(9 + x, 7 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.094)';
    cx.fillRect(10 + x, 7 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.024)';
    cx.fillRect(11 + x, 7 + y, 1, 1);
    cx.fillRect(x, 8 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.087)';
    cx.fillRect(1 + x, 8 + y, 1, 1);
    cx.fillStyle='rgba(97,97,97,0.276)';
    cx.fillRect(2 + x, 8 + y, 1, 1);
    cx.fillStyle='rgba(250,245,245,0.953)';
    cx.fillRect(3 + x, 8 + y, 1, 1);
    cx.fillStyle='rgba(254,163,158,0.992)';
    cx.fillRect(4 + x, 8 + y, 1, 1);
    cx.fillStyle='rgba(255,98,89,1)';
    cx.fillRect(5 + x, 8 + y, 1, 1);
    cx.fillRect(6 + x, 8 + y, 1, 1);
    cx.fillStyle='rgba(254,163,158,0.992)';
    cx.fillRect(7 + x, 8 + y, 1, 1);
    cx.fillStyle='rgba(250,245,245,0.953)';
    cx.fillRect(8 + x, 8 + y, 1, 1);
    cx.fillStyle='rgba(97,97,97,0.276)';
    cx.fillRect(9 + x, 8 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.087)';
    cx.fillRect(10 + x, 8 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.024)';
    cx.fillRect(11 + x, 8 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.016)';
    cx.fillRect(x, 9 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.063)';
    cx.fillRect(1 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.142)';
    cx.fillRect(2 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(85,85,85,0.315)';
    cx.fillRect(3 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(221,217,217,0.772)';
    cx.fillRect(4 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(250,243,243,0.953)';
    cx.fillRect(5 + x, 9 + y, 1, 1);
    cx.fillRect(6 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(221,217,217,0.772)';
    cx.fillRect(7 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(85,85,85,0.315)';
    cx.fillRect(8 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.142)';
    cx.fillRect(9 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.063)';
    cx.fillRect(10 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.016)';
    cx.fillRect(11 + x, 9 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.008)';
    cx.fillRect(x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.031)';
    cx.fillRect(1 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.087)';
    cx.fillRect(2 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.157)';
    cx.fillRect(3 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.22)';
    cx.fillRect(4 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.252)';
    cx.fillRect(5 + x, 10 + y, 1, 1);
    cx.fillRect(6 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.22)';
    cx.fillRect(7 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.157)';
    cx.fillRect(8 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.087)';
    cx.fillRect(9 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.031)';
    cx.fillRect(10 + x, 10 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.008)';
    cx.fillRect(11 + x, 10 + y, 1, 1);
    cx.fillRect(x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.016)';
    cx.fillRect(1 + x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.039)';
    cx.fillRect(2 + x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.079)';
    cx.fillRect(3 + x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.118)';
    cx.fillRect(4 + x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.142)';
    cx.fillRect(5 + x, 11 + y, 1, 1);
    cx.fillRect(6 + x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.118)';
    cx.fillRect(7 + x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.079)';
    cx.fillRect(8 + x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.039)';
    cx.fillRect(9 + x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.016)';
    cx.fillRect(10 + x, 11 + y, 1, 1);
    cx.fillStyle='rgba(0,0,0,0.008)';
    cx.fillRect(11 + x, 11 + y, 1, 1);
};
