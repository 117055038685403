import { trackPromise } from 'react-promise-tracker';
import ApiProvider from '../providers/apiProvider';
import { IData, IOrder } from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Service to work with process API resources
 *
 * @class ProcessService
 */
export class ProcessService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/processes(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return ['name'];
    }

    /**
     * Get list of processes
     *
     * @params {string} search A search string
     * @params {IOrder} order An order params
     *
     * @return {Promise<Object>}
     */
    list(search: string, order: IOrder): Promise<AxiosResponse> {

        return this.http
            .get(this.url(), {
                params: this.prepareListParams(search, order),
                headers: this.headers,
            });
    }

    /**
     * Bulk processes update
     *
     * @param {IData[]} processes
     *
     * @return {Promise<AxiosResponse<T>>}
     */
    bulkUpdate(processes: IData[]): Promise<AxiosResponse> {

        return this.http
            .patch(this.url() + '/bulk', processes, {
                headers: this.headers,
            });
    }
}
